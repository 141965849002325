import ServicesHelper from "@app/services/serviceshelper";

export interface Image {
    id: number,
    is_public: boolean,
    created_at: number,
    thumbnail_uri?: string,
    image_uri: string,
}

interface RecipeImagesResponse {
    images: Image[]
}

export class ImagesService {

    private uri: string;
    private servicesHelper: ServicesHelper

    public constructor(uri: string, servicesHelper: ServicesHelper) {
        this.uri = uri;
        this.servicesHelper = servicesHelper;
    }

    public uploadRecipeImage(recipeId: number, image: File): Promise<Response> {
        const formData = new FormData()
        formData.append("imageFile", image, image.name);
        return this.servicesHelper.do_post_with_formdata<Response>(`${this.uri}recipes/${recipeId}`, formData);
    }

    public getRecipeImages(recipeId: number): Promise<RecipeImagesResponse> {
        return this.servicesHelper.do_get<RecipeImagesResponse>(`${this.uri}recipes/${recipeId}`);
    }

    public uploadProfileAvatar(avatarFile: File): Promise<Response> {
        const formData = new FormData()
        formData.append("avatarFile", avatarFile, avatarFile.name);
        return this.servicesHelper.do_post_with_formdata<Response>(`${this.uri}avatars`, formData);
    }
 }
