import AwesomeDebouncePromise from "awesome-debounce-promise";
import * as React from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { TrashFill } from "react-bootstrap-icons";

import FormControlComponent from "@app/components/common/FormControlComponent";
import { FieldValidation, FieldValidations } from "@app/models/fields";
import { Hop } from "@app/models/hop";
import { HopAddition, HopForm, HopUse, getHopAdditionTime, hopFriendlyFormString, hopFriendlyUseString } from "@app/models/recipe";
import ServicesHelper from "@app/services/serviceshelper";
import { calculateIBU } from "@app/utils/recipe_calculator";



interface Props {
    recipeId: number,
    hopAddition: HopAddition,
    recipeAvgGravity: number,
    recipeBatchSize: number,
    onChange: (hopAddition: HopAddition) => void,
    onDelete: (hopAdditionId: number) => void,
    totalHops: number,
}

interface State {
    hopName: string,
    showHopSuggestion: boolean,
    hopSuggestions: Hop[],
    invalidFields: FieldValidations,
    hopTimeValue: string,
    hopTemperature: string,
}

const getHops = (value: string) => {
    return ServicesHelper.instance().ingredients().getHops(value).then(resp => {
        return resp.hops;
    }, err => { throw err; })
  };

const getHopsDebounced = AwesomeDebouncePromise(
    getHops,
    800,
    { key: (value: string) => "HopName" },
);

const saveAlphaAcid = (recipeId: number, hopAdditionId: number, alphaAcid: number) => {
    return ServicesHelper.instance().recipes().updateRecipeHopAdditionAlphaAcid(recipeId, hopAdditionId, alphaAcid);
};

const saveAlphaAcidDebounced = AwesomeDebouncePromise(
    saveAlphaAcid,
    800,
    { key: (recipeId: number, hopAdditionId: number, alphaAcid: number) => `AlphaAcid_${hopAdditionId}` },
);

const saveAmount = (recipeId: number, hopAdditionId: number, amount: number) => {
    return ServicesHelper.instance().recipes().updateRecipeHopAdditionAmount(recipeId, hopAdditionId, amount);
};

const saveAmountDebounced = AwesomeDebouncePromise(
    saveAmount,
    800,
    { key: (recipeId: number, hopAdditionId: number, amount: number) => `Amount_${hopAdditionId}` },
);

class EditHopComponent extends React.Component<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            showHopSuggestion: false,
            hopSuggestions: [],
            hopName: props.hopAddition.hop.name,
            invalidFields: {},
            hopTimeValue: this.getHopTime(),
            hopTemperature: props.hopAddition.temperature?.toFixed() ?? "",
        };
    }

    public fieldValueIsAFloat(fieldName: string, fieldValue: string) : number | undefined {
        let tmpFieldValue = fieldValue;

        if(tmpFieldValue.indexOf(",") !== -1 && !tmpFieldValue.endsWith(",")) {
            tmpFieldValue = tmpFieldValue.replace(",", ".");
        }

        const value = Number(tmpFieldValue);

        if (isNaN(value)) {
            const invalidFields = this.state.invalidFields ?? {};
            invalidFields[fieldName] = { message: `${tmpFieldValue} is not a Number`, currentValue: tmpFieldValue }
            this.setState({
                ...this.state,
                invalidFields,
            })
            return undefined;
        }

        return value;
    }

    public fieldValueIsAnInt(fieldName: string, fieldValue: string) : number | undefined {
        const value = Number(fieldValue);
        if (!Number.isInteger(value)) {
            const invalidFields = this.state.invalidFields ?? {};
            invalidFields[fieldName] = { message: `${fieldValue} is not an Integer`, currentValue: fieldValue }
            this.setState({
                ...this.state,
                invalidFields,
            })
            return undefined;
        }

        return value;
    }

    public reduceFieldValidations(fieldName: string) : FieldValidations {
        const newFieldValidations: FieldValidations = {};
        const fieldValidations = this.state.invalidFields ?? {}

        Object.keys(fieldValidations).forEach(key => {
            if (key !== fieldName) {
                newFieldValidations[key] = fieldValidations[key];
            }
        })

        return newFieldValidations;
    }

    public getFieldValidation(fieldName: string) : FieldValidation | undefined {
        if(this.state.invalidFields) {
            if(this.state.invalidFields[fieldName]) {
                return this.state.invalidFields[fieldName];
            }
        }

        return undefined;
    }

    public onChangeHopName = async (evt) => {
        const currentHopName = evt.target.value;

        this.setState({
            ...this.state,
            hopName: currentHopName,
        });

        if(currentHopName !== this.state.hopName) {
            const hops = await getHopsDebounced(currentHopName as string);
            this.setState({
                ...this.state,
                showHopSuggestion: true,
                hopSuggestions: hops,
                hopName: currentHopName,
            });
        }
    }

    public onClickHopSuggestion = (hop: Hop, evt) => {
        this.setState({
            ...this.state,
            showHopSuggestion: false,
            hopName: hop.name,
        });

        const { hopAddition, recipeId } = this.props;

        ServicesHelper.instance()
            .recipes()
            .updateRecipeHopAdditionHop(recipeId, hopAddition.id, hop.id)
            .then(resp => { return; }, err => { throw err; });

        this.props.onChange({
            ...this.props.hopAddition,
            hop,
            alpha_acid: hop.alpha_acid
        })
    }

    public renderHopSuggestions() {
        const { hopSuggestions } = this.state;
        return (<ul className="suggestions-list">
            {hopSuggestions.map(hop => {
                return (<li className="suggestion-item" key={hop.id} onClick={(evt) => this.onClickHopSuggestion(hop, evt)}>{hop.name} - AA {hop.alpha_acid} %</li>);
            })}
        </ul>);
    }

    public onKeyUpHopName = (evt) => {
        if(evt.keyCode === 13) {
            this.setState({
                ...this.state,
                showHopSuggestion: false,
            });

            // todo new hop probably ?
        }
    }

    public onChangeAlphaAcid = async (evt) => {
        const alphaAcid = this.fieldValueIsAFloat("alpha_acid", evt.target.value as string);

        if (alphaAcid === undefined) {
            return;
        }

        const { recipeId, hopAddition } = this.props;

        this.props.onChange({
            ...hopAddition,
            alpha_acid: alphaAcid,
        });

        const invalidFields = this.reduceFieldValidations("alpha_acid");
        this.setState({
            ...this.state,
            invalidFields,
        });

        await saveAlphaAcidDebounced(recipeId, hopAddition.id, alphaAcid);
    }


    public onChangeAmount = async (evt) => {
        const amount = this.fieldValueIsAFloat("hop_amount", evt.target.value as string);

        if (amount === undefined) {
            return;
        }

        const { recipeId, hopAddition } = this.props;

        this.props.onChange({
            ...hopAddition,
            amount,
        });

        const invalidFields = this.reduceFieldValidations("hop_amount");
        this.setState({
            ...this.state,
            invalidFields,
        });

        await saveAmountDebounced(recipeId, hopAddition.id, amount);
    }

    public onChangeHopUse = (evt) => {
        const hopUse = evt.target.value as HopUse;
        const { hopAddition, recipeId } = this.props;

        ServicesHelper.instance()
            .recipes()
            .updateRecipeHopAdditionUse(recipeId, hopAddition.id, hopUse)
            .then(resp => { return; }, err => { throw err; });

        let hopTime = hopAddition.time;
        let temperature = hopAddition.temperature;

        if (hopAddition.use !== HopUse.DryHop && hopUse === HopUse.DryHop && hopTime) {
            hopTime = hopTime * 1440;
        }

        if (hopAddition.use === HopUse.DryHop && hopUse !== HopUse.DryHop && hopTime) {
            hopTime = hopTime / 1440;
        }

        if (hopUse === HopUse.Whirlpool && hopAddition.temperature)
        {
            temperature = 0;
        }

        this.props.onChange({
            ...this.props.hopAddition,
            use: hopUse,
            time: hopTime,
            temperature,
        });
    }

    public onChangeHopForm = (evt) => {
        ServicesHelper.instance()
            .recipes()
            .updateRecipeHopAdditionHopForm(this.props.recipeId, this.props.hopAddition.id, evt.target.value as HopForm)
            .then(resp => { return; }, err => { throw err; });

        this.props.onChange({
            ...this.props.hopAddition,
            form: evt.target.value as HopForm,
        });
    }

    public onClickDeleteButton = (evt) => {
        this.props.onDelete(this.props.hopAddition.id);
    }

    public getHopTime() : string {
        const { hopAddition } = this.props;
        return getHopAdditionTime(hopAddition);
    }

    public onChangeHopTime = (evt) => {
        const hopTime = this.fieldValueIsAnInt("hop_time", evt.target.value as string);

        this.setState({
            ...this.state,
            hopTimeValue: evt.target.value,
        });

        if (hopTime !== undefined) {
            const invalidFields = this.reduceFieldValidations("hop_time");
            this.setState({
                ...this.state,
                invalidFields,
                hopTimeValue: evt.target.value,
            });
        }
    }

    public onChangeHopTemperature = (evt) => {
        const hopTemperature = this.fieldValueIsAnInt("hop_temperature", evt.target.value as string);

        this.setState({
            ...this.state,
            hopTemperature: evt.target.value,
        });

        if (hopTemperature !== undefined) {
            const invalidFields = this.reduceFieldValidations("hop_temperature");
            this.setState({
                ...this.state,
                invalidFields,
                hopTemperature: evt.target.value,
            });
        }
    }

    public onBlurHopTemperature = (evt) => {
        const hopTemperature = this.fieldValueIsAnInt("hop_temperature", evt.target.value as string);

        this.setState({
            ...this.state,
            hopTemperature: evt.target.value,
        });

        if (hopTemperature !== undefined) {
            const invalidFields = this.reduceFieldValidations("hop_temperature");
            this.setState({
                ...this.state,
                invalidFields,
                hopTemperature: evt.target.value,
            });

            this.props.onChange({
                ...this.props.hopAddition,
                temperature: hopTemperature,
            });

            ServicesHelper.instance()
                .recipes()
                .updateRecipeHopAdditionTemperature(this.props.recipeId, this.props.hopAddition.id, hopTemperature)
                .then(resp => { return; }, err => { throw err; });
        }
    }

    public onBlurHopTime = (evt) => {
        let hopTime = this.fieldValueIsAnInt("hop_time", evt.target.value as string);

        const { hopAddition, recipeId } = this.props;

        this.setState({
            ...this.state,
            hopTimeValue: evt.target.value,
        });

        if (hopTime === undefined) {
            return;
        }

        if (this.props.hopAddition.use === HopUse.DryHop) {
            hopTime = hopTime * 1440;
        }

        ServicesHelper.instance()
            .recipes()
            .updateRecipeHopAdditionTime(recipeId, hopAddition.id, hopTime)
            .then(resp => { return; }, err => { throw err; });

        this.props.onChange({
            ...hopAddition,
            time: hopTime,
        });

        const invalidFields = this.reduceFieldValidations("hop_time");
        this.setState({
            ...this.state,
            invalidFields,
        });
    }

    public closeHopSuggestions() {
        this.setState({
            ...this.state,
            showHopSuggestion: false,
            hopSuggestions: [],
        });
    }

    public onBlurHopName = (evt) => {
        setTimeout(() => this.closeHopSuggestions(), 350);
    }

    public render() {
        const { hopAddition, recipeAvgGravity, recipeBatchSize, totalHops } = this.props;
        const { hopName, showHopSuggestion, hopSuggestions, hopTimeValue, hopTemperature } = this.state;

        return (
        <div className="hopFullRow">
            <Row>
                <Col sm="1">
                    Brand:
                </Col>
                <Col sm="2">
                    <Form.Control
                        value={hopName}
                        onChange={this.onChangeHopName}
                        onKeyUp={this.onKeyUpHopName}
                        onBlur={this.onBlurHopName}
                        size="sm"
                    />
                    { showHopSuggestion && hopSuggestions.length > 0 ?
                        this.renderHopSuggestions()
                        : null }
                </Col>
                <Col sm="2">
                    Use:
                    <Form.Select defaultValue={hopAddition.use} onChange={this.onChangeHopUse}>
                        <option value={HopUse.Mash}>{hopFriendlyUseString(HopUse.Mash)}</option>
                        <option value={HopUse.Boil}>{hopFriendlyUseString(HopUse.Boil)}</option>
                        <option value={HopUse.Whirlpool}>{hopFriendlyUseString(HopUse.Whirlpool)}</option>
                        <option value={HopUse.DryHop}>{hopFriendlyUseString(HopUse.DryHop)}</option>
                    </Form.Select>
                </Col>
                <Col sm="3">
                    Time:
                    <FormControlComponent
                        value={hopTimeValue}
                        fieldValidation={this.getFieldValidation("hop_time")}
                        size="sm"
                        onBlur={this.onBlurHopTime}
                        onChange={this.onChangeHopTime}
                        className="quarterFormControl"
                    />
                    {hopAddition.use === HopUse.DryHop ? `days` : `mins`}
                </Col>
                <Col sm="2">
                    Form:
                    <Form.Select className="hopFormControl" defaultValue={hopAddition.form} onChange={this.onChangeHopForm}>
                        <option value={HopForm.Fresh}>{hopFriendlyFormString(HopForm.Fresh)}</option>
                        <option value={HopForm.Pellet}>{hopFriendlyFormString(HopForm.Pellet)}</option>
                        <option value={HopForm.Extract}>{hopFriendlyFormString(HopForm.Extract)}</option>
                    </Form.Select>
                </Col>
            </Row>
            <Row className="hopSubRow">
                <Col className="hopSubCol" sm="2">
                    AA:
                    <FormControlComponent
                        value={hopAddition.alpha_acid}
                        fieldValidation={this.getFieldValidation("alpha_acid")}
                        onChange={this.onChangeAlphaAcid}
                        size="sm"
                        htmlSize={2}
                        className="halfFormControl"
                    />
                    %
                </Col>
                <Col className="hopSubCol" sm="2">
                    Amount:
                    <FormControlComponent
                        value={hopAddition.amount}
                        fieldValidation={this.getFieldValidation("hop_amount")}
                        onChange={this.onChangeAmount}
                        size="sm"
                        className="quarterFormControl"
                    />
                    g
                </Col>
                <Col className="hopSubCol" sm="3">
                    {hopAddition.use === HopUse.Whirlpool ? `Temperature: ` : null}
                    {hopAddition.use === HopUse.Whirlpool ?
                        <FormControlComponent
                            value={hopTemperature}
                            fieldValidation={this.getFieldValidation("hop_temperature")}
                            onChange={this.onChangeHopTemperature}
                            onBlur={this.onBlurHopTemperature}
                            size="sm"
                            htmlSize={4}
                            className="quarterFormControl"
                        />
                    : null}
                    {hopAddition.use === HopUse.Whirlpool ? `°C` : null}
                </Col>
                <Col className="hopSubCol" sm="2">
                    {((hopAddition.use === HopUse.Boil || hopAddition.use === HopUse.Whirlpool) && hopAddition.time > 0) ?
                        `IBU: ${calculateIBU(hopAddition, recipeBatchSize, hopAddition.time, recipeAvgGravity).toFixed(2)}`
                        : null}
                </Col>
                <Col className="hopSubCol" sm="1">
                    {((hopAddition.amount/totalHops)*100.00).toFixed(2)} %
                </Col>
                <Col className="hopSubCol" sm={{offset:1, span:1}}>
                    <Button variant="danger" size="sm" onClick={this.onClickDeleteButton}>
                        <TrashFill color="white" />
                    </Button>
                </Col>
            </Row>
        </div>);
    }

}

export default EditHopComponent;