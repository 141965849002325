import * as React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { PlusCircle } from 'react-bootstrap-icons';

import Button from "@app/components/common/Button";
import PaginationComponent from "@app/components/common/PaginationComponent";
import AddProducerComponent from "@app/components/ingredients/AddProducerComponent";
import EditProducerComponent from "@app/components/ingredients/EditProducerComponent";
import { ProducerItemComponent } from "@app/components/ingredients/ProducerItemComponent";
import { Producer } from "@app/models/producer";
import ServicesHelper from "@app/services/serviceshelper";

import LoadingComponent from "../common/LoadingComponent";

interface ProducersComponentState {
    producers: Producer[],
    totalCount: number,
    isLoading: boolean,
    showDeleteModal: boolean,
    producerToDelete?: Producer,
    showAddModal: boolean,
}

interface Props {
    id?: number,
    action?: string,
}

class ProducersComponent extends React.Component<Props, ProducersComponentState> {

    public constructor(props: Props) {
        super(props)

        this.state = {
            producers: [],
            totalCount: 0,
            isLoading: false,
            showDeleteModal: false,
            showAddModal: false,
        };
    }

    public componentDidMount() {
        this.loadProducers();
    }

    public loadProducers() {
        this.setState({
            ...this.state,
            isLoading: true
        });

        ServicesHelper.instance().ingredients().getProducers().then((resp) => {
            this.setState({
                ...this.state,
                producers: resp.producers,
                totalCount: resp.count,
                isLoading: false
            });
        }, err => { throw err; });
    }

    public componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<ProducersComponentState>, snapshot?: any): void {
        if(prevProps.action !== undefined && (this.props.action === undefined || this.props.action === "browse")) {
            this.loadProducers();
            window.scrollTo(0, 0);
        }
    }

    public onClickShowAddModal = (evt: React.MouseEvent<HTMLButtonElement>) => {
        this.setState({
            ...this.state,
            showAddModal: true,
        });
    }

    public renderAddButton() {
        return (
            <Row>
                <Col>
                    <Button onClick={this.onClickShowAddModal}>
                        <PlusCircle /> Producer
                    </Button>
                </Col>
            </Row>
        );
    }

    public onAddProducer = () => {
        this.loadProducers();
    }

    public onHideProducerModal = () => {
        this.setState({
            ...this.state,
            showAddModal: false,
        });
    }

    public renderProducersTable() {
        const producers = this.state.producers;
        const { showAddModal, totalCount, isLoading } = this.state;
        const currentPage = (this.props.id ?? 1) - 1;
        return (
        <>
            <AddProducerComponent
                show={showAddModal}
                onAdd={this.onAddProducer}
                onHide={this.onHideProducerModal}
            />
            <Container fluid className="tableContainer">
                <Row className="tableHeader">
                    <Col>
                        Name
                    </Col>
                    <Col>
                        Country
                    </Col>
                    <Col>
                        Abbreviation(s)
                    </Col>
                    <Col>
                    </Col>
                </Row>
                {   isLoading ? <Row className="ingredientsRowItem"><LoadingComponent /></Row> :
                    producers.map((producer) => (<ProducerItemComponent key={producer.id} producer={producer} />))
                }
                <Row className="paginationRow">
                    <PaginationComponent
                        numberOfPages={Math.ceil(totalCount / 20)}
                        currentPage={currentPage+1}
                        pageLinkPrefix={`/ingredients/yeasts/browse/`}
                    />
                </Row>
            </Container>
        </>
        );
    }

    public renderContent() {
        const action = this.props.action ?? "browse";
        const producer = this.state.producers.find(_producer => _producer.id === this.props.id);

        switch(action) {
            case "browse":
                return this.renderProducersTable();
            case "edit":
                return (<EditProducerComponent producer={producer} />);
        }

        return (<></>);
    }

    public render() {
        return (
            <>
                <Row>
                    <Col>
                        <h3>Producers</h3>
                    </Col>
                </Row>
                {this.renderAddButton()}
                <Container fluid>
                    {this.renderContent()}
                </Container>
            </>
        );
    }
}

export default ProducersComponent;