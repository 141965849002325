import * as React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { PlusSquare } from "react-bootstrap-icons";

import Button from "@app/components/common/Button";
import LoadingComponent from "@app/components/common/LoadingComponent";
import RecipeRatingFormComponent from "@app/components/recipes/RecipeRatingFormComponent";
import RecipeRatingItemComponent from "@app/components/recipes/RecipeRatingItemComponent";
import RecipeStarRatingComponent from "@app/components/recipes/RecipeStarRatingComponent";
import { RecipeRating } from "@app/models/recipe";
import ServicesHelper from "@app/services/serviceshelper";



interface Props {
    recipeId: number,
    selectedRatingId?: number,
}

interface State {
    isLoading: boolean,
    showAddRatingForm: boolean,
    ratings: RecipeRating[],
    totalCount: number,
    average: number,
    page?: number,
    selectedRating?: RecipeRating,
}

class RecipeRatingsComponent extends React.Component<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            isLoading: true,
            showAddRatingForm: false,
            ratings: [],
            totalCount: 0,
            average: 0,
        }
    }

    private fetchSelectedRating(): void {
        const { recipeId, selectedRatingId } = this.props;

        if(!selectedRatingId) {
            return;
        }

        this.setState({
            ...this.state,
            isLoading: true,
        });

        ServicesHelper.instance().recipes().getRecipeRating(
            recipeId,
            selectedRatingId,
        ).then(
            (resp) => {
                this.setState({
                    ...this.state,
                    selectedRating: resp.rating,
                });
            },
            (err) => { throw err; }
        );
    }

    private fetchRatings(): void {
        const { page } = this.state;
        const { recipeId } = this.props;
        const limit = 10;
        const offset = (page ?? 0) * limit;

        this.setState({
            ...this.state,
            isLoading: true,
        });

        ServicesHelper.instance().recipes().getRecipeRatings(
            recipeId,
            offset,
            limit,
            "true"
        ).then(
            (resp) => {
                this.setState({
                    ...this.state,
                    ratings: resp.ratings,
                    isLoading: false,
                    average: resp.average,
                    totalCount: resp.total_count,
                });
            },
            (err) => { throw err; }
        )
    }

    public componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        if(prevProps.recipeId !== this.props.recipeId || prevState.page !== this.state.page) {
            this.fetchRatings();
        }

        if(this.props.selectedRatingId && prevProps.selectedRatingId !== this.props.selectedRatingId) {
            this.fetchSelectedRating();
        }
    }

    public componentDidMount(): void {
        this.fetchSelectedRating();
        this.fetchRatings();
    }

    public onDeleteRating = (ratingId: number) => {
        this.setState({
            ...this.state,
            ratings: this.state.ratings.filter((rating: RecipeRating) => rating.id !== ratingId)
        });
    }

    public onUpdateRating = (updatedRecipeRating: RecipeRating) => {
        this.setState({
            ...this.state,
            ratings: this.state.ratings.map((recipeRating: RecipeRating) => {
                if(recipeRating.id === updatedRecipeRating.id) {
                    return updatedRecipeRating;
                }

                return recipeRating;
            }),
        });
    }

    public renderRatings() {
        const { ratings, average } = this.state;
        return (
        <>
            <Row>
                <Col sm={{offset: 1, span: 10}}>
                    Average <RecipeStarRatingComponent rating={average} />
                </Col>
            </Row>
            <>
            {
                ratings.length > 0 ? ratings.map((rating: RecipeRating) => {
                    return <RecipeRatingItemComponent
                        key={rating.id}
                        rating={rating}
                        onDelete={this.onDeleteRating}
                        onUpdate={this.onUpdateRating}
                    />
                }) : <Row>No rating yet...</Row>
            }
            </>
        </>)
    }

    public onHideAddForm = () => {
        this.setState({
            ...this.state,
            showAddRatingForm: false,
        });
    }

    public onShowAddForm = () => {
        this.setState({
            ...this.state,
            showAddRatingForm: true,
        });
    }

    public onCreateRating = (recipeRating: RecipeRating) => {
        this.setState({
            ...this.state,
            ratings: [recipeRating, ...this.state.ratings],
        });
    }

    public render() {
        const { recipeId } = this.props;
        const { isLoading, showAddRatingForm, selectedRating } = this.state;
        return(<Container>
            <Row>
            <Button onClick={this.onShowAddForm}>
                <PlusSquare /> Add rating...
            </Button>
            <RecipeRatingFormComponent
                isVisible={showAddRatingForm}
                onHide={this.onHideAddForm}
                onCreate={this.onCreateRating}
                recipeId={recipeId}
            />
            {
                selectedRating ? <RecipeRatingItemComponent
                    rating={selectedRating}
                    onDelete={this.onDeleteRating}
                    onUpdate={this.onUpdateRating}
                /> : null
            }
            </Row>
            { isLoading ? <LoadingComponent /> : this.renderRatings() }
        </Container>)
    }
}

export default RecipeRatingsComponent;