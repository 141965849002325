import * as React from "react";
import { Alert, Col, Form, Modal, Row } from "react-bootstrap";
import { ExclamationTriangle, PlusCircle, Save } from "react-bootstrap-icons";

import SourceWater from "@app/assets/img/ingredients/source_water.svg";
import Button from "@app/components/common/Button";
import FormControlComponent from "@app/components/common/FormControlComponent";
import LoadingComponent from "@app/components/common/LoadingComponent";
import { FieldValidations, fieldValueIsANumber, fieldValueIsAnInteger, getFieldValidation } from "@app/models/fields";
import { AlkalinityType, SourceWaterProfile, WaterProfile } from "@app/models/waterProfile";
import ServicesHelper from "@app/services/serviceshelper";


interface Props {
    sourceWaterProfileId?: number;
    onHide: (sourceWaterProfile?: SourceWaterProfile) => void;
    isVisible: boolean;
}

interface State {
    name: string,
    notes: string,
    calcium: string,
    sulfate: string,
    chloride: string,
    sodium: string,
    magnesium: string,
    ph: string,
    alkalinity: string,
    alkalinityType: AlkalinityType,
    userId?: number,
    isDirty: boolean,
    invalidFields: FieldValidations,
    isLoading: boolean,
}

class EditSourceWaterProfileComponent extends React.Component<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            name: "",
            notes: "",
            calcium: "0",
            sulfate: "0",
            chloride: "0",
            sodium: "0",
            magnesium: "0",
            ph: "7.0",
            alkalinity: "0",
            alkalinityType: AlkalinityType.CaCO3,
            isDirty: false,
            isLoading: false,
            invalidFields: {},
        }
    }

    public UNSAFE_componentWillReceiveProps(nextProps: Readonly<Props>, nextContext: any): void {
        const { sourceWaterProfileId } = this.props;

        if(sourceWaterProfileId !== nextProps.sourceWaterProfileId) {
            this.fetchSourceWaterProfile(nextProps.sourceWaterProfileId);
        }
    }

    public fetchSourceWaterProfile(sourceWaterProfileId: number) {
        this.setState({
            ...this.state,
            isLoading: true,
        })

        ServicesHelper.instance().ingredients().getSourceWaterProfile(sourceWaterProfileId).then(resp => {
            this.setState({
                ...this.state,
                name: resp.source_water_profile.name,
                notes: resp.source_water_profile.notes,
                calcium: resp.source_water_profile.calcium.toFixed(0),
                sulfate: resp.source_water_profile.sulfate.toFixed(0),
                chloride: resp.source_water_profile.chloride.toFixed(0),
                sodium: resp.source_water_profile.sodium.toFixed(0),
                magnesium: resp.source_water_profile.magnesium.toFixed(0),
                ph: resp.source_water_profile.ph.toFixed(1),
                alkalinity: resp.source_water_profile.alkalinity.toFixed(0),
                alkalinityType: resp.source_water_profile.alkalinity_type,
                userId: resp.source_water_profile.user_id,
                isLoading: false,
            })
        }, err => {throw err;})
    }

    public componentDidMount(): void {
        const { sourceWaterProfileId } = this.props;

        if(sourceWaterProfileId) {
            this.fetchSourceWaterProfile(sourceWaterProfileId);
        }
    }

    public onChangeName = (evt: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            ...this.state,
            name: evt.target.value,
            isDirty: true,
        });
    }

    public onChangeNotes = (evt: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            ...this.state,
            notes: evt.target.value,
            isDirty: true,
        });
    }

    public onChangeCalcium = (evt: React.ChangeEvent<HTMLInputElement>) => {
        const { invalidFields } = this.state;
        const validation = fieldValueIsAnInteger(invalidFields, "calcium", evt.target.value)

        this.setState({
            ...this.state,
            invalidFields: validation.fieldValidations,
            calcium: evt.target.value,
            isDirty: true,
        });
    }

    public onChangeSulfate = (evt: React.ChangeEvent<HTMLInputElement>) => {
        const { invalidFields } = this.state;
        const validation = fieldValueIsAnInteger(invalidFields, "sulfate", evt.target.value)

        this.setState({
            ...this.state,
            invalidFields: validation.fieldValidations,
            sulfate: evt.target.value,
            isDirty: true,
        });
    }

    public onChangeChloride = (evt: React.ChangeEvent<HTMLInputElement>) => {
        const { invalidFields } = this.state;
        const validation = fieldValueIsAnInteger(invalidFields, "chloride", evt.target.value)

        this.setState({
            ...this.state,
            invalidFields: validation.fieldValidations,
            chloride: evt.target.value,
            isDirty: true,
        });
    }

    public onChangeSodium = (evt: React.ChangeEvent<HTMLInputElement>) => {
        const { invalidFields } = this.state;
        const validation = fieldValueIsAnInteger(invalidFields, "sodium", evt.target.value)

        this.setState({
            ...this.state,
            invalidFields: validation.fieldValidations,
            sodium: evt.target.value,
            isDirty: true,
        });
    }

    public onChangeMagnesium = (evt: React.ChangeEvent<HTMLInputElement>) => {
        const { invalidFields } = this.state;
        const validation = fieldValueIsAnInteger(invalidFields, "magnesium", evt.target.value)

        this.setState({
            ...this.state,
            invalidFields: validation.fieldValidations,
            magnesium: evt.target.value,
            isDirty: true,
        });
    }

    public onChangePH = (evt: React.ChangeEvent<HTMLInputElement>) => {
        const { invalidFields } = this.state;
        const validation = fieldValueIsANumber(invalidFields, "ph", evt.target.value)

        this.setState({
            ...this.state,
            invalidFields: validation.fieldValidations,
            ph: evt.target.value,
            isDirty: true,
        });
    }

    public onChangeAlkalinity = (evt: React.ChangeEvent<HTMLInputElement>) => {
        const { invalidFields } = this.state;
        const validation = fieldValueIsAnInteger(invalidFields, "alkalinity", evt.target.value)

        this.setState({
            ...this.state,
            invalidFields: validation.fieldValidations,
            alkalinity: evt.target.value,
            isDirty: true,
        });
    }

    public onChangeAlkalinityType = (evt: React.ChangeEvent<HTMLSelectElement>) => {
        this.setState({
            alkalinityType: evt.target.value as AlkalinityType,
            isDirty: true,
        });
    }

    public renderForm() {
        const {
            name,
            notes,
            calcium,
            sulfate,
            chloride,
            sodium,
            magnesium,
            ph,
            alkalinity,
            alkalinityType,
            invalidFields
        } = this.state;

        return (<>
            <Row>
                <Col sm={{span: 3, offset: 1}}>
                    Name:
                </Col>
                <Col sm="4">
                    <FormControlComponent
                        value={name}
                        onChange={this.onChangeName} />
                </Col>
            </Row>
            <Row>
                <Col sm={{span: 3, offset: 1}}>
                    Calcium:
                </Col>
                <Col sm="2">
                    <FormControlComponent
                        value={calcium}
                        onChange={this.onChangeCalcium}
                        fieldValidation={getFieldValidation(invalidFields, "calcium")}
                    />
                </Col>
                <Col sm="1">
                    ppm
                </Col>
            </Row>
            <Row>
                <Col sm={{span: 3, offset: 1}}>
                    Sulfate:
                </Col>
                <Col sm="2">
                    <FormControlComponent
                        value={sulfate}
                        onChange={this.onChangeSulfate}
                        fieldValidation={getFieldValidation(invalidFields, "sulfate")}
                    />
                </Col>
                <Col sm="1">
                    ppm
                </Col>
            </Row>
            <Row>
                <Col sm={{span: 3, offset: 1}}>
                    Chloride:
                </Col>
                <Col sm="2">
                    <FormControlComponent
                        value={chloride}
                        onChange={this.onChangeChloride}
                        fieldValidation={getFieldValidation(invalidFields, "chloride")}
                    />
                </Col>
                <Col sm="1">
                    ppm
                </Col>
            </Row>
            <Row>
                <Col sm={{span: 3, offset: 1}}>
                    Sodium:
                </Col>
                <Col sm="2">
                    <FormControlComponent
                        value={sodium}
                        onChange={this.onChangeSodium}
                        fieldValidation={getFieldValidation(invalidFields, "sodium")}
                    />
                </Col>
                <Col sm="1">
                    ppm
                </Col>
            </Row>
            <Row>
                <Col sm={{span: 3, offset: 1}}>
                    Magnesium:
                </Col>
                <Col sm="2">
                    <FormControlComponent
                        value={magnesium}
                        onChange={this.onChangeMagnesium}
                        fieldValidation={getFieldValidation(invalidFields, "magnesium")}
                    />
                </Col>
                <Col sm="1">
                    ppm
                </Col>
            </Row>
            <Row>
                <Col sm={{span: 3, offset: 1}}>
                        pH:
                </Col>
                <Col sm="3">
                    <FormControlComponent
                        value={ph}
                        onChange={this.onChangePH}
                        fieldValidation={getFieldValidation(invalidFields, "ph")}
                    />
                </Col>
            </Row>
            <Row>
                <Col sm={{span: 3, offset: 1}}>
                    Alkalinity:
                </Col>
                <Col sm="2">
                    <FormControlComponent
                        value={alkalinity}
                        onChange={this.onChangeAlkalinity}
                        fieldValidation={getFieldValidation(invalidFields, "alkalinity")}
                    />
                </Col>
                <Col sm="1">
                    ppm as
                </Col>
                <Col sm="2">
                    <Form.Select defaultValue={alkalinityType} onChange={this.onChangeAlkalinityType}>
                        <option value={AlkalinityType.CaCO3}>CaCO3</option>
                        <option value={AlkalinityType.HCO3}>HCO3</option>
                    </Form.Select>
                </Col>
            </Row>
            <Row>
                <Col sm={{span: 3, offset: 1}}>
                    Notes:
                </Col>
                <Col sm="4">
                    <FormControlComponent
                        value={notes}
                        onChange={this.onChangeNotes} />
                </Col>
            </Row>
        </>);
    }

    public onClickSave = (evt: React.MouseEvent<HTMLButtonElement>) => {
        const {
            name,
            notes,
            calcium,
            sulfate,
            chloride,
            sodium,
            magnesium,
            ph,
            alkalinity,
            alkalinityType,
        } = this.state;

        const sourceWaterProfile: SourceWaterProfile = {
            id: this.props.sourceWaterProfileId,
            name,
            notes,
            calcium: Number.parseInt(calcium, 10),
            sulfate: Number.parseInt(sulfate, 10),
            chloride: Number.parseInt(chloride, 10),
            sodium: Number.parseInt(sodium, 10),
            magnesium: Number.parseInt(magnesium, 10),
            ph: Number.parseFloat(ph),
            alkalinity: Number.parseInt(alkalinity, 10),
            alkalinity_type: alkalinityType,
        };

        if (sourceWaterProfile.name.length === 0) {
            // todo error message
            return;
        }

        if (sourceWaterProfile.id) {
            ServicesHelper.instance().ingredients().updateSourceWaterProfile(sourceWaterProfile).then(resp => {
                this.setState({
                    ...this.state,
                    isDirty: false,
                });

                this.props.onHide(sourceWaterProfile);
            }, err => {throw err;});

        } else {
            ServicesHelper.instance().ingredients().addSourceWaterProfile(sourceWaterProfile).then(resp => {
                this.setState({
                    ...this.state,
                    isDirty: false,
                });

                this.props.onHide({
                    ...sourceWaterProfile,
                    id: resp.source_water_profile_id,
                    user_id: resp.user_id,
                });

            }, err => {throw err;});
        }
    }

    public onClickCancel = (evt: React.MouseEvent<HTMLButtonElement>) => {
        this.props.onHide();
    }

    public render() {
        const { isLoading, isDirty } = this.state
        const { isVisible, sourceWaterProfileId } = this.props;

        return (
        <Modal show={isVisible}>
            <Modal.Header>
                <Modal.Title>
                    <img src={SourceWater} width="40px" height="40px" className="nav-item-icon"/>
                    {sourceWaterProfileId ? `Edit Source Water Profile` : `New Source Water Profile`}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                    isDirty ? (
                        <Row>
                            <Col>
                                <Alert variant="warning">
                                    <ExclamationTriangle />
                                    Current changes aren't saved yet
                                </Alert>
                            </Col>
                        </Row>
                    ) : null
                }
                {
                    isLoading ? <LoadingComponent /> : this.renderForm()
                }
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="grey"
                    onClick={this.onClickCancel}
                >
                    Cancel
                </Button>
                <Button
                    variant="blue"
                    disabled={!isDirty}
                    onClick={this.onClickSave}
                >
                        {sourceWaterProfileId ?
                            (<><Save/> Save</>) : (<><PlusCircle /> Add</>)
                        }
                </Button>
            </Modal.Footer>
        </Modal>);
    }
}

export default EditSourceWaterProfileComponent;

