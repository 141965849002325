import * as React from "react";
import { Col , Row } from "react-bootstrap";
import { Link } from 'react-router-dom';

import { NotificationProps } from "@app/components/notifications/base_notification";
import { RecipeImportedNotificationData } from "@app/models/notifications";


interface State {
    i?: number;
}

class RecipeImportedNotificationComponent extends React.Component<NotificationProps<RecipeImportedNotificationData>, State> {
    public constructor(props: NotificationProps<RecipeImportedNotificationData>) {
        super(props);
    }

    public onClick = (evt: React.MouseEvent<Element>) => {
        this.props.ack(this.props.notification.getId());
    }

    public render() {
        const { data } = this.props;
        return <Row>
            <Col sm={{span: 8, offset: 1}}>
                <Link
                    to={`/recipes/view/${data.recipeId}`}
                    onClick={this.onClick}
                >
                    <strong>{data.recipeName}</strong>
                </Link> was imported.
            </Col>
        </Row>;
    }
}

export default RecipeImportedNotificationComponent;