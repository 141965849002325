import * as React from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { TrashFill } from "react-bootstrap-icons";

import { MeasurementType, getMeasurementTypeUnit } from "@app/models/measurement";
import { RecipeOtherIngredient } from "@app/models/recipe";
import { TimeUsed, friendlyTimeUsedString, getAllTimeUsed } from "@app/models/time_used";
import ServicesHelper from "@app/services/serviceshelper";

import FormControlComponent from "../common/FormControlComponent";

interface Props {
    recipeId: number,
    otherIngredient: RecipeOtherIngredient,
    onChange: (otherIngredient: RecipeOtherIngredient) => void,
    onDelete: (otherIngredientId: number) => void,
}

interface State {
    amount: string;
}

class EditOtherIngredientComponent extends React.Component<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            amount: props.otherIngredient.amount.toFixed(1),
        };
    }

    public onChangeTimeUsed = (evt) => {
        const timeUsed = evt.target.value as TimeUsed;

        ServicesHelper.instance()
            .recipes()
            .updateRecipeOtherIngredientTimeUsed(
                this.props.recipeId,
                this.props.otherIngredient.id,
                timeUsed)
            .then(resp => { return; }, err => { throw err; });

        this.props.onChange({
            ...this.props.otherIngredient,
            time_used: timeUsed,
        });
    }

    public onChangeAmount = (evt) => {
        this.setState({
            ...this.state,
            amount: evt.target.value,
        });
    }

    public onBlurAmount = (evt) => {
        const amount = Number.parseFloat(this.state.amount);
        if (Number.isNaN(amount)) {
            // todo
            return;
        }

        ServicesHelper.instance()
            .recipes()
            .updateRecipeOtherIngredientAmount(this.props.recipeId, this.props.otherIngredient.id, amount)
            .then(resp => { return; }, err => { throw err; });

        this.props.onChange({
            ...this.props.otherIngredient,
            amount,
        });
    }

    public onChangeMeasurementType = (evt) => {
        const measurementType = evt.target.value as MeasurementType;

        ServicesHelper.instance()
            .recipes()
            .updateRecipeOtherIngredientMeasurementType(
                this.props.recipeId,
                this.props.otherIngredient.id,
                measurementType)
            .then(resp => { return; }, err => { throw err; });

        this.props.onChange({
            ...this.props.otherIngredient,
            measurement_type: measurementType,
        });
    }

    public render() {
        const { otherIngredient, onDelete } = this.props;
        return (
            <Row>
                <Col sm={{offset:1, span: 2}}>
                    {otherIngredient.name}
                </Col>
                <Col sm="2">
                    <Form.Select
                        onChange={this.onChangeTimeUsed}
                        defaultValue={otherIngredient.time_used}
                        value={otherIngredient.time_used}
                    >
                    {
                        getAllTimeUsed().map(timeUsed => <option key={timeUsed} value={timeUsed}>{friendlyTimeUsedString(timeUsed)}</option>)
                    }
                    </Form.Select>
                </Col>
                <Col sm="1">
                    Amount:
                </Col>
                <Col sm="1">
                    <FormControlComponent
                            size="sm"
                            value={this.state.amount}
                            onChange={this.onChangeAmount}
                            onBlur={this.onBlurAmount}
                    />
                </Col>
                <Col sm="1">
                    <Form.Select
                        onChange={this.onChangeMeasurementType}
                        defaultValue={otherIngredient.measurement_type}
                        value={otherIngredient.measurement_type}
                    >
                        <option value={MeasurementType.Mass}>{getMeasurementTypeUnit(MeasurementType.Mass)}</option>
                        <option value={MeasurementType.Volume}>{getMeasurementTypeUnit(MeasurementType.Volume)}</option>
                    </Form.Select>
                </Col>
                <Col sm={{offset:1, span:1}}>
                    <Button size="sm" variant="danger" onClick={(evt) => { onDelete(otherIngredient.id); }}>
                        <TrashFill color="white" />
                    </Button>
                </Col>
            </Row>
        );
    }
}

export default EditOtherIngredientComponent;