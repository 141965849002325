import * as React from "react";
import { hot } from "react-hot-loader";
import "@app/assets/scss/App.scss";
import { BrowserRouter as Router } from 'react-router-dom';

import LayoutComponent from "@app/components/LayoutComponent";
import { AuthManager } from "@app/services/authmanager";
import ServicesHelper from "@app/services/serviceshelper";
import { RootStore } from "stores/rootstore";

const rootStore = new RootStore();
const authManager = new AuthManager(rootStore);

ServicesHelper.instance((services: ServicesHelper) => {
    services.setRootStore(rootStore);
    authManager.initiate_authentication(services);
})

class App extends React.Component<Record<string,never>, undefined> {
    public render() {
        return (
            <div className="app">
                <Router>
                    <LayoutComponent store={rootStore}></LayoutComponent>
                </Router>
            </div>
        );
    }
}

declare let module: object;

export default hot(module)(App);
