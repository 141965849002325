import AwesomeDebouncePromise from "awesome-debounce-promise";
import * as React from 'react';
import { Col, Container, Form, Modal, Row } from "react-bootstrap";
import { ArrowLeftCircle, PlusCircle } from "react-bootstrap-icons";
import { LinkContainer } from "react-router-bootstrap";

import Button from "@app/components/common/Button";
import LoadingComponent from "@app/components/common/LoadingComponent";
import NoResultRow from "@app/components/common/NoResultRow";
import PaginationComponent from "@app/components/common/PaginationComponent";
import SortableColumnComponent from "@app/components/common/SortableColumnComponent";
import AddFermentableComponent from "@app/components/ingredients/AddFermentableComponent";
import { FermentableComponent } from "@app/components/ingredients/FermentableComponent";
import { FermentableItemComponent } from "@app/components/ingredients/FermentableItemComponent";
import { Fermentable } from "@app/models/fermentable";
import ServicesHelper from "@app/services/serviceshelper";

import "@app/assets/scss/components/TableCommon.scss";



const getFermentables = (value: string, offset: number, limit: number, sortBy: string) => {
    return ServicesHelper.instance().ingredients().getFermentables(value, offset, limit, sortBy).then(resp => {
        return resp;
    }, err => {throw err;})
  };

const getFermentablesDebounced = AwesomeDebouncePromise(
    getFermentables,
    300,
    { key: (value: string, offset: number, limit: number, sortBy: string) => "FermentableNameFilter" },
);

interface FermentablesComponentState {
    fermentables: Fermentable[],
    totalCount: number,
    isLoading: boolean,
    showDeleteModal: boolean,
    fermentableToDelete?: Fermentable,
    nameFilter: string,
    showAddModal: boolean,
    sortBy: string,
}

interface Props {
    id?: number,
    action?: string,
    isAdmin: boolean,
}

class FermentablesComponent extends React.Component<Props, FermentablesComponentState> {

    public constructor(props: Props) {
        super(props)

        this.state = {
            fermentables: [],
            totalCount: 0,
            isLoading: false,
            showAddModal: false,
            showDeleteModal: false,
            nameFilter: "",
            sortBy: "name",
        }
    }

    public componentDidMount() {
        this.loadFermentables();
    }

    public componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<FermentablesComponentState>, snapshot?: any): void {
        if((prevProps.id !== this.props.id && (this.props.action === "browse" || !this.props.action))
           || prevState.sortBy !== this.state.sortBy
        )
        {
            this.loadFermentables();
            window.scrollTo(0, 0);
        }
    }

    public loadFermentables() {
        this.setState({
            ...this.state,
            isLoading: true
        });

        const currentPage = (this.props.id ?? 1) - 1;
        const { nameFilter, sortBy } = this.state;

        ServicesHelper.instance().ingredients().getFermentables(nameFilter, currentPage * 20, 20, sortBy).then((resp) => {
            this.setState({
                ...this.state,
                fermentables: resp.fermentables,
                totalCount: resp.count,
                isLoading: false,
            })
        }, err => {throw err;});
    }

    public onClickAddFermentable = (evt) => {
        this.setState({
            ...this.state,
            showAddModal: true,
        });
    }

    public renderAddFermentableButton() {
        return (
        <Row>
            <Col>
                <Button onClick={this.onClickAddFermentable}>
                    <PlusCircle /> Fermentable
                </Button>
            </Col>
        </Row>);
    }

    public onChangeNameFilter = async (evt) => {
        const currentPage = 0;
        const { sortBy } = this.state;

        this.setState({
            ...this.state,
            nameFilter: evt.target.value,
        });

        if(evt.target.value.length >= 3) {
            this.setState({
                ...this.state,
                isLoading: true,
                nameFilter: evt.target.value,
            });

            const fermentablesResponse = await getFermentablesDebounced(evt.target.value as string, currentPage * 20, 20, sortBy);

            this.setState({
                ...this.state,
                isLoading: false,
                fermentables: fermentablesResponse.fermentables,
                totalCount: fermentablesResponse.count,
            });

        } else if(evt.target.value.length === 0) {
            this.setState({
                ...this.state,
                nameFilter: evt.target.value,
            }, () => { this.loadFermentables() });
        }
    }

    public onHideAddModal = () => {
        this.setState({
            ...this.state,
            showAddModal: false,
        });
    }

    public onClickSortableColumn = (name: string) => {
        const { sortBy } = this.state;
        let newSortBy: string;

        if(sortBy.endsWith(name)) {
            newSortBy = sortBy.startsWith("-") ? `${name}`: `-${name}`;
        } else {
            newSortBy = name;
        }

        this.setState({
            ...this.state,
            sortBy: newSortBy,
        });
    }

    public renderFermentablesTable() {
        const { fermentables, totalCount, nameFilter, showAddModal, isLoading, sortBy } = this.state;
        const { isAdmin } = this.props;
        const currentPage = (this.props.id ?? 1) - 1

        return (
        <>
            <AddFermentableComponent show={showAddModal} onHide={this.onHideAddModal} />
            <Container fluid className="tableContainer">
                <Row className="filterRow">
                    <Col sm={{offset:1, span:1}}>
                        Name:
                    </Col>
                    <Col sm="3">
                        <Form.Control value={nameFilter} onChange={this.onChangeNameFilter} size="sm" />
                    </Col>
                </Row>
                <Row className="tableHeader">
                    <SortableColumnComponent
                        onClick={this.onClickSortableColumn}
                        span={3}
                        currentSortBy={sortBy}
                        label="Name"
                        name="name"
                    />
                    <SortableColumnComponent
                        onClick={this.onClickSortableColumn}
                        span={2}
                        currentSortBy={sortBy}
                        label="Type"
                        name="type"
                    />
                    <Col sm="1">
                        Lovibond
                    </Col>
                    <Col sm="1">
                        PPG
                    </Col>
                    <Col sm="2">
                        Producer
                    </Col>
                    <Col sm="3">
                        Actions
                    </Col>
                </Row>
                {
                    isLoading ?
                    <Row className="ingredientsRowItem">
                        <LoadingComponent className="loadingRow" />
                    </Row> :
                    (fermentables.length === 0 ?
                        <NoResultRow
                            className="ingredientsRowItem"
                            caption="No fermentable matching..."
                        /> :
                        fermentables.map((fermentable) => (<FermentableItemComponent
                                        key={fermentable.id}
                                        fermentable={fermentable}
                                        onDelete={this.onDeleteFermentable}
                                        canEdit={isAdmin} />))
                        )
                }
                <Row className="paginationRow">
                    <PaginationComponent
                        numberOfPages={Math.ceil(totalCount / 20)}
                        currentPage={currentPage+1}
                        pageLinkPrefix={`/ingredients/fermentables/browse/`}
                    />
                </Row>
            </Container>
        </>
        );

    }

    private renderFermentable(fermentableId: number) {
        return (
            <>
                <Row>
                    <LinkContainer to="/ingredients/fermentables">
                        <Button>
                            <ArrowLeftCircle />
                            Back
                        </Button>
                    </LinkContainer>
                </Row>
                <Row>
                    <FermentableComponent fermentableId={fermentableId} />
                </Row>
            </>
        );
    }

    public renderContent() {
        const action: string = this.props.action ?? "browse";
        const fermentableId: number = this.props.id ?? 0;

        switch(action) {
            case "browse":
                return this.renderFermentablesTable();
            case "view":
                return this.renderFermentable(fermentableId);
        }

        return (<></>);

    }
    public onDeleteFermentable = (fermentable: Fermentable) => {
        this.setState({
            ...this.state,
            showDeleteModal: true,
            fermentableToDelete: fermentable,
        });
    }

    public cancelDeleteFermentable = (evt) => {
        this.closeDeleteModal();
    }

    public deleteFermentableButton = (evt) => {
        const fermentableToDelete = this.state.fermentableToDelete;
        this.closeDeleteModal();

        ServicesHelper.instance().ingredients().deleteFermentable(fermentableToDelete).then(resp => {
            if(resp.status === 200) {
                this.setState({
                    ...this.state,
                    fermentables: this.state.fermentables.filter(fermentable => fermentable.id !== fermentableToDelete.id),
                });
            }
        }, err => {throw err;});
    }

    public closeDeleteModal() {
        this.setState({
            ...this.state,
            showDeleteModal: false,
            fermentableToDelete: undefined,
        });
    }

    public render() {
        const action: string = this.props.action ?? "browse";
        const { fermentableToDelete, showDeleteModal } = this.state;
        return (
            <>
                <Row>
                    <Col>
                        <h3>Fermentables</h3>
                    </Col>
                </Row>
                { action === "browse" ? this.renderAddFermentableButton() : null}
                <Container fluid>
                    {this.renderContent()}
                </Container>
                <Modal show={showDeleteModal}>
                    <Modal.Header>
                        <Modal.Title>Delete fermentable</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Are you sure you want to delete <strong>{fermentableToDelete?.name ?? ""}</strong> fermentable ?</Modal.Body>
                    <Modal.Footer>
                        <Button variant="red" onClick={this.cancelDeleteFermentable}>
                            No
                        </Button>
                        <Button variant="blue" onClick={this.deleteFermentableButton}>
                            Yes
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}

export default FermentablesComponent;