import * as React from "react";
import { Alert, Button, Col, Container, Row } from "react-bootstrap";

import LoadingComponent from "@app/components/common/LoadingComponent";
import JobRowItemComponent from "@app/components/jobs/JobRowItemComponent";
import { Job, JobStatus } from "@app/models/job";
import ServicesHelper from "@app/services/serviceshelper";

interface Props {
    status: JobStatus,
    canAutoRefresh?: boolean,
}

interface State {
    isLoading: boolean,
    autoRefresh: boolean,
    jobs: Job[],
    count: number,
}

class JobsComponent extends React.Component<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            isLoading: true,
            autoRefresh: false,
            jobs: [],
            count: 0,
        };
    }

    public componentDidMount(): void {
        ServicesHelper.instance().jobs().getJobs(
            20, 0, "-created_at", this.props.status,
        ).then(resp => {
            this.setState({
                ...this.state,
                isLoading: false,
                jobs: resp.jobs,
                count: resp.count,
            });

            if(this.state.autoRefresh && this.props.canAutoRefresh) {
                setTimeout(() => { this.fetchJobs() }, 2000);
            }
        }, err => {throw err;});
    }

    public fetchJobs(): void {
        ServicesHelper.instance().jobs().getJobs(
            20, 0, "-created_at", this.props.status,
        ).then(resp => {
            this.setState({
                ...this.state,
                isLoading: false,
                jobs: resp.jobs,
                count: resp.count,
            });

            if(this.state.autoRefresh && this.props.canAutoRefresh) {
                setTimeout(() => { this.fetchJobs() }, 2000);
            }
        }, err => {throw err;});
    }

    public onChangeAutoRefresh = (evt) => {
        if (!this.props.canAutoRefresh) {
            return;
        }

        this.setState({
            ...this.state,
            autoRefresh: !this.state.autoRefresh,
        }, () => {
            if (this.state.autoRefresh) {
                this.fetchJobs();
            }
        });
    }

    public renderJobs() {
        const { jobs, autoRefresh } = this.state;
        const { canAutoRefresh } = this.props;
        return <>
            {
                canAutoRefresh ? (
                <Row>
                    <Col sm={{offset:1, span: 2}}>
                        <Button
                            variant={autoRefresh ? "success" : "danger"}
                            onClick={this.onChangeAutoRefresh}
                            size="sm"
                        >
                            Auto-Refresh: {autoRefresh ? "On" : "Off"}
                        </Button>
                    </Col>
                </Row>) : null
            }
            <Row className="tableHeader">
               <Col sm="4">Type</Col>
               <Col sm="2">Progress</Col>
               <Col sm="2">Status</Col>
               <Col sm="2">Created At</Col>
               <Col sm="2">Updated At</Col>
            </Row>
            { jobs.length === 0 ?
                <Row className="emptyRow">
                    <Col sm={{offset:3, span: 6}}>
                        <Alert variant="warning">No job found...</Alert>
                    </Col>
                </Row> : null
            }
            { jobs.map(job => <JobRowItemComponent key={job.id} job={job} />) }
        </>;
    }

    public render() {
        const { isLoading } = this.state;

        return <Container fluid className="tableContainer">
            { isLoading ? <LoadingComponent /> : this.renderJobs()}
        </Container>;
    }
}

export default JobsComponent;
