export enum TimeUsed {
    Mash = "mash",
    Boil = "boil",
    Fermentation = "fermentation",
    Packaging = "packaging",
}

export const friendlyTimeUsedString = (timeUsed: TimeUsed): string => {
    switch(timeUsed) {
        case TimeUsed.Mash:
            return "Mash";
        case TimeUsed.Boil:
            return "Boil";
        case TimeUsed.Fermentation:
            return "Fermentation";
        case TimeUsed.Packaging:
            return "Packaging";
    }
}

export const getAllTimeUsed = (): TimeUsed[] => {
    return [
        TimeUsed.Mash,
        TimeUsed.Boil,
        TimeUsed.Fermentation,
        TimeUsed.Packaging,
    ];
}