import * as React from "react";
import { Col, ProgressBar, Row } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

import { Job, jobStatusPrettyString } from "@app/models/job";
import { timestampToDateTime } from "@app/utils/date";

interface Props {
    job: Job
}

class JobRowItemComponent extends React.Component<Props, Record<string, never>> {
    public constructor(props: Props) {
        super(props);
    }

    public render() {
        const { job } = this.props;
        return (
        <LinkContainer to={`/jobs/${job.id}`}>
            <Row className="small-text rowItem linkRowItem">
                <Col sm="4">{job.data.name}</Col>
                <Col sm="2">
                    <ProgressBar now={job.data.progress * 100.00} />
                </Col>
                <Col sm="2">{jobStatusPrettyString(job.status)}</Col>
                <Col sm="2" className="small-text">{timestampToDateTime(job.created_at)}</Col>
                <Col sm="2" className="small-text">{timestampToDateTime(job.updated_at)}</Col>
            </Row>
        </LinkContainer>
        );
    }
}

export default JobRowItemComponent;