import * as React from "react";
import { Alert, Col, Container, Form, Modal, Row } from "react-bootstrap";
import { Save } from "react-bootstrap-icons";

import Producer from "@app/assets/img/ingredients/producer.svg";
import Button from "@app/components/common/Button";
import RowFormControlComponent from "@app/components/common/RowFormControlComponent";
import { Country } from "@app/models/producer";
import { ProducerToCreate } from "@app/services/ingredients";
import { BadRequestError } from "@app/services/models";
import ServicesHelper from "@app/services/serviceshelper";

interface Props {
    show: boolean,
    onHide: () => void,
    onAdd: () => void,
}

interface State {
    name: string,
    countries: Country[],
    abbreviations: string,
    countryId: number,
    isSubmitting: boolean,
    isCreated: boolean,
    errors?: string[],
}

class AddProducerComponent extends React.Component<Props, State> {

    public constructor(props: Props) {
        super(props);

        this.state = {
            name: "",
            countries: [],
            countryId: 0,
            abbreviations: "",
            isSubmitting: false,
            isCreated: false,
            errors: [],
        };
    }

    public componentDidMount(): void {
        ServicesHelper.instance().ingredients().getCountries().then(resp => {
            this.setState({
                ...this.state,
                countries: resp.countries,
            });
        }, err => {throw err;});
    }

    public onChangeName = (evt: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            ...this.state,
            name: evt.target.value,
        });
    }

    public onChangeCountry = (evt: React.ChangeEvent<HTMLSelectElement>) => {
        this.setState({
            ...this.state,
            countryId: Number.parseInt(evt.target.value, 10),
        });
    }

    public onChangeAbbreviations = (evt: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            ...this.state,
            abbreviations: evt.target.value,
        });
    }

    public onClickAddProducer = (evt: React.MouseEvent<HTMLButtonElement>) => {
        const { name, countryId, abbreviations } = this.state;
        const errors: string[] = [];

        if(name.length === 0) {
            errors.push("Must specify a name");
        }

        if(errors.length > 0) {
            this.setState({
                ...this.state,
                errors,
            });

            return;
        }

        const producer: ProducerToCreate = {
            name,
            country: countryId,
            abbreviations: abbreviations.split(',')
        };

        this.setState({
            ...this.state,
            errors: [],
            isSubmitting: true,
        });

        ServicesHelper.instance().ingredients().addProducer(producer).then(resp => {
            this.setState({
                ...this.state,
                isSubmitting: false,
                isCreated: true,
            });

            setTimeout(() => {
                this.setState({
                    ...this.state,
                    isSubmitting: false,
                    isCreated: false,
                    errors: [],
                    name: "",
                    abbreviations: "",
                    countryId: 0,
                });
                this.props.onAdd();
                this.props.onHide();
            }, 1500)
        }).catch(error => {
            const badRequest = error as BadRequestError;
            return badRequest;
        }).then(error => {
            if(error === undefined) {
                return;
            }

            const badRequestError = error as BadRequestError;
            this.setState({
                ...this.state,
                isSubmitting: false,
                isCreated: false,
                errors: [...this.state.errors, badRequestError.message],
            });
        }, err => {throw err;});
    }

    public render() {
        const { name, abbreviations, countries, countryId, isSubmitting, isCreated, errors} = this.state;
        const { show, onHide } = this.props;
        const disabled = isSubmitting || isCreated;

        return (
            <Modal show={show}>
                <Modal.Header>
                    <Modal.Title>
                        <img src={Producer} width="40px" height="40px" className="float-icon" />
                        New Producer
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container fluid>
                        <Row>
                            <Col md={{ span:8, offset:2}}>
                            {errors.length > 0 ? (
                                <Alert variant="danger">
                                    <ul>
                                        {errors.map(err => { return (<li key={err}>{err}</li>) })}
                                    </ul>
                                </Alert>
                            ) : null}
                            {isCreated ? <Alert variant="success">
                                            Producer created !
                                        </Alert> : null}
                            </Col>
                        </Row>
                        <RowFormControlComponent
                            label="Name"
                            placeholder="Producer name"
                            value={name}
                            onChange={this.onChangeName}
                            disabled={disabled}
                            labelWidth={3}
                        />
                        <Row>
                            <Col md={{span: 3}}>
                                <Form.Label>
                                    Country
                                </Form.Label>
                            </Col>
                            <Col md="9">
                                <Form.Select
                                    value={countryId}
                                    onChange={this.onChangeCountry}
                                    disabled={disabled ?? false}
                                >
                                    <option value="0">Unknown</option>
                                    <>
                                    { countries.map(country => <option key={country.id} value={country.id}>{country.name}</option>) }
                                    </>
                                </Form.Select>
                            </Col>
                        </Row>
                        <RowFormControlComponent
                            label="Abbreviations"
                            placeholder="Abbreviations, separated by a comma ','"
                            value={abbreviations}
                            onChange={this.onChangeAbbreviations}
                            disabled={disabled}
                            labelWidth={3}
                        />
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="normal" onClick={this.onClickAddProducer} disabled={disabled}>
                        <Save /> Add
                    </Button>
                    <Button variant="red" onClick={onHide}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
            );
    }
}

export default AddProducerComponent;