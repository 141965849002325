import { BrewSession, BrewSessionLogEntry, BrewSessionPhase } from "@app/models/brew_session";
import { BrewingSetup, ChillingMethod, NewBrewingSetup, SetupType } from "@app/models/brewing_setup";
import { PersistedRecipeWithRecipe, RecipeFermentable, RecipeHop, RecipeYeast } from "@app/models/recipe";
import ServicesHelper from "@app/services/serviceshelper";

export interface GenericResponse {
    success: boolean,
}

export interface ImportBrewSessionsResponse extends GenericResponse {
    event_id: string,
}

export interface BrewingSetupsResponse {
    brewing_setups: BrewingSetup[],
}

export interface BrewingSetupResponse {
    brewing_setup: BrewingSetup,
}

export interface CreateBrewingSetupBody {
    brewing_setup: NewBrewingSetup,
}

export interface CreateBrewingSetupResponse {
    brewing_setup: BrewingSetup,
}

export interface BrewingSetupUpdateResponse {
    success: boolean,
    message?: string,
}

export interface UpdateBrewingSetupFloatField {
    value: number,
}

export interface UpdateBrewingSetupTextField {
    value: string,
}

export interface UpdateBrewingSetupBooleanField {
    value: boolean,
}

export interface UpdateBrewingSetupType {
    value: SetupType,
}

export interface UpdateBrewingSetupChillingMethod {
    value: ChillingMethod,
}

export interface CreateBrewSessionBody {
    recipe_id: number,
    brewing_setup_id: number,
    batch_name: string,
}

export interface CreateBrewSessionResponse {
    brew_session_id: number,
}

export interface BrewSessionResponse {
    brew_session: BrewSession,
    recipe: PersistedRecipeWithRecipe,
    brewing_setup: BrewingSetup,
}

export interface BrewSessionsResponse {
    brew_sessions: BrewSession[],
    count: number,
}

export interface AddBrewSessionLogBody {
    brew_session_log: BrewSessionLogEntry,
}

export interface AddBrewSessionLogResponse {
    id: number,
}

export interface UpdateBrewSessionPhaseBody {
    value: BrewSessionPhase,
}

export interface DeleteBrewingSetupResponse {
    success: boolean,
    error?: string,
}

export interface PlannedRecipeIngredientsResponse {
    hops: RecipeHop[],
    fermentables: RecipeFermentable[],
    yeasts: RecipeYeast[],
}

export interface ImportBrewSessionsBody {
    brewers_friend_api_key: string,
    brewing_setup_id: number,
}

export enum BrewingSetupField {
    KettleVolume = "kettle_volume",
    MashTunVolume = "mash_tun_volume",
    KettleLosses = "kettle_losses",
    GrainAbsorption = "grain_absorption",
    BoilEvaporationRate = "boil_evaporation_rate",
    DefaultBoilSize = "default_boil_size",
    DefaultBatchSize = "default_batch_size",
    DefaultEfficiency = "default_efficiency",
}

export class BrewingService {

    private uri: string;
    private servicesHelper: ServicesHelper

    public constructor(uri: string, servicesHelper: ServicesHelper) {
        this.uri = uri;
        this.servicesHelper = servicesHelper;
    }

    public getBrewingSetup(brewingSetupId: number): Promise<BrewingSetupResponse> {
        return this.servicesHelper.do_get<BrewingSetupResponse>(`${this.uri}setups/${brewingSetupId}`);
    }

    public getBrewingSetups(): Promise<BrewingSetupsResponse> {
        return this.servicesHelper.do_get<BrewingSetupsResponse>(`${this.uri}setups/`);
    }

    public deleteBrewingSetup(brewingSetupId: number) : Promise<DeleteBrewingSetupResponse> {
        return this.servicesHelper.do_delete_with_response<DeleteBrewingSetupResponse>(`${this.uri}setups/${brewingSetupId}`);
    }

    public createBrewingSetup(brewingSetup: NewBrewingSetup): Promise<CreateBrewingSetupResponse> {
        return this.servicesHelper.do_post<CreateBrewingSetupResponse, CreateBrewingSetupBody>(`${this.uri}setups/`, {brewing_setup: brewingSetup });
    }

    public updateBrewingSetupField(brewingSetupId: number, brewingSetupField: BrewingSetupField, value: number) : Promise<BrewingSetupUpdateResponse> {
        const route = brewingSetupField as string;
        return this.servicesHelper.do_put<BrewingSetupUpdateResponse, UpdateBrewingSetupFloatField>(`${this.uri}setups/${brewingSetupId}/${route}`, { value});
    }

    public updateBrewingSetupSetupType(brewingSetupId: number, value: SetupType) : Promise<BrewingSetupUpdateResponse> {
        return this.servicesHelper.do_put<BrewingSetupUpdateResponse, UpdateBrewingSetupType>(`${this.uri}setups/${brewingSetupId}/setup_type`, {value});
    }

    public updateBrewingSetupIsDefault(brewingSetupId: number, value: boolean) : Promise<BrewingSetupUpdateResponse> {
        return this.servicesHelper.do_put<BrewingSetupUpdateResponse, UpdateBrewingSetupBooleanField>(`${this.uri}setups/${brewingSetupId}/is_default`, {value});
    }

    public updateBrewingSetupChillingMethod(brewingSetupId: number, value: ChillingMethod) : Promise<BrewingSetupUpdateResponse> {
        return this.servicesHelper.do_put<BrewingSetupUpdateResponse, UpdateBrewingSetupChillingMethod>(`${this.uri}setups/${brewingSetupId}/chilling_method`, {value});
    }

    public updateBrewingSetupName(brewingSetupId: number, value: string) : Promise<BrewingSetupUpdateResponse> {
        return this.servicesHelper.do_put<BrewingSetupUpdateResponse, UpdateBrewingSetupTextField>(`${this.uri}setups/${brewingSetupId}/name`, {value});
    }

    public createBrewSession(recipeId: number, brewingSetupId: number, batchName: string): Promise<CreateBrewSessionResponse> {
        return this.servicesHelper.do_post<CreateBrewSessionResponse, CreateBrewSessionBody>(`${this.uri}brew_sessions/`, {
            recipe_id: recipeId,
            brewing_setup_id: brewingSetupId,
            batch_name: batchName,
        });
    }

    public getBrewSession(brewSessionId: number): Promise<BrewSessionResponse> {
        return this.servicesHelper.do_get<BrewSessionResponse>(`${this.uri}brew_sessions/${brewSessionId}`);
    }

    public getBrewSessions(sort = "-created_at", limit = 10, offset = 0): Promise<BrewSessionsResponse> {
        return this.servicesHelper.do_get<BrewSessionsResponse>(`${this.uri}brew_sessions/mine?limit=${limit}&offset=${offset}&sort=${sort}`);
    }

    public addBrewSessionLog(brewSessionId: number, logEntry: BrewSessionLogEntry): Promise<AddBrewSessionLogResponse> {
        return this.servicesHelper.do_post<AddBrewSessionLogResponse, AddBrewSessionLogBody>(
            `${this.uri}brew_sessions/${brewSessionId}/logs`,
            {
                brew_session_log: logEntry,
            }
        );
    }

    public updateBrewSessionLog(brewSessionId: number, brewLogId: number, logEntry: BrewSessionLogEntry): Promise<GenericResponse> {
        return this.servicesHelper.do_put<GenericResponse, AddBrewSessionLogBody>(
            `${this.uri}brew_sessions/${brewSessionId}/logs/${brewLogId}`,
            {
                brew_session_log: logEntry,
            }
        );
    }

    public deleteBrewSessionLog(brewSessionId: number, brewLogId: number): Promise<Response> {
        return this.servicesHelper.do_delete(`${this.uri}brew_sessions/${brewSessionId}/logs/${brewLogId}`);
    }

    public deleteBrewSession(brewSessionId: number): Promise<Response> {
        return this.servicesHelper.do_delete(`${this.uri}brew_sessions/${brewSessionId}`);
    }

    public updateBrewSessionPhase(brewSessionId: number, phase: BrewSessionPhase): Promise<GenericResponse> {
        return this.servicesHelper.do_put<GenericResponse, UpdateBrewSessionPhaseBody>(`${this.uri}brew_sessions/${brewSessionId}/phase`, {
            value: phase,
        });
    }

    public getPlannedRecipesIngredients(): Promise<PlannedRecipeIngredientsResponse> {
        return this.servicesHelper.do_get<PlannedRecipeIngredientsResponse>(`${this.uri}brew_sessions/planned/ingredients`);
    }

    public importBrewSessions(brewersFriendApiKey: string, brewingSetupId: number): Promise<ImportBrewSessionsResponse> {
        return this.servicesHelper.do_post<ImportBrewSessionsResponse, ImportBrewSessionsBody>(
            `${this.uri}brew_sessions/import`,
            {
                brewers_friend_api_key: brewersFriendApiKey,
                brewing_setup_id: brewingSetupId,
            }
        );
    }
 }
