import { BeerStyle } from "@app/models/beer_style";
import { BrewingSetup } from "@app/models/brewing_setup";
import { Fermentable } from "@app/models/fermentable";
import { Hop } from "@app/models/hop";
import { MeasurementType } from "@app/models/measurement";
import { TimeUsed } from "@app/models/time_used";
import { ProfileAvatar, User } from "@app/models/user";
import { RecipeWaterAdjustor } from "@app/models/water_adjustor";
import { SourceWaterProfile } from "@app/models/waterProfile";
import { Yeast } from "@app/models/yeast";

export enum ImportedRecipeFormat {
    BeerXML = "beer-xml",
    BraumeisterJSON = "braumeister-json",
    BraumeisterArchive = "braumeister-archive"
}

export enum HopForm {
    Pellet = "pellet",
    Extract = "extract",
    Fresh = "fresh"
}

export interface HopFormsMap {
    [key: string]: HopForm
}

export const hopFriendlyFormString = (hopForm: HopForm): string => {
    switch(hopForm) {
        case HopForm.Fresh:
            return "Fresh";
        case HopForm.Pellet:
            return "Pellet";
        case HopForm.Extract:
            return "Extract";
    }
}

export const getAllHopForms = (): HopFormsMap => {
    const hopFormsMap: HopFormsMap = {};

    hopFormsMap[hopFriendlyFormString(HopForm.Fresh)] = HopForm.Fresh;
    hopFormsMap[hopFriendlyFormString(HopForm.Pellet)] = HopForm.Pellet;
    hopFormsMap[hopFriendlyFormString(HopForm.Extract)] = HopForm.Extract;

    return hopFormsMap;
}

export enum HopUse {
    Mash = "mash",
    Boil = "boil",
    Whirlpool = "whirlpool",
    DryHop = "dry-hop"
}

export interface HopUsesMap {
    [key: string]: HopUse
}

export const hopFriendlyUseString = (hopUse: HopUse) : string => {
    switch(hopUse) {
        case HopUse.Mash:
            return "Mash";
        case HopUse.Boil:
            return "Boil";
        case HopUse.Whirlpool:
            return "Whirlpool";
        case HopUse.DryHop:
            return "Dry Hop";
    }
}

export const getAllHopUses = (): HopUsesMap => {
    const hopUsesMap: HopUsesMap = {};

    hopUsesMap[hopFriendlyUseString(HopUse.Mash)] = HopUse.Mash;
    hopUsesMap[hopFriendlyUseString(HopUse.Boil)] = HopUse.Boil;
    hopUsesMap[hopFriendlyUseString(HopUse.Whirlpool)] = HopUse.Whirlpool;
    hopUsesMap[hopFriendlyUseString(HopUse.DryHop)] = HopUse.DryHop;

    return hopUsesMap;
}

export interface HopAddition {
    id?: number,
    hop: Hop,
    amount: number,
    time: number,
    form: HopForm,
    use: HopUse,
    utilization?: number,
    temperature?: number,
    alpha_acid?: number,
}

export const getHopTimeString = (hopUse: HopUse, time: number): string => {
    if(hopUse === HopUse.Boil || hopUse === HopUse.Whirlpool) {
        return time.toString();
    }
    else if(hopUse === HopUse.DryHop) {
        return (time / 1440).toFixed(0);
    }

    return "";
}

export const getHopAdditionTime = (hopAddition: HopAddition): string => {
    return getHopTimeString(hopAddition.use, hopAddition.time);
}

export interface RecipeFermentable {
    fermentable: Fermentable,
    amount: number,
}

export interface RecipeHop {
    hop: Hop,
    amount: number,
}

export enum MashStepType {
    Infusion = "infusion",
    Sparge = "sparge",
    Rest = "rest"
}

export interface MashStep {
    id: number,
    order: number,
    description: string,
    type: MashStepType,
    volume: number,
    time: number,
    temperature: number,
}

export const getMashFriendlyString =(mashStepType: MashStepType) : string => {
    switch(mashStepType) {
        case MashStepType.Infusion:
            return "Infusion"
        case MashStepType.Rest:
            return "Rest"
        case MashStepType.Sparge:
            return "Sparge"
    }
}

export interface RecipeYeast {
    yeast: Yeast,
    attenuation: number,
    amount: number,
}

export enum RecipeType {
    AllGrain = "all-grain",
    Extract = "extract"
}

export interface RecipeCalculations {
    original_gravity: number,
    pre_boil_gravity: number,
    final_gravity: number,
    abv: number,
    ibu: number,
    srm: number,
}

export interface RecipeOtherIngredient {
    id?: number,
    name: string,
    amount: number,
    time_used: TimeUsed,
    measurement_type: MeasurementType,
}

export interface Recipe {
    id: number,
    name: string,
    type: RecipeType,
    batch_size: number,
    boil_size: number,
    boil_time: number,
    calculations?: RecipeCalculations,
    efficiency: number,
    notes: string,
    fermentables: RecipeFermentable[],
    hop_additions: HopAddition[],
    mash_steps: MashStep[],
    yeast: RecipeYeast,
    style: BeerStyle,
    water_adjustors: RecipeWaterAdjustor[],
    other_ingredients: RecipeOtherIngredient[],
    source_water?: SourceWaterProfile,
}

export interface RecipeImportError {
    message: string,
    entity_type?: string,
    entity_id?: number,
}

export interface RecipeImportErrors {
    recipe_errors: RecipeImportError[],
}

export interface RecipeAuthor {
    id: number,
    name: string,
    created_at: number,
    avatar?: ProfileAvatar,
}

export interface PersistedRecipe {
    id: number,
    name: string,
    type: RecipeType,
    created_at: number,
    updated_at: number,
    brewing_setup?: BrewingSetup,
    is_public: boolean,
    import_errors?: RecipeImportErrors,
    imported_recipe_id?: number,
    is_mine: boolean,
    author?: RecipeAuthor,
}

export interface PersistedRecipeWithRecipe extends PersistedRecipe {
    recipe: Recipe
}

export interface RecipeRating {
    comment: string,
    rating: number,
    user_id: number,
    mine?: boolean,
    created_at: number,
    id: number,
    recipe_id: number,
    user?: User,
}