import * as React from "react";
import { Alert, Col, Container, Form, Modal, Row } from "react-bootstrap";
import { Save } from "react-bootstrap-icons";

import YeastIcon from "@app/assets/img/ingredients/yeast.svg";
import Button from "@app/components/common/Button";
import RowFormControlComponent from "@app/components/common/RowFormControlComponent";
import { Yeast, YeastFlocculation, YeastForm, YeastType, friendlyYeastFlocculationString, friendlyYeastFormString, friendlyYeastTypeString } from "@app/models/yeast";
import ServicesHelper from "@app/services/serviceshelper";

interface Props {
    show: boolean,
    onHide: () => void,
    onAdd: () => void,
}

interface State {
    name: string,
    type: YeastType,
    form: YeastForm,
    flocculation: YeastFlocculation,
    minTemperature: string,
    maxTemperature: string,
    attenuation: string,
    isSubmitting: boolean,
    isCreated: boolean,
    errors?: string[],
}

class AddYeastComponent extends React.Component<Props, State> {

    public constructor(props: Props) {
        super(props);

        this.state = {
            name: "",
            type: YeastType.Ale,
            form: YeastForm.Dry,
            flocculation: YeastFlocculation.Low,
            minTemperature: "15",
            maxTemperature: "22",
            attenuation: "80",
            isSubmitting: false,
            isCreated: false,
            errors: [],
        };
    }

    public onChangeName = (evt: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            ...this.state,
            name: evt.target.value,
        });
    }

    public onChangeAttenuation = (evt: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            ...this.state,
            attenuation: evt.target.value,
        });
    }

    public onChangeMaxTemperature = (evt: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            ...this.state,
            maxTemperature: evt.target.value,
        });
    }

    public onChangeMinTemperature = (evt: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            ...this.state,
            minTemperature: evt.target.value,
        });
    }

    public onChangeType = (evt: React.ChangeEvent<HTMLSelectElement>) => {
        this.setState({
            ...this.state,
            type: evt.target.value as YeastType,
        });
    }

    public onChangeForm = (evt: React.ChangeEvent<HTMLSelectElement>) => {
        this.setState({
            ...this.state,
            form: evt.target.value as YeastForm,
        });
    }

    public onChangeFlocculation = (evt: React.ChangeEvent<HTMLSelectElement>) => {
        this.setState({
            ...this.state,
            flocculation: evt.target.value as YeastFlocculation,
        });
    }

    public onClickAddYeast = (evt: React.MouseEvent<HTMLButtonElement>) => {
        const { name, type, form, flocculation, minTemperature, maxTemperature, attenuation} = this.state;
        const errors: string[] = [];

        const intAttenuation = Number.parseInt(attenuation, 10);
        const floatMinTemperature = Number.parseFloat(minTemperature);
        const floatMaxTemperature = Number.parseFloat(maxTemperature);

        if(Number.isNaN(intAttenuation)) {
            errors.push("Invalid Attenuation");
        }

        if(Number.isNaN(floatMinTemperature)) {
            errors.push("Invalid Minimum temperature");
        }

        if(Number.isNaN(floatMaxTemperature)) {
            errors.push("Invalid Maximum temperature");
        }

        if(name.length === 0) {
            errors.push("Must specify a name");
        }

        if(errors.length > 0) {
            this.setState({
                ...this.state,
                errors,
            });

            return;
        }

        const yeast: Yeast = {
            name,
            type,
            form,
            flocculation,
            min_temperature: floatMinTemperature,
            max_temperature: floatMaxTemperature,
            attenuation: intAttenuation,
        }

        this.setState({
            ...this.state,
            errors: [],
            isSubmitting: true,
        });

        ServicesHelper.instance().ingredients().addYeast(yeast).then(resp => {
            this.setState({
                ...this.state,
                isSubmitting: false,
                isCreated: true,
            });

            setTimeout(() => {
                this.props.onHide();
                this.props.onAdd();
                this.setState({
                    ...this.state,
                    isSubmitting: false,
                    isCreated: false,
                    name: "",
                    type: YeastType.Ale,
                    form: YeastForm.Dry,
                    flocculation: YeastFlocculation.High,
                    minTemperature: "15",
                    maxTemperature: "22",
                    attenuation: "80",
                    errors: [],
                });
            }, 1500);
        }, err => {throw err;})
    }

    public render() {
        const { name, type, form, flocculation, minTemperature, maxTemperature, attenuation, isSubmitting, isCreated, errors} = this.state;
        const { show, onHide } = this.props;
        const disabled = isSubmitting || isCreated;
        return (
            <Modal show={show}>
                <Modal.Header>
                    <Modal.Title>
                        <img src={YeastIcon} width="40px" height="40px" className="float-icon" />
                        New Yeast
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container fluid>
                        <Row>
                            <Col md={{ span:8, offset:2}}>
                                {errors.length > 0 ? (
                                    <Alert variant="danger">
                                        <ul>
                                            {errors.map(err => { return (<li key={err}>{err}</li>) })}
                                        </ul>
                                    </Alert>
                                ) : null}
                                {isCreated ? <Alert variant="success">
                                                Yeast created !
                                            </Alert> : null}
                            </Col>
                        </Row>
                        <RowFormControlComponent
                            label="Name"
                            value={name}
                            onChange={this.onChangeName}
                            placeholder="Yeast name"
                            disabled={disabled}
                            labelWidth={3}
                        />
                        <Row>
                            <Col md="3">Type</Col>
                            <Col md="8">
                                <Form.Select defaultValue={type} onChange={this.onChangeType} disabled={disabled}>
                                    <option value={YeastType.Ale}>{friendlyYeastTypeString(YeastType.Ale)}</option>
                                    <option value={YeastType.Lager}>{friendlyYeastTypeString(YeastType.Lager)}</option>
                                </Form.Select>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="3">Form</Col>
                            <Col>
                                <Form.Select defaultValue={form} onChange={this.onChangeForm} disabled={disabled}>
                                    <option value={YeastForm.Dry}>{friendlyYeastFormString(YeastForm.Dry)}</option>
                                    <option value={YeastForm.Liquid}>{friendlyYeastFormString(YeastForm.Liquid)}</option>
                                </Form.Select>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="3">Flocculation</Col>
                            <Col>
                                <Form.Select defaultValue={flocculation} onChange={this.onChangeFlocculation} disabled={disabled}>
                                    <option value={YeastFlocculation.Low}>{friendlyYeastFlocculationString(YeastFlocculation.Low)}</option>
                                    <option value={YeastFlocculation.Medium}>{friendlyYeastFlocculationString(YeastFlocculation.Medium)}</option>
                                    <option value={YeastFlocculation.High}>{friendlyYeastFlocculationString(YeastFlocculation.High)}</option>
                                </Form.Select>
                            </Col>
                        </Row>
                        <RowFormControlComponent
                            label="Attenuation"
                            labelWidth={3}
                            value={attenuation}
                            type="number"
                            placeholder="0"
                            onChange={this.onChangeAttenuation}
                            disabled={disabled}
                        />
                        <RowFormControlComponent
                            label="Minimum Temperature"
                            labelWidth={3}
                            type="number"
                            placeholder="0"
                            value={minTemperature}
                            onChange={this.onChangeMinTemperature}
                            disabled={disabled}
                        />
                        <RowFormControlComponent
                            label="Maximum Temperature"
                            labelWidth={3}
                            type="number"
                            placeholder="0"
                            value={maxTemperature}
                            onChange={this.onChangeMaxTemperature}
                            disabled={disabled}
                        />
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.onClickAddYeast}>
                        <Save /> Add
                    </Button>
                    <Button variant="red" onClick={onHide}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
            );
    }
}

export default AddYeastComponent;