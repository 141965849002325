import { Job, JobStatus } from "@app/models/job";
import { NotificationData, NotificationDataType } from "@app/models/notifications";
import ServicesHelper from "@app/services/serviceshelper";


export interface NotificationItem {
    id: number;
    type: NotificationDataType;
    data: NotificationData;
    created_at: number;
    acked_at?: number;
}

export interface NotificationsResponse {
    total_count: number,
    notifications: NotificationItem[],
}


export class NotificationsService {

    private uri: string;
    private servicesHelper: ServicesHelper

    public constructor(uri: string, servicesHelper: ServicesHelper) {
        this.uri = uri;
        this.servicesHelper = servicesHelper;
    }

    public getUnreadNotifications(): Promise<NotificationsResponse> {
        return this.servicesHelper.do_get<NotificationsResponse>(`${this.uri}mine`);
    }

    public ackNotification(notificationId: string): Promise<Response> {
        return this.servicesHelper.do_empty_post(`${this.uri}${notificationId}/ack`);
    }
 }
