
import * as React from "react";
import { Container, Form } from "react-bootstrap";

import AlertComponent from "@app/components/common/AlertComponent";
import Button from "@app/components/common/Button";
import { AlertObject, AlertType } from "@app/models/alert";
import { BadRequestError } from "@app/services/models";
import ServicesHelper from "@app/services/serviceshelper";

interface Props {
    email: string;
    onChangeEmail: (email: string) => void;
    onExit: () => void;
}

interface State {
    isSubmitting: boolean,
    alerts: AlertObject[],
    resetCode: string,
    changePassword: boolean,
    newPassword: string,
    newPasswordConfirmation: string,
}

class ResetPasswordComponent extends React.Component<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            isSubmitting: false,
            alerts: [],
            resetCode: "",
            changePassword: false,
            newPassword: "",
            newPasswordConfirmation: "",
        }
    }

    public onChangeEmail = (evt: React.ChangeEvent<HTMLInputElement>) => {
        this.props.onChangeEmail(evt.target.value);
    }

    public onExit = () => {
        this.setState({
            ...this.state,
            isSubmitting: false,
            changePassword: false,
            resetCode: "",
            newPassword: "",
            newPasswordConfirmation: "",
            alerts: [],
        }, this.props.onExit);
    }

    public onClickRequestPasswordReset = (evt: React.MouseEvent<HTMLButtonElement>) => {
        this.setState({
            ...this.state,
            isSubmitting: true,
        });

        ServicesHelper.instance().user().requestPasswordReset(
            this.props.email
        ).then(resp => {
            this.setState({
                ...this.state,
                isSubmitting: false,
                alerts: [new AlertObject(AlertType.Success, "You will shortly received an email with your reset code")],
            });
        }, err => {
            this.setState({
                ...this.state,
                isSubmitting: false,
            });

            if(err instanceof Response) {
                if(err.status === 400) {
                    return err.json() as Promise<BadRequestError>;
                }
            }
        }).then(badRequest => {
            if(!badRequest) {
                return;
            }

            this.setState({
                ...this.state,
                alerts: [AlertObject.fromBadRequest(badRequest)],
                isSubmitting: false,
            });
        }, err => { throw err; });
    }

    public onClickChangePassword = (evt: React.MouseEvent<HTMLButtonElement>) => {
        const { resetCode, newPassword, newPasswordConfirmation } = this.state;
        const { email } = this.props;

        if(!resetCode || resetCode.length === 0) {
            this.setState({
                ...this.state,
                alerts: [
                    new AlertObject(AlertType.Warning, "You must have a reset code to reset your password")
                ]
            });
            return;
        }

        if(newPassword !== newPasswordConfirmation) {
            this.setState({
                ...this.state,
                alerts: [
                    new AlertObject(AlertType.Warning, "Password and Confirmation mismatches")
                ]
            });
            return;
        }

        if(newPassword.length < 8) {
            this.setState({
                ...this.state,
                alerts: [
                    new AlertObject(AlertType.Warning, "Password must have a minimum of 8 characters")
                ]
            });
            return;
        }

        this.setState({
            ...this.state,
            alerts: [],
            isSubmitting: true,
        });

        ServicesHelper.instance().user().passwordReset(
            email,
            newPassword,
            resetCode,
        ).then(resp => {
            this.setState({
                ...this.state,
                isSubmitting: false,
                changePassword: false,
                alerts: [
                    new AlertObject(AlertType.Success, "Your password was changed with success !")
                ]
            });

            setTimeout(this.onExit, 2000);
        }, err => {
            this.setState({
                ...this.state,
                isSubmitting: false,
            });

            if(err instanceof Response) {
                if(err.status === 400) {
                    return err.json() as Promise<BadRequestError>;
                }
            }
        }).then(badRequest => {
            if(!badRequest) {
                return;
            }

            this.setState({
                ...this.state,
                alerts: [AlertObject.fromBadRequest(badRequest)],
                isSubmitting: false,
            });
        }, err => { throw err; });
    }

    public onClickIGotMyRequestCode = (evt: React.MouseEvent<HTMLButtonElement>) => {
        this.setState({
            ...this.state,
            alerts: [],
            changePassword: true,
        });
    }

    public onClickCancelPasswordReset = (evt: React.MouseEvent<HTMLButtonElement>) => {
        this.onExit();
    }

    public onChangeResetCode = (evt: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            ...this.state,
            resetCode: evt.target.value
        });
    }

    public onChangeNewPassword = (evt: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            ...this.state,
            newPassword: evt.target.value
        });
    }

    public onChangeNewPasswordConfirmation = (evt: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            ...this.state,
            newPasswordConfirmation: evt.target.value
        });
    }

    public renderAlerts(alerts: AlertObject[]) {
        return <>
            { alerts.map(a => {
                return <AlertComponent alert={a} key={a.id()} />
            }) }
        </>
    }

    public render() {
        const { email } = this.props;
        const { isSubmitting, changePassword, alerts } = this.state;

        return <>
            <h3>Reset Password</h3>
            {
                alerts.length !== 0 ? this.renderAlerts(alerts) : null
            }
            <Form.Group controlId="email">
                <Form.Label>Email</Form.Label>
                <Form.Control
                    className="loginFormControl"
                    type="text"
                    placeholder="name@email.net"
                    value={email}
                    disabled={isSubmitting}
                    onChange={this.onChangeEmail}
                />
            </Form.Group>
            {
                changePassword ? null :
                <Container>
                    <Button
                        className="formButton"
                        disabled={isSubmitting}
                        variant="blue"
                        onClick={this.onClickRequestPasswordReset}
                    >
                        Request Password Reset
                    </Button>
                    <Button
                        className="formButton"
                        disabled={isSubmitting}
                        variant="blue"
                        onClick={this.onClickIGotMyRequestCode}
                    >
                        I got my reset code
                    </Button>
                    <Button
                        className="formButton"
                        variant="blue"
                        onClick={this.onClickCancelPasswordReset}
                    >
                        Cancel
                    </Button>
                </Container>
            }
            {
                changePassword ? <>
                <Form.Group controlId="newPassword">
                    <Form.Label>New Password</Form.Label>
                    <Form.Control
                        className="loginFormControl"
                        type="password"
                        placeholder="*******"
                        disabled={isSubmitting}
                        onChange={this.onChangeNewPassword}
                    />
                </Form.Group>
                <Form.Group controlId="newPassword">
                    <Form.Label>New Password Confirmation</Form.Label>
                    <Form.Control
                        className="loginFormControl"
                        type="password"
                        placeholder="*******"
                        disabled={isSubmitting}
                        onChange={this.onChangeNewPasswordConfirmation}
                    />
                </Form.Group>
                <Form.Group controlId="resetCode">
                    <Form.Label>Reset Code</Form.Label>
                    <Form.Control
                        className="loginFormControl"
                        type="text"
                        placeholder="abc123"
                        disabled={isSubmitting}
                        onChange={this.onChangeResetCode}
                    />
                </Form.Group>
                <Container>
                    <Button
                        className="formButton"
                        disabled={isSubmitting}
                        variant="blue"
                        onClick={this.onClickChangePassword}
                    >
                        Change Password
                    </Button>
                    <Button
                        className="formButton"
                        variant="blue"
                        onClick={this.onClickCancelPasswordReset}
                    >
                        Cancel
                    </Button>
                </Container>
                </> : null
            }
        </>
    }
}

export default ResetPasswordComponent;