import AwesomeDebouncePromise from "awesome-debounce-promise";
import * as React from "react";
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import { X } from "react-bootstrap-icons";

import HopIcon from '@app/assets/img/ingredients/hop.svg';
import FormControlComponent from "@app/components/common/FormControlComponent";
import LoadingComponent from "@app/components/common/LoadingComponent";
import { FieldValidations, fieldValueIsANumber, getFieldValidation, hasAnyFieldValidation } from "@app/models/fields";
import { Hop } from "@app/models/hop";
import { HopAddition, HopForm, HopUse, getAllHopForms, getAllHopUses } from "@app/models/recipe";
import ServicesHelper from "@app/services/serviceshelper";



interface Props {
    show: boolean,
    onClose: () => void,
    onAdd: (hopAddition: HopAddition) => void,
}

interface State {
    isLoading: boolean,
    hopNameFilter: string,
    matchingHops: Hop[],
    selectedHop?: Hop,
    alphaAcid?: string,
    fieldValidations: FieldValidations,
    form: HopForm,
    use: HopUse,
}

enum Fields {
    AlphaAcid = "AlphaAcid"
}

const getMatchingHops = (value: string) => {
    return ServicesHelper.instance().ingredients().getHops(value).then(resp => {
          return resp;
    }, err => { throw err; });
};

const getMatchingHopsDebounced = AwesomeDebouncePromise(
    getMatchingHops,
    800,
    { key: (value: string) => "HopNameFilter"},
);

class AddHopAdditionComponent extends React.Component<Props, State> {

    public constructor(props: Props) {
        super(props);

        this.state = {
            isLoading: false,
            hopNameFilter: "",
            matchingHops: [],
            fieldValidations: {},
            form: HopForm.Pellet,
            use: HopUse.Boil,
        };
    }

    public onSelectHop = (hop: Hop) => {
        this.setState({
            ...this.state,
            selectedHop: hop,
            matchingHops: [],
            hopNameFilter: "",
        });
    }

    public onChangeHopNameFilter = async (evt) => {
        const hopNameFilter = evt.target.value;

        this.setState({
            ...this.state,
            hopNameFilter,
            isLoading: true,
        });

        if (hopNameFilter.length >= 3) {
            const hopsResponse = await getMatchingHopsDebounced(hopNameFilter as string);

            this.setState({
                ...this.state,
                isLoading: false,
                matchingHops: hopsResponse.hops,
            })
        }
    }

    public renderHopsChoice() {
        const { isLoading, hopNameFilter, matchingHops } = this.state;

        return (
            <>
            <Row>
                <Col>
                    Name:
                    <FormControlComponent value={hopNameFilter} onChange={this.onChangeHopNameFilter} />
                </Col>
            </Row>
            { isLoading && hopNameFilter.length >= 3 ?
                <LoadingComponent /> :
                <>
                    {
                        matchingHops.length === 0 && hopNameFilter.length >= 3 ?
                        <Row>
                            <Col>
                                No result found...
                            </Col>
                        </Row>
                        :
                        matchingHops.map(hop =>
                        <Row key={hop.id}>
                            <Col>
                                {hop.name}
                            </Col>
                            <Col>
                                {hop.alpha_acid.toFixed(2)}
                            </Col>
                            <Col>
                                <Button size="sm" variant="primary" onClick={() => { this.onSelectHop(hop); }}>
                                    Select
                                </Button>
                            </Col>
                        </Row>)
                    }
                </>
                }
            </>
        );
    }

    public onChangeAlphaAcid = (evt) => {
        const newValue = evt.target.value;
        const fieldValidationsReturn = fieldValueIsANumber(this.state.fieldValidations, Fields.AlphaAcid, newValue as string);

        this.setState({
            ...this.state,
            alphaAcid: newValue,
            fieldValidations: fieldValidationsReturn.fieldValidations
        });
    }

    public onBlurAlphaAcid = (evt) => {
        const newValue = evt.target.value;
        const fieldValidationsReturn = fieldValueIsANumber(this.state.fieldValidations, Fields.AlphaAcid, newValue as string);

        this.setState({
            ...this.state,
            alphaAcid: newValue,
            fieldValidations: fieldValidationsReturn.fieldValidations
        });
    }

    public onChangeHopForm = (evt) => {
        this.setState({
            ...this.state,
            form: evt.target.value as HopForm,
        });
    }

    public onChangeHopUse = (evt) => {
        this.setState({
            ...this.state,
            use: evt.target.value as HopUse,
        });
    }

    public onClickUnselect = (evt) => {
        this.setState({
            ...this.state,
            alphaAcid: undefined,
            selectedHop: undefined,
        });
    }

    public renderHopAdditionForm() {
        const { selectedHop, alphaAcid, fieldValidations, form, use } = this.state;
        const hopForms = getAllHopForms();
        const hopUses = getAllHopUses();

        return (<>
            <Row>
                <Col>Name:</Col>
                <Col>{selectedHop.name}</Col>
                <Col>
                    <Button size="sm" onClick={this.onClickUnselect}>
                        <X />
                    </Button>
                </Col>
            </Row>
            <Row>
                <Col>Alpha Acid:</Col>
                <Col>
                    <FormControlComponent
                        value={alphaAcid ?? selectedHop.alpha_acid.toFixed(2)}
                        onChange={this.onChangeAlphaAcid}
                        fieldValidation={getFieldValidation(fieldValidations, Fields.AlphaAcid)}
                    />
                </Col>
            </Row>
            <Row>
                <Col>Form:</Col>
                <Col>
                    <Form.Select onChange={this.onChangeHopForm} value={form}>
                        { Object.keys(hopForms).map(hopFormString => {
                            return (<option key={hopForms[hopFormString]} value={hopForms[hopFormString]}>
                                {hopFormString}
                            </option>);
                        }) }
                    </Form.Select>
                </Col>
            </Row>
            <Row>
                <Col>Use:</Col>
                <Col>
                    <Form.Select onChange={this.onChangeHopUse} value={use}>
                        { Object.keys(hopUses).map(hopUseString => {
                            return (<option key={hopUses[hopUseString]} value={hopUses[hopUseString]}>
                                {hopUseString}
                            </option>);
                        }) }
                    </Form.Select>
                </Col>
            </Row>
        </>);
    }

    public onClickAdd = (evt) => {
        const { selectedHop, alphaAcid, form, use } = this.state
        const parsedAlphaAcid = Number(alphaAcid);
        this.props.onAdd({
            hop: selectedHop,
            amount: 0,
            time: 0,
            form,
            use,
            alpha_acid: parsedAlphaAcid === selectedHop.alpha_acid ||
                        Number.isNaN(parsedAlphaAcid) ?
                            undefined :
                            parsedAlphaAcid,
        });

        this.setState({
            ...this.state,
            selectedHop: undefined,
            alphaAcid: undefined,
            form: HopForm.Pellet,
            use: HopUse.Boil,
            fieldValidations: {},
        });
    }

    public onClose = (evt) => {
        this.props.onClose();

        this.setState({
            ...this.state,
            selectedHop: undefined,
            alphaAcid: undefined,
            form: HopForm.Pellet,
            use: HopUse.Boil,
            fieldValidations: {},
        });
    }

    public render() {
        const { show, onClose } = this.props;
        const { selectedHop, fieldValidations } = this.state;

        return (
            <Modal show={show} onHide={onClose}>
                <Modal.Header>
                    <Modal.Title>
                        <img src={HopIcon} width="40px" height="40px" />
                        Add Hop addition
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        {
                            !selectedHop ? this.renderHopsChoice() : this.renderHopAdditionForm()
                        }
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="primary"
                        disabled={!selectedHop || hasAnyFieldValidation(fieldValidations)}
                        onClick={this.onClickAdd}
                        className="btn-margin-top"
                    >
                            Add
                    </Button>
                    <Button variant="secondary" onClick={this.onClose}>Cancel</Button>
                </Modal.Footer>
            </Modal>);
    }

}

export default AddHopAdditionComponent;