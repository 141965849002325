
import { observer } from "mobx-react";
import React = require("react");
import { Col, Container, Row } from "react-bootstrap";
import { Check2Circle, XCircle } from "react-bootstrap-icons";

import Button from "@app/components/common/Button";
import RecipeImportedNotificationComponent from "@app/components/notifications/RecipeImportedNotificationComponent";
import RecipeRatingPostedComponent from "@app/components/notifications/RecipeRatingPostedComponent";
import RecipesExportReadyNotificationComponent from "@app/components/notifications/RecipesExportReadyNotificationComponent";
import { NotificationDataType, NotificationEvent, RecipeImportedNotificationData, RecipeRatingPostedData, RecipesExportReadyData } from "@app/models/notifications";
import ServicesHelper from "@app/services/serviceshelper";
import { NotificationsStore } from "@app/stores/notificationsstore";


interface Props {
    store: NotificationsStore;
    onClose: () => void;
}

@observer
class NotificationPanel extends React.Component<Props, Record<string,never>> {
    public constructor(props: Props) {
        super(props);
    }

    public ackNotification = (notificationId: string) => {
        ServicesHelper.instance().notifications().ackNotification(notificationId).then(
            resp => {
                this.props.store.deleteNotification(notificationId);
                this.props.onClose();
            },
            err => { throw err; }
        )
    }

    public onClickAckNotification = (evt: React.MouseEvent<HTMLButtonElement>, notification: NotificationEvent) => {
        this.ackNotification(notification.getId());
    }

    public renderInnerNotification(notification: NotificationEvent) {
        const notificationData = notification.getConcreteData()
        switch(notification.getType()) {
            case NotificationDataType.RecipeImported:
                return <RecipeImportedNotificationComponent
                            notification={notification}
                            data={notificationData as RecipeImportedNotificationData}
                            ack={this.ackNotification}
                        />
            case NotificationDataType.RecipesExportReady:
                return <RecipesExportReadyNotificationComponent
                            notification={notification}
                            data={notificationData as RecipesExportReadyData}
                            ack={this.ackNotification}
                        />
            case NotificationDataType.RecipeRatingPosted:
                return <RecipeRatingPostedComponent
                            notification={notification}
                            data={notificationData as RecipeRatingPostedData}
                            ack={this.ackNotification}
                        />
            default:
                return <></>
        }
    }

    public renderNotification(notification: NotificationEvent) {
        return <>
            <Row key={notification.getId()}>
                <Col sm="9">
                    {notification.getCaption()}
                </Col>
                <Col sm="1">
                    <Button
                        variant="normal"
                        size="small"
                        onClick={(evt) => {this.onClickAckNotification(evt, notification)}}
                    >
                        <Check2Circle />
                    </Button>
                </Col>
                <Col sm="2"></Col>
            </Row>
            {
                this.renderInnerNotification(notification)
            }
        </>;
    }

    public renderNotifications() {
        const notifications = this.props.store.notifications;

        if(notifications.length === 0) {
            return <Container>
                There's no notification...
            </Container>
        }

        return <Container>
            { notifications.map((n: NotificationEvent) => this.renderNotification(n)) }
        </Container>
    }

    public onClickCloseBtn = (evt: React.MouseEvent<HTMLButtonElement>) => {
        this.props.onClose();
    }

    public render() {
        return <div className="notificationPanel">
            <Container>
                <Row>
                    <Col>
                        <h4>Notification(s)</h4>
                    </Col>
                    <Col>
                        <Button
                            onClick={this.onClickCloseBtn}
                            size="small"
                            variant="red"
                        >
                            <XCircle />
                        </Button>
                    </Col>
                </Row>
            </Container>
            {this.renderNotifications()}
        </div>;
    }
}

export default NotificationPanel;