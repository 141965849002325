import * as React from "react";
import {render} from "react-dom";

import App from "@app/components/App";

const rootEl = document.getElementById("root");

render(
     <App/>,
    rootEl,
);
