
export enum ExternalService {
    BrewersFriend = "brewers_friend"
}

export const externalServicePrettyName = (externalService: ExternalService): string => {
    switch(externalService) {
        case ExternalService.BrewersFriend:
            return "Brewers Friend";
    }

    return "";
}