import * as convert from 'convert-units';

import { HopAddition, HopUse, MashStepType, Recipe, RecipeFermentable } from "@app/models/recipe";
import { AlkalinityType, WaterReport } from "@app/models/waterProfile";

export const calculateFermentablePoints = (recipe: Recipe) : number => {
    return recipe.fermentables.reduce( (currentValue: number, recipeFermentable: RecipeFermentable) => {
        return currentValue + (
            convert(recipeFermentable.amount).from('g').to('lb') * recipeFermentable.fermentable.point_per_pound_gallon
        );
    }, 0);
}

export const calculateRecipePreBoilGU = (recipe: Recipe) : number => {
    const fermentablesPoints = calculateFermentablePoints(recipe);
    const preBoilVolume = convert(recipe.boil_size).from('l').to('gal');
    const gu: number = (fermentablesPoints / preBoilVolume) * (recipe.efficiency/100.00);
    return gu;
}


export const calculateRecipeGU = (recipe: Recipe) : number => {
    const fermentablesPoints : number = calculateFermentablePoints(recipe);
    const finalVolume = convert(recipe.batch_size).from('l').to('gal');
    const gu: number = (fermentablesPoints / finalVolume) * (recipe.efficiency/100.00);
    return gu;
}

export const calculateRecipeFullGU = (recipe: Recipe) : number => {
    const fermentablesPoints : number = calculateFermentablePoints(recipe);
    const finalVolume = convert(recipe.batch_size).from('l').to('gal');
    const gu: number = (fermentablesPoints / finalVolume);
    return gu;
}

export const calculateRecipeOG = (recipe: Recipe) : number => {
    const gu: number = calculateRecipeGU(recipe);
    const og: number = 1.000 + (gu/1000);
    return og;
}

export const calculateRecipePreBoilGravity = (recipe: Recipe) : number => {
    const gu: number = calculateRecipePreBoilGU(recipe);
    const og: number = 1.000 + (gu/1000);
    return og;
}

export const calculateRecipeFG = (recipe: Recipe) : number => {
    const gu: number = calculateRecipeGU(recipe);
    const finalGU: number = gu - (gu * (recipe.yeast.attenuation/100.00))
    const fg: number = 1.000 + (finalGU/1000)
    return fg;
}

export const calculateRecipeABV = (originalGravity: number, finalGravity: number) => {
    return (originalGravity - finalGravity) * 131.25;
}

export const calculateBoilTimeFactor = (minute: number): number => {
    return (1.000000 - (Math.pow(Math.E, -0.04 * minute)))/4.15;
}

export const calculateBignessFactor = (gravity: number): number => {
    return (1.65 * Math.pow(0.000125, gravity - 1.0000));
}

export const calculateHopUtilization = (boilMinute: number, gravity: number): number => {
    return calculateBignessFactor(gravity) * calculateBoilTimeFactor(boilMinute);
}

export const calculateIBU = (hopAddition: HopAddition, finalVolume: number, boilMinute: number, gravity: number): number => {
    const hopU = hopAddition.use === HopUse.Boil ? calculateHopUtilization(boilMinute, gravity) : 0.03;
    return (((hopAddition.alpha_acid/100.00)*hopAddition.amount*1000)/finalVolume)*hopU;
}

export const calculateTotalIBU = (recipe: Recipe) : number => {
    let totalIBU = 0;
    const avgGravity: number = (calculateRecipeOG(recipe) + calculateRecipePreBoilGravity(recipe))/2.000

    recipe.hop_additions.forEach(hopAddition => {
        if(hopAddition.use === HopUse.DryHop)
        {
            return;
        }

        totalIBU += calculateIBU(hopAddition, recipe.batch_size, hopAddition.time, avgGravity);
    })

    return totalIBU;
}

export const calculateSRM = (recipe: Recipe): number => {
    let grainsColors = 0;
    recipe.fermentables.forEach(fermentable => {
        grainsColors += convert(fermentable.amount).from('g').to('lb') * fermentable.fermentable.lovibond;
    })

    const mcu: number = grainsColors / convert(recipe.batch_size).from('l').to('gal')
    return 1.4922 * (Math.pow(mcu, 0.6859));
}

export const calculateDiastaticPower = (recipe: Recipe): number => {
    const diastaticTotal = recipe.fermentables.reduce(
        (currentValue: number, recipeFermentable: RecipeFermentable) => currentValue + (recipeFermentable.amount * recipeFermentable.fermentable.diastatic_power),
        0
    );

    const weightTotal = recipe.fermentables.reduce(
        (initialValue: number, recipeFermentable: RecipeFermentable) => initialValue + recipeFermentable.amount, 0
    );

    if (weightTotal === 0) {
        return 0;
    }

    return (diastaticTotal / weightTotal);
}


export const getMaltPercentage = (recipe: Recipe, minimumLovibond: number, maximumLovibond?: number): number | undefined => {
    const totalMaltWeight = recipe.fermentables.reduce(
        (initialValue: number, recipeFermentable: RecipeFermentable) => initialValue + recipeFermentable.amount,
        0,
    );

    const matchingMaltWeight = recipe.fermentables.reduce(
        (initialValue: number, recipeFermentable: RecipeFermentable) => {
            if(recipeFermentable.fermentable.lovibond >= minimumLovibond && (maximumLovibond && recipeFermentable.fermentable.lovibond <= maximumLovibond)) {
                return initialValue + recipeFermentable.amount;
            }

            return initialValue;
        },
        0,
    );

    if (totalMaltWeight === 0) {
        return 0;
    }

    return matchingMaltWeight / totalMaltWeight;
}

export const getAcidMaltPercentage = (recipe: Recipe, nameMatch = "acid"): number | undefined => {
    const totalMaltWeight = recipe.fermentables.reduce(
        (initialValue: number, recipeFermentable: RecipeFermentable) => initialValue + recipeFermentable.amount,
        0,
    );

    const matchingMaltWeight = recipe.fermentables.reduce(
        (initialValue: number, recipeFermentable: RecipeFermentable) => {
            if(recipeFermentable.fermentable.name.indexOf(nameMatch) !== -1) {
                return initialValue + recipeFermentable.amount;
            }

            return initialValue;
        },
        0,
    );

    if (totalMaltWeight === 0) {
        return 0;
    }

    return matchingMaltWeight / totalMaltWeight;
}

export const calculateMashPH = (recipe: Recipe, baseMaltPH = 5.5): number => {
    /*
    Estimated Mash pH = (wort pH from malt COA) + (RA x 0.03) – (% crystal malt x 0.025) – (% lightly roasted malts x 0.03) – (% darkly roasted malts x 0.05) – (% acidulated malt x 0.01).
    Residual Alkalinity (RA) = (ppm carbonate/bicarbonate in water x 0.046) – (ppm calcium in water x 0.04) – (ppm magnesium in water x 0.03).
    */

    // 5.9

    const waterReport = calculateWaterReport(recipe, 0);
    const residualAlkalinity = (waterReport.bicarbonate * 0.046) - (waterReport.calcium * 0.04) - (waterReport.magnesium * 0.03);
    const mashPH = (baseMaltPH +
        (residualAlkalinity * 0.03) -
        ((getMaltPercentage(recipe, 10, 120)*100.00)*0.025) -
        ((getMaltPercentage(recipe, 120.01, 300)*100.00)*0.03) -
        ((getMaltPercentage(recipe, 300.01)*100.00)*0.05) -
        ((getAcidMaltPercentage(recipe)*100.00)*0.01)
    );

    return mashPH;
}

export const calculateIonPPM = (sourceWaterPPM: number, grainsMass: number, totalMass: number, infusionVolume: number, spargeVolume: number, finalVolume: number, grainsAbsorptionRatio = 1.000) => {
    const totalVolume: number = infusionVolume + spargeVolume;
    const adjustorFinalMass = totalMass * ((infusionVolume - ( (grainsMass/1000.0)* grainsAbsorptionRatio))/totalVolume);
    return sourceWaterPPM + (adjustorFinalMass/finalVolume);
}

export const calculateWaterReport = (recipe: Recipe, grainsAbsorptionRatio = 1.000): WaterReport => {
    let calcium = 0;
    let magnesium = 0;
    let sodium = 0;
    let chloride = 0;
    let sulfate = 0;
    let bicarbonate = 0;
    let grainsTotal = 0;

    recipe.fermentables.forEach(fermentable => {
        grainsTotal += fermentable.amount;
    });

    recipe.water_adjustors.forEach(waterAdjustor =>{
        if (waterAdjustor.data) {
            const adjustorData = waterAdjustor.data;
            if (adjustorData.calcium_ratio) {
                calcium += (waterAdjustor.amount*1000) * adjustorData.calcium_ratio;
            }

            if (adjustorData.magnesium_ratio) {
                magnesium += (waterAdjustor.amount*1000) * adjustorData.magnesium_ratio;
            }

            if (adjustorData.sodium_ratio) {
                sodium += (waterAdjustor.amount*1000) * adjustorData.sodium_ratio;
            }

            if (adjustorData.chloride_ratio) {
                chloride += (waterAdjustor.amount*1000) * adjustorData.chloride_ratio;
            }

            if (adjustorData.sulfate_ratio) {
                sulfate += (waterAdjustor.amount*1000) * adjustorData.sulfate_ratio;
            }

            if (adjustorData.bicarbonate_ratio) {
                bicarbonate += (waterAdjustor.amount*1000) * adjustorData.bicarbonate_ratio;
            }

        }
    });

    let waterVolumeTotal = 0;
    let spargeVolume = 0;
    let infusionVolume = 0;

    recipe.mash_steps.forEach(mashStep => {
        if (mashStep.type === MashStepType.Infusion) {
            infusionVolume += mashStep.volume;
        }

        if(mashStep.type === MashStepType.Sparge) {
            spargeVolume += mashStep.volume;
        }

        if (mashStep.type === MashStepType.Infusion || mashStep.type === MashStepType.Sparge) {
            waterVolumeTotal += mashStep.volume;
        }
    });

    const ppmCalcium = calculateIonPPM(recipe.source_water?.calcium ?? 0,
        grainsTotal,
        calcium,
        infusionVolume,
        spargeVolume,
        recipe.batch_size,
        grainsAbsorptionRatio
        );

    const ppmMagnesium = calculateIonPPM(recipe.source_water?.magnesium ?? 0,
        grainsTotal,
        magnesium,
        infusionVolume,
        spargeVolume,
        recipe.batch_size,
        grainsAbsorptionRatio);

    const ppmSodium = calculateIonPPM(recipe.source_water?.sodium ?? 0,
        grainsTotal,
        sodium,
        infusionVolume,
        spargeVolume,
        recipe.batch_size,
        grainsAbsorptionRatio);

    const ppmChloride = calculateIonPPM(recipe.source_water?.chloride ?? 0,
        grainsTotal,
        chloride,
        infusionVolume,
        spargeVolume,
        recipe.batch_size, grainsAbsorptionRatio);

    const ppmSulfate = calculateIonPPM(recipe.source_water?.sulfate ?? 0,
        grainsTotal,
        sulfate,
        infusionVolume,
        spargeVolume,
        recipe.batch_size, grainsAbsorptionRatio);

    let sourceWaterBicarbonate = 0;

    if (recipe.source_water) {
        if(recipe.source_water.alkalinity_type === AlkalinityType.CaCO3) {
            sourceWaterBicarbonate = (recipe.source_water.alkalinity/50)*61;
        } else {
            sourceWaterBicarbonate = recipe.source_water.alkalinity;
        }
    }

    const ppmBicarbonate = calculateIonPPM(sourceWaterBicarbonate,
        grainsTotal,
        bicarbonate,
        infusionVolume,
        spargeVolume,
        recipe.batch_size, grainsAbsorptionRatio);

    return {
        calcium: ppmCalcium,
        magnesium: ppmMagnesium,
        sodium: ppmSodium,
        chloride: ppmChloride,
        sulfate: ppmSulfate,
        bicarbonate: ppmBicarbonate,
    };
}