import * as React from "react";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import { CaretRightSquare, Pencil, Search } from "react-bootstrap-icons";

import FormControlComponent from "@app/components/common/FormControlComponent";
import LoadingComponent from "@app/components/common/LoadingComponent";
import { AlkalinityType, SourceWaterProfile } from "@app/models/waterProfile";
import ServicesHelper from "@app/services/serviceshelper";

interface Props {
    recipeId: number,
    sourceWaterProfile?: SourceWaterProfile,
    onChange?: (sourceWaterProfile?: SourceWaterProfile) => void,
}

interface State {
    sourceWaterProfiles: SourceWaterProfile[],
    isLoading: boolean,
    showSourceWaterProfilesModal: boolean,
    nameFilter: string,
}

class EditSourceWaterComponent extends React.Component<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            sourceWaterProfiles: [],
            isLoading: false,
            showSourceWaterProfilesModal: false,
            nameFilter: "",
        }
    }

    public onCloseModal = () => {
        this.setState({
            ...this.state,
            showSourceWaterProfilesModal: false,
        });
    }

    public onClickEdit = (evt) => {
        this.setState({
            ...this.state,
            showSourceWaterProfilesModal: true,
            isLoading: true,
        });

        ServicesHelper.instance().ingredients().getSourceWaterProfiles().then(resp => {
            this.setState({
                ...this.state,
                isLoading: false,
                sourceWaterProfiles: resp.source_water_profiles,
            });
        }, err => { throw err; });
    }

    public onChoose = (sourceWaterProfile?: SourceWaterProfile) => {
        if(this.props.onChange) {
            this.props.onChange(sourceWaterProfile);
        }

        ServicesHelper.instance()
            .recipes()
            .updateRecipeSourceWater(this.props.recipeId, sourceWaterProfile?.id ?? undefined)
            .then(resp => { return; }, err => { throw err; });

        this.setState({
            ...this.state,
            showSourceWaterProfilesModal: false,
        });
    }

    public renderSourceWaterProfiles() {
        const { sourceWaterProfiles, nameFilter } = this.state;
        let shownSourceWaterProfiles = sourceWaterProfiles;

        if (nameFilter.length !== 0) {
            shownSourceWaterProfiles = shownSourceWaterProfiles.filter(waterProfile => waterProfile.name.includes(nameFilter));
        }

        return (
            <>
                <Row>
                    <Col sm="2">
                        None
                    </Col>
                    <Col sm={{ span:2, offset: 1}}>
                        <Button size="sm" onClick={() => { this.onChoose(undefined) }}>
                            <CaretRightSquare /> Select
                        </Button>
                    </Col>
                </Row>
                { shownSourceWaterProfiles.map(sourceWaterProfile => {
                    return this.renderSourceWaterProfileRow(sourceWaterProfile, true);
                }) }
            </>
        )
    }

    public renderSourceWaterProfileRow(sourceWaterProfile: SourceWaterProfile, showSelectButton?: boolean) {
        return (
            <div key={sourceWaterProfile.id}>
                <Row>
                    <Col sm="2">Name</Col>
                    <Col sm="1">Ca<span className="waterProfileSuperScript">+2</span></Col>
                    <Col sm="1">Mg<span className="waterProfileSuperScript">+2</span></Col>
                    <Col sm="1">Na<span className="waterProfileSuperScript">+</span></Col>
                    <Col sm="1">Cl<span className="waterProfileSuperScript">-</span></Col>
                    <Col sm="1">SO<span className="waterProfileSubScript">4</span><span className="waterProfileSuperScript">-2</span></Col>
                    <Col sm="1">pH</Col>
                    <Col sm="3">Alkalinity ({
                        sourceWaterProfile.alkalinity_type === AlkalinityType.CaCO3 ?
                            (<>CaCO<span className="waterProfileSubScript">3</span></>) :
                            (<>HCO<span className="waterProfileSubScript">3</span></>)
                    })</Col>
                    <Col sm="1"></Col>
                </Row>
                <Row>
                    <Col sm="2">{sourceWaterProfile.name}</Col>
                    <Col sm="1">{sourceWaterProfile.calcium}</Col>
                    <Col sm="1">{sourceWaterProfile.magnesium}</Col>
                    <Col sm="1">{sourceWaterProfile.sodium}</Col>
                    <Col sm="1">{sourceWaterProfile.chloride}</Col>
                    <Col sm="1">{sourceWaterProfile.sulfate}</Col>
                    <Col sm="1">{sourceWaterProfile.ph}</Col>
                    <Col sm="2">{sourceWaterProfile.alkalinity}</Col>
                    <Col sm="2">
                        {
                            showSelectButton ? (
                                <Button size="sm" onClick={() => { this.onChoose(sourceWaterProfile) }}>
                                    <CaretRightSquare /> Select
                                </Button>
                            ): null
                        }
                    </Col>
                </Row>
            </div>
        );
    }

    public onChangeNameFilter = (evt) => {
        this.setState({
            ...this.state,
            nameFilter: evt.target.value,
        });
    }

    public render() {
        const { showSourceWaterProfilesModal, isLoading, nameFilter } = this.state;
        const { sourceWaterProfile } = this.props;

        return (
        <Container fluid>
            <h5>Source Water</h5>
            {
                sourceWaterProfile ? this.renderSourceWaterProfileRow(sourceWaterProfile) : null
            }
            <Row>
                <Col sm={{span:1}}>
                    <Button onClick={this.onClickEdit}>
                        <Pencil /> Edit
                    </Button>
                </Col>
                <Col sm="3">{ sourceWaterProfile ? null : `Not Selected` }</Col>
            </Row>
            <Modal size="lg" show={showSourceWaterProfilesModal} onHide={this.onCloseModal}>
                <Modal.Header>
                    <Modal.Title>Source water</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Container>
                        <h5>Filter</h5>
                        <Row>
                            <Col sm={{offset:1, span:1}}>
                                <Search />
                            </Col>
                            <Col sm="2">
                                Name:
                            </Col>
                            <Col sm="4">
                                <FormControlComponent size="sm" onChange={this.onChangeNameFilter} value={nameFilter}/>
                            </Col>
                        </Row>
                    </Container>
                    <Container>
                        <h5>Available source water(s)</h5>
                        { isLoading ? <LoadingComponent /> : this.renderSourceWaterProfiles() }
                    </Container>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={this.onCloseModal}>Cancel</Button>
                </Modal.Footer>
            </Modal>
        </Container>);
    }
}

export default EditSourceWaterComponent;