import * as React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { ArrowClockwise, PlusCircle } from "react-bootstrap-icons";
import { LinkContainer } from 'react-router-bootstrap';

import Button from "@app/components/common/Button";
import EditSourceWaterProfileComponent from "@app/components/ingredients/EditSourceWaterProfileComponent";
import SourceWaterProfileItem from "@app/components/ingredients/SourceWaterProfileItem";
import { SourceWaterProfile } from "@app/models/waterProfile";
import ServicesHelper from "@app/services/serviceshelper";

interface Props {
    id?: number,
    action?: string,
    isAdmin: boolean,
    userId: number,
}

interface State {
    isLoading: boolean,
    sourceWaterProfiles: SourceWaterProfile[],
    showEditSourceWaterProfileForm: boolean,
    selectedSourceWaterProfileId?: number,
}

class SourceProfileWatersComponent extends React.Component<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            isLoading: true,
            sourceWaterProfiles: [],
            showEditSourceWaterProfileForm: false,
        };
    }

    public loadSourceWaterProfiles() {
        this.setState({
            ...this.state,
            isLoading: true,
            sourceWaterProfiles: [],
        });

        ServicesHelper.instance().ingredients().getSourceWaterProfiles().then(resp => {
            this.setState({
                ...this.state,
                isLoading: false,
                sourceWaterProfiles: resp.source_water_profiles,
            });
        }, err => { throw err; })
    }

    public componentDidMount(): void {
        if (this.props.action === undefined) {
            this.loadSourceWaterProfiles();
        }
    }

    public componentDidUpdate(prevProps: Readonly<Props>): void {
        if (prevProps.action !== undefined && this.props.action === undefined) {
            this.loadSourceWaterProfiles();
        }
    }

    public onClickRefresh = (evt) => {
        this.loadSourceWaterProfiles();
    }

    public onClickAdd = (evt: React.MouseEvent<HTMLButtonElement>) => {
        this.setState({
            ...this.state,
            selectedSourceWaterProfileId: undefined,
            showEditSourceWaterProfileForm: true,
        });
    }

    public renderTable() {
        const { isAdmin, userId } = this.props;

        return (
        <Container fluid className="tableContainer">
            <Row className="mb-2">
                <Col>
                    <Button variant="blue" onClick={this.onClickAdd}>
                        <PlusCircle /> Add
                    </Button>
                </Col>
                <Col>
                    <Button variant="blue" onClick={this.onClickRefresh}>
                        <ArrowClockwise /> Refresh
                    </Button>
                </Col>
            </Row>
            <Row className="tableHeader">
                <Col sm="2">
                    Name
                </Col>
                <Col sm="1">
                    Ca
                </Col>
                <Col sm="1">
                    SO<sub>4</sub>
                </Col>
                <Col sm="1">
                    Cl
                </Col>
                <Col sm="1">
                    Na
                </Col>
                <Col sm="1">
                    Mg
                </Col>
                <Col>
                    Alkalinity
                </Col>
                <Col sm="2">
                    Action
                </Col>
            </Row>
            {
                this.state.sourceWaterProfiles.map(sourceWaterProfile => <SourceWaterProfileItem
                    key={sourceWaterProfile.id}
                    sourceWaterProfile={sourceWaterProfile}
                    canEdit={isAdmin || sourceWaterProfile.user_id === userId}
                    onEdit={this.onClickEdit}
                    onDelete={this.onDelete}
                />)
            }
        </Container>)
    }

    public onDelete = (sourceWaterProfile: SourceWaterProfile) => {
        const { sourceWaterProfiles } = this.state;

        this.setState({
            ...this.state,
            sourceWaterProfiles: sourceWaterProfiles.filter(swp => swp.id !== sourceWaterProfile.id),
        });
    }

    public onClickEdit = (sourceWaterProfile: SourceWaterProfile) => {
        this.setState({
            ...this.state,
            showEditSourceWaterProfileForm: true,
            selectedSourceWaterProfileId: sourceWaterProfile.id,
        });
    }

    public renderContent() {
        return this.renderTable();
    }

    public onHideEditSourceWaterProfileForm = (sourceWaterProfile?: SourceWaterProfile) => {
        if(!sourceWaterProfile) {
            this.setState({
                ...this.state,
                selectedSourceWaterProfileId: undefined,
                showEditSourceWaterProfileForm: false,
            });

            return;
        }

        let applied = false;

        const sourceWaterProfiles = this.state.sourceWaterProfiles.map(swp => {
            if(swp.id === sourceWaterProfile.id) {
                applied = true;
                return sourceWaterProfile;
            }
            return swp;
        })

        this.setState({
            ...this.state,
            sourceWaterProfiles: applied ? sourceWaterProfiles : [sourceWaterProfile, ...sourceWaterProfiles],
            showEditSourceWaterProfileForm: false,
        });
    }

    public render() {
        const { isLoading, selectedSourceWaterProfileId, showEditSourceWaterProfileForm } = this.state;

        return (<Container fluid>
            <Row>
                <h3>Source Water Profiles</h3>
            </Row>
            <Row>
                <Col sm={{offset:1, span:10}}>
                    {!isLoading ? this.renderContent() : null}
                </Col>
            </Row>
            <EditSourceWaterProfileComponent
                sourceWaterProfileId={selectedSourceWaterProfileId}
                onHide={this.onHideEditSourceWaterProfileForm}
                isVisible={showEditSourceWaterProfileForm}
            />
        </Container>)
    }
}

export default SourceProfileWatersComponent;