import AwesomeDebouncePromise from "awesome-debounce-promise";
import * as React from "react";
import { Alert, Col, Container, Form, Modal, Row } from "react-bootstrap";
import { BoxArrowInRight, Search } from "react-bootstrap-icons";

import Grain from '@app/assets/img/ingredients/grain.svg';
import Button from "@app/components/common/Button";
import { Fermentable, fermentableTypeFriendlyString } from "@app/models/fermentable";
import ServicesHelper from "@app/services/serviceshelper";

import "@app/assets/scss/components/AddFermentableToRecipe.scss";

interface Props {
    onAddFermentable: (fermentable: Fermentable) => void,
}

interface State {
    fermentablesSuggestion?: Fermentable[],
    selectedFermentable?: Fermentable,
    name: string;
    formVisible: boolean;
}

const getFermentables = (value: string) => {
    return ServicesHelper.instance().ingredients().getFermentables(value).then(resp => {
        return resp.fermentables;
    }, err => { throw err; });
  };

const getFermentablesDebounced = AwesomeDebouncePromise(
    getFermentables,
    800,
    { key: (value: string) => "FermentableName" },
);


class AddFermentableToRecipe extends React.Component<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            name: "",
            formVisible: false,
        };
    }

    public onChangeName = async (evt: React.ChangeEvent<HTMLInputElement>) => {
        if(evt.target.value.length === 0) {
            this.setState({
                ...this.state,
                fermentablesSuggestion: [],
                name: "",
            });

            return;
        }

        this.setState({
            ...this.state,
            name: evt.target.value,
        });

        if(evt.target.value.length < 3) {
            return;
        }

        const fermentables = await getFermentablesDebounced(evt.target.value);
        this.setState({
            ...this.state,
            fermentablesSuggestion: fermentables,
        });
    }

    public onShow = (evt: React.MouseEvent<HTMLButtonElement>) => {
        this.setState({
            ...this.state,
            formVisible: true,
        });
    }

    public onSelectFermentable = (fermentable: Fermentable) => {
        this.props.onAddFermentable(fermentable);

        this.setState({
            ...this.state,
            selectedFermentable: undefined,
            fermentablesSuggestion: [],
            name: "",
            formVisible: false,
        });
    }

    public onHide = (evt: React.MouseEvent<HTMLButtonElement>) => {
        this.setState({
            ...this.state,
            formVisible: false,
        });
    }

    public render() {
        const { formVisible, name, fermentablesSuggestion } = this.state;

        return (
        <>
        <Modal size="xl" show={formVisible}>
            <Modal.Header>
                <Modal.Title>
                    <img src={Grain} width="40px" height="40px" />
                    Add fermentable
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container fluid>
                    <Row>
                        <Col sm="12">
                            <Search />
                            Name:
                            <Form.Control size="sm" value={name} placeholder="Name" onChange={this.onChangeName} />
                        </Col>
                    </Row>
                    <>
                        <Row>
                            <Col sm="3">Name</Col>
                            <Col sm="2">Type</Col>
                            <Col sm="2">PPG</Col>
                            <Col sm="2">Lovibond</Col>
                            <Col sm="2">-</Col>
                        </Row>
                    {
                        fermentablesSuggestion && fermentablesSuggestion.length > 0 ?
                        fermentablesSuggestion.map(fermentable => {
                            return (
                                <Row key={fermentable.id}>
                                    <Col sm="3">{fermentable.name}</Col>
                                    <Col sm="2">{fermentableTypeFriendlyString(fermentable.fermentable_type)}</Col>
                                    <Col sm="2">{fermentable.point_per_pound_gallon}</Col>
                                    <Col sm="2">{fermentable.lovibond}</Col>
                                    <Col sm="2">
                                        <Button variant="blue" onClick={() => {this.onSelectFermentable(fermentable)}}>
                                            <BoxArrowInRight /> Select
                                        </Button>
                                    </Col>
                                </Row>
                            );
                        }) : <Row>
                            <Col sm={{offset:1 , span:8}}>
                                <Alert variant="warning">No result yet...</Alert>
                            </Col>
                        </Row>
                    }
                    </>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="grey" onClick={this.onHide}>Cancel</Button>
            </Modal.Footer>
        </Modal>
        <Row>
            <Col sm={{ span: 2, offset:1 }}>
                <Button onClick={this.onShow} className="btn-margin-top">
                    + Fermentable
                </Button>
            </Col>
        </Row>
        </>
        );
    }
}

export default AddFermentableToRecipe;