import * as React from "react";
import { Col, Row } from "react-bootstrap";
import { Pencil, Trash } from "react-bootstrap-icons";

import Button from "@app/components/common/Button";
import ConfirmationModalComponent from "@app/components/common/ConfirmationModalComponent";
import { WaterProfile } from "@app/models/waterProfile";
import ServicesHelper from "@app/services/serviceshelper";

interface Props {
    waterProfile: WaterProfile,
    onDelete: (waterProfile: WaterProfile) => void,
    onEdit: (waterProfile: WaterProfile) => void,
    canEdit: boolean,
}

interface State {
    showDeleteModal: boolean,
}

class WaterProfileItem extends React.Component<Props, State> {
     public constructor(props: Props) {
         super(props);

         this.state = {
            showDeleteModal: false,
         };
     }

     public onClickDelete = (evt: React.MouseEvent<HTMLButtonElement>) => {
        this.setState({
            ...this.state,
            showDeleteModal: true,
        });
     }

     public hideDeleteModal() {
        this.setState({
            ...this.state,
            showDeleteModal: false,
        });
     }

     public onCancelDelete = () => {
        this.hideDeleteModal();
     }

     public onConfirmDelete = () => {
        const { waterProfile } = this.props;
        ServicesHelper.instance().ingredients().deleteWaterProfile(waterProfile.id).then(
            resp => {
                this.hideDeleteModal();
                this.props.onDelete(waterProfile);
            },
            err => { throw err;}
        )
     }

     public onClickEdit = (evt: React.MouseEvent<HTMLButtonElement>) => {
        const { waterProfile } = this.props;
        this.props.onEdit(waterProfile);
     }

     public render() {
        const { waterProfile, canEdit } = this.props;
        const { showDeleteModal } = this.state;

        return (
            <Row className="tableRow">
                <Col sm="2">
                    {waterProfile.name}
                </Col>
                <Col sm="1">
                    {waterProfile.calcium}
                </Col>
                <Col sm="1">
                    {waterProfile.bicarbonate}
                </Col>
                <Col sm="1">
                    {waterProfile.chloride}
                </Col>
                <Col sm="1">
                    {waterProfile.sulfate}
                </Col>
                <Col sm="1">
                    {waterProfile.sodium}
                </Col>
                <Col sm="1">
                    {waterProfile.magnesium}
                </Col>
                <Col sm="3">
                    { canEdit ? (
                        <>
                            <Button size="small" variant="blue" onClick={this.onClickEdit}>
                                <Pencil /> Edit
                            </Button>
                            <Button size="small" variant="red" onClick={this.onClickDelete} >
                                <Trash /> Delete
                            </Button>
                        </>) : `-` }
                </Col>
                <ConfirmationModalComponent
                    isVisible={showDeleteModal}
                    title="Delete water profile"
                    body={`Are you sure you want to delete '${waterProfile.name}' water profile ?`}
                    onConfirm={this.onConfirmDelete}
                    onCancel={this.onCancelDelete}
                />
            </Row>
        );
     }
}

export default WaterProfileItem