import { User, UserRank } from "@app/models/user";
import { ErrorType } from "@app/services/models";
import { RootStore } from "stores/rootstore";

import ServicesHelper from "./serviceshelper";
import { TapClient } from "./tapclient";

export interface Token {
    access_token: string,
    refresh_token: string,
    expire_at: number
}


export interface UserInfo {
    id: string,
    display_name: string,
    email: string,
    created_at: number,
    user_rank: UserRank,
}

export interface Session {
    created_at: number,
    expire_at: number,
    timeleft: number,
    key: string,
}

export interface SignUpBody {
    display_name: string,
    email: string,
    password: string,
    beta_key?: string,
}

export interface SignUpResponse {
    success?: boolean,
    message?: string,
    error_type?: ErrorType,

}

export interface SessionResponse {
    session: Session,
    user: UserInfo,
}

export interface LoginResponse extends SessionResponse {
    refresh_key: string,
}

export class AuthManager {
    private static _instance: AuthManager = null;
    // private static RefreshTokenStorageKey: string = "BraumeisterUIRefresh"
    // private static NonceStorageKey: string = "BraumeisterUINonce"

    private static SessionKey = "session_key";
    private static RefreshKey = "refresh_key";
    // private static ChallengeKey: string = "challenge";

    private bmapi: string;
    private user?: User;
    private services: ServicesHelper;
    private tapClient?: TapClient;

    private rootStore: RootStore;

    public afterAuthenticationCallback?: (token: string) => void;

    constructor(rootStore: RootStore) {
        this.user = null;
        this.services = null;
        this.rootStore = rootStore;
        AuthManager._instance = this;
        this.tapClient = null;
    }

    public static instance(): AuthManager {
        if(AuthManager._instance) {
            return AuthManager._instance;
        } else {
            throw Error("Auth Manager instace doesn't exists!");
        }
    }

    private setSessionStoreData(sessionResponse: SessionResponse) {
        localStorage.setItem(AuthManager.SessionKey, sessionResponse.session.key);

        this.user = {
            user_id: sessionResponse.user.id,
            display_name: sessionResponse.user.display_name,
            email: sessionResponse.user.email,
            created_at: sessionResponse.user.created_at,
            user_rank: sessionResponse.user.user_rank,
        };

        // publish to app state
        this.rootStore.authStore.setSessionKey(sessionResponse.session.key);
        this.rootStore.userStore.setUser(this.user)
        this.rootStore.authStore.setAuthenticated(true);

        if(this.afterAuthenticationCallback) {
            this.afterAuthenticationCallback(sessionResponse.session.key);
        }
    }

    private setLoginStoreData(loginResponse: LoginResponse) {
        this.setSessionStoreData(loginResponse);
        localStorage.setItem(AuthManager.RefreshKey, loginResponse.refresh_key);
    }

    public do_login(email: string, password: string): Promise<LoginResponse | void> {
        return this.services.do_fetch(`${this.bmapi}sessions/login`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({email, password}),
            }).then(response => {
                if(response.status === 201) {
                    return response.json();
                } else {
                    throw Error();
                }
            }
            ).then(response => {
                const loginResponse = response as LoginResponse;
                this.setLoginStoreData(loginResponse);
                this.tapClient.connect();
                this.rootStore.notificationsStore.getInitialNotifications();
                if(loginResponse) {
                    return loginResponse;
                }

                return response as LoginResponse;
            }, err => {
                throw err;
            });
    }

    public do_signUp(signUpBody: SignUpBody) : Promise<SignUpResponse> {
        return this.services.do_fetch(`${this.bmapi}users/`,
            {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(signUpBody)
            })
        .then(resp => resp.json() as SignUpResponse);
    }

    public do_signout() {
        // todo service call
        localStorage.removeItem(AuthManager.SessionKey);
        localStorage.removeItem(AuthManager.RefreshKey);
        this.rootStore.authStore.setAuthenticated(false);
        this.rootStore.authStore.setSessionKey("");
    }

    public initiate_session_refresh(sessionKey: string, refreshKey: string) : Promise<LoginResponse> {
        return this.services.do_fetch(`${this.bmapi}sessions/${sessionKey}/refresh/${refreshKey}`, {
            method: "POST"
        }).then(resp => {
            if(resp.status === 201) {
                // console.log(resp.json());
                // this.tapClient.connect()
                return resp.json();
            } else {
                return undefined;
            }
        }).then(resp => {
            if(resp === undefined) {
                this.trigger_login();
                return undefined;
            }

            const loginResponse = resp as LoginResponse;
            this.setLoginStoreData(loginResponse);

            return loginResponse;
        });
    }

    public do_session_refresh() : Promise<LoginResponse> {
        const sessionKey = localStorage.getItem(AuthManager.SessionKey);
        const refreshKey = localStorage.getItem(AuthManager.RefreshKey);

        const resp = this.initiate_session_refresh(sessionKey, refreshKey);
        this.tapClient.connect();

        return resp;
    }

    public initiate_authentication(services: ServicesHelper) {
        this.services = services;
        this.bmapi = services.configuration.bmapi.uri;
        this.tapClient = new TapClient(this.rootStore, services.configuration.tap.uri);

        const sessionKey = services.configuration.desktopMode ? "1" : localStorage.getItem(AuthManager.SessionKey);

        if(sessionKey) {
            this.rootStore.authStore.setIsCheckingSessionKey(true);
            this.services.do_fetch(`${this.bmapi}sessions/${sessionKey}`, {}, true, 0, 10)
                .then(resp => {
                    this.rootStore.authStore.setIsCheckingSessionKey(false);
                    if(resp.status === 200) {
                        return resp.json();
                    } else if(resp.status === 404) {
                        // token is probably invalid trigger a login
                        // check if we have a refresh token
                        const refreshKey = localStorage.getItem(AuthManager.RefreshKey)
                        if(refreshKey) {
                            this.initiate_session_refresh(sessionKey, refreshKey).then(loginResp => {
                                if(loginResp) {
                                    return loginResp;
                                }
                            }, err => { throw err; })
                        }

                        return undefined;
                    }

                // todo handle failure
                }).then(resp => {
                    if(resp === undefined) {
                        this.trigger_login()
                        return;
                    }

                    const sessionResponse = resp as SessionResponse;
                    this.setSessionStoreData(sessionResponse);
                    this.tapClient.connect();
                    this.rootStore.notificationsStore.getInitialNotifications();
                }, err => { throw err; });
        } else {
            this.trigger_login();
        }
    }

    private trigger_login() {
        localStorage.removeItem(AuthManager.SessionKey)
        // const uiUri = ServicesHelper.instance().ui_configuration().uri;
        // redirect to login screen
        // const history = useHistory()
        // history.push("/login")
        // window.location.href = `${this.iamUri}login?redirect_uri=${uiUri}/signin.html&nonce=${nonce}`
    }

    private generate_string(length) : string {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        for ( let i = 0; i < length; i++ ) {
           result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
     }

}