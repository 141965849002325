import * as React from "react";
import { Alert, Col, Container, Form, Modal, Row } from "react-bootstrap";
import { Redirect } from 'react-router';

import ChoseBrewingSetupComponent from "@app/components/breweries/ChooseBrewingSetupComponent";
import Button from "@app/components/common/Button";
import ServicesHelper from "@app/services/serviceshelper";

interface Props {
    show: boolean,
    sourceRecipeId: number,
    sourceRecipeName: string,
    onClose: () => void,
}

interface State {
    recipeName: string,
    brewingSetupId?: number,
    createdRecipeId?: number,
}

class CopyRecipeComponent extends React.Component<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            recipeName: `Copy of ${this.props.sourceRecipeName}`,
        }
    }

    public onChangeRecipeName = (evt: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            ...this.state,
            recipeName: evt.target.value,
        });
    }

    public onSelectBrewingSetup = (selectedBrewingSetupId: number) => {
        this.setState({
            ...this.state,
            brewingSetupId: selectedBrewingSetupId,
        });
    }

    public onClickCreate = (evt: React.MouseEvent<HTMLButtonElement>) => {
        const { recipeName, brewingSetupId } = this.state;
        const { sourceRecipeId } = this.props;

        if (brewingSetupId && recipeName.length > 1) {
                ServicesHelper.instance()
                .recipes()
                .copyRecipe(sourceRecipeId, recipeName, brewingSetupId)
                .then(resp => {
                    this.setState({
                        ...this.state,
                        createdRecipeId: resp.recipe_id,
                    }, () => {
                        setTimeout(() => {
                            this.props.onClose();
                        }, 1000);
                    });
                }, err => {throw err;});
        }
    }

    public render() {
        const { show, onClose, sourceRecipeName } = this.props;
        const { recipeName, createdRecipeId } = this.state;

        return (
        <Modal show={show} onHide={onClose}>
            <Modal.Header>
                <Modal.Title>Copy a Recipe</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Container>
                    <Row>
                        Source Recipe Name: {sourceRecipeName}
                    </Row>
                    { createdRecipeId ?
                        <Row>
                            <Alert variant="success">Recipe copied with success !</Alert>
                        </Row> : null
                    }
                    <Row>
                        <Col>Name:</Col>
                        <Col>
                            <Form.Control value={recipeName} onChange={this.onChangeRecipeName} />
                        </Col>
                    </Row>
                    <ChoseBrewingSetupComponent onChange={this.onSelectBrewingSetup} />
                </Container>
            </Modal.Body>

            <Modal.Footer>
                <Button
                    variant="normal"
                    disabled={createdRecipeId !== undefined}
                    onClick={this.onClickCreate}
                >
                    Create
                </Button>
                <Button
                    variant="grey"
                    disabled={createdRecipeId !== undefined}
                    onClick={this.props.onClose}>
                    Cancel
                </Button>
            </Modal.Footer>
            { createdRecipeId ? <Redirect to={`/recipes/view/${createdRecipeId}`}/> : null }
        </Modal>);
    }
}

export default CopyRecipeComponent;
