import { ApiStore } from '@app/stores/apistore';
import { AuthStore } from '@app/stores/authstore';
import { NotificationsStore } from '@app/stores/notificationsstore';
import { SettingsStore } from '@app/stores/settingsstore';
import { UserStore } from '@app/stores/userstore';

/**
 * Root Store Class with
 */
export class RootStore {
  apiStore: ApiStore;
  authStore: AuthStore;
  userStore: UserStore;
  settingsStore: SettingsStore;
  notificationsStore: NotificationsStore;

  constructor() {
    this.apiStore = new ApiStore(this);
    this.authStore = new AuthStore(this);
    this.userStore = new UserStore(this); // Pass `this` into stores for easy communication
    this.settingsStore = new SettingsStore(this);
    this.notificationsStore = new NotificationsStore(this);
  }
}
