
export enum BrewSessionPhase {
   Planning = "planning",
   Brewing = "brewing",
   PrimaryFermentation ="primary-fermentation",
   SecondaryFermentation = "secondary-fermentation",
   Conditioning = "conditioning",
   Ready = "ready",
   AllGone = "all-gone",
}

export enum BrewSessionLogEvent {
    BrewDayComplete = "brew-day-complete",
    FermentationComplete = "fermentation-complete",
    DryHopped = "dry-hopped",
    Racked = "racked",
    Packaged = "packaged",
}

export interface EventData {
    original_gravity?: number,
    final_gravity?: number,
    gravity?: number,
    temperature?: number,
    volume?: number,
}

export const getLogEventFriendyString = (logEvent: BrewSessionLogEvent): string => {
    switch(logEvent) {
        case BrewSessionLogEvent.BrewDayComplete:
            return "Brew Day Complete";
        case BrewSessionLogEvent.FermentationComplete:
            return "Fermentation Complete";
        case BrewSessionLogEvent.DryHopped:
            return "Dry Hopped";
        case BrewSessionLogEvent.Racked:
            return "Racked";
        case BrewSessionLogEvent.Packaged:
            return "Packaged";
    }
}

export interface BrewSessionLogEntry {
    id?: number,
    brew_session_id?: number,
    created_at: number,
    comment: string,
    event_type: BrewSessionLogEvent,
    event_data: EventData,
}

export interface RecipeSummary {
    name: string,
    created_at: number,
    updated_at: number,
}

export interface BrewSession {
    id: number,
    batch_name: string,
    user_id: number,
    recipe_id: number,
    recipe?: RecipeSummary,
    phase: BrewSessionPhase,
    brewing_setup_id: number,
    logs: BrewSessionLogEntry[],
    created_at: number,
    updated_at: number,
}

export const getPhaseFriendlyString = (brewSessionPhase: BrewSessionPhase): string => {
    switch(brewSessionPhase) {
        case BrewSessionPhase.Planning:
            return "Planning";
        case BrewSessionPhase.Brewing:
            return "Brewing";
        case BrewSessionPhase.PrimaryFermentation:
            return "Primary Fermentation";
        case BrewSessionPhase.SecondaryFermentation:
            return "Secondary Fermentation";
        case BrewSessionPhase.Conditioning:
            return "Conditioning";
        case BrewSessionPhase.Ready:
            return "Ready";
        case BrewSessionPhase.AllGone:
            return "All Gone";
    }
}

export const getBrewDateFromLog = (brewLogs: BrewSessionLogEntry[]): number | void => {
    const brewDayLog = brewLogs.find(l => l.event_type === BrewSessionLogEvent.BrewDayComplete);
    return brewDayLog?.created_at;
}

export interface AttenuationInfo {
    attenuation: number,
    abv: number,
}

export const getAttenuationInfo = (brewLogs: BrewSessionLogEntry[]): AttenuationInfo | void => {
    const brewDayComplete = brewLogs.find(log => log.event_type === BrewSessionLogEvent.BrewDayComplete);
    const fermentationComplete = brewLogs.find(log => log.event_type === BrewSessionLogEvent.FermentationComplete);

    if (!brewDayComplete || !fermentationComplete) {
        return;
    }

    const gravity = brewDayComplete.event_data.original_gravity - fermentationComplete.event_data.final_gravity;

    const attenuation = (gravity/(brewDayComplete.event_data.original_gravity - 1.000))*100.00;
    const abv = gravity * 131.25;

    return {
        attenuation,
        abv,
    };
}

export const getBrewDayCompleteEvent = (brewLogs: BrewSessionLogEntry[]): BrewSessionLogEntry | void => {
    return brewLogs.find(log => log.event_type === BrewSessionLogEvent.BrewDayComplete);
}