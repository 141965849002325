

export enum MeasurementType {
    Volume = "volume",
    Mass = "mass",
}

export const friendlyMeasurementTypeString = (measurementType: MeasurementType): string => {
    switch(measurementType) {
        case MeasurementType.Volume:
            return "Volume";
        case MeasurementType.Mass:
            return "Mass";
    }
}

export const getMeasurementTypeUnit = (measurementType: MeasurementType): string => {
    switch(measurementType) {
        case MeasurementType.Volume:
            return "ml";
        case MeasurementType.Mass:
            return "gram(s)";
    }
}