import AwesomeDebouncePromise from "awesome-debounce-promise";
import * as React from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { PlusCircle } from "react-bootstrap-icons";

import Button from "@app/components/common/Button";
import LoadingComponent from "@app/components/common/LoadingComponent";
import NoResultRow from "@app/components/common/NoResultRow";
import PaginationComponent from "@app/components/common/PaginationComponent";
import SortableColumnComponent from "@app/components/common/SortableColumnComponent";
import AddYeastComponent from "@app/components/ingredients/AddYeastComponent";
import YeastItemComponent from "@app/components/ingredients/YeastItemComponent";
import { Yeast } from "@app/models/yeast";
import ServicesHelper from "@app/services/serviceshelper";

const getYeasts = (value: string, offset: number, limit: number, sortBy: string) => {
    return ServicesHelper.instance().ingredients().getYeasts(value, offset, limit, sortBy).then(resp => {
        return resp;
    })
  };

const getYeastsDebounced = AwesomeDebouncePromise(
    getYeasts,
    300,
    { key: (value: string, offset: number, limit: number, sortBy: string) => "YeastNameFilter" },
);

interface State {
    yeasts: Yeast[],
    totalCount: number,
    isLoading: boolean,
    showAddModal: boolean,
    sortBy: string,
    nameFilter?: string,
}

interface Props {
    id?: number,
    action?: string,
}

class YeastsComponent extends React.Component<Props, State> {
    public constructor(props: Props) {
        super(props)

        this.state = {
            yeasts: [],
            totalCount: 0,
            isLoading: false,
            showAddModal: false,
            sortBy: "name",
        }
    }

    public componentDidMount() {
        this.loadYeasts();
    }

    public componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        if(
            (prevProps.action !== undefined && (this.props.action === undefined || this.props.action === "browse"))
            || prevProps.id !== this.props.id
            || prevState.sortBy !== this.state.sortBy
        ) {
            this.loadYeasts();
            window.scrollTo(0, 0);
        }
    }

    public loadYeasts() {
        const offset = ((this.props.id ?? 1) - 1) * 20;
        const { sortBy, nameFilter } = this.state;

        this.setState({
            ...this.state,
            isLoading: true
        });

        ServicesHelper.instance().ingredients().getYeasts(nameFilter, offset, 20, sortBy).then((resp) => {
            this.setState({
                ...this.state,
                yeasts: resp.yeasts,
                totalCount: resp.count,
                isLoading: false,
            })
        }, err => {throw err;});
    }

    public onClickShowAddModal = (evt: React.MouseEvent<HTMLButtonElement>) => {
        this.setState({
            ...this.state,
            showAddModal: true,
        });
    }

    public renderAddButton() {
        return (
            <Row>
                <Col>
                    <Button onClick={this.onClickShowAddModal}>
                        <PlusCircle /> Yeast
                    </Button>
                </Col>
            </Row>
        );
    }

    public onClickSortByColumn = (name: string) => {
        const { sortBy } = this.state;
        let newSortBy: string;

        if(sortBy.endsWith(name)) {
            newSortBy = sortBy.startsWith("-") ? `${name}`: `-${name}`;
        } else {
            newSortBy = name;
        }

        this.setState({
            ...this.state,
            sortBy: newSortBy,
        });
    }

    public onChangeNameFilter = async (evt) => {
        const currentPage = 0;
        const { sortBy } = this.state;

        this.setState({
            ...this.state,
            nameFilter: evt.target.value,
        });

        if(evt.target.value.length >= 3) {
            this.setState({
                ...this.state,
                isLoading: true,
                nameFilter: evt.target.value,
            });

            const yeastsResponse = await getYeastsDebounced(evt.target.value as string, currentPage * 20, 20, sortBy);

            this.setState({
                ...this.state,
                isLoading: false,
                yeasts: yeastsResponse.yeasts,
                totalCount: yeastsResponse.count,
            });

        } else if(evt.target.value.length === 0) {
            this.setState({
                ...this.state,
                nameFilter: evt.target.value,
            }, () => { this.loadYeasts() });
        }
    }

    public renderYeastsTable() {
        const { isLoading, totalCount, sortBy, nameFilter } = this.state;
        const yeasts = this.state.yeasts;
        const currentPage = (this.props.id ?? 1) - 1

        return (
        <>
            <Container fluid className="tableContainer">
                <Row className="filterRow">
                    <Col sm={{offset:1, span:1}}>
                        Name:
                    </Col>
                    <Col sm="3">
                        <Form.Control value={nameFilter} onChange={this.onChangeNameFilter} size="sm" />
                    </Col>
                </Row>
                <Row className="tableHeader">
                    <SortableColumnComponent
                        name="name"
                        label="Name"
                        span={2}
                        currentSortBy={sortBy}
                        onClick={this.onClickSortByColumn}
                    />
                    <SortableColumnComponent
                        name="type"
                        label="Type"
                        span={1}
                        currentSortBy={sortBy}
                        onClick={this.onClickSortByColumn}
                    />
                    <SortableColumnComponent
                        name="form"
                        label="Form"
                        span={1}
                        currentSortBy={sortBy}
                        onClick={this.onClickSortByColumn}
                    />
                    <SortableColumnComponent
                        name="flocculation"
                        label="Flocculation"
                        span={2}
                        currentSortBy={sortBy}
                        onClick={this.onClickSortByColumn}
                    />
                    <SortableColumnComponent
                        name="attenuation"
                        label="Attenuation"
                        span={2}
                        currentSortBy={sortBy}
                        onClick={this.onClickSortByColumn}
                    />
                    <Col sm="2">
                        Temperature
                    </Col>
                    <Col sm="1">
                        Action
                    </Col>
                </Row>
                {
                    isLoading ?
                    <Row className="ingredientsRowItem"><LoadingComponent /></Row> :
                        (yeasts.length === 0 ?
                        <NoResultRow
                            className="ingredientsRowItem"
                            caption="No yeast matching..."
                        />:
                        yeasts.map(yeast => <YeastItemComponent key={yeast.id} yeast={yeast}></YeastItemComponent>)
                    )
                }
                <Row className="paginationRow">
                    <PaginationComponent
                        numberOfPages={Math.ceil(totalCount / 20)}
                        currentPage={currentPage+1}
                        pageLinkPrefix={`/ingredients/yeasts/browse/`}
                    />
                </Row>
            </Container>
        </>
        );

    }

    public renderContent() {
        const action: string = this.props.action ?? "browse";

        switch(action) {
            case "browse":
                return this.renderYeastsTable();
        }

        return (<></>);
    }

    public onHideModal = () => {
        this.setState({
            ...this.state,
            showAddModal: false,
        });
    }

    public onAddYeast = () => {
        this.loadYeasts();
    }

    public render() {
        const { showAddModal } = this.state;

        return (
            <>
                <Row>
                    <Col>
                        <h3>Yeasts</h3>
                    </Col>
                </Row>
                <AddYeastComponent
                    show={showAddModal}
                    onHide={this.onHideModal}
                    onAdd={this.onAddYeast}
                />
                {this.renderAddButton()}
                <Container fluid>
                    {this.renderContent()}
                </Container>
            </>
        );
    }
}

export default YeastsComponent;