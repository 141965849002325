import * as React from "react";
import { Alert, Col, Container, Form, Row } from "react-bootstrap";

import Button from "@app/components/common/Button";
import LoadingComponent from "@app/components/common/LoadingComponent";
import RowFormControlComponent from "@app/components/common/RowFormControlComponent";
import { UserProfile } from "@app/models/user";
import ServicesHelper from "@app/services/serviceshelper";


interface State {
    isLoading: boolean,
    userProfile?: UserProfile,
    avatarFile?: File,
}

class MyProfileComponent extends React.Component<Record<string, never>, State> {
    public constructor(props: Record<string, never>) {
        super(props);

        this.state = {
            isLoading: true,
        };
    }

    public componentDidMount(): void {
        ServicesHelper.instance().user().getMyProfile().then(resp => {
            this.setState({
                ...this.state,
                isLoading: false,
                userProfile: resp,
            });
        }, err => { throw err; });
    }

    public onChangeLocation = (evt: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            ...this.state,
            userProfile: {
                ...this.state.userProfile,
                location: evt.target.value,
            }
        });
    }

    public onChangeWebsite = (evt: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            ...this.state,
            userProfile: {
                ...this.state.userProfile,
                website: evt.target.value,
            }
        });
    }

    public onChangeBrewery = (evt: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            ...this.state,
            userProfile: {
                ...this.state.userProfile,
                my_brewery: evt.target.value,
            }
        });
    }

    public onBlurLocation = (evt: React.FocusEvent<HTMLInputElement>) => {
        ServicesHelper.instance().user().updateProfileLocation(evt.target.value).then(undefined, err => { throw err; });
    }

    public onBlurWebsite = (evt: React.FocusEvent<HTMLInputElement>) => {
        ServicesHelper.instance().user().updateProfileWebSite(evt.target.value).then(undefined, err => { throw err; });
    }

    public onBlurMyBrewery = (evt: React.FocusEvent<HTMLInputElement>) => {
        ServicesHelper.instance().user().updateProfileMyBrewery(evt.target.value).then(undefined, err => { throw err; });
    }

    public onChangeAvatarFile = (evt: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            ...this.state,
            avatarFile: evt.target.files[0],
        });
    }

    public onUploadAvatar = (evt: React.MouseEvent<HTMLButtonElement>) => {
        ServicesHelper.instance().images().uploadProfileAvatar(this.state.avatarFile).then(resp => {
            this.setState({
                ...this.state,
                avatarFile: undefined,
            })
        }, err => {throw err;});
    }

    public renderProfile(userProfile: UserProfile) {
        const { avatarFile } = this.state;
        const sessionKey = ServicesHelper.instance().getSessionKey()
        return <>
            <Row>
                My Avatar
            </Row>
            <Row>
                <Col sm="6">
                    { userProfile.avatar ? <img src={`${userProfile.avatar.image_uri}?session_key=${sessionKey}`} /> : <Alert variant="warning">No avatar yet...</Alert>}
                </Col>
            </Row>
            <Row>
                <Col sm="2">
                    Upload Image
                </Col>
                <Col sm="4">
                    <Form.Control type="file" name="avatarFile" onChange={this.onChangeAvatarFile} />
                </Col>
                <Col sm="2">
                    <Button variant="blue" disabled={!avatarFile} onClick={this.onUploadAvatar}>Upload</Button>
                </Col>
            </Row>
            <Row>
                My Brewery
            </Row>
            <Row>
                <Form.Control
                    as="textarea"
                    rows={5}
                    value={userProfile.my_brewery}
                    onChange={this.onChangeBrewery}
                    onBlur={this.onBlurMyBrewery}
                />
            </Row>
            <RowFormControlComponent
                label="Location"
                labelWidth={2}
                value={userProfile.location}
                onChange={this.onChangeLocation}
                onBlur={this.onBlurLocation}
            />
            <RowFormControlComponent
                label="Website"
                labelWidth={2}
                value={userProfile.website}
                onChange={this.onChangeWebsite}
                onBlur={this.onBlurWebsite}
            />
        </>
    }

    public render() {
        const { isLoading, userProfile } = this.state;

        return <>
            <h4>My Profile</h4>
            { isLoading ? <Row>
                <LoadingComponent />
            </Row> : <Container>
                <Row>
                    <Col md={{span: 10}}>
                        {this.renderProfile(userProfile)}
                    </Col>
                </Row>
            </Container> }
        </>
    }
}

export default MyProfileComponent;