import * as React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { BoxArrowInDown, ClipboardPlus, PencilSquare } from "react-bootstrap-icons";
import { LinkContainer } from "react-router-bootstrap";

import Button from "@app/components/common/Button";
import LoadingComponent from "@app/components/common/LoadingComponent";
import CopyRecipeComponent from "@app/components/recipes/CopyRecipeComponent";
import RecipeAuthorComponent from "@app/components/recipes/RecipeAuthorComponent";
import RecipeImagesComponent from "@app/components/recipes/RecipeImagesComponent";
import RecipeRatingsComponent from "@app/components/recipes/RecipeRatingsComponent";
import { HopAddition, HopUse, PersistedRecipeWithRecipe, getMashFriendlyString, hopFriendlyFormString, hopFriendlyUseString } from "@app/models/recipe";
import { friendlyTimeUsedString } from "@app/models/time_used";
import ServicesHelper from "@app/services/serviceshelper";
import { calculateIBU, calculateRecipeABV, calculateRecipeFG, calculateRecipeOG, calculateRecipePreBoilGravity, calculateSRM, calculateTotalIBU } from "@app/utils/recipe_calculator";

import "@app/assets/scss/components/ViewRecipeComponent.scss";
import "@app/assets/scss/srm.scss";


interface Props {
    recipeId: number,
    ratingId?: number,
}

interface State {
    isLoading: boolean,
    showCopyModal: boolean,
    recipe?: PersistedRecipeWithRecipe,
}

class ViewRecipeComponent extends React.Component<Props, State> {

    public constructor(props: Props) {
        super(props);
        this.state = {
            isLoading: true,
            showCopyModal: false,
        }
    }

    public componentWillReceiveProps(nextProps: Readonly<Props>, nextContext: any): void {
        if (this.props.recipeId !== nextProps.recipeId) {
            ServicesHelper.instance().recipes().getRecipe(nextProps.recipeId).then((resp) => {
                this.setState({
                    ...this.state,
                    recipe: resp,
                    isLoading: false,
                });
            }, err => { throw err; });
        }
    }

    public componentDidMount() {
        ServicesHelper.instance().recipes().getRecipe(this.props.recipeId).then((resp) => {
            this.setState({
                ...this.state,
                recipe: resp,
                isLoading: false,
            });
        }, err => { throw err; });
    }

    public onClickCopy = (evt: React.MouseEvent<HTMLButtonElement>) => {
        this.setState({
            ...this.state,
            showCopyModal: true,
        });
    }

    public onHideCopyModal = () => {
        this.setState({
            ...this.state,
            showCopyModal: false,
        });
    }

    public renderFermentables() {
        const fermentables = this.state.recipe.recipe.fermentables;

        return (
            <Container fluid className="recipeContainer">
                <Row className="recipeHeader">
                    <Col>Name</Col>
                    <Col>Amount</Col>
                    <Col>Lovibond</Col>
                    <Col>PPG</Col>
                </Row>
                {
                    fermentables.map(fermentable => (
                        <Row className="recipeRow" key={`${fermentable.fermentable.name}-${fermentable.amount}-${fermentable.fermentable.point_per_pound_gallon}`}>
                            <Col>{fermentable.fermentable.name}</Col>
                            <Col>{fermentable.amount} g</Col>
                            <Col>{fermentable.fermentable.lovibond}</Col>
                            <Col>{fermentable.fermentable.point_per_pound_gallon}</Col>
                        </Row>
                    ))
                }
            </Container>
        )
    }

    public renderMash() {
        const mashes = this.state.recipe.recipe.mash_steps.sort((a, b) => (a.order - b.order));

        return (
            <Container fluid className="recipeContainer">
                <Row className="recipeHeader">
                    <Col>Type</Col>
                    <Col>Volume</Col>
                    <Col>Temperature</Col>
                    <Col>Time</Col>
                </Row>
                {mashes.map(mash => (
                <Row className="recipeRow" key={`${mash.order}-${mash.volume}-${mash.temperature}-${mash.volume}`}>
                    <Col>{getMashFriendlyString(mash.type)}</Col>
                    <Col>{mash.volume} Liter(s)</Col>
                    <Col>{mash.temperature > 0 ? `${mash.temperature} °C` : null}</Col>
                    <Col>{mash.time > 0 ? `${mash.time} minute(s)` : null}</Col>
                </Row>
                ))}
            </Container>
        )
    }

    public hopFriendlyTimeString(hopAddition: HopAddition) : string {
        if(hopAddition.time === 0) {
            return null;
        }

        if(hopAddition.use === HopUse.Boil || hopAddition.use === HopUse.Mash || hopAddition.use === HopUse.Whirlpool) {
            return `${hopAddition.time} minute(s)`;
        }

        if(hopAddition.use === HopUse.DryHop) {
            return `${hopAddition.time/(24*60)} day(s)`;
        }
    }

    public renderHops() {
        const hops = this.state.recipe.recipe.hop_additions.sort( (a: HopAddition, b: HopAddition) => {
            const rank = {
                [HopUse.Mash]: 0,
                [HopUse.Boil]: 1,
                [HopUse.Whirlpool]: 2,
                [HopUse.DryHop]: 3
            }

            if(a.use === b.use) {
                return b.time - a.time
            }

            return rank[a.use] - rank[b.use]
        })

        const recipe = this.state.recipe.recipe;
        const avgGravity: number = (calculateRecipeOG(recipe) + calculateRecipePreBoilGravity(recipe))/2.000

        return (<Container fluid className="recipeContainer">
                    <Row className="recipeHeader">
                        <Col>Brand</Col>
                        <Col>Alpha Acid</Col>
                        <Col>Used</Col>
                        <Col>Time</Col>
                        <Col>Form</Col>
                        <Col>Temperature</Col>
                        <Col>IBU</Col>
                    </Row>
                {hops.map(hop => {
                        const hopTimeString = this.hopFriendlyTimeString(hop);
                        const hopTime = hop.time;
                        return (
                            <Row className="recipeRow" key={`${hop.hop.name}-${hop.use}-${hop.time}`}>
                                <Col>{hop.hop.name}</Col>
                                <Col>{hop.alpha_acid} %</Col>
                                <Col>{hopFriendlyUseString(hop.use)}</Col>
                                <Col>{hopTimeString ? hopTimeString : null}</Col>
                                <Col>{hopFriendlyFormString(hop.form)}</Col>
                                <Col>{ hop.use === HopUse.Whirlpool && hop.temperature ? `${hop.temperature} °C` : null }</Col>
                                <Col>{((hop.use === HopUse.Boil || hop.use === HopUse.Whirlpool) && hopTime > 0) ? calculateIBU(hop, recipe.batch_size, hopTime, avgGravity).toFixed(2) : null}</Col>
                            </Row>
                        )
                    }
                )}
        </Container>)
    }

    public renderYeast() {
        const yeast = this.state.recipe.recipe.yeast;
        return (<Container fluid className="recipeContainerMarginBottom">
            <Row>
                <Col><span className="recipeLabel">Name</span>: {yeast.yeast.name}</Col>
                <Col><span className="recipeLabel">Attenuation</span>: {yeast.attenuation} %</Col>
            </Row>
        </Container>)
    }

    public renderWaterAdjustment() {
        return (
            <Container fluid className="recipeContainer">
                <Row className="recipeHeader">
                    <Col>Name</Col>
                    <Col>Amount</Col>
                    <Col>Time Used</Col>
                </Row>
                { this.state.recipe.recipe.water_adjustors.map(waterAdjustor => {
                    return (
                    <Row className="recipeRow" key={waterAdjustor.id}>
                        <Col>{waterAdjustor.name}</Col>
                        <Col>{waterAdjustor.amount.toFixed(1)} g</Col>
                        <Col>{friendlyTimeUsedString(waterAdjustor.time_used)}</Col>
                    </Row>)
                }) }
            </Container>
        );
    }

    public renderOtherIngredients() {
        return (
            <Container fluid className="recipeContainer">
                <Row className="recipeHeader">
                    <Col>Name</Col>
                    <Col>Amount</Col>
                    <Col>Time Used</Col>
                </Row>
                { this.state.recipe.recipe.other_ingredients.map(otherIngredient => {
                    return (
                    <Row className="recipeRow" key={otherIngredient.id}>
                        <Col>{otherIngredient.name}</Col>
                        <Col>{otherIngredient.amount.toFixed(1)} g</Col>
                        <Col>{friendlyTimeUsedString(otherIngredient.time_used)}</Col>
                    </Row>)
                }) }
            </Container>
        );
    }

    public renderRecipe() {
        const showCopyModal = this.state.showCopyModal;
        const persistedRecipe = this.state.recipe;
        const recipe = this.state.recipe.recipe;
        const preboilGravity = calculateRecipePreBoilGravity(recipe);
        const originalGravity = calculateRecipeOG(recipe);
        const finalGravity = calculateRecipeFG(recipe);
        const abv = calculateRecipeABV(originalGravity, finalGravity);
        const ibu = calculateTotalIBU(recipe);
        const srm = calculateSRM(recipe);
        const squareSRM = srm > 40 ? 40 : srm;
        const { ratingId } = this.props;

        return (
            <Container fluid>
                <Row>
                    <Col sm="8">
                        <h3 className="recipeTitle">{recipe.name}</h3>
                    </Col>
                    <Col sm={{span:2, offset: 2}}>
                        { persistedRecipe.imported_recipe_id && persistedRecipe.is_mine ?
                            <a target="_blank" href={ServicesHelper.instance().recipes().getImportedRecipeURL(recipe.id)}>
                                <Button variant="light-blue" size="small">
                                    Download Imported Recipe
                                </Button>
                            </a> : null
                        }
                    </Col>
                </Row>
                <Row>
                    <Col sm={{offset:0, span: 6}}>
                        <Button onClick={this.onClickCopy} variant="light-blue">
                            <ClipboardPlus /> Copy
                        </Button>
                        { persistedRecipe.is_mine ?
                        <>
                            <LinkContainer to={`/recipes/edit/${recipe.id}`}>
                                <Button variant="blue" className="button-margin-left">
                                    <PencilSquare /> Modify
                                </Button>
                            </LinkContainer>
                            <LinkContainer to={`/brews/new/${recipe.id}`}>
                                <Button variant="yellow" className="button-margin-left">
                                    <BoxArrowInDown /> Brew
                                </Button>
                            </LinkContainer>
                        </> : null }
                    </Col>
                </Row>
                <Row className="recipeContainerMarginBottom">
                    <Col md="6">
                        <span className="recipeLabel">Batch size</span>: {recipe.batch_size} liter(s) <br />
                        <span className="recipeLabel">Boil size</span>: {recipe.boil_size} liter(s) <br />
                        <span className="recipeLabel">OG</span>: {originalGravity.toFixed(3)} <br />
                        <span className="recipeLabel">FG</span>: {finalGravity.toFixed(3)} <br />
                        <span className="recipeLabel">SRM</span>: {srm.toFixed(1)} <div className={`srm-${squareSRM.toFixed(0)} srm-square`}></div><br />
                        <span className="recipeLabel">IBU</span>: {ibu.toFixed(2)}
                    </Col>
                    <Col md="6">
                        <span className="recipeLabel">Boil time</span>: {recipe.boil_time} minute(s) <br />
                        <span className="recipeLabel">Efficiency</span>: {recipe.efficiency} % <br />
                        <span className="recipeLabel">Pre-Boil Gravity</span>: {preboilGravity.toFixed(3)} <br />
                        <span className="recipeLabel">ABV</span>: {abv.toFixed(2)} % <br />
                        <span className="recipeLabel">Style</span>: {recipe.style.name} <br />
                        <span className="recipeLabel">Visibility</span>: {persistedRecipe.is_public ? 'Public' : 'Private'}
                    </Col>
                </Row>
                {
                    !persistedRecipe.is_mine && persistedRecipe.author ?
                        <Row className="recipeContainerMarginBottom">
                            <RecipeAuthorComponent
                                author={persistedRecipe.author}
                            />
                        </Row> :
                        null
                }
                <Row>
                    <h4 className="recipeSectionTitle">Fermentable(s)</h4>
                    {this.renderFermentables()}
                </Row>
                <Row>
                    <h4 className="recipeSectionTitle">Mash</h4>
                    {this.renderMash()}
                </Row>
                <Row>
                    <h4 className="recipeSectionTitle">Hop(s)</h4>
                    {this.renderHops()}
                </Row>
                {
                    recipe.other_ingredients.length !== 0 ?
                    <Row>
                        <h4 className="recipeSectionTitle">Other ingredient(s)</h4>
                        {this.renderOtherIngredients()}
                    </Row> :
                    null
                }
                {
                    recipe.other_ingredients.length !== 0 ?
                    <Row>
                        <h4 className="recipeSectionTitle">Water Adjustment</h4>
                        {this.renderWaterAdjustment()}
                    </Row> :
                    null
                }
                <Row>
                    <h4 className="recipeSectionTitle">Yeast</h4>
                    {this.renderYeast()}
                </Row>
                <Row>
                    <h4 className="recipeSectionTitle">Note(s)</h4>
                    <Container fluid>
                        {recipe.notes.length === 0 ? `...` : recipe.notes}
                    </Container>
                </Row>
                <Row>
                    <h4 className="recipeSectionTitle">Rating(s)</h4>
                    <RecipeRatingsComponent
                        recipeId={recipe.id}
                        selectedRatingId={ratingId}
                    />
                </Row>
                <RecipeImagesComponent
                    recipeId={persistedRecipe.id}
                    isUploadFormAvailable={persistedRecipe.is_mine}
                />
                { persistedRecipe.import_errors && persistedRecipe.import_errors.recipe_errors.length > 0 ?
                    <Row>
                        <h4 className="recipeSectionTitle">Import error(s)</h4>
                        <Col sm={{offset:1, span: 10}}>
                            <Container fluid>
                                {
                                    persistedRecipe.import_errors.recipe_errors.map(recipeError => {
                                        return (
                                            <Row>
                                                <Col>
                                                    {recipeError.message}
                                                </Col>
                                            </Row>
                                        );
                                    })
                                }
                            </Container>
                        </Col>
                    </Row>
                    : null }
                <CopyRecipeComponent
                    show={showCopyModal}
                    onClose={this.onHideCopyModal}
                    sourceRecipeId={recipe.id}
                    sourceRecipeName={recipe.name}
                />
            </Container>
        )
    }

    public render() {
        return (
            <Container fluid>
                {this.state.isLoading ? <LoadingComponent /> : this.renderRecipe()}
            </Container>
        )
    }
}

export default ViewRecipeComponent;