import * as React from "react";
import { Alert, Row } from "react-bootstrap";

import CardImage from "@app/components/common/CardImage";
import LoadingComponent from "@app/components/common/LoadingComponent";
import UploadRecipeImageComponent from "@app/components/recipes/UploadRecipeImageComponent";
import { Image } from "@app/services/images";
import ServicesHelper from "@app/services/serviceshelper";


interface Props {
    recipeId: number,
    isUploadFormAvailable: boolean,
}

interface State {
    isLoading: boolean,
    images: Image[],
}


class RecipeImagesComponent extends React.Component<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            isLoading: true,
            images: [],
        };
    }

    public loadImages() {
        this.setState({
            ...this.state,
            images: [],
            isLoading: true,
        });

        ServicesHelper.instance().images().getRecipeImages(this.props.recipeId).then(resp => {
            this.setState({
                ...this.state,
                isLoading: false,
                images: resp.images,
            });
        }, err => { throw err; });
    }

    public componentDidMount(): void {
        this.loadImages();
    }

    public onImageUploaded = () => {
        this.loadImages();
    }

    public renderImages() {
        const { images } = this.state;

        return <>
            {
                images.length === 0 ?
                    <Alert variant="warning">There's no image yet for this recipe...</Alert> :
                    images.map(image => <CardImage image={image} />)
            }
        </>
    }

    public render() {
        const { isLoading } = this.state;
        const { recipeId, isUploadFormAvailable } = this.props;

        return <>
            <Row>
                <h4 className="recipeSectionTitle">Image(s)</h4>
            </Row>
            <Row>
                {
                    isLoading ?
                        <LoadingComponent /> :
                        this.renderImages()
                }
            </Row>
            {
                isUploadFormAvailable ?
                    <UploadRecipeImageComponent recipeId={recipeId} onImageUploaded={this.onImageUploaded} /> :
                    null
            }
        </>
    }
}

export default RecipeImagesComponent;