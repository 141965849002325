import * as React from 'react';
import { Button, Col, Row } from "react-bootstrap";
import { Pencil, Trash } from "react-bootstrap-icons";

import { Fermentable, fermentableTypeFriendlyString } from "@app/models/fermentable";
import "@app/assets/scss/components/IngredientsItem.scss"

interface Props {
    fermentable: Fermentable
    onDelete?: (fermentable: Fermentable) => void,
    canEdit: boolean,
}

export class FermentableItemComponent extends React.Component<Props, Record<string,never>> {

    public constructor(props: Props) {
        super(props)
    }

    public onDeleteButton = (evt) => {
        if(this.props.onDelete) {
            this.props.onDelete(this.props.fermentable);
        }
    }

    public render() {
        const { fermentable, canEdit } = this.props;

        return (
            <Row className="ingredientsRowItem">
                <Col sm="3">
                    {fermentable.name}
                </Col>
                <Col sm="2">
                    {fermentableTypeFriendlyString(fermentable.fermentable_type)}
                </Col>
                <Col sm="1">
                    {fermentable.lovibond}
                </Col>
                <Col sm="1">
                    {fermentable.point_per_pound_gallon}
                </Col>
                <Col sm="2">
                    { fermentable.producer?.name ?? ""}
                </Col>
                <Col sm="3">
                    { canEdit ? (
                    <>
                        <Button size="sm">
                            <Pencil /> Edit
                        </Button> |
                        <Button size="sm" onClick={this.onDeleteButton}>
                            <Trash /> Delete
                        </Button>
                    </>) : null
                    }
                </Col>
            </Row>
        );
    }
}