import { Hop } from "@app/models/hop";
import { WaterAdjustor } from "@app/models/water_adjustor";
import { SourceWaterProfile, WaterProfile } from "@app/models/waterProfile";
import { Yeast } from "@app/models/yeast";

import { RecipeUpdateResponse } from "./recipes";
import ServicesHelper from "./serviceshelper";
import { Fermentable } from "../models/fermentable";
import { Country, Producer } from "../models/producer";

export interface FermentablesResponse {
    fermentables: Fermentable[],
    count: number
}

export interface FermentableResponse {
    fermentable: Fermentable
}

export interface AddFermentableBody {
    fermentable: Fermentable
    producer_id?: number
}

export interface AddFermentableResponse {
    fermentable_id: number
}

export interface ProducersResponse {
    producers: Producer[],
    count: number,
}

export interface ProducerUpdateResponse {
    success: boolean,
}

export interface ProducerTextUpdateBody {
    value: string,
}


export interface ProducerNumberUpdateBody {
    value: number,
}

export interface ProducerTextArrayUpdateBody {
    value: string[],
}

export interface HopsResponse {
    hops: Hop[],
    count: number,
}

export interface AddHopBody {
    hop: Hop
}

export interface AddHopResponse {
    hop_id: number;
}

export interface CountriesResponse {
    countries: Country[];
}

export interface ProducerToCreate extends Omit<Producer, "country"> {
    country: number;
}

export interface AddProducerBody {
    producer: ProducerToCreate;
}

export interface AddProducerResponse {
    producer_id: number;
}

export interface YeastsResponse {
    yeasts: Yeast[],
    count: number,
}

export interface AddYeastBody {
    yeast: Yeast,
}

export interface AddYeastResponse {
    yeast_id: number,
}

export interface AddWaterProfileBody {
    water_profile: WaterProfile,
}

export interface AddWaterProfileResponse {
    water_profile_id: number,
    user_id: number,
}

export interface WaterProfilesResponse {
    water_profiles: WaterProfile[],
}

export interface SourceWaterProfilesResponse {
    source_water_profiles: SourceWaterProfile[],
}

export interface SourceWaterProfileResponse {
    source_water_profile: SourceWaterProfile,
}

export interface WaterProfileResponse {
    water_profile: WaterProfile,
}

export interface AddSourceWaterProfileBody {
    source_water_profile: SourceWaterProfile,
}

export interface AddSourceWaterProfileResponse {
    source_water_profile_id: number,
    user_id: number,
}

export interface UpdateWaterResponse {
    success: boolean,
}

export interface WaterAdjustorsResponse {
    water_adjustors: WaterAdjustor[],
}

export class IngredientsService {

    private uri: string;
    private servicesHelper: ServicesHelper

    public constructor(uri: string, servicesHelper: ServicesHelper) {
        this.uri = uri;
        this.servicesHelper = servicesHelper;
    }

    public getFermentables(name?: string, offset = 0, limit = 20, sortBy = "name"): Promise<FermentablesResponse> {
        let suffix = `?offset=${offset}&limit=${limit}&order=${sortBy}`;

        if(name) {
            suffix = `${suffix}&name=${name}`
        }

        return this.servicesHelper.do_get<FermentablesResponse>(`${this.uri}fermentables${suffix}`);
    }

    public addFermentable(fermentable: Fermentable, producerId?: number): Promise<AddFermentableResponse> {
        return this.servicesHelper.do_post<AddFermentableResponse, AddFermentableBody>(`${this.uri}fermentables`, { fermentable, producer_id: producerId });
    }

    public getProducers(): Promise<ProducersResponse> {
        return this.servicesHelper.do_get<ProducersResponse>(`${this.uri}producers`);
    }

    public getCountries(): Promise<CountriesResponse> {
        return this.servicesHelper.do_get<CountriesResponse>(`${this.uri}countries`);
    }

    public getFermentable(fermentableId: number): Promise<FermentableResponse> {
        return this.servicesHelper.do_get<FermentableResponse>(`${this.uri}fermentables/${fermentableId}`);
    }

    public deleteFermentable(fermentable: Fermentable): Promise<Response> {
        return this.servicesHelper.do_delete(`${this.uri}fermentables/${fermentable.id}`);
    }

    public getHops(name?: string, offset = 0, limit = 20, sortBy = "name"): Promise<HopsResponse> {
        let route = `${this.uri}hops?offset=${offset}&limit=${limit}&order=${sortBy}`;

        if(name) {
            route = `${route}&name=${name}`;
        }

        return this.servicesHelper.do_get<HopsResponse>(route);
    }

    public addHop(hop: Hop): Promise<AddHopResponse> {
        return this.servicesHelper.do_post<AddHopResponse, AddHopBody>(`${this.uri}hops`, { hop });
    }

    public deleteHop(hop: Hop) : Promise<Response> {
        return this.servicesHelper.do_delete(`${this.uri}hops/${hop.id}`);
    }

    public addProducer(producer: ProducerToCreate) : Promise<AddProducerResponse> {
        return this.servicesHelper.do_post<AddProducerResponse, AddProducerBody>(`${this.uri}producers`, { producer });
    }

    public updateProducerName(producerId: number, name: string) {
        return this.servicesHelper.do_put<ProducerUpdateResponse, ProducerTextUpdateBody>(`${this.uri}producers/${producerId}/name`, { value: name });
    }

    public updateProducerCountry(producerId: number, countryId: number) {
        return this.servicesHelper.do_put<ProducerUpdateResponse, ProducerNumberUpdateBody>(`${this.uri}producers/${producerId}/country`, { value: countryId });
    }

    public updateProducerAbbreviations(producerId: number, abbreviations: string[]) {
        return this.servicesHelper.do_put<ProducerUpdateResponse, ProducerTextArrayUpdateBody>(`${this.uri}producers/${producerId}/abbreviations`, { value: abbreviations });
    }

    public getYeasts(name?: string, offset = 0, limit = 20, sortBy = "name"): Promise<YeastsResponse> {
        let route = `${this.uri}yeasts?offset=${offset}&limit=${limit}&order=${sortBy}`;
        if(name) {
            route += `&name=${name}`;
        }
        return this.servicesHelper.do_get<YeastsResponse>(route);
    }

    public addYeast(yeast: Yeast): Promise<AddYeastResponse> {
        return this.servicesHelper.do_post<AddYeastResponse, AddYeastBody>(`${this.uri}yeasts`, { yeast });
    }

    public getWaterProfiles(): Promise<WaterProfilesResponse> {
        return this.servicesHelper.do_get<WaterProfilesResponse>(`${this.uri}water_profiles`);
    }

    public getSourceWaterProfiles(): Promise<SourceWaterProfilesResponse> {
        return this.servicesHelper.do_get<SourceWaterProfilesResponse>(`${this.uri}source_water_profiles`);
    }

    public getWaterProfile(waterProfileId: number): Promise<WaterProfileResponse> {
        return this.servicesHelper.do_get<WaterProfileResponse>(`${this.uri}water_profiles/${waterProfileId}`);
    }

    public getSourceWaterProfile(sourceWaterProfileId: number): Promise<SourceWaterProfileResponse> {
        return this.servicesHelper.do_get<SourceWaterProfileResponse>(`${this.uri}source_water_profiles/${sourceWaterProfileId}`);
    }

    public addWaterProfile(waterProfile: WaterProfile): Promise<AddWaterProfileResponse> {
        return this.servicesHelper.do_post<AddWaterProfileResponse, AddWaterProfileBody>(`${this.uri}water_profiles`, {
            water_profile: waterProfile
        });
    }

    public deleteWaterProfile(waterProfileId: number): Promise<Response> {
        return this.servicesHelper.do_delete(`${this.uri}water_profiles/${waterProfileId}`);
    }

    public deleteSourceWaterProfile(sourceWaterProfileId: number): Promise<Response> {
        return this.servicesHelper.do_delete(`${this.uri}source_water_profiles/${sourceWaterProfileId}`);
    }

    public updateWaterProfile(waterProfile: WaterProfile): Promise<UpdateWaterResponse> {
        return this.servicesHelper.do_put<UpdateWaterResponse, AddWaterProfileBody>(`${this.uri}water_profiles/${waterProfile.id}`, {
            water_profile: waterProfile
        });
    }

    public addSourceWaterProfile(sourceWaterProfile: SourceWaterProfile): Promise<AddSourceWaterProfileResponse> {
        return this.servicesHelper.do_post<AddSourceWaterProfileResponse, AddSourceWaterProfileBody>(`${this.uri}source_water_profiles`, {
            source_water_profile: sourceWaterProfile
        });
    }

    public updateSourceWaterProfile(sourceWaterProfile: SourceWaterProfile): Promise<UpdateWaterResponse> {
        return this.servicesHelper.do_put<UpdateWaterResponse, AddSourceWaterProfileBody>(`${this.uri}source_water_profiles/${sourceWaterProfile.id}`, {
            source_water_profile: sourceWaterProfile
        });
    }

    public getWaterAdjustors(): Promise<WaterAdjustorsResponse> {
        return this.servicesHelper.do_get<WaterAdjustorsResponse>(`${this.uri}water_adjustors`);
    }
}
