


import * as React from "react";

import RatingStarComponent from "@app/components/recipes/RatingStarComponent";


interface Props {
    rating: number,
    size?: string,
    onClick?: (evt: React.MouseEvent<SVGElement>, starId: number) => void,
}


class RecipeStarRatingComponent extends React.Component<Props, Record<never, string>> {
    public constructor(props: Props) {
        super(props);
    }

    public render() {

        const { onClick, rating } = this.props;

        if(onClick) {
            return <>
                {[1, 2, 3, 4, 5].map( (i) => this.renderStar(i))}
            </>
        }

        const stars = new Array<number>();
        for(let i=0; i<=(rating/2); i++) {
            stars.push(i+1);
        }

        return <>
             {stars.map( (i) => this.renderStar(i) )}
        </>
    }

    public renderStar(starId: number) {
        const { rating, size, onClick } = this.props;

        const starValue = starId * 2;

        let starFilling: "half" | "full" | "empty" = "empty";

        if(rating >= starValue) {
            starFilling = "full"
        } else if(rating === (starValue - 1)) {
            starFilling = "half";
        }

        return <RatingStarComponent
            key={starId}
            star={starFilling}
            size={size}
            onClick={(evt) => {
                if(onClick) {
                    onClick(evt, starId);
                }
            }}
        />
    }
}


export default RecipeStarRatingComponent;