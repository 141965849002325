import { action, makeObservable, observable } from 'mobx';

import { RootStore } from './rootstore';

export interface IAuthStore {
  sessionKey: string;
  isAuthenticated: boolean;
  isCheckingSessionKey: boolean;
}

export class AuthStore implements IAuthStore {
  private rootStore: RootStore;

  @observable sessionKey: string = null;
  @observable isAuthenticated = false;
  @observable isCheckingSessionKey = false;

  constructor(rootStore?: RootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
  }

  @action setSessionKey(sessionKey: string): void {
      this.sessionKey = sessionKey;
  }

  @action setAuthenticated(isAuthenticated: boolean): void {
      this.isAuthenticated = isAuthenticated;
  }

  @action setIsCheckingSessionKey(isCheckingSessionKey: boolean): void {
      this.isCheckingSessionKey = isCheckingSessionKey;
  }

}
