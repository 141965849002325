import * as React from "react";
import { Col , Row } from "react-bootstrap";
import { Link } from 'react-router-dom';

import { NotificationProps } from "@app/components/notifications/base_notification";
import { RecipeRatingPostedData } from "@app/models/notifications";


class RecipeRatingPostedComponent extends React.Component<NotificationProps<RecipeRatingPostedData>, Record<string, never>> {
    public constructor(props: NotificationProps<RecipeRatingPostedData>) {
        super(props);
    }

    public onClickDownload = (evt: React.MouseEvent<HTMLAnchorElement>) => {
        this.props.ack(this.props.notification.getId());
    }

    public onClick = (evt: React.MouseEvent<Element>) => {
        this.props.ack(this.props.notification.getId());
    }

    public render() {
        const { data } = this.props;

        return <Row>
            <Col sm={{span: 8, offset: 1}}>
                <Link
                    to={`/recipes/view/${data.recipeId}/${data.ratingId}`}
                    onClick={this.onClick}
                >
                    on {data.recipeName} by {data.authorName}
                </Link>
            </Col>
        </Row>;
    }
}

export default RecipeRatingPostedComponent;