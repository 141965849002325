
export interface ServiceDefinition {
    uri: string;
}

export interface UIConfiguration {
    uri: string;
}

export interface ServicesConfiguration {
    bmapi: ServiceDefinition;
    ui: UIConfiguration;
    tap: ServiceDefinition;
    desktopMode: boolean;
}

export enum ErrorType {
    UserInput = "user-input",
    InvalidData = "invalid-data",
}

export interface BadRequestError {
    error_type: ErrorType,
    message: string,
}
