import * as React from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

import LoadingComponent from "@app/components/common/LoadingComponent";
import JobsComponent from "@app/components/jobs/JobsComponent";
import UserChangePasswordComponent from "@app/components/settings/UserChangePasswordComponent";
import { JobStatus } from "@app/models/job";
import { UserSetting } from "@app/models/user";
import ServicesHelper from "@app/services/serviceshelper";

interface State {
    isLoading: boolean,
    isSaving: boolean,
    userSetting?: UserSetting,
    isJobsVisible: boolean,
}

class SettingsComponent extends React.Component<Record<string, never>, State> {
    public constructor(props: Record<string, never>) {
        super(props);

        this.state = {
            isLoading: true,
            isSaving: false,
            isJobsVisible: false,
        };
    }

    public componentDidMount(): void {
        ServicesHelper.instance().user().getSetting().then(resp => {
            this.setState({
                isLoading: false,
                userSetting: resp.setting,
            });
        }, err => { throw err; })
    }

    public onGenerateApiKey = (evt) => {
        this.setState({
            ...this.state,
            isSaving: true,
        });

        ServicesHelper.instance().user().generateApiKey().then(resp => {
            this.setState({
                ...this.state,
                isSaving: false,
                userSetting: {
                    ...this.state.userSetting,
                    api_key: resp.api_key,
                }
            });
        }, err => { throw err; });
    }

    public renderSetting() {
        const { isSaving, userSetting, isJobsVisible } = this.state;

        return (<>
            <Row>
                <Col>
                    API Key:
                </Col>
                <Col>
                    <Form.Control
                        value={userSetting.api_key ?? ""}
                        disabled={true}
                    />
                </Col>
                <Col>
                    <Button
                        size="sm"
                        onClick={this.onGenerateApiKey}
                        disabled={isSaving}
                    >
                        Generate
                    </Button>
                </Col>
            </Row>
            <UserChangePasswordComponent />
            <Row>
                <Col>
                    <h3>Import Tool(s)</h3>
                </Col>
            </Row>
            <Row>
                <LinkContainer to={`/import`}>
                    <Button variant="warning">
                    Go to import Wizard
                    </Button>
                </LinkContainer>
            </Row>
            <Button onClick={this.toggleJobsSection}>
                { isJobsVisible ? `Hide job(s)` : `Show job(s)` }
            </Button>
            { isJobsVisible ?
                <>
                    <h5>Job(s) in progress</h5>
                    <JobsComponent status={JobStatus.InProgress} canAutoRefresh={true} />
                    <h5>Job(s) completed</h5>
                    <JobsComponent status={JobStatus.Completed} />
                    <h5>Job(s) failed</h5>
                    <JobsComponent status={JobStatus.Failed} />
                </> : null
            }
        </>)
    }

    public toggleJobsSection = (evt) => {
        this.setState({
            ...this.state,
            isJobsVisible: !this.state.isJobsVisible,
        });
    }

    public render() {
        const { isLoading } = this.state;

        return (<Container>
            <Row>
                <Col>
                    <h3>Setting(s)</h3>
                </Col>
            </Row>

            {isLoading ?
                <LoadingComponent /> :
                this.renderSetting()
            }

        </Container>)
    }
}

export default SettingsComponent;