import { ApiService } from "@app/services/api";
import { AuthManager } from "@app/services/authmanager";
import { BrewingService } from "@app/services/brewing";
import { ImagesService } from "@app/services/images";
import { IngredientsService } from "@app/services/ingredients";
import { JobsService } from "@app/services/jobs";
import { ServicesConfiguration, UIConfiguration } from "@app/services/models";
import { NotificationsService } from "@app/services/notifications";
import { RecipesService } from "@app/services/recipes";
import { UserService } from "@app/services/user";
import { RootStore } from "@app/stores/rootstore";


const delay = (t: number) => {
    return new Promise(resolve => setTimeout(resolve, t));
}

class ServicesHelper {
    // Singleton part
    private static _instance: ServicesHelper;

    public static instance(wasInitiatedCallback?: (services: ServicesHelper) => void): ServicesHelper {
        if(this._instance) {
            return this._instance;
        }

        this._instance = new ServicesHelper(wasInitiatedCallback);

        return this._instance;
    }

    // Members
    public configuration: ServicesConfiguration;

    // Callback
    private wasInitiatedCallback: (services: ServicesHelper) => void;
    private rootStore: RootStore;

    public constructor(wasInitiatedCallback?: (services: ServicesHelper) => void) {
        this.configuration = {
            bmapi: {
                uri: ""
            },
            ui: {
                uri: ""
            },
            tap: {
                uri: ""
            },
            desktopMode: false,
        }

        this.wasInitiatedCallback = wasInitiatedCallback;
        this.init();
    }

    public setRootStore(rootStore: RootStore) {
        this.rootStore = rootStore;
    }

    public getSessionKey(): string {
        return this.rootStore.authStore.sessionKey;
    }

    public fetchApiInfo() {
        this.api().getApiInfo().then(resp => {
            this.rootStore.apiStore.setApiInfo(resp);
        }, err => { throw err; });
    }

    private init() {
        fetch("/service.json").then(response => response.json()).then(serviceConfig => {
            this.configuration = serviceConfig as ServicesConfiguration;
            this.wasInitiatedCallback(this);
            this.rootStore.settingsStore.setIsDesktopMode(this.configuration.desktopMode);
            this.fetchApiInfo();
        }, err => { throw err; });
    }

    public ui_configuration(): UIConfiguration {
        return this.configuration.ui;
    }

    public ingredients(): IngredientsService {
        return new IngredientsService(`${this.configuration.bmapi.uri}ingredients/`, this);
    }

    public recipes(): RecipesService {
        return new RecipesService(`${this.configuration.bmapi.uri}recipes/`, this);
    }

    public brewing(): BrewingService {
        return new BrewingService(`${this.configuration.bmapi.uri}brewing/`, this);
    }

    public user(): UserService {
        return new UserService(`${this.configuration.bmapi.uri}users/`, this);
    }

    public jobs(): JobsService {
        return new JobsService(`${this.configuration.bmapi.uri}jobs/`, this);
    }

    public images(): ImagesService {
        return new ImagesService(`${this.configuration.bmapi.uri}images/`, this);
    }

    public notifications(): NotificationsService {
        return new NotificationsService(`${this.configuration.bmapi.uri}notifications/`, this);
    }

    public api(): ApiService {
        return new ApiService(`${this.configuration.bmapi.uri}api/`, this);
    }

    public do_get<T>(input: RequestInfo): Promise<T> {
        return this.fetch_with_session(input, { headers: { "Session-Key": this.getSessionKey() }}).then(response => {
            if (!response.ok) {
              throw new Error(response.statusText);
            }
            return response.json() as Promise<T>;
        });
    }

    public do_post_with_formdata<T>(input: RequestInfo, body: FormData): Promise<T> {

        return fetch(input, {
            method: "POST",
            body,
            headers: { "Session-Key": this.getSessionKey() }}
            ).then(response => {

            if (!response.ok) {
              throw new Error(response.statusText);
            }
            return response.json() as Promise<T>;

        });
    }

    public do_post<T, B>(input: RequestInfo, body: B): Promise<T> {

        return fetch(input, {
            method: "POST",
            body: JSON.stringify(body),
            headers: { "Session-Key": this.getSessionKey(), "Content-Type": "application/json" }}
            ).then(response => {

            if (!response.ok) {
              // throw new ServiceError(response, Promise.resolve(response.json()));
              throw response;
            }
            return response.json() as Promise<T>;
        });
    }

    public do_empty_post<T>(input: RequestInfo): Promise<T> {

        return fetch(input, {
            method: "POST",
            headers: { "Session-Key": this.getSessionKey(), "Content-Type": "application/json" }}
            ).then(response => {

            if (!response.ok) {
              throw new Error(response.statusText);
            }
            return response.json() as Promise<T>;
        });
    }

    public do_delete(input: RequestInfo): Promise<Response> {

        return fetch(input, {
            method: "DELETE",
            headers: { "Session-Key": this.getSessionKey() }}
            ).then(response => {

            if (!response.ok) {
              throw new Error(response.statusText);
            }

            return response;
        });
    }

    public do_delete_with_response<T>(input: RequestInfo): Promise<T> {

        return fetch(input, {
            method: "DELETE",
            headers: { "Session-Key": this.getSessionKey() }}
            ).then(response => {

            if (!response.ok) {
              throw new Error(response.statusText);
            }

            return response.json() as Promise<T>;
        });
    }

    public do_post_raw<B>(input: RequestInfo, body: B): Promise<Response> {

        return fetch(input, {
            method: "POST",
            body: JSON.stringify(body),
            headers: { "Session-Key": this.getSessionKey(), "Content-Type": "application/json" }}
            ).then(response => {

            if (!response.ok) {
              throw new Error(response.statusText);
            }

            return response;
        });
    }

    public do_put<T, B>(input: RequestInfo, body: B): Promise<T> {

        return fetch(input, {
            method: "PUT",
            body: JSON.stringify(body),
            headers: { "Session-Key": this.getSessionKey(), "Content-Type": "application/json" }}
            ).then(response => {

            if (!response.ok) {
                throw response;
            }

            return response.json() as Promise<T>;
        });
    }

    public fetch_with_session(request: RequestInfo, reqInit: RequestInit) {
        return fetch(request, reqInit).then(resp => {
            if(resp.status === 401) {
                return AuthManager.instance().do_session_refresh().then(loginResponse => {
                    if(loginResponse === undefined) {
                        return resp;
                    } else {
                        return Promise.resolve(fetch(request, {
                            ...reqInit,
                            headers: {
                                ...reqInit.headers,
                                "Session-Key": loginResponse.session.key,
                            }
                        }).then(secondResp => {
                            return secondResp;
                        }));
                    }
                });
            } else {
                return resp;
            }
        });
    }

    public do_fetch(request: RequestInfo, reqInit: RequestInit, retry = true, count = 0, maxRetry = 2): Promise<Response> {
        return fetch(request, reqInit).then(resp => {
            return resp;
        }, err => {
            if (retry && count < maxRetry) {
                return delay(500).then(
                    () =>
                    { return this.do_fetch(request, reqInit, retry, count + 1, maxRetry); }
                );
            } else {
                throw err;
            }
        });
    }
}

export default ServicesHelper;