import { BrewSession } from "@app/models/brew_session";
import { PersistedRecipe } from "@app/models/recipe";
import { UserProfile, UserSetting } from "@app/models/user";
import ServicesHelper from "@app/services/serviceshelper";

export interface UserSettingResponse {
    setting: UserSetting
}

export interface GenerateApiKeyResponse {
    api_key: string,
}

export interface ChangePasswordResponse {
    success: boolean,
    message?: string,
}

export interface ChangePasswordBody {
    old_password: string,
    new_password: string,
}

export interface UserTextUpdateBody {
    value: string,
}

export interface PutResponse {
    success: boolean,
}

export interface GetUserProfileResponse {
    user_profile: UserProfile,
    recipes: PersistedRecipe[],
    brew_sessions: BrewSession[],
}

export interface ResetPasswordRequestBody {
    email: string,
}

export interface ResetPasswordBody {
    email: string,
    new_password: string,
    reset_code: string,
}

export class UserService {

    private uri: string;
    private servicesHelper: ServicesHelper

    public constructor(uri: string, servicesHelper: ServicesHelper) {
        this.uri = uri;
        this.servicesHelper = servicesHelper;
    }

    public getSetting(): Promise<UserSettingResponse> {
        return this.servicesHelper.do_get<UserSettingResponse>(`${this.uri}setting`)
    }

    public generateApiKey(): Promise<GenerateApiKeyResponse> {
        return this.servicesHelper.do_empty_post<GenerateApiKeyResponse>(`${this.uri}setting/api_key`);
    }

    public changePassword(oldPassword: string, newPassword: string): Promise<ChangePasswordResponse> {
        return this.servicesHelper.do_put<ChangePasswordResponse, ChangePasswordBody>(`${this.uri}password`, {
            old_password: oldPassword,
            new_password: newPassword,
        });
    }

    public getMyProfile(): Promise<UserProfile> {
        return this.servicesHelper.do_get<UserProfile>(`${this.uri}profile/mine`);
    }

    public getUserProfile(userId: number): Promise<GetUserProfileResponse> {
        return this.servicesHelper.do_get<GetUserProfileResponse>(`${this.uri}profile/${userId}`);
    }

    public updateProfileLocation(location: string): Promise<PutResponse> {
        return this.servicesHelper.do_put<PutResponse, UserTextUpdateBody>(`${this.uri}profile/location`, {value: location});
    }

    public updateProfileMyBrewery(myBrewery: string): Promise<PutResponse> {
        return this.servicesHelper.do_put<PutResponse, UserTextUpdateBody>(`${this.uri}profile/my_brewery`, {value: myBrewery});
    }

    public updateProfileWebSite(website: string): Promise<PutResponse> {
        return this.servicesHelper.do_put<PutResponse, UserTextUpdateBody>(`${this.uri}profile/website`, {value: website});
    }

    public requestPasswordReset(email: string): Promise<Response> {
        return this.servicesHelper.do_post<Response, ResetPasswordRequestBody>(`${this.uri}password/reset`, {
            email,
        });
    }

public passwordReset(email: string, new_password: string, reset_code: string): Promise<Response> {
        return this.servicesHelper.do_put<Response, ResetPasswordBody>(`${this.uri}password/reset`, {
            email,
            new_password,
            reset_code,
        });
    }
 }
