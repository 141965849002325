import * as React from "react";
import { Button, Container, Modal, Row } from "react-bootstrap";

import LoadingComponent from "@app/components/common/LoadingComponent";
import { WaterAdjustor } from "@app/models/water_adjustor";
import ServicesHelper from "@app/services/serviceshelper";

interface Props {
    show: boolean,
    onClose: () => void,
    onChoose: (waterAdjustor: WaterAdjustor) => void,
}

interface State {
    isLoading: boolean,
    waterAdjustors: WaterAdjustor[]
}

class AddWaterAdjustorComponent extends React.Component<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            isLoading: true,
            waterAdjustors: [],
        }
    }

    public componentDidMount(): void {
        ServicesHelper.instance().ingredients().getWaterAdjustors().then(resp => {
            this.setState({
                ...this.state,
                isLoading: false,
                waterAdjustors: resp.water_adjustors,
            })
        }, err => { throw err; });
    }

    public renderWaterAdjustors() {
        return <>
            {
                this.state.waterAdjustors.map(waterAdjustor =>
                    <Row key={waterAdjustor.id}>
                        <Button className="btn-modal" onClick={(evt) => { this.onChoose(waterAdjustor); }}>{waterAdjustor.name}</Button>
                    </Row>)
            }
        </>;
    }

    public onChoose = (waterAdjustor: WaterAdjustor) => {
        this.props.onChoose(waterAdjustor);
    }

    public render() {
        const { show, onClose } = this.props;
        const { isLoading } = this.state;

        return (
        <Modal show={show} onHide={onClose}>
            <Modal.Header>
                <Modal.Title>Add water adjustor</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <p>Select water adjustor:</p>
                <Container>
                    { isLoading ? <LoadingComponent /> : this.renderWaterAdjustors() }
                </Container>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="secondary" onClick={this.props.onClose}>Cancel</Button>
            </Modal.Footer>
        </Modal>);
    }
}

export default AddWaterAdjustorComponent;