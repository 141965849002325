
import * as React from "react";
import { Star, StarFill, StarHalf } from "react-bootstrap-icons";


interface Props {
    star: "half" | "full" | "empty",
    size?: string,
    onClick?: (evt: React.MouseEvent<SVGElement>) => void,
}


class RatingStarComponent extends React.Component<Props, Record<never, string>> {
    public constructor(props: Props) {
        super(props);
    }

    public render() {
        const { star, onClick, size } = this.props;
        const computedSize = size ?? "2em";

        switch(star) {
            case "empty":
                return <Star fontSize={computedSize} onClick={onClick} />
            case "half":
                return <StarHalf fontSize={computedSize} onClick={onClick} />
            case "full":
                return <StarFill fontSize={computedSize} onClick={onClick} />
        }
    }
}

export default RatingStarComponent;