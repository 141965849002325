import { observer } from "mobx-react";
import * as React from "react";
import { Alert, Container, Form } from "react-bootstrap";
import { DoorOpen, ExclamationTriangle } from "react-bootstrap-icons";

import Button from "@app/components/common/Button";
import ResetPasswordComponent from "@app/components/users/ResetPasswordComponent";
import SignUpComponent from "@app/components/users/SignUpComponent";
import { AuthManager } from "@app/services/authmanager";
import ServicesHelper from "@app/services/serviceshelper";
import { ApiStore } from "@app/stores/apistore";
import { AuthStore } from "@app/stores/authstore";


import "@app/assets/scss/components/LoginComponent.scss";

enum ComponentState {
    Login = "login",
    SignUp = "sign-up",
    ResetPassword = "reset-password",
}

interface State {
    isSubmitting: boolean;
    email: string;
    password: string;
    resetCode: string;
    firstCheck: boolean;
    componentState: ComponentState,
    changePassword: boolean,
    loginError?: string;
}

interface Props {
    authStore: AuthStore;
    apiStore: ApiStore;
}

@observer
class LoginComponent extends React.Component <Props, State>{

    public constructor(props: Props) {
        super(props);

        this.state = {
            isSubmitting: false,
            email: "",
            password: "",
            resetCode: "",
            firstCheck: false,
            changePassword: false,
            componentState: ComponentState.Login,
        }
    }

    public componentDidMount(): void {
        this.setState({
            ...this.state,
            firstCheck: true,
        });

    }

    public componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        if(!prevState.firstCheck)
        {
            this.setState({
                ...this.state,
                firstCheck: true,
            })
            AuthManager.instance().initiate_authentication(ServicesHelper.instance());
        }
    }

    public onChangeEmailValue = (email: string) => {
        this.setState({
            ...this.state,
            email,
        });
    }

    public onChangeEmail = (evt) => {
        this.setState({
            ...this.state,
            email: evt.target.value
        });
    }

    public onChangePassword = (evt) => {
        this.setState({
            ...this.state,
            password: evt.target.value
        });
    }

    public login = () => {
        this.setState({
            ...this.state,
            loginError: undefined,
            isSubmitting: true
        });

        AuthManager.instance().do_login(this.state.email, this.state.password).catch(
            err => {
                this.setState({
                    ...this.state,
                    isSubmitting: false,
                    loginError: "Invalid email and/or password!"
                })
            }
        );
    }

    public onClickSignUpForm = (evt: React.MouseEvent<HTMLButtonElement>) => {
        this.setState({
            ...this.state,
            loginError: undefined,
            componentState: ComponentState.SignUp,
        })
    }

    public onClickHideSignUpForm = (evt: React.MouseEvent<HTMLButtonElement>) => {
        this.setState({
            ...this.state,
            loginError: undefined,
            componentState: ComponentState.Login,
        })
    }

    public onUserCreated = () => {
        this.setState({
            ...this.state,
            loginError: undefined,
            componentState: ComponentState.Login,
        })
    }

    public onKeyUp = (evt) => {
        if(evt.keyCode === 13 && !this.state.isSubmitting) {
            this.login();
        }
    }

    public renderSignUpForm() {
        return <>
            <SignUpComponent onCreated={this.onUserCreated} isBetaMode={this.props.apiStore.apiInfo?.beta_mode ?? false}>
            </SignUpComponent>
            <Container className="cancelButtonContainer">
                <Button variant="blue" onClick={this.onClickHideSignUpForm}>Cancel</Button>
            </Container>
        </>
    }

    public onClickIForgotMyPassword = (evt: React.MouseEvent<HTMLButtonElement>) => {
        this.setState({
            ...this.state,
            componentState: ComponentState.ResetPassword,
        });
    }

    public onCancelResetPassword = () => {
        this.setState({
            ...this.state,
            componentState: ComponentState.Login,
        });
    }

    public renderResetPasswordForm() {
        const { email } = this.state;

        return <ResetPasswordComponent
            email={email}
            onChangeEmail={this.onChangeEmailValue}
            onExit={this.onCancelResetPassword}
        />
    }

    public renderLoginForm() {
        const { loginError, isSubmitting } = this.state;

        return <Form>
        <h3>
            <DoorOpen width="48" height="48"/> Login
        </h3>
        <Container fluid>
            { loginError ? (
                <Alert variant="danger">
                   <ExclamationTriangle /> {loginError}
                </Alert>
            ) : null}
        </Container>
        <Form.Group controlId="Email">
            <Form.Label>Email</Form.Label>
            <Form.Control
                className="loginFormControl"
                type="text"
                placeholder="name@email.net"
                disabled={isSubmitting}
                onChange={this.onChangeEmail}
            />
        </Form.Group>
        <Form.Group controlId="Password">
            <Form.Label>Password</Form.Label>
            <Form.Control
                className="loginFormControl"
                type="password"
                placeholder="******"
                disabled={isSubmitting}
                onChange={this.onChangePassword}
                onKeyUp={this.onKeyUp}
            />
        </Form.Group>
        <Container className="loginFormButtonContainer">
            <Button
                className="formButton"
                variant="blue"
                disabled={isSubmitting}
                onClick={this.login}
            >
                Login
            </Button>
            <Button
                className="formButton"
                variant="blue"
                disabled={isSubmitting}
                onClick={this.onClickSignUpForm}
            >
                Create an account
            </Button>
            <Button
                className="formButton"
                variant="blue"
                disabled={isSubmitting}
                onClick={this.onClickIForgotMyPassword}
            >
                I forgot my password
            </Button>
        </Container>
    </Form>
    }

    public innerRender() {
        const { componentState } = this.state;

        switch(componentState) {
            case ComponentState.Login:
                return this.renderLoginForm();
            case ComponentState.SignUp:
                return this.renderSignUpForm();
            case ComponentState.ResetPassword:
                return this.renderResetPasswordForm();
        }
    }

    public render() {
        return (
            <Container className="loginComponent">
                {this.props.authStore.isCheckingSessionKey ?
                    (<h3>Checking your existing session...</h3>) :
                    this.innerRender()
                }
            </Container>
            );
    }
}

export default LoginComponent;