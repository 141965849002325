import * as React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from 'react-router-dom';

import { RecipeAuthor } from "@app/models/recipe";
import ServicesHelper from "@app/services/serviceshelper";

import "@app/assets/scss/components/AuthorComponent.scss";

interface Props {
    author: RecipeAuthor
}

class RecipeAuthorComponent extends React.Component<Props, Record<string, never>> {
    public constructor(props: Props) {
        super(props);
    }

    public render() {
        const { author } = this.props;
        const sessionKey = ServicesHelper.instance().getSessionKey();

        return (<Container>
            <Row>
                <Col sm={{offset:6, span: 2}}>
                    <h4>Author</h4>
                    <Link to={`/profiles/${author.id}`}>{author.name}</Link>
                </Col>
                <Col sm="2">
                    { author.avatar?.image_uri ?
                        <img width="50%" src={`${author.avatar.image_uri}?session_key=${sessionKey}`} /> :
                        null }
                </Col>
            </Row>
        </Container>)
    }
}

export default RecipeAuthorComponent;