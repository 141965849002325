import AwesomeDebouncePromise from "awesome-debounce-promise"
import * as React from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import "@app/assets/scss/components/EditRecipeComponent.scss";
import "@app/assets/scss/srm.scss";
import { PencilSquare } from "react-bootstrap-icons";

import FormControlComponent from "@app/components/common/FormControlComponent";
import LoadingComponent from "@app/components/common/LoadingComponent";
import AddFermentableToRecipe from "@app/components/recipes/AddFermentableToRecipe";
import AddHopAdditionComponent from "@app/components/recipes/AddHopAdditionComponent";
import AddOtherIngredientComponent from "@app/components/recipes/AddOtherIngredientComponent";
import AddWaterAdjustorComponent from "@app/components/recipes/AddWaterAdjustorComponent";
import EditFermentableComponent from "@app/components/recipes/EditFermentableComponent";
import EditHopComponent from "@app/components/recipes/EditHopComponent";
import EditMashStepComponent from "@app/components/recipes/EditMashStepComponent";
import EditOtherIngredientComponent from "@app/components/recipes/EditOtherIngredientComponent";
import EditSourceWaterComponent from "@app/components/recipes/EditSourceWaterComponent";
import EditWaterAdjustorComponent from "@app/components/recipes/EditWaterAdjustorComponent";
import EditYeastComponent from "@app/components/recipes/EditYeastComponent";
import RecipeWaterReportComponent from "@app/components/recipes/RecipeWaterReportComponent";
import { BeerStyle } from "@app/models/beer_style";
import { BrewingSetup } from "@app/models/brewing_setup";
import { Fermentable } from "@app/models/fermentable";
import { FieldValidation, FieldValidations } from "@app/models/fields";
import { HopAddition, HopUse, MashStep, MashStepType, PersistedRecipeWithRecipe, RecipeFermentable, RecipeOtherIngredient, RecipeYeast } from "@app/models/recipe";
import { RecipeWaterAdjustor, WaterAdjustor } from "@app/models/water_adjustor";
import { SourceWaterProfile } from "@app/models/waterProfile";
import { RecipeFloatField, RecipeIntField, RecipeMashStepFloatField, RecipeMashStepIntField, RecipeTextField } from "@app/services/recipes";
import ServicesHelper from "@app/services/serviceshelper";
import { calculateDiastaticPower, calculateMashPH, calculateRecipeABV, calculateRecipeFG, calculateRecipeOG, calculateRecipePreBoilGravity, calculateSRM, calculateTotalIBU } from "@app/utils/recipe_calculator";

import Button from "../common/Button";


interface Props {
    recipeId: number,
}

interface State {
    isLoading: boolean,
    recipe?: PersistedRecipeWithRecipe,
    beerStyles: BeerStyle[],
    invalidFields?: FieldValidations,
    showAddWaterAdjustorModal: boolean,
    showAddOtherIngredientModal: boolean,
    showAddHopAdditionModal: boolean,
    availableBrewingSetups: BrewingSetup[],
}

const saveTextFieldValue = (recipeId: number, field: RecipeTextField, value: string) => {
  return ServicesHelper.instance().recipes().updateRecipeTextField(recipeId, field, value).then(resp => {
        return resp;
  }, err => { throw err; });
};

const saveFloatValue = (recipeId: number, field: RecipeFloatField, value: number) => {
    return ServicesHelper.instance().recipes().updateRecipeFloatField(recipeId, field, value).then(resp => {
        return resp;
    }, err => { throw err; });
};

const saveMashStepFloatValue = (recipeId: number, field: RecipeMashStepFloatField, mashStepId: number, value: number) => {
    return ServicesHelper.instance().recipes().updateRecipeMashStepFloatField(recipeId, field, mashStepId, value).then(resp => {
        return resp;
    }, err => { throw err; });
};

const saveMashStepIntValue = (recipeId: number, field: RecipeMashStepIntField, mashStepId: number, value: number | string) => {
    return ServicesHelper.instance().recipes().updateRecipeMashStepIntField(recipeId, field, mashStepId, value).then(resp => {
        return resp;
    }, err => { throw err; });
};

const saveIntValue = (recipeId: number, field: RecipeIntField, value: number) => {
    return ServicesHelper.instance().recipes().updateRecipeIntField(recipeId, field, value).then(resp => {
        return resp;
    }, err => { throw err; });
};

const saveFermentableAmount = (recipeId: number, fermentableName: string, value: number) => {
    return ServicesHelper.instance().recipes().updateFermentableAmount(recipeId, fermentableName, value).then(resp => {
        return resp;
    }, err => { throw err; })
};

const saveTextFieldValueDebounced = AwesomeDebouncePromise(
    saveTextFieldValue,
    800,
    { key: (recipeId: number, field: RecipeTextField, value: string) => field },
);

const saveFloatValueDebounced = AwesomeDebouncePromise(
    saveFloatValue,
    800,
    { key: (recipeId: number, field: RecipeFloatField, value: number) => field },
);

const saveMashStepFloatValueDebounced = AwesomeDebouncePromise(
    saveMashStepFloatValue,
    800,
    { key: (recipeId: number, field: RecipeMashStepFloatField, mashStepId: number, value: number) => `${field}_${mashStepId}` },
);

const saveMashStepIntValueDebounced = AwesomeDebouncePromise(
    saveMashStepIntValue,
    800,
    { key: (recipeId: number, field: RecipeMashStepIntField, mashStepId: number, value: number | string) => `${field}_${mashStepId}` },
);

const saveIntValueDebounced = AwesomeDebouncePromise(
    saveIntValue,
    800,
    { key: (recipeId: number, field: RecipeIntField, value: number) => field },
);

const saveFermentableAmountValueDebounced = AwesomeDebouncePromise(
    saveFermentableAmount,
    800,
    { key: (recipeId: number, fermentableName: string, value: number) => fermentableName },
);


class EditRecipeComponent extends React.Component<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            isLoading: true,
            beerStyles: [],
            showAddWaterAdjustorModal: false,
            availableBrewingSetups: [],
            showAddHopAdditionModal: false,
            showAddOtherIngredientModal: false,
        };
    }

    public componentDidMount(): void {
        ServicesHelper.instance().recipes().getBeerStyles().then(resp => {
            this.setState({
                ...this.state,
                beerStyles: resp.styles,
            });
        }, err => { throw err; });

        ServicesHelper.instance().brewing().getBrewingSetups().then(resp => {
            this.setState({
                ...this.state,
                availableBrewingSetups: resp.brewing_setups,
            });
        }, err => { throw err; });

        ServicesHelper.instance().recipes().getRecipe(this.props.recipeId).then(resp => {
            this.setState({
                ...this.state,
                recipe: resp,
                isLoading: false,
            });
        }, err => { throw err; });

        window.scrollTo(0, 0);
    }

    public onChangeRecipeName = async (evt) => {
        const recipe = this.state.recipe;
        recipe.name = evt.target.value;
        recipe.recipe.name = evt.target.value;

        this.setState({
            ...this.state,
            recipe,
        });

        await saveTextFieldValueDebounced(recipe.id, RecipeTextField.Name, recipe.recipe.name);
    }

    public onChangeRecipeNotes = async (evt) => {
        const recipe = this.state.recipe;
        recipe.recipe.notes = evt.target.value;

        this.setState({
            ...this.state,
            recipe,
        });

        await saveTextFieldValueDebounced(recipe.id, RecipeTextField.Notes, recipe.recipe.notes);
    }

    public reduceFieldValidations(fieldName: string) : FieldValidations {
        const newFieldValidations: FieldValidations = {};
        const fieldValidations = this.state.invalidFields ?? {}

        Object.keys(fieldValidations).forEach(key => {
            if (key !== fieldName) {
                newFieldValidations[key] = fieldValidations[key];
            }
        })

        return newFieldValidations;
    }

    public fieldValueIsANumber(fieldName: string, fieldValue: string) : number | undefined {
        const value = Number(fieldValue);

        if (isNaN(value)) {
            const invalidFields = this.state.invalidFields ?? {};
            invalidFields[fieldName] = { message: `${fieldValue} is not a Number`, currentValue: fieldValue }
            this.setState({
                ...this.state,
                invalidFields,
            })
            return undefined;
        }

        return value;
    }

    public fieldValueIsAnInt(fieldName: string, fieldValue: string) : number | undefined {
        const value = Number(fieldValue);
        if (!Number.isInteger(value)) {
            const invalidFields = this.state.invalidFields ?? {};
            invalidFields[fieldName] = { message: `${fieldValue} is not an Integer`, currentValue: fieldValue }
            this.setState({
                ...this.state,
                invalidFields,
            })
            return undefined;
        }

        return value;
    }

    public onChangeBatchSize = async (evt) => {
        const recipe = this.state.recipe;
        const batchSize = this.fieldValueIsANumber("batch_size", evt.target.value as string);
        if (batchSize === undefined){
            return;
        }


        recipe.recipe.batch_size = batchSize;
        const invalidFields = this.reduceFieldValidations("batch_size");

        this.setState({
            ...this.state,
            recipe,
            invalidFields,
        });

        await saveFloatValueDebounced(recipe.id, RecipeFloatField.BatchSize, batchSize);
    }

    public onChangeBoilSize = async (evt) => {
        const recipe = this.state.recipe;
        const boilSize = this.fieldValueIsANumber("boil_size", evt.target.value as string);
        if (boilSize === undefined){
            return;
        }

        recipe.recipe.boil_size = boilSize;
        const invalidFields = this.reduceFieldValidations("boil_size");

        this.setState({
            ...this.state,
            recipe,
            invalidFields,
        });

        await saveFloatValueDebounced(recipe.id, RecipeFloatField.BoilSize, boilSize);
    }

    public onChangeBoilTime = async (evt) => {
        const recipe = this.state.recipe;
        const boilTime = this.fieldValueIsAnInt("boil_time", evt.target.value as string);
        if (boilTime === undefined){
            return;
        }


        recipe.recipe.boil_time = boilTime;
        const invalidFields = this.reduceFieldValidations("boil_time");

        this.setState({
            ...this.state,
            recipe,
            invalidFields,
        });

        await saveIntValueDebounced(recipe.id, RecipeIntField.BoilTime, boilTime);
    }

    public onChangeEfficiency = async (evt) => {
        const recipe = this.state.recipe;
        const efficiency = this.fieldValueIsANumber("efficiency", evt.target.value as string);
        if (efficiency === undefined){
            return;
        }


        recipe.recipe.efficiency = efficiency;
        const invalidFields = this.reduceFieldValidations("efficiency");

        this.setState({
            ...this.state,
            recipe,
            invalidFields,
        });

        await saveFloatValueDebounced(recipe.id, RecipeFloatField.Efficiency, efficiency);
    }

    public getFieldValidation(fieldName: string) : FieldValidation | undefined {
        if(this.state.invalidFields) {
            if(this.state.invalidFields[fieldName]) {
                return this.state.invalidFields[fieldName];
            }
        }

        return undefined;
    }

    public getBeerStyleByName(name: string): BeerStyle | undefined {
        let selectedBeerStyle: BeerStyle | undefined;

        this.state.beerStyles.forEach(beerStyle => {
            if(beerStyle.name === name) {
                selectedBeerStyle = beerStyle;
            }
        })

        return selectedBeerStyle;
    }

    public onChangeBeerStyle = (evt) => {
        if(!this.state.recipe) {
            return;
        }

        const beerStyle = this.getBeerStyleByName(evt.target.value as string);
        if(!beerStyle) {
            return;
        }

        const recipe = this.state.recipe;
        recipe.recipe.style = beerStyle;

        this.setState({
            ...this.state,
            recipe,
        });

        ServicesHelper.instance()
            .recipes()
            .updateRecipeTextField(recipe.id, RecipeTextField.Style, beerStyle.name)
            .then(resp => { return; }, err => { throw err; });
    }

    public onChangeFermentableAmount = async (name: string, amount: number) => {
        const recipe = this.state.recipe;
        const newRecipeFermentables: RecipeFermentable[] = [];

        recipe.recipe.fermentables.forEach(fermentable => {
            if(fermentable.fermentable.name === name) {
                fermentable.amount = amount;
            }

            newRecipeFermentables.push(fermentable);
        });

        const newRecipe: PersistedRecipeWithRecipe = {
            ...recipe,
            recipe: {
                ...recipe.recipe,
                fermentables: newRecipeFermentables,
            },
        };

        this.setState({
            ...this.state,
            recipe: newRecipe,
        });

        await saveFermentableAmountValueDebounced(recipe.id, name, amount);
    }

    public onDeleteFermentable = (fermentable: RecipeFermentable) => {

        const newRecipeFermentables: RecipeFermentable[] = [];

        this.state.recipe.recipe.fermentables.forEach(recipeFermentable => {
            if(recipeFermentable.fermentable.id === fermentable.fermentable.id) {
                return;
            }

            newRecipeFermentables.push(recipeFermentable);
        });

        this.setState({
            ...this.state,
            recipe: {
                ...this.state.recipe,
                recipe: {
                    ...this.state.recipe.recipe,
                    fermentables: newRecipeFermentables,
                }
            }
        });

        ServicesHelper.instance().recipes().deleteFermentableFromRecipe(
            this.state.recipe.id,
            fermentable.fermentable.name
        ).then(resp => { return; }, err => { throw err; });
    }

    public renderFermentables() {
        const recipe = this.state.recipe;

        const diastaticPower = calculateDiastaticPower(recipe.recipe);

        if(!recipe) {
            return (<></>);
        }

        const fermentables = recipe.recipe.fermentables;
        let totalGrains = 0;
        recipe.recipe.fermentables.forEach(fermentable => {
            totalGrains += fermentable.amount;
        })

        return (
        <Container fluid>
            {fermentables.map(fermentable =>
                    <EditFermentableComponent
                        key={fermentable.fermentable.name}
                        fermentable={fermentable}
                        totalGrains={totalGrains}
                        onChangeAmount={this.onChangeFermentableAmount}
                        onDelete={this.onDeleteFermentable}
                    />
                )
            }
            <Row>
                <Col sm={{ span:2, offset:1 }}>
                    Total: {(totalGrains / 1000.000).toFixed(2)} kg(s)
                </Col>
                <Col sm="2">
                    Diastatic Power: {diastaticPower.toFixed(0)}
                </Col>
            </Row>
            <AddFermentableToRecipe onAddFermentable={this.onAddFermentable}/>
        </Container>);
    }

    public onAddFermentable = (fermentable: Fermentable) => {
        const recipe = this.state.recipe;
        if(!recipe) {
            return;
        }

        let existingFermentable: Fermentable;

        recipe.recipe.fermentables.forEach(recipeFermentable => {
            if(recipeFermentable.fermentable.id === fermentable.id) {
                existingFermentable = recipeFermentable.fermentable;
            }
        })

        if(existingFermentable) {
            // todo
            return;
        }

        const newFermentables: RecipeFermentable[] = recipe.recipe.fermentables;
        newFermentables.push({
            fermentable,
            amount: 0
        })

        const newRecipe: PersistedRecipeWithRecipe = {
            ...recipe,
            recipe: {
                ...recipe.recipe,
                fermentables: newFermentables,
            }
        }

        ServicesHelper.instance()
            .recipes()
            .addFermentableToRecipe(recipe.id, fermentable.id, 0)
            .then(resp => { return; }, err => { throw err; });

        this.setState({
            ...this.state,
            recipe: newRecipe,
        });
    }

    public onChangeMashType = (mashStepId: number, type: MashStepType) => {
        ServicesHelper.instance()
            .recipes()
            .updateRecipeMashStepType(this.state.recipe.id, mashStepId, type)
            .then(resp => { return; }, err => { throw err; });

        const newMashSteps: MashStep[] = [];

        this.state.recipe.recipe.mash_steps.forEach(mashStep => {
            if(mashStep.id === mashStepId) {
                mashStep.type = type;
            }

            newMashSteps.push(mashStep);
        });

        this.setState({
            ...this.state,
            recipe: {
                ...this.state.recipe,
                recipe: {
                    ...this.state.recipe.recipe,
                    mash_steps: newMashSteps,
                }
            }
        });
    }

    public onChangeMashStepVolume = async (mashStepId: number, volume: number) => {
        const newMashSteps: MashStep[] = [];

        this.state.recipe.recipe.mash_steps.forEach(mashStep => {
            if(mashStep.id === mashStepId) {
                mashStep.volume = volume;
            }

            newMashSteps.push(mashStep);
        })

        this.setState({
            ...this.state,
            recipe: {
                ...this.state.recipe,
                recipe: {
                    ...this.state.recipe.recipe,
                    mash_steps: newMashSteps,
                }
            },
        });

        await saveMashStepFloatValueDebounced(this.state.recipe.id, RecipeMashStepFloatField.Volume, mashStepId, volume);
    }

    public onChangeMashStepTemperature = async (mashStepId: number, temperature: number) => {

        const newMashSteps: MashStep[] = [];

        this.state.recipe.recipe.mash_steps.forEach(mashStep => {
            if(mashStep.id === mashStepId) {
                mashStep.temperature = temperature;
            }

            newMashSteps.push(mashStep);
        })

        this.setState({
            ...this.state,
            recipe: {
                ...this.state.recipe,
                recipe: {
                    ...this.state.recipe.recipe,
                    mash_steps: newMashSteps,
                }
            },
        });

        await saveMashStepFloatValueDebounced(this.state.recipe.id, RecipeMashStepFloatField.Temperature, mashStepId, temperature);
    }

    public onChangeMashStepTime = async (mashStepId: number, time: number) => {
        const newMashSteps: MashStep[] = [];

        this.state.recipe.recipe.mash_steps.forEach(mashStep => {
            if(mashStep.id === mashStepId) {
                mashStep.time = time;
            }

            newMashSteps.push(mashStep);
        })

        this.setState({
            ...this.state,
            recipe: {
                ...this.state.recipe,
                recipe: {
                    ...this.state.recipe.recipe,
                    mash_steps: newMashSteps,
                }
            },
        });

        await saveMashStepIntValueDebounced(this.state.recipe.id, RecipeMashStepIntField.Time, mashStepId, time);
    }

    public onDeleteMashStep = (mashStepId: number) => {
        ServicesHelper.instance().recipes().deleteMashStepFromRecipe(this.state.recipe.id, mashStepId).then(resp => {
            if(resp.status === 200) {

                const newMashSteps: MashStep[] = [];
                this.state.recipe.recipe.mash_steps.forEach(mashStep => {
                    if(mashStep.id === mashStepId) {
                        return;
                    }

                    newMashSteps.push(mashStep);
                });

                this.setState({
                    ...this.state,
                    recipe: {
                        ...this.state.recipe,
                        recipe: {
                            ...this.state.recipe.recipe,
                            mash_steps: newMashSteps,
                        }
                    }
                });
            }
        }, err => { throw err; });
    }

    public renderMash() {
        const mashSteps = this.state.recipe.recipe.mash_steps

        return (
            <Container fluid>
                {mashSteps.map(mashStep => {
                    return (
                        <EditMashStepComponent
                            key={mashStep.id}
                            mashStep={mashStep}
                            onDelete={this.onDeleteMashStep}
                            onChangeType={this.onChangeMashType}
                            onChangeVolume={this.onChangeMashStepVolume}
                            onChangeTemperature={this.onChangeMashStepTemperature}
                            onChangeTime={this.onChangeMashStepTime}
                        />
                    );
                })}
                <Row>
                    <Col sm={{ span: 2, offset:1 }}>
                        <Button variant="blue" onClick={this.onClickAddMashStep} className="btn-margin-top">+ Mash Step</Button>
                    </Col>
                </Row>
            </Container>
        )
    }

    public onClickAddMashStep = (evt) => {
        ServicesHelper.instance().recipes().createRecipeMashStep(this.state.recipe.id).then(resp => {
            const newMashSteps = this.state.recipe.recipe.mash_steps;
            newMashSteps.push(resp.mash_step);

            this.setState({
                ...this.state,
                recipe: {
                    ...this.state.recipe,
                    recipe: {
                        ...this.state.recipe.recipe,
                        mash_steps: newMashSteps,
                    }
                }
            });
        }, err => { throw err; });
    }

    public onHopAdditionChange = (hopAddition: HopAddition) => {
        const newHopAdditions: HopAddition[] = [];

        this.state.recipe.recipe.hop_additions.forEach(recipeHopAddition => {
            if (recipeHopAddition.id === hopAddition.id) {
                newHopAdditions.push(hopAddition);
            } else {
                newHopAdditions.push(recipeHopAddition);
            }
        });

        this.setState({
            ...this.state,
            recipe: {
                ...this.state.recipe,
                recipe: {
                    ...this.state.recipe.recipe,
                    hop_additions: newHopAdditions,
                }
            }
        });
    }


    public onClickAddHopAddition = () => {
        this.setState({
            ...this.state,
            showAddHopAdditionModal: true,
        });
    }

    public onCloseAddHopAddition = () => {
        this.setState({
            ...this.state,
            showAddHopAdditionModal: false,
        })
    }

    public onDeleteHop = (hopAdditionId: number) => {
        ServicesHelper.instance()
            .recipes()
            .deleteHopAdditionFromRecipe(this.state.recipe.id, hopAdditionId)
            .then(resp => { return; }, err => { throw err; });

        this.setState({
            ...this.state,
            recipe: {
                ...this.state.recipe,
                recipe: {
                    ...this.state.recipe.recipe,
                    hop_additions: this.state.recipe.recipe.hop_additions.filter(hopAddition => hopAddition.id !== hopAdditionId),
                }
            }
        });
    }

    public renderHops() {
        const hops = this.state.recipe.recipe.hop_additions.sort( (a: HopAddition, b: HopAddition) => {
            const rank = {
                [HopUse.Mash]: 0,
                [HopUse.Boil]: 1,
                [HopUse.Whirlpool]: 2,
                [HopUse.DryHop]: 3
            }

            if(a.use === b.use) {
                return b.time - a.time
            }

            return rank[a.use] - rank[b.use]
        });

        const recipe = this.state.recipe.recipe;
        const showAddHopAdditionModal = this.state.showAddHopAdditionModal;
        const avgGravity: number = (calculateRecipeOG(recipe) + calculateRecipePreBoilGravity(recipe))/2.000
        let totalHops = 0;

        hops.forEach(hop => {
            totalHops += hop.amount;
        });

        return (<Container fluid className="editRecipeContainer">
                    {hops.map(hop => {
                            return (
                                <EditHopComponent key={hop.id}
                                                hopAddition={hop}
                                                recipeId={recipe.id}
                                                recipeAvgGravity={avgGravity}
                                                recipeBatchSize={recipe.batch_size}
                                                onChange={this.onHopAdditionChange}
                                                onDelete={this.onDeleteHop}
                                                totalHops={totalHops}
                                />
                            )
                        }
                    )}
                    <Row className="hopsInformationRow">
                        <Col sm={{span: 2, offset: 1}}>
                            Total Hops: {totalHops.toFixed(1)} g
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={{span: 2, offset: 1}}>
                            <Button variant="blue" onClick={this.onClickAddHopAddition}>
                                + Hop
                            </Button>
                        </Col>
                    </Row>
                    <AddHopAdditionComponent
                        show={showAddHopAdditionModal}
                        onClose={this.onCloseAddHopAddition}
                        onAdd={this.onAddHopAddition}
                    />
        </Container>);
    }

    public onAddHopAddition = (hopAddition: HopAddition) => {
        this.setState({
            ...this.state,
            showAddHopAdditionModal: false,
        });

        ServicesHelper.instance().recipes().createRecipeHopAddition(this.state.recipe.id,
            hopAddition.hop.id,
            hopAddition.use,
            hopAddition.form,
            hopAddition.alpha_acid
        ).then(resp => {
            const newHopAdditions: HopAddition[] = [...this.state.recipe.recipe.hop_additions];
            newHopAdditions.push(resp.hop_addition);

            this.setState({
                ...this.state,
                recipe: {
                    ...this.state.recipe,
                    recipe: {
                        ...this.state.recipe.recipe,
                        hop_additions: newHopAdditions,
                    }
                }
            });
        }, err => { throw err; });
    }

    public onClickAddHop = (evt) => {
        ServicesHelper.instance().recipes().createRecipeHopAddition(this.state.recipe.id).then(resp => {
            const newHopAdditions: HopAddition[] = [...this.state.recipe.recipe.hop_additions];
            newHopAdditions.push(resp.hop_addition);

            this.setState({
                ...this.state,
                recipe: {
                    ...this.state.recipe,
                    recipe: {
                        ...this.state.recipe.recipe,
                        hop_additions: newHopAdditions,
                    }
                }
            });
        }, err => { throw err; });
    }

    public onChangeRecipeYeast = (recipeYeast: RecipeYeast) => {
        this.setState({
            ...this.state,
            recipe: {
                ...this.state.recipe,
                recipe: {
                    ...this.state.recipe.recipe,
                    yeast: recipeYeast,
                }
            }
        });
    }

    public renderYeast() {
        return (
            <Container fluid className="editRecipeContainer">
                <EditYeastComponent recipeId={this.state.recipe.id}
                                    recipeYeast={this.state.recipe.recipe.yeast}
                                    onChange={this.onChangeRecipeYeast}
                />
            </Container>
        );
    }

    public onClickWaterAdjustor = (evt) => {
        this.setState({
            ...this.state,
            showAddWaterAdjustorModal: true,
        });
    }

    public closeAddWaterAdjustorModal = () => {
        this.setState({
            ...this.state,
            showAddWaterAdjustorModal: false,
        });
    }

    public onChooseWaterAdjustor = (waterAdjustor: WaterAdjustor) => {
        this.setState({
            ...this.state,
            showAddWaterAdjustorModal: false,
        });

        ServicesHelper.instance().recipes().addRecipeWaterAdjustor(this.state.recipe.id, waterAdjustor.name).then(resp => {
            const waterAdjustors = this.state.recipe.recipe.water_adjustors;
            waterAdjustors.push(resp.water_adjustor);

            this.setState({
                ...this.state,
                showAddWaterAdjustorModal: false,
                recipe: {
                    ...this.state.recipe,
                    recipe: {
                        ...this.state.recipe.recipe,
                        water_adjustors: waterAdjustors,
                    },
                }
            });
        }, err => { throw err; })

    }

    public onChangeWaterAdjustor = (waterAdjustor: RecipeWaterAdjustor) => {
        const waterAdjustors: RecipeWaterAdjustor[] = [];

        this.state.recipe.recipe.water_adjustors.forEach(recipeWaterAdjustor => {
            if (waterAdjustor.id === recipeWaterAdjustor.id) {
                waterAdjustors.push(waterAdjustor);
            } else {
                waterAdjustors.push(recipeWaterAdjustor);
            }
        });

        this.setState({
            ...this.state,
            recipe: {
                ...this.state.recipe,
                recipe: {
                    ...this.state.recipe.recipe,
                    water_adjustors: waterAdjustors,
                }
            }
        });
    }

    public onDeleteWaterAdjustor = (waterAdjustorId: number) => {
        ServicesHelper.instance()
            .recipes()
            .deleteRecipeWaterAdjustor(this.state.recipe.id, waterAdjustorId)
            .then(resp => { return; }, err => { throw err; });

        this.setState({
            ...this.state,
            recipe: {
                ...this.state.recipe,
                recipe: {
                    ...this.state.recipe.recipe,
                    water_adjustors: this.state.recipe.recipe.water_adjustors.filter(waterAdjustor => waterAdjustor.id !== waterAdjustorId),
                }
            }
        });
    }

    public onChangeSourceWater = (sourceWaterProfile?: SourceWaterProfile) => {
        this.setState({
            ...this.state,
            recipe: {
                ...this.state.recipe,
                recipe: {
                    ...this.state.recipe.recipe,
                    source_water: sourceWaterProfile,
                }
            }
        });
    }

    public onAddOtherIngredient = (otherIngredient: RecipeOtherIngredient) => {
        this.setState({
            ...this.state,
            showAddOtherIngredientModal: false,
        });

        ServicesHelper.instance().recipes().addRecipeOtherIngredient(this.state.recipe.id, otherIngredient.name, otherIngredient.time_used).then(resp => {
            const otherIngredients = this.state.recipe.recipe.other_ingredients;
            otherIngredients.push(resp.other_ingredient);
            this.setState({
                ...this.state,
                recipe: {
                    ...this.state.recipe,
                    recipe: {
                        ...this.state.recipe.recipe,
                        other_ingredients: otherIngredients,
                    }
                }
            });
        }, err => { throw err; });
    }

    public onShowAddOtherIngredientModal = () => {
        this.setState({
            ...this.state,
            showAddOtherIngredientModal: true,
        });
    }

    public onHideAddOtherIngredientModal = () => {
        this.setState({
            ...this.state,
            showAddOtherIngredientModal: false,
        });
    }

    public onChangeOtherIngredient = (otherIngredient: RecipeOtherIngredient) => {
        const otherIngredients: RecipeOtherIngredient[] = [];

        this.state.recipe.recipe.other_ingredients.forEach(oi => {
            if (oi.id === otherIngredient.id) {
                otherIngredients.push(otherIngredient);
            } else {
                otherIngredients.push(oi);
            }
        });

        this.setState({
            ...this.state,
            recipe: {
                ...this.state.recipe,
                recipe: {
                    ...this.state.recipe.recipe,
                    other_ingredients: otherIngredients,
                }
            }
        });
    }

    public onDeleteOtherIngredient = (otherIngredientId: number) => {
        ServicesHelper.instance().recipes().deleteRecipeOtherIngredient(this.state.recipe.id, otherIngredientId).then(resp => {
            this.setState({
                ...this.state,
                recipe: {
                    ...this.state.recipe,
                    recipe: {
                        ...this.state.recipe.recipe,
                        other_ingredients: this.state.recipe.recipe.other_ingredients.filter(otherIngredient => otherIngredient.id !== otherIngredientId),
                    }
                }
            });
        }, err => { throw err; });
    }

    public renderOtherIngredients() {
        const { recipe, showAddOtherIngredientModal } = this.state;

        return (
            <Container fluid className="editRecipeContainer">
                {
                    recipe.recipe.other_ingredients.map(otherIngredient => <EditOtherIngredientComponent
                        key={otherIngredient.id}
                        otherIngredient={otherIngredient}
                        recipeId={recipe.id}
                        onChange={this.onChangeOtherIngredient}
                        onDelete={this.onDeleteOtherIngredient}
                    />)
                }
                <Row>
                    <Col sm={{span: 2, offset: 1}}>
                        <Button variant="blue" onClick={this.onShowAddOtherIngredientModal} className="btn-margin-top">
                            + Ingredient
                        </Button>
                    </Col>
                </Row>
                <AddOtherIngredientComponent
                    show={showAddOtherIngredientModal}
                    onClose={this.onHideAddOtherIngredientModal}
                    onChoose={this.onAddOtherIngredient}
                />
            </Container>
        );
    }

    public renderWaterAdjustment() {
        const { showAddWaterAdjustorModal, recipe } = this.state;

        return (
            <>
                <EditSourceWaterComponent
                    recipeId={recipe.id}
                    sourceWaterProfile={recipe.recipe.source_water}
                    onChange={this.onChangeSourceWater}
                />
                <Container fluid className="editRecipeContainer">
                    <h5>Water Adjustment</h5>
                    {
                        recipe.recipe.water_adjustors.map(waterAdjustor => {
                            return <EditWaterAdjustorComponent
                                    key={waterAdjustor.id}
                                    waterAdjustor={waterAdjustor}
                                    recipeId={recipe.id}
                                    onChange={this.onChangeWaterAdjustor}
                                    onDelete={this.onDeleteWaterAdjustor}
                            />;
                        })
                    }
                    <Row>
                        <Col sm={{span: 2, offset: 1}}>
                            <Button variant="blue" onClick={this.onClickWaterAdjustor} className="btn-margin-top">
                                + Water Adjustor
                            </Button>
                        </Col>
                    </Row>
                    <AddWaterAdjustorComponent
                        show={showAddWaterAdjustorModal}
                        onClose={this.closeAddWaterAdjustorModal}
                        onChoose={this.onChooseWaterAdjustor}
                    />
                </Container>
                <RecipeWaterReportComponent
                    recipe={recipe.recipe}
                    grainAbsorptionRatio={recipe.brewing_setup?.grain_absorption}
                />
            </>
        );
    }

    public onChangeBrewingSetup = (evt) => {
        const brewingSetupId: number = Number.parseInt(evt.target.value as string, 10);

        ServicesHelper.instance().recipes().updateRecipeBrewingSetup(this.state.recipe.id, brewingSetupId).then(resp => {
            if(resp.success && resp.brewing_setup) {
                this.setState({
                    ...this.state,
                    recipe: {
                        ...this.state.recipe,
                        brewing_setup: resp.brewing_setup,
                    }
                });
            }
        }, err => { throw err; });
    }

    public onClickSetRecipePrivate = (evt: React.MouseEvent<HTMLButtonElement>) => {
        ServicesHelper.instance().recipes().updateRecipeIsPublic(
            this.state.recipe.id,
            false
        ).then(resp => {
            this.setState({
                ...this.state,
                recipe: {
                    ...this.state.recipe,
                    is_public: false,
                },
            });
        }, err => {throw err;});
    }

    public onClickSetRecipePublic = (evt: React.MouseEvent<HTMLButtonElement>) => {
        ServicesHelper.instance().recipes().updateRecipeIsPublic(
            this.state.recipe.id,
            true,
        ).then(resp => {
            this.setState({
                ...this.state,
                recipe: {
                    ...this.state.recipe,
                    is_public: true,
                },
            });
        }, err => {throw err;});
    }

    public render() {
        if(this.state.isLoading) {
            return (<Container>
                    <LoadingComponent />
                </Container>);
        }

        if(!this.state.recipe){
            return (<></>);
        }

        const recipe = this.state.recipe?.recipe;
        const persistedRecipe = this.state.recipe;
        const brewingSetup = this.state.recipe?.brewing_setup;

        const { availableBrewingSetups } = this.state;
        const preboilGravity = calculateRecipePreBoilGravity(recipe);
        const originalGravity = calculateRecipeOG(recipe);
        const finalGravity = calculateRecipeFG(recipe);
        const abv = calculateRecipeABV(originalGravity, finalGravity);
        const srm = calculateSRM(recipe);
        const ibu = calculateTotalIBU(recipe);
        const squareSRM = srm > 40 ? 40 : srm;
        const mashPH = calculateMashPH(recipe);

        return (
        <Container fluid>
            <Row>
                <h3>
                    <PencilSquare />
                    Recipe Editor
                </h3>
            </Row>
            <Container fluid className="editRecipeContainer">
                <Row>
                    <Col md="2">
                        <Form.Label>Name</Form.Label>:
                    </Col>
                    <Col md="4">
                        <Form.Control size="sm" type="text" value={recipe.name} onChange={this.onChangeRecipeName}/>
                    </Col>
                </Row>
                <Row>
                    <Col md="2">
                        <Form.Label>Batch size</Form.Label>:
                    </Col>
                    <Col md="1">
                        <FormControlComponent
                            size="sm"
                            value={recipe.batch_size}
                            fieldValidation={this.getFieldValidation("batch_size")}
                            onChange={this.onChangeBatchSize}
                        />
                    </Col>
                    <Col md="1" className="controlSuffix">
                        liter(s)
                    </Col>
                    <Col md={{ span:2, offset:1 }}>
                        <Form.Label>Boil time</Form.Label>:
                    </Col>
                    <Col md="1">
                        <FormControlComponent
                            size="sm"
                            value={recipe.boil_time}
                            fieldValidation={this.getFieldValidation("boil_time")}
                            onChange={this.onChangeBoilTime}
                        />
                    </Col>
                    <Col md="2">
                        minute(s)
                    </Col>
                </Row>
                <Row>
                    <Col md="2">
                        <Form.Label>Boil size</Form.Label>:
                    </Col>
                    <Col md="1">
                        <Form.Control type="text"
                                    size="sm"
                                    htmlSize={8}
                                    className={this.getFieldValidation("boil_size") ? `fieldError` : null}
                                    value={this.getFieldValidation("boil_size")?.currentValue ?? recipe.boil_size}
                                    onChange={this.onChangeBoilSize}
                        />
                    </Col>
                    <Col md="2">
                        liter(s)
                    </Col>
                    <Col md="2">
                        <Form.Label>Efficiency</Form.Label>:
                    </Col>
                    <Col md="1">
                        <Form.Control size="sm"
                                    type="text"
                                    htmlSize={8}
                                    className={this.getFieldValidation("efficiency") ? `fieldError` : null}
                                    value={this.getFieldValidation("efficiency")?.currentValue ?? recipe.efficiency}
                                    onChange={this.onChangeEfficiency}
                        />
                    </Col>
                    <Col md="2">
                        %
                    </Col>
                </Row>
                <Row>
                    <Col md="2">
                        <Form.Label>Style</Form.Label>:
                    </Col>
                    <Col md="3">
                        <Form.Select className="beerStyleDropDown" onChange={this.onChangeBeerStyle} defaultValue={recipe.style.name}>
                            {this.state.beerStyles.map(beerStyle =>
                                (<option key={beerStyle.id} value={beerStyle.name}>{beerStyle.name}</option>)
                            )}
                        </Form.Select>
                    </Col>
                    <Col md="2">
                        Brewing Setup:
                    </Col>
                    <Col md="4">
                        <Form.Select defaultValue={brewingSetup?.id ?? 0} onChange={this.onChangeBrewingSetup} >
                                <option value="0">Not Selected</option>
                                {
                                    availableBrewingSetups.map(_brewingSetup => {
                                        return (<option key={_brewingSetup.id} value={_brewingSetup.id}>
                                            {_brewingSetup.name}
                                        </option>)
                                    })
                                }
                        </Form.Select>
                    </Col>
                </Row>
                <Row className="margin-bottom-1">
                    <Col md="6">
                        <span className="recipeLabel">OG</span>: {originalGravity.toFixed(3)} <br />
                        <span className="recipeLabel">FG</span>: {finalGravity.toFixed(3)} <br />
                        <span className={`recipeLabel`}>SRM</span>: {srm.toFixed(1)} <div className={`srm-${squareSRM.toFixed(0)} srm-square`}></div><br />
                        <span className="recipeLabel">Visibility</span>:
                        <Button
                            className="margin-left-1"
                            size="small"
                            variant={ !persistedRecipe.is_public ? `normal` : `grey`}
                            onClick={this.onClickSetRecipePrivate}
                        >
                            Private
                        </Button>
                        <Button
                            size="small"
                            variant={ persistedRecipe.is_public ? `normal` : `grey`}
                            onClick={this.onClickSetRecipePublic}
                        >
                            Public
                        </Button>
                        <br />
                    </Col>
                    <Col md="6">
                        <span className="recipeLabel">Pre-Boil Gravity</span>: {preboilGravity.toFixed(3)} <br />
                        <span className="recipeLabel">ABV</span>: {abv.toFixed(2)} % <br />
                        <span className="recipeLabel">IBU</span>: {ibu.toFixed(2)} <br />
                        <span className="recipeLabel">Mash PH</span>: {mashPH.toFixed(2)}
                    </Col>
                </Row>
                <Row>
                    <h4 className="recipeSectionTitle">Fermentable(s)</h4>
                    {this.renderFermentables()}
                </Row>
                <Row>
                    <h4 className="recipeSectionTitle">Mash</h4>
                    {this.renderMash()}
                </Row>
                <Row>
                    <h4 className="recipeSectionTitle">Hop(s)</h4>
                    {this.renderHops()}
                </Row>
                <Row>
                    <h4 className="recipeSectionTitle">Yeast</h4>
                    {this.renderYeast()}
                </Row>
                <Row>
                    <h4 className="recipeSectionTitle">Other ingredient(s)</h4>
                    {this.renderOtherIngredients()}
                </Row>
                <Row>
                    <h4 className="recipeSectionTitle">Water</h4>
                    {this.renderWaterAdjustment()}
                </Row>
                <Row>
                    <h4 className="recipeSectionTitle">Note(s)</h4>
                    <Form.Control value={recipe.notes} onChange={this.onChangeRecipeNotes}></Form.Control>
                </Row>
            </Container>
        </Container>);
    }
}

export default EditRecipeComponent;