import * as React from "react";
import { Col , Row } from "react-bootstrap";
import { FileEarmarkArrowDown } from "react-bootstrap-icons";

import { NotificationProps } from "@app/components/notifications/base_notification";
import { RecipesExportReadyData } from "@app/models/notifications";
import ServicesHelper from "@app/services/serviceshelper";


class RecipesExportReadyNotificationComponent extends React.Component<NotificationProps<RecipesExportReadyData>, Record<string, never>> {
    public constructor(props: NotificationProps<RecipesExportReadyData>) {
        super(props);
    }

    public onClickDownload = (evt: React.MouseEvent<HTMLAnchorElement>) => {
        this.props.ack(this.props.notification.getId());
    }

    public render() {
        const { data } = this.props;
        const servicesHelper = ServicesHelper.instance();
        const fileUrl = `${servicesHelper.configuration.bmapi.uri}files/${data.fileId}/${data.fileName}/download?session_key=${servicesHelper.getSessionKey()}`;

        return <Row>
            <Col sm={{span: 8, offset: 1}}>
                <a target="_blank" href={fileUrl} onClick={this.onClickDownload}>
                    <FileEarmarkArrowDown /> Download
                </a>
            </Col>
        </Row>;
    }
}

export default RecipesExportReadyNotificationComponent;