import * as React from "react";
import { Alert, Col, Modal, Row } from "react-bootstrap";
import { ExclamationTriangle, PlusCircle, Save } from "react-bootstrap-icons";

import WaterProfileIcon from "@app/assets/img/ingredients/water_profile.svg";
import Button from "@app/components/common/Button";
import FormControlComponent from "@app/components/common/FormControlComponent";
import LoadingComponent from "@app/components/common/LoadingComponent";
import { FieldValidations, fieldValueIsAnInteger, getFieldValidation } from "@app/models/fields";
import { WaterProfile } from "@app/models/waterProfile";
import ServicesHelper from "@app/services/serviceshelper";

interface Props {
    waterProfileId?: number,
    isVisible: boolean,
    onHide: (waterProfile?: WaterProfile) => void,
}

interface State {
    name: string,
    notes: string,
    calcium: string;
    bicarbonate: string;
    sulfate: string;
    chloride: string;
    sodium: string;
    magnesium: string;
    userId?: number;
    isDirty: boolean;
    invalidFields: FieldValidations;
    isLoading: boolean;
}

class EditProfileWaterComponent extends React.Component<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            name: "",
            notes: "",
            calcium: "",
            bicarbonate: "",
            sulfate: "",
            chloride: "",
            sodium: "",
            magnesium: "",
            userId: undefined,
            isDirty: false,
            isLoading: false,
            invalidFields: {},
        }
    }

    public componentDidMount(): void {
        const { waterProfileId } = this.props;

        if (this.props.waterProfileId) {
            this.fetchWaterProfile(waterProfileId);
        }
    }

    public fetchWaterProfile(waterProfileId: number) {
        this.setState({
            ...this.state,
            isLoading: true,
        });

        ServicesHelper.instance().ingredients().getWaterProfile(waterProfileId).then(resp => {
            this.setState({
                ...this.state,
                name: resp.water_profile.name,
                notes: resp.water_profile.notes,
                calcium: resp.water_profile.calcium.toFixed(0),
                bicarbonate: resp.water_profile.bicarbonate.toFixed(0),
                sulfate: resp.water_profile.sulfate.toFixed(0),
                chloride: resp.water_profile.chloride.toFixed(0),
                sodium: resp.water_profile.sodium.toFixed(0),
                magnesium: resp.water_profile.magnesium.toFixed(0),
                userId: resp.water_profile.user_id,
                isLoading: false,
            })
        }, err => {throw err;})
    }

    public applyEmptyProfile() {
        this.setState({
            ...this.state,
            name: "",
            notes: "",
            calcium: "0",
            bicarbonate: "0",
            sulfate: "0",
            chloride: "0",
            sodium: "0",
            magnesium: "0",
            userId: undefined,
            isLoading: false,
        })
    }

    public UNSAFE_componentWillReceiveProps(nextProps: Readonly<Props>, nextContext: any) {
        const { waterProfileId } = this.props;

        if(nextProps.waterProfileId !== waterProfileId) {
            if(nextProps.waterProfileId) {
                this.fetchWaterProfile(nextProps.waterProfileId);
            } else {
                this.applyEmptyProfile();
            }
        }
    }

    public onChangeName = (evt: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            ...this.state,
            name: evt.target.value,
            isDirty: true,
        });
    }

    public onChangeNotes = (evt: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            ...this.state,
            notes: evt.target.value,
            isDirty: true,
        });
    }

    public onChangeCalcium = (evt: React.ChangeEvent<HTMLInputElement>) => {
        const { invalidFields } = this.state;
        const validation = fieldValueIsAnInteger(invalidFields, "calcium", evt.target.value)

        this.setState({
            ...this.state,
            calcium: evt.target.value,
            invalidFields: validation.fieldValidations,
            isDirty: true,
        });
    }

    public onChangeBicarbonate = (evt: React.ChangeEvent<HTMLInputElement>) => {
        const { invalidFields } = this.state;
        const validation = fieldValueIsAnInteger(invalidFields, "bicarbonate", evt.target.value)

        this.setState({
            ...this.state,
            bicarbonate: evt.target.value,
            invalidFields: validation.fieldValidations,
            isDirty: true,
        });
    }

    public onChangeSulfate = (evt: React.ChangeEvent<HTMLInputElement>) => {
        const { invalidFields } = this.state;
        const validation = fieldValueIsAnInteger(invalidFields, "sulfate", evt.target.value)

        this.setState({
            ...this.state,
            sulfate: evt.target.value,
            invalidFields: validation.fieldValidations,
            isDirty: true,
        });
    }

    public onChangeChloride = (evt: React.ChangeEvent<HTMLInputElement>) => {
        const { invalidFields } = this.state;
        const validation = fieldValueIsAnInteger(invalidFields, "chloride", evt.target.value)

        this.setState({
            ...this.state,
            chloride: evt.target.value,
            invalidFields: validation.fieldValidations,
            isDirty: true,
        });
    }

    public onChangeSodium = (evt: React.ChangeEvent<HTMLInputElement>) => {
        const { invalidFields } = this.state;
        const validation = fieldValueIsAnInteger(invalidFields, "sodium", evt.target.value)

        this.setState({
            ...this.state,
            sodium: evt.target.value,
            invalidFields: validation.fieldValidations,
            isDirty: true,
        });
    }

    public onChangeMagnesium = (evt: React.ChangeEvent<HTMLInputElement>) => {
        const { invalidFields } = this.state;
        const validation = fieldValueIsAnInteger(invalidFields, "magnesium", evt.target.value)

        this.setState({
            ...this.state,
            magnesium: evt.target.value,
            invalidFields: validation.fieldValidations,
            isDirty: true,
        });
    }

    public renderForm() {
        const { name, notes, calcium, bicarbonate, sulfate, chloride, sodium, magnesium, invalidFields } = this.state;
        return (<>
            <Row>
                <Col sm={{span: 3, offset: 1}}>
                    Name:
                </Col>
                <Col sm="4">
                    <FormControlComponent
                        value={name}
                        onChange={this.onChangeName} />
                </Col>
            </Row>
            <Row>
                <Col sm={{span: 3, offset: 1}}>
                    Calcium:
                </Col>
                <Col sm="2">
                    <FormControlComponent
                        value={calcium}
                        onChange={this.onChangeCalcium}
                        fieldValidation={getFieldValidation(invalidFields, "calcium")}
                    />
                </Col>
                <Col sm="1">
                    ppm
                </Col>
            </Row>
            <Row>
                <Col sm={{span: 3, offset: 1}}>
                    Bicarbonate:
                </Col>
                <Col sm="2">
                    <FormControlComponent
                        value={bicarbonate}
                        onChange={this.onChangeBicarbonate}
                        fieldValidation={getFieldValidation(invalidFields, "bicarbonate")}
                    />
                </Col>
                <Col sm="1">
                    ppm
                </Col>
            </Row>
            <Row>
                <Col sm={{span: 3, offset: 1}}>
                    Sulfate:
                </Col>
                <Col sm="2">
                    <FormControlComponent
                        value={sulfate}
                        onChange={this.onChangeSulfate}
                        fieldValidation={getFieldValidation(invalidFields, "sulfate")}
                    />
                </Col>
                <Col sm="1">
                    ppm
                </Col>
            </Row>
            <Row>
                <Col sm={{span: 3, offset: 1}}>
                    Chloride:
                </Col>
                <Col sm="2">
                    <FormControlComponent
                        value={chloride}
                        onChange={this.onChangeChloride}
                        fieldValidation={getFieldValidation(invalidFields, "chloride")}
                    />
                </Col>
                <Col sm="1">
                    ppm
                </Col>
            </Row>
            <Row>
                <Col sm={{span: 3, offset: 1}}>
                    Sodium:
                </Col>
                <Col sm="2">
                    <FormControlComponent
                        value={sodium}
                        onChange={this.onChangeSodium}
                        fieldValidation={getFieldValidation(invalidFields, "sodium")}
                    />
                </Col>
                <Col sm="1">
                    ppm
                </Col>
            </Row>
            <Row>
                <Col sm={{span: 3, offset: 1}}>
                    Magnesium:
                </Col>
                <Col sm="2">
                    <FormControlComponent
                        value={magnesium}
                        onChange={this.onChangeMagnesium}
                        fieldValidation={getFieldValidation(invalidFields, "magnesium")}
                    />
                </Col>
                <Col sm="1">
                    ppm
                </Col>
            </Row>
            <Row>
                <Col sm={{span: 3, offset: 1}}>
                    Notes:
                </Col>
                <Col sm="4">
                    <FormControlComponent
                        value={notes}
                        onChange={this.onChangeNotes} />
                </Col>
            </Row>
        </>);
    }

    public onClickSave = (evt: React.MouseEvent<HTMLButtonElement>) => {
        const { onHide } = this.props;

        const {
            name, notes, calcium, bicarbonate, sulfate, chloride, sodium, magnesium, userId
        } = this.state;

        const { waterProfileId } = this.props;

        const waterProfile: WaterProfile = {
            id: waterProfileId,
            name,
            notes,
            calcium: Number.parseInt(calcium, 10),
            bicarbonate: Number.parseInt(bicarbonate, 10),
            sulfate: Number.parseInt(sulfate, 10),
            chloride: Number.parseInt(chloride, 10),
            sodium: Number.parseInt(sodium, 10),
            magnesium: Number.parseInt(magnesium, 10),
            user_id: userId,
        }

        if (waterProfile.name.length === 0) {
            // todo error message
            return;
        }

        if (waterProfileId) {
            ServicesHelper.instance().ingredients().updateWaterProfile(waterProfile).then(resp => {
                this.setState({
                    ...this.state,
                    isDirty: false,
                });

                onHide(waterProfile);

            }, err => {throw err;});
        } else {
            ServicesHelper.instance().ingredients().addWaterProfile(waterProfile).then(resp => {
                this.setState({
                    ...this.state,
                    isDirty: false,
                });

                onHide({
                    ...waterProfile,
                    id: resp.water_profile_id,
                    user_id: resp.user_id,
                });
            }, err => {throw err;});
        }
    }

    public onClickCancel = (evt: React.MouseEvent<HTMLButtonElement>) => {
        this.props.onHide()
    }

    public render() {
        const { waterProfileId, isVisible } = this.props;
        const { isLoading, isDirty } = this.state

        return (
        <Modal show={isVisible}>
            <Modal.Header>
                <Modal.Title>
                    <img src={WaterProfileIcon} width="40px" height="40px" className="nav-item-icon" />
                    {waterProfileId ? `Edit Water Profile` : `New Water Profile`}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                    isDirty ? (
                        <Row>
                            <Col>
                                <Alert variant="warning">
                                    <ExclamationTriangle />
                                    Current changes aren't saved yet
                                </Alert>
                            </Col>
                        </Row>
                    ) : null
                }
                { isLoading ? <LoadingComponent /> : this.renderForm()}
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="grey"
                    onClick={this.onClickCancel}
                >
                    Cancel
                </Button>
                <Button
                    variant="blue"
                    disabled={!isDirty}
                    onClick={this.onClickSave}
                >
                        {waterProfileId ?
                            (<><Save/> Save</>) : (<><PlusCircle /> Add</>)
                        }
                </Button>
            </Modal.Footer>
        </Modal>);
    }
}

export default EditProfileWaterComponent;