import React = require("react");
import { Button, Col, Row } from "react-bootstrap";
import "@app/assets/scss/components/IngredientsItem.scss"
import { Trash } from "react-bootstrap-icons";

import { Yeast, friendlyYeastFlocculationString, friendlyYeastFormString, friendlyYeastTypeString } from "@app/models/yeast";

interface Props {
    yeast: Yeast,
    onDelete?: (yeast: Yeast) => void,
}

class YeastItemComponent extends React.Component<Props, Record<string, never>> {

    public constructor(props: Props) {
        super(props)
    }

    public onDeleteButton = (evt) => {
        if(this.props.onDelete) {
            this.props.onDelete(this.props.yeast);
        }
    }

    public render() {
        const { yeast } = this.props;

        return (
            <Row className="ingredientsRowItem">
                <Col sm="2">
                    {yeast.name}
                </Col>
                <Col sm="1">
                    {friendlyYeastTypeString(yeast.type)}
                </Col>
                <Col sm="1">
                    {friendlyYeastFormString(yeast.form)}
                </Col>
                <Col sm="2">
                    {friendlyYeastFlocculationString(yeast.flocculation)}
                </Col>
                <Col sm="2">
                    {yeast.attenuation} %
                </Col>
                <Col sm="2">
                    {`${yeast.min_temperature.toFixed(1)} - ${yeast.max_temperature.toFixed(1)} °C`}
                </Col>
                <Col sm="1">
                    <Button size="sm" onClick={this.onDeleteButton}>
                        <Trash /> Delete
                    </Button>
                </Col>
            </Row>
        );
    }
}

export default YeastItemComponent;