import { action, makeObservable, observable } from 'mobx';

import { User } from '@app/models/user';

import { RootStore } from './rootstore';

export interface IUserStore {
  user?: User
}

export class UserStore implements IUserStore {
  private rootStore: RootStore;

  @observable user;

  constructor(rootStore?: RootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
  }

  @action setUser  = (user: User): void => {
    this.user = user
  }

}