import { action, makeObservable, observable } from 'mobx';

import { ApiInfo } from '@app/models/api';

import { RootStore } from './rootstore';

export interface IApiStore {
  apiInfo?: ApiInfo
}

export class ApiStore implements IApiStore {
  private rootStore: RootStore;

  @observable apiInfo;

  constructor(rootStore?: RootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
  }

  @action setApiInfo  = (apiInfo: ApiInfo): void => {
    this.apiInfo = apiInfo
  }

}