import { INotificationEvent, NotificationEvent, PingMessage } from "@app/models/notifications";
import { RootStore } from "@app/stores/rootstore";

interface ConnectionRequest {
    SessionKey: string,
}

export class TapClient {

    private rootStore: RootStore;

    private webSocket: WebSocket;
    private uri: string;

    public constructor(rootStore: RootStore, uri: string) {
        this.rootStore = rootStore;
        this.uri = uri;
    }

    public connect() {
        if (!this.uri) {
            return;
        }

        this.webSocket = new WebSocket(`${this.uri}`);
        this.webSocket.onopen = this.onConnectionOpen;
    }

    public onMessageReceived = (e: MessageEvent) => {
        const receivedMessage = JSON.parse(e.data as string) as object;

        if ((receivedMessage as PingMessage)?.ping) {
            return;
        }

        const notification = receivedMessage as INotificationEvent;
        if(notification.data) {
            this.rootStore.notificationsStore.pushNotification(new NotificationEvent(notification));
        }
    }

    public onConnectionOpen = (e: Event) => {
        const connectionRequest: ConnectionRequest = {
            SessionKey: this.rootStore.authStore.sessionKey
        };

        const ws = e.target as WebSocket;

        ws.send(JSON.stringify(connectionRequest));
        ws.onmessage = this.onMessageReceived;
    }
}