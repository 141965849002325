import React = require("react");
import { Alert, Container, Form } from "react-bootstrap";
import { Signpost2 } from "react-bootstrap-icons";

import Button from "@app/components/common/Button";
import { AuthManager } from "@app/services/authmanager";
import { ErrorType } from "@app/services/models";

interface State {
    errors: string[],
    isSubmitting: boolean,
    email: string,
    password: string,
    passwordConfirmation: string,
    displayName: string,
    betaKey: string,
}

interface Props {
    onCreated?: () => void,
    isBetaMode: boolean,
}

class SignUpComponent extends React.Component<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            errors: [],
            isSubmitting: false,
            email: "",
            password: "",
            passwordConfirmation: "",
            displayName: "",
            betaKey: "",
        }
    }

    public onChangeEmail = (evt) => {
        this.setState({
            ...this.state,
            email: evt.target.value,
        });
    }

    public onChangeDisplayName = (evt) => {
        this.setState({
            ...this.state,
            displayName: evt.target.value,
        });
    }

    public onChangePassword = (evt) => {
        this.setState({
            ...this.state,
            password: evt.target.value,
        });
    }

    public onChangePasswordConfirm = (evt) => {
        this.setState({
            ...this.state,
            passwordConfirmation: evt.target.value,
        });
    }

    public onChangeBetaKey = (evt) => {
        this.setState({
            ...this.state,
            betaKey: evt.target.value,
        });
    }

    public signUp = (evt) => {
        const errors: string[] = [];
        if(this.state.password.length < 8) {
            errors.push(`Password must contains at least 8 characters.`)
        }

        if(this.state.password !== this.state.passwordConfirmation) {
            errors.push(`Password and password confirmation aren't identical.`)
        }

        if(this.state.email.length < 4 && this.state.email.indexOf('@') === -1) {
            errors.push(`Invalid email address.`)
        }

        if(this.state.displayName.length < 6) {
            errors.push(`Display name must contains at least 6 characters.`)
        }

        if(errors.length > 0) {
            this.setState({
                ...this.state,
                errors,
            })

            return;
        }

        this.setState({
            ...this.state,
            isSubmitting: true,
            errors,
        });

        AuthManager.instance().do_signUp({
            email: this.state.email,
            display_name: this.state.displayName,
            password: this.state.password,
            beta_key: this.props.isBetaMode ? this.state.betaKey : undefined,
        })
        .then(resp => {
            if(resp.success === true) {
                this.setState({
                    ...this.state,
                    isSubmitting: false,
                });

                if(this.props.onCreated) {
                    this.props.onCreated();
                }
            } else if(resp.error_type === ErrorType.UserInput || resp.error_type === ErrorType.InvalidData) {
                this.setState({
                    ...this.state,
                    isSubmitting: false,
                    errors: [resp.message],
                });
            }
        }, err => { throw err; });
    }

    public renderErrors() {
        return (
        <>
        {this.state.errors.map(error =>
            (<Alert key={error} variant="danger">
                {error}
            </Alert>)
            )}
        </>);
    }

    public renderBetaKeyForm() {
        const { isSubmitting } = this.state;
        return (
            <Form.Group controlId="BetaKey">
            <Form.Label>Beta Key</Form.Label>
            <Form.Control
                type="text"
                placeholder="aaaa-0000"
                disabled={isSubmitting}
                onChange={this.onChangeBetaKey}
            />
            </Form.Group>
        );
    }

    public render() {
        const { errors, isSubmitting } = this.state;

        return (
        <Container>
            <Container>
                {errors.length > 0 ? this.renderErrors() : null}
            </Container>
            <Form>
                <h3>
                    <Signpost2 width="48" height="48"/>
                    Create an account
                </h3>
                <Form.Group controlId="Email">
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="name@email.net"
                                disabled={isSubmitting}
                                onChange={this.onChangeEmail}
                            />
                </Form.Group>
                <Form.Group controlId="displayName">
                            <Form.Label>Display Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="username"
                                disabled={isSubmitting}
                                onChange={this.onChangeDisplayName}
                            />
                </Form.Group>
                <Form.Group controlId="password">
                            <Form.Label>Password</Form.Label>
                            <Form.Control
                                type="password"
                                placeholder="****"
                                disabled={isSubmitting}
                                onChange={this.onChangePassword}
                            />
                </Form.Group>
                <Form.Group controlId="password_confirm">
                            <Form.Label>Password (confirm)</Form.Label>
                            <Form.Control
                                type="password"
                                placeholder="****"
                                disabled={isSubmitting}
                                onChange={this.onChangePasswordConfirm}
                            />
                </Form.Group>

                { this.props.isBetaMode ? this.renderBetaKeyForm() : null }

                <Container fluid className="signUpButtonContainer">
                    <Button
                        variant="blue"
                        disabled={isSubmitting}
                        onClick={this.signUp}
                    >
                        Sign Up
                    </Button>
                </Container>
            </Form>
        </Container>);
    }
}

export default SignUpComponent;