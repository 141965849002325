import * as React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { PlusCircle } from "react-bootstrap-icons";

import Button from "@app/components/common/Button";
import EditProfileWaterComponent from "@app/components/ingredients/EditProfileWaterComponent";
import WaterProfileItem from "@app/components/ingredients/WaterProfileItem";
import { WaterProfile } from "@app/models/waterProfile";
import ServicesHelper from "@app/services/serviceshelper";


interface Props {
    id?: number,
    action?: string,
    isAdmin: boolean,
    userId: number,
}

interface State {
    isLoading: boolean,
    isProfileWaterFormVisible: boolean,
    selectedWaterProfileId?: number,
    waterProfiles: WaterProfile[],
}

class ProfileWatersComponent extends React.Component<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            isLoading: true,
            isProfileWaterFormVisible: false,
            waterProfiles: [],
        };
    }

    public componentDidMount(): void {
        ServicesHelper.instance().ingredients().getWaterProfiles().then(resp => {
            this.setState({
                ...this.state,
                isLoading: false,
                waterProfiles: resp.water_profiles,
            });
        }, err => { throw err; })
    }

    public onClickAddWaterProfile = (evt: React.MouseEvent<HTMLButtonElement>) => {
        this.setState({
            ...this.state,
            isProfileWaterFormVisible: true,
            selectedWaterProfileId: undefined,
        });
    }

    public onEditWaterProfile = (waterProfile: WaterProfile) => {
        this.setState({
            ...this.state,
            isProfileWaterFormVisible: true,
            selectedWaterProfileId: waterProfile.id,
        })
    }

    public renderTable() {
        const { isAdmin, userId } = this.props;

        return (
        <Container fluid className="tableContainer">
            <Row>
                <Col>
                    <Button
                        variant="blue"
                        onClick={this.onClickAddWaterProfile}
                        className="mb-2"
                    >
                        <PlusCircle /> Add
                    </Button>
                </Col>
            </Row>
            <Row className="tableHeader">
                <Col sm="2">
                    Name
                </Col>
                <Col sm="1">
                    Ca
                </Col>
                <Col sm="1">
                    HCO<sub>3</sub>
                </Col>
                <Col sm="1">
                    SO<sub>4</sub>
                </Col>
                <Col sm="1">
                    Cl
                </Col>
                <Col sm="1">
                    Na
                </Col>
                <Col sm="1">
                    Mg
                </Col>
                <Col sm="2">
                    -
                </Col>
            </Row>
            {
                this.state.waterProfiles.map(waterProfile => {
                return <WaterProfileItem
                            key={waterProfile.id}
                            waterProfile={waterProfile}
                            canEdit={isAdmin || (waterProfile.user_id && waterProfile.user_id === userId)}
                            onEdit={this.onEditWaterProfile}
                            onDelete={this.onDeleteWaterProfile}
                        />
                })
            }
        </Container>)
    }

    public renderContent() {
        return this.renderTable();
    }

    public onDeleteWaterProfile = (waterProfile: WaterProfile) => {
        this.setState({
            ...this.state,
            waterProfiles: this.state.waterProfiles.filter(wp => wp.id !== waterProfile.id),
        });
    }

    public onHideWaterProfileForm = (waterProfile?: WaterProfile) => {
        if(!waterProfile) {
            this.setState({
                ...this.state,
                isProfileWaterFormVisible: false,
            })
            return;
        }

        const { waterProfiles } = this.state;
        let isWaterProfileApplied = false;

        const newWaterProfiles = waterProfiles.map((wp: WaterProfile) => {
            if(wp.id === waterProfile.id) {
                isWaterProfileApplied = true;
                return waterProfile;
            } else {
                return wp;
            }
        });

        this.setState({
            ...this.state,
            isProfileWaterFormVisible: false,
            waterProfiles: !isWaterProfileApplied ? [waterProfile, ...newWaterProfiles] : newWaterProfiles,
        });
    }

    public render() {
        const { isLoading, isProfileWaterFormVisible, selectedWaterProfileId } = this.state;

        return (<Container fluid>
            <Row>
                <h3>Water Profiles</h3>
            </Row>
            <Row>
                <Col sm={{offset:1, span:10}}>
                    {!isLoading ? this.renderContent() : null}
                </Col>
            </Row>
            <EditProfileWaterComponent
                isVisible={isProfileWaterFormVisible}
                waterProfileId={selectedWaterProfileId}
                onHide={this.onHideWaterProfileForm}
            />
        </Container>)
    }
}

export default ProfileWatersComponent;