import * as React from 'react';
import { Nav, NavItem } from 'react-bootstrap';
import { RouteComponentProps, withRouter } from 'react-router';
import { Link } from 'react-router-dom';

import "@app/assets/scss/components/SideMenuComponent.scss";
import BrewSession from "@app/assets/img/menu/brew_session.svg";
import BrewingSetup from "@app/assets/img/menu/brewing_setup.svg";
import Home from "@app/assets/img/menu/home.svg";
import Ingredients from "@app/assets/img/menu/ingredients.svg";
import Recipe from "@app/assets/img/menu/recipe.svg";
import Water from "@app/assets/img/menu/water.svg";


type Props = RouteComponentProps<Record<string,never>>;

class SideMenuComponent extends React.Component<Props, Record<string,never>> {

    public constructor(props: Props) {
        super(props);
    }

    public render() {
        const { pathname } = this.props.location;

        return (
        <Nav className="side-menu flex-column" area-orientation="vertical">
            <Link to="/home">
                <NavItem className={pathname.startsWith("/home") || pathname === "/" ? `active side-menu-nav-item` : `side-menu-nav-item`}>
                    <img src={Home}  width="32px" height="32px" /> Home
                </NavItem>
            </Link>
            <Link to="/ingredients">
                <NavItem className={pathname.startsWith("/ingredients") ? `active side-menu-nav-item` : `side-menu-nav-item`}>
                    <img src={Ingredients}  width="32px" height="32px" /> Ingredients
                </NavItem>
            </Link>
            <Link to="/recipes">
                <NavItem className={pathname.startsWith("/recipes") ? `active side-menu-nav-item` : `side-menu-nav-item`}>
                    <img src={Recipe} width="32px" height="32px" /> Recipes
                </NavItem>
            </Link>
            <Link to="/brews">
                <NavItem className={pathname.startsWith("/brews") ? `active side-menu-nav-item` : `side-menu-nav-item`}>
                    <img src={BrewSession} width="32px" height="32px" /> Brews
                </NavItem>
            </Link>
            <Link to="/waters">
                <NavItem className={pathname.startsWith("/waters") ? `active side-menu-nav-item` : `side-menu-nav-item`}>
                    <img src={Water} width="32px" height="32px" /> Water
                </NavItem>
            </Link>
            <Link to="/setups">
                <NavItem className={pathname.startsWith("/setups") ? `active side-menu-nav-item` : `side-menu-nav-item`}>
                    <img src={BrewingSetup} width="32px" height="32px" /> Brewing Setups
                </NavItem>
            </Link>
        </Nav>
        );
    }
}

export default withRouter(SideMenuComponent)