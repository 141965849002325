import * as React from "react";
import { Col, Container, Row } from "react-bootstrap";

import { Recipe } from "@app/models/recipe";
import { calculateWaterReport } from "@app/utils/recipe_calculator";

interface Props {
    recipe: Recipe,
    grainAbsorptionRatio?: number,
}

class RecipeWaterReportComponent extends React.Component<Props, Record<string, never>> {
    public constructor(props: Props) {
        super(props);
    }

    public calculateIonPPM(sourceWaterPPM: number, grainsMass: number, totalMass: number, infusionVolume: number, spargeVolume: number, finalVolume: number) {
        const totalVolume: number = infusionVolume + spargeVolume;
        const grainsAbsorptionRatio: number = this.props.grainAbsorptionRatio ?? 1.000;
        const adjustorFinalMass = totalMass * ((infusionVolume - ( (grainsMass/1000.0)* grainsAbsorptionRatio))/totalVolume);
        return sourceWaterPPM + (adjustorFinalMass/finalVolume);
    }

    public render() {
        const { recipe } = this.props;
        const waterReport = calculateWaterReport(recipe, 1.000);

        return (
        <Container fluid>
            <h5>Water Report</h5>
            <Row>
                    <Col sm={{offset:1, span:1}}>Ca<span className="waterProfileSuperScript">+2</span></Col>
                    <Col sm="1">Mg<span className="waterProfileSuperScript">+2</span></Col>
                    <Col sm="1">Na<span className="waterProfileSuperScript">+</span></Col>
                    <Col sm="1">Cl<span className="waterProfileSuperScript">-</span></Col>
                    <Col sm="1">SO<span className="waterProfileSubScript">4</span><span className="waterProfileSuperScript">-2</span></Col>
                    <Col sm="1">HCO<span className="waterProfileSubScript">3</span></Col>
            </Row>
            <Row>
                <Col sm={{offset:1, span:1}}>{waterReport.calcium.toFixed(1)}</Col>
                <Col sm="1">{waterReport.magnesium.toFixed(1)}</Col>
                <Col sm="1">{waterReport.sodium.toFixed(1)}</Col>
                <Col sm="1">{waterReport.chloride.toFixed(1)}</Col>
                <Col sm="1">{waterReport.sulfate.toFixed(1)}</Col>
                <Col sm="1">{waterReport.bicarbonate.toFixed(1)}</Col>
            </Row>
        </Container>
        );
    }
}

export default RecipeWaterReportComponent;