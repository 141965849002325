import React = require("react");
import { Button, Col, Row } from "react-bootstrap";
import { Trash } from "react-bootstrap-icons";

import { Hop } from "@app/models/hop";

import "@app/assets/scss/components/IngredientsItem.scss";


interface Props {
    hop: Hop,
    onDelete?: (hop: Hop) => void,
    canEdit: boolean,
}

export class HopItemComponent extends React.Component<Props, Record<string,never>> {

    public constructor(props: Props) {
        super(props)
    }

    public onClickDelete = (evt) => {
        if(this.props.onDelete) {
            this.props.onDelete(this.props.hop);
        }
    }

    public render() {
        const { hop, canEdit } = this.props;

        return (
            <Row className="ingredientsRowItem">
                <Col sm="3">
                    {hop.name}
                </Col>
                <Col sm="3">
                    {hop.alpha_acid.toFixed(2)} %
                </Col>
                <Col sm="3">
                    {canEdit ?
                        <Button size="sm" onClick={this.onClickDelete}>
                            <Trash /> Delete
                        </Button> : null
                    }
                </Col>
            </Row>
        );
    }
}