import * as React from "react";
import { Card, CardGroup, Col, Container, Row } from "react-bootstrap";
import { RouteComponentProps, withRouter } from 'react-router';
import { Link } from 'react-router-dom';

import LoadingComponent from "@app/components/common/LoadingComponent";
import { BrewSession, getPhaseFriendlyString } from "@app/models/brew_session";
import { PersistedRecipe } from "@app/models/recipe";
import { UserProfile } from "@app/models/user";
import ServicesHelper from "@app/services/serviceshelper";
import { timestampToDate } from "@app/utils/date";


interface State {
    isLoading: boolean,
    userProfile?: UserProfile,
    recipes?: PersistedRecipe[],
    brewSessions?: BrewSession[],
}

interface ComponentParams {
    userId: number,
}

type Props = RouteComponentProps<ComponentParams>;


class UserProfileComponent extends React.Component<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            isLoading: true,
        };
    }

    public componentDidMount(): void {
        const userId = this.props.match.params.userId as number;

        ServicesHelper.instance().user().getUserProfile(userId).then(resp => {
            this.setState({
                ...this.state,
                isLoading: false,
                userProfile: resp.user_profile,
                recipes: resp.recipes,
                brewSessions: resp.brew_sessions,
            });
        }, err => { throw err; });
    }

    public renderRecipes() {
        const { recipes } = this.state;
        return <Card>
            <Card.Header>Recipe(s)</Card.Header>
            <Card.Body>
                { recipes.map((recipe: PersistedRecipe) => <Row>
                    <Col>
                        <Link to={`/recipes/view/${recipe.id}`}>
                            {recipe.name}
                        </Link>
                    </Col>
                    <Col>{timestampToDate(recipe.created_at)}</Col>
                </Row>) }
            </Card.Body>
        </Card>
    }

    public renderBrewSessions() {
        const { brewSessions } = this.state;
        return <Card>
            <Card.Header>Brew Session(s)</Card.Header>
            <Card.Body>
                { brewSessions.map((brewSession: BrewSession) => <Row>
                    <Col>{brewSession.batch_name}</Col>
                    <Col>
                        <Link to={`/recipes/view/${brewSession.recipe_id}`}>
                            {brewSession.recipe.name}
                        </Link>
                    </Col>
                    <Col>{getPhaseFriendlyString(brewSession.phase)}</Col>
                    <Col>{timestampToDate(brewSession.created_at)}</Col>
                </Row>) }
            </Card.Body>
        </Card>
    }

    public renderProfile(userProfile: UserProfile) {
        const sessionKey = ServicesHelper.instance().getSessionKey();

        return <>
            <Row>
                <h4>{ userProfile.user?.display_name }'s Profile</h4>
            </Row>
            <Row className="margin-bottom-1">
                <Col sm="4">
                    <Card>
                        <Card.Header>Avatar</Card.Header>
                        <Card.Body>
                            <img src={`${userProfile.avatar?.image_uri}?session_key=${sessionKey}`} />
                        </Card.Body>
                    </Card>
                </Col>
                <Col sm="8">
                    <Card>
                        <Card.Header>{userProfile.user?.display_name}'s Brewery</Card.Header>
                        <Card.Body>
                        { userProfile.my_brewery }
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row className="margin-bottom-1">
                <Col sm="3">Location</Col>
                <Col sm="3">{ userProfile.location }</Col>
                <Col sm="3">Website</Col>
                <Col sm="3">{ userProfile.website }</Col>
            </Row>
            <Row>
                <Col sm="12">
                    <CardGroup>
                        { this.renderRecipes() }
                        { this.renderBrewSessions() }
                    </CardGroup>
                </Col>
            </Row>
        </>

    }

    public render() {
        const { isLoading, userProfile } = this.state;

        return <Container>
            { isLoading ?
                <LoadingComponent /> :
                this.renderProfile(userProfile)
            }
        </Container>

    }
}

export default withRouter(UserProfileComponent);