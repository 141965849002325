import * as React from "react";
import { Alert, Button, Col, Container, Form, Row } from "react-bootstrap";
import { ArrowLeftCircle } from "react-bootstrap-icons";
import { LinkContainer } from "react-router-bootstrap";

import { Country, Producer } from "@app/models/producer";
import ServicesHelper from "@app/services/serviceshelper";

interface State {
    name: string,
    countryId: number,
    countries: Country[],
    abbreviations: string,
    errors?: string[],
}

interface Props {
    producer: Producer,
}

class EditProducerComponent extends React.Component<Props, State> {

    public constructor(props: Props) {
        super(props);

        this.state = {
            name: props.producer.name,
            countryId: 0,
            countries: [],
            abbreviations: props.producer.abbreviations.join(','),
            errors: [],
        };
    }

    public componentDidMount(): void {
        ServicesHelper.instance().ingredients().getCountries().then(resp => {
            this.setState({
                ...this.state,
                countries: resp.countries,
                countryId: this.props.producer.country ? resp.countries.find(
                    (country: Country) => country.name.toLowerCase() === this.props.producer.country.toLocaleLowerCase())?.id
                    ?? 0 : 0,
            });
        }, err => {throw err;});
    }

    public onChangeName = (evt: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            ...this.state,
            name: evt.target.value,
        });
    }

    public onChangeCountry = (evt: React.ChangeEvent<HTMLSelectElement>) => {
        this.setState({
            ...this.state,
            countryId: Number.parseInt(evt.target.value, 10),
        });

        ServicesHelper.instance()
            .ingredients()
            .updateProducerCountry(this.props.producer.id, this.state.countryId)
            .then(resp => { return; }, err => {throw err;});
    }

    public onChangeAbbreviations = (evt: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            ...this.state,
            abbreviations: evt.target.value,
        });
    }

    public onBlurName = (evt: React.FocusEvent<HTMLInputElement>) => {
        this.setState({
            ...this.state,
            name: evt.target.value,
        });

        ServicesHelper.instance()
            .ingredients()
            .updateProducerName(this.props.producer.id, this.state.name)
            .then(resp => { return; }, err => {throw err;});
    }

    public onBlurAbbreviations = (evt: React.FocusEvent<HTMLInputElement>) => {
        this.setState({
            ...this.state,
            abbreviations: evt.target.value,
        });

        ServicesHelper.instance()
            .ingredients()
            .updateProducerAbbreviations(this.props.producer.id, this.state.abbreviations.split(','))
            .then(resp => { return; }, err => {throw err;});
    }

    public render() {
        const { name, countries, countryId, abbreviations, errors} = this.state;

        return (
            <Container fluid>
                <Row>
                    <LinkContainer to="/ingredients/producers">
                        <Button>
                            <ArrowLeftCircle />
                            Back
                        </Button>
                    </LinkContainer>
                </Row>
                <Row>
                    <Col md={{ span:8, offset:2}}>
                        {errors.length > 0 ? (
                            <Alert variant="danger">
                                <ul>
                                    {errors.map(err => { return (<li key={err}>{err}</li>) })}
                                </ul>
                            </Alert>
                        ) : null}
                        <Form>
                            <Form.Group controlId="name">
                                <Form.Label>Name</Form.Label>
                                <Form.Control type="text"
                                            placeholder="Producer name"
                                            value={name}
                                            onChange={this.onChangeName}
                                            onBlur={this.onBlurName}
                                />
                            </Form.Group>
                            <Form.Group controlId="country">
                                <Form.Label>Country</Form.Label>
                                <Form.Select value={countryId}
                                            defaultValue={"0"}
                                            onChange={this.onChangeCountry}
                                >
                                    <option value="0">Unknown</option>
                                    <>
                                    { countries.map(country => <option key={country.id} value={country.id}>{country.name}</option>) }
                                    </>
                                </Form.Select>
                            </Form.Group>
                            <Form.Group controlId="abbreviations">
                                <Form.Label>Abbreviations</Form.Label>
                                <Form.Control type="text"
                                            placeholder="Abbreviations, separated by a comma ','"
                                            value={abbreviations}
                                            onChange={this.onChangeAbbreviations}
                                            onBlur={this.onBlurAbbreviations}
                                />
                            </Form.Group>
                        </Form>
                    </Col>
                </Row>
            </Container>
            );
    }
}

export default EditProducerComponent;