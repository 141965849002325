import { action, makeAutoObservable, makeObservable, observable } from 'mobx';

import { RootStore } from './rootstore';

export interface ISettingsStore {
  isDesktopMode: boolean;
}

export class SettingsStore implements ISettingsStore {
  private rootStore: RootStore;

  @observable isDesktopMode = false;

  constructor(rootStore?: RootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
  }

  @action setIsDesktopMode(isDesktopMode: boolean): void {
      this.isDesktopMode = isDesktopMode;
  }
}
