import * as React from "react";
import { Container, Nav } from "react-bootstrap";
import { RouteComponentProps, withRouter } from 'react-router';
import { LinkContainer } from 'react-router-bootstrap';

import SourceWater from "@app/assets/img/ingredients/source_water.svg";
import WaterProfile from "@app/assets/img/ingredients/water_profile.svg";
import ProfileWatersComponent from "@app/components/ingredients/ProfileWatersComponent";
import SourceWatersComponent from "@app/components/ingredients/SourceWatersComponent";
import { UserRank } from "@app/models/user";
import { UserStore } from "@app/stores/userstore";

type WatersParams = {
  id?: number;
  activeTab?: string;
  action?: string;
};

interface ComponentProps {
    userStore: UserStore,
}

type Props = RouteComponentProps<WatersParams> & ComponentProps;


class WatersComponent extends React.Component<Props, Record<string, never>> {

    public constructor(props: Props) {
        super(props);
    }

    public renderContent() {
        const {id, activeTab, action} = this.props.match.params;
        const isAdmin = this.props.userStore.user.user_rank === UserRank.Admin;
        const userId = this.props.userStore.user.user_id;

        switch(activeTab) {
            default:
            case "profile":
                return (<ProfileWatersComponent
                    id={id}
                    action={action}
                    isAdmin={isAdmin}
                    userId={userId}
                />);
            case "source":
                return (<SourceWatersComponent
                    id={id}
                    action={action}
                    isAdmin={isAdmin}
                    userId={userId}
                />);
        }
    }

    public render() {
        const activeTab = this.props.match.params.activeTab as string ?? "profile";

        return (
        <>
            <Container fluid>
                <Nav>
                    <LinkContainer to="/waters/profile">
                        <Nav.Link active={activeTab === "profile"}>
                            <img src={WaterProfile} width="40px" height="40px" className="nav-item-icon" />
                            Profile
                        </Nav.Link>
                    </LinkContainer>
                    <LinkContainer to="/waters/source">
                        <Nav.Link active={activeTab === "source"}>
                            <img src={SourceWater} width="40px" height="40px" className="nav-item-icon" />
                            Source Water
                        </Nav.Link>
                    </LinkContainer>
                </Nav>
            </Container>
            <Container fluid>
                {this.renderContent()}
            </Container>
        </>
        )
    }
}

export default withRouter(WatersComponent);