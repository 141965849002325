export interface FieldValidation {
    message: string,
    currentValue: string,
}

export interface FieldValidations {
    [fieldName: string]: FieldValidation,
}

export interface FieldValidationsReturn<T> {
    value?: T,
    fieldValidations: FieldValidations,
}

export const getFieldValidation = (fieldValidations: FieldValidations, fieldName: string): FieldValidation | undefined => {
    if(fieldValidations[fieldName]) {
        return fieldValidations[fieldName];
    }

    return undefined;
}

export const reduceFieldValidations = (fieldValidations: FieldValidations, fieldName: string): FieldValidations => {
    const newFieldValidations: FieldValidations = {};

    Object.keys(fieldValidations).forEach(key => {
        if(key !== fieldName) {
            newFieldValidations[key] = fieldValidations[key];
        }
    });

    return newFieldValidations;
}

export const fieldValueIsANumber = (fieldValidations: FieldValidations, fieldName: string, fieldValue: string) : FieldValidationsReturn<number> => {
    const value = Number(fieldValue);
    const fieldValidationsReturn: FieldValidationsReturn<number> = {
        fieldValidations: {
            ...fieldValidations
        }
    };

    if (isNaN(value)) {
        fieldValidationsReturn.fieldValidations[fieldName] = { message: `${fieldValue} is not a Number`, currentValue: fieldValue }
    } else {
        fieldValidationsReturn.fieldValidations = reduceFieldValidations(fieldValidations, fieldName);
        fieldValidationsReturn.value = value
    }

    return fieldValidationsReturn;
}

export const fieldValueIsAnInteger = (fieldValidations: FieldValidations, fieldName: string, fieldValue: string) : FieldValidationsReturn<number> => {
    const value = Number(fieldValue);
    const fieldValidationsReturn: FieldValidationsReturn<number> = {
        fieldValidations: {
            ...fieldValidations
        }
    };

    if (!Number.isInteger(value)) {
        fieldValidationsReturn.fieldValidations[fieldName] = { message: `${fieldValue} is not an Integer`, currentValue: fieldValue }
    } else {
        fieldValidationsReturn.fieldValidations = reduceFieldValidations(fieldValidations, fieldName);
        fieldValidationsReturn.value = value
    }

    return fieldValidationsReturn;
}

export const hasAnyFieldValidation = (fieldValidations: FieldValidations): boolean => {
    return Object.keys(fieldValidations).length > 0;
}