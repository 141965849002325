import * as React from "react";
import { Alert, Col, Container, Form, Modal, Row } from "react-bootstrap";
import { HourglassSplit, PlusCircle, Tools } from "react-bootstrap-icons";
import { RouteComponentProps, withRouter } from "react-router";
import { Link } from 'react-router-dom'

import Button from "@app/components/common/Button";
import FormControlComponent from "@app/components/common/FormControlComponent";
import { BrewingSetup } from "@app/models/brewing_setup";
import ServicesHelper from "@app/services/serviceshelper";


interface State {
    name: string,
    isLoading: boolean,
    brewingSetups: BrewingSetup[],
    selectedBrewingSetupId: number,
    isPublic: boolean,
}

interface ComponentProps {
    onHide: () => void,
    isVisible: boolean,
}

type Props = ComponentProps & RouteComponentProps<Record<string, never>>;

class NewRecipeComponent extends React.Component<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            isLoading: true,
            name: "",
            brewingSetups: [],
            selectedBrewingSetupId: 0,
            isPublic: false,
        }
    }

    public componentDidMount() {
        ServicesHelper.instance().brewing().getBrewingSetups().then(resp => {
            const defaultBrewingSetup = resp.brewing_setups.filter(bs => bs.is_default)
            const selectedBrewingSetupId = defaultBrewingSetup.length > 0 ? defaultBrewingSetup[0].id : (
                resp.brewing_setups.length > 0 ? resp.brewing_setups[0].id ?? 0 : 0
            );

            this.setState({
                ...this.state,
                isLoading: false,
                brewingSetups: resp.brewing_setups,
                selectedBrewingSetupId,
            });
        }, err => { throw err; });
    }

    public onChangeName = (evt: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            ...this.state,
            name: evt.target.value,
        });
    }

    public onChangeBrewingSetup = (evt: React.ChangeEvent<HTMLSelectElement>) => {
        const brewingSetupId = Number.parseInt(evt.target.value, 10);

        this.setState({
            ...this.state,
            selectedBrewingSetupId: brewingSetupId,
        });
    }

    public onCreateButton = (evt: React.MouseEvent<HTMLButtonElement>) => {
        const { selectedBrewingSetupId, name, isPublic } = this.state;

        ServicesHelper.instance().recipes().createRecipe(selectedBrewingSetupId, name, isPublic).then(resp => {
            this.props.history.push(`/recipes/edit/${resp.recipe_id}`);
        }, err => { throw err; });
    }

    public renderBrewingSetups() {
        const { brewingSetups, selectedBrewingSetupId } = this.state;
        return (<>
                {brewingSetups.length !== 0 ? (
                    <Form.Select onChange={this.onChangeBrewingSetup} defaultValue={selectedBrewingSetupId}>
                        {brewingSetups.map(brewingSetup => <option key={brewingSetup.id} value={brewingSetup.id}>{brewingSetup.name} {brewingSetup.is_default ? `(default)` : ``}</option>)}
                    </Form.Select>
                    ) :
                    <Alert variant="warning">
                        You must create a brewing setup first <br />
                        <Link to="/setups">
                            <Tools />  Here
                        </Link>
                    </Alert>
                }</>);
    }

    public onClickSetPrivate = (evt: React.MouseEvent<HTMLButtonElement>) => {
        this.setState({
            ...this.state,
            isPublic: false,
        });
    }


    public onClickSetPublic = (evt: React.MouseEvent<HTMLButtonElement>) => {
        this.setState({
            ...this.state,
            isPublic: true,
        });
    }

    public onClickCancel = (evt: React.MouseEvent<HTMLButtonElement>) => {
        this.props.onHide();
    }

    public render() {
        const {name, isLoading, brewingSetups, selectedBrewingSetupId, isPublic} = this.state;
        const { isVisible } = this.props;

        return (

            <Modal show={isVisible}>
                <Modal.Header>
                    <Modal.Title>New Recipe</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className="mb-2">
                        <Col sm="3">Name:</Col>
                        <Col sm="6">
                            <FormControlComponent
                                value={name}
                                onChange={this.onChangeName}
                            />
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col sm="3">Visibility:</Col>
                        <Col sm="6">
                            <Button
                                variant={ !isPublic ? `normal` : `grey`}
                                onClick={this.onClickSetPrivate}
                            >
                                Private
                            </Button>
                            <Button
                                variant={ isPublic ? `normal` : `grey`}
                                onClick={this.onClickSetPublic}
                            >
                                Public
                            </Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="3">Setup:</Col>
                        <Col sm="3">
                            { isLoading ? <HourglassSplit /> : this.renderBrewingSetups()}
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="grey"
                        onClick={this.onClickCancel}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="blue"
                        onClick={this.onCreateButton}
                        disabled={selectedBrewingSetupId === undefined || brewingSetups.length === 0}
                    >
                        <PlusCircle /> Create
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default withRouter(NewRecipeComponent);