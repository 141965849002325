import React = require("react");
import { Button, Col, Row } from "react-bootstrap";
import "@app/assets/scss/components/IngredientsItem.scss"
import { Pencil } from "react-bootstrap-icons";
import { LinkContainer } from "react-router-bootstrap";

import { Producer } from "@app/models/producer";

interface Props {
    producer: Producer
}

export class ProducerItemComponent extends React.Component<Props, Record<string, never>> {

    public constructor(props: Props) {
        super(props)
    }

    public render() {
        const { producer } = this.props;
        const abbreviations = producer.abbreviations.join(', ');

        return (
            <Row className="ingredientsRowItem">
                <Col>
                    {producer.name}
                </Col>
                <Col>
                    {producer.country}
                </Col>
                <Col>
                    {abbreviations}
                </Col>
                <Col>
                    <LinkContainer to={`/ingredients/producers/edit/${producer.id}`}>
                        <Button size="sm">
                            <Pencil /> Edit
                        </Button>
                    </LinkContainer>
                </Col>
            </Row>
        );
    }
}