import { observer } from "mobx-react";
import * as React from "react";
import { ButtonGroup, ButtonToolbar, Col, Container, Row } from "react-bootstrap";
import { Envelope, Gear, PersonCircle } from "react-bootstrap-icons";
import { LinkContainer } from "react-router-bootstrap";
import { Route, Switch } from 'react-router-dom'

import Flask from '@app/assets/img/flask.svg';
import BrewingSetupsComponent from "@app/components/breweries/BrewingSetupsComponent";
import BrewsComponent from "@app/components/breweries/BrewsComponent";
import Button from "@app/components/common/Button";
import LoadingComponent from "@app/components/common/LoadingComponent";
import DashboardComponent from "@app/components/dashboard/DashboardComponent";
import ImportWizardComponent from "@app/components/importWizard/ImportWizardComponent";
import IngredientsComponent from "@app/components/ingredients/IngredientsComponent";
import WatersComponent from "@app/components/ingredients/WatersComponent";
import JobComponent from "@app/components/jobs/JobComponent";
import NotificationsPanel from "@app/components/NotificationsPanel";
import RecipesComponent from "@app/components/recipes/RecipesComponent";
import SettingsComponent from "@app/components/settings/SettingsComponent";
import SideMenuComponent from "@app/components/SideMenuComponent";
import LoginComponent from "@app/components/users/LoginComponent";
import MyProfileComponent from "@app/components/users/MyProfileComponent";
import UserProfileComponent from "@app/components/users/UserProfileComponent";
import { AuthManager } from "@app/services/authmanager";
import { RootStore } from "@app/stores/rootstore";

import "@app/assets/scss/components/LayoutComponent.scss";


interface Props {
    store: RootStore
}

interface State {
    isNotificationsPanelVisible: boolean
}

@observer
class LayoutComponent extends React.Component<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            isNotificationsPanelVisible: false
        };
    }

    public onSignOut = (evt: React.MouseEvent<HTMLButtonElement>) => {
        AuthManager.instance().do_signout();
    }

    public onClosePanel = () => {
        this.setState({
            ...this.state,
            isNotificationsPanelVisible: !this.state.isNotificationsPanelVisible,
        });
    }

    public onClickNotification = (evt: React.MouseEvent<HTMLButtonElement>) => {
        this.onClosePanel()
    }

    public render() {
        const displayLogin = this.props.store.settingsStore.isDesktopMode ?
            false :
            !this.props.store.authStore.isAuthenticated;

        if (!this.props.store.userStore?.user && !displayLogin) {
            return <LoadingComponent />;
        }

        const { display_name } = this.props.store.userStore?.user ?? "";
        const { isNotificationsPanelVisible } = this.state;
        const notificationsCount = this.props.store.notificationsStore.notificationsCount;

        return (
        <Container fluid className="layoutContainer">
            <Row className="layoutHeader">
                <Col sm="2">
                    <img src={Flask} width="40px" height="40px" className="appLogo" />
                    <h3>Brew Alchemist</h3>
                </Col>
                {
                    !displayLogin ?
                        <Col sm={{span: 2, offset: 8}}>
                            <ButtonToolbar>
                                <ButtonGroup className="mr-2">
                                    <span className="userInformation">{display_name}</span>
                                    <Button
                                        size="small"
                                        className="toolbar-button"
                                        variant="blue"
                                        onClick={this.onClickNotification}
                                    >
                                        { notificationsCount > 0 ? <span className="notificationBadge">{notificationsCount}</span> : null }
                                        <Envelope />
                                    </Button>
                                    <LinkContainer to="/my_profile">
                                        <Button
                                            size="small"
                                            className="toolbar-button"
                                            variant="blue"
                                        >
                                            <PersonCircle />
                                        </Button>
                                    </LinkContainer>
                                    <LinkContainer to="/settings">
                                        <Button
                                            size="small"
                                            className="toolbar-button"
                                            variant="blue"
                                        >
                                            <Gear />
                                        </Button>
                                    </LinkContainer>
                                    <Button
                                        size="small"
                                        variant="red"
                                        className="signOutButton"
                                        onClick={this.onSignOut}
                                    >
                                        Sign-out
                                    </Button>
                                </ButtonGroup>
                            </ButtonToolbar>
                            { isNotificationsPanelVisible ?
                                <NotificationsPanel
                                    store={this.props.store.notificationsStore}
                                    onClose={this.onClosePanel}
                                /> : null }
                        </Col> :
                        null
                }
            </Row>
        { displayLogin ?
            <Row>
                <LoginComponent
                    authStore={this.props.store.authStore}
                    apiStore={this.props.store.apiStore}
                />
            </Row> :
            <Row>
                <Col md="2">
                    <SideMenuComponent />
                </Col>
                <Col md="9">
                    <Switch>
                        <Route path={["/ingredients/:activeTab/:action?/:id?","/ingredients/:activeTab/:action?", "/ingredients"]}>
                            <IngredientsComponent userStore={this.props.store.userStore} />
                        </Route>
                        <Route path={["/recipes/:action/:recipeId/:ratingId?", "/recipes/:action/:recipeId?", "/recipes/:action", "/recipes"]}>
                            <RecipesComponent />
                        </Route>
                        <Route path={["/setups/:action/:id?", "/setups/:action", "/setups"]}>
                            <BrewingSetupsComponent />
                        </Route>
                        <Route path={["/waters/:activeTab/:action?/:id?", "/waters/:activeTab/:action?", "/waters/:activeTab", "/waters"]}>
                            <WatersComponent userStore={this.props.store.userStore}/>
                        </Route>
                        <Route path={["/brews/:action?/:id?","/brews"]}>
                            <BrewsComponent />
                        </Route>
                        <Route path={["/jobs/:id"]}>
                            <JobComponent />
                        </Route>
                        <Route path="/profiles/:userId">
                            <UserProfileComponent />
                        </Route>
                        <Route path="/my_profile">
                            <MyProfileComponent />
                        </Route>
                        <Route path="/settings">
                            <SettingsComponent />
                        </Route>
                        <Route path="/import">
                            <ImportWizardComponent notificationsStore={this.props.store.notificationsStore} />
                        </Route>
                        <Route path={["/", "/home"]}>
                            <DashboardComponent />
                        </Route>
                    </Switch>
                </Col>
            </Row>
            }
        </Container>
        )
    }
}

export default LayoutComponent;