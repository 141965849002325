import React = require("react");
import { Button, Container, Row } from "react-bootstrap";

import { Fermentable, fermentableTypeFriendlyString } from "@app/models/fermentable";
import ServicesHelper from "@app/services/serviceshelper";

interface Props {
    fermentableId: number;
}

interface State {
    isLoading: boolean;
    fermentable?: Fermentable;
    isReadOnly: boolean;
}

export class FermentableComponent extends React.Component<Props, State> {
    public constructor(props: Props) {
        super(props);
        this.state = {
            isLoading: true,
            isReadOnly: true
        }
    }

    public componentDidMount() {
        const fermentableId = this.props.fermentableId;
        ServicesHelper.instance().ingredients().getFermentable(fermentableId).then((resp) => {
            this.setState({
                isLoading: false,
                fermentable: resp.fermentable
            });
        }, err => {throw err;})
    }

    public switchEditMode = (enabled: boolean) => {
        this.setState({
            ...this.state,
            isReadOnly: !enabled
        });
    }

    public render() {
        const {isReadOnly, isLoading, fermentable} = this.state;
        if(isLoading) {
            return (<>
                Loading...
            </>)
        }

        return (
            <Container>
                <Row>
                    Name: {fermentable.name}
                </Row>
                <Row>
                    Point per gallons/pounds: {fermentable.point_per_pound_gallon}
                </Row>
                <Row>
                    Lovibond: {fermentable.lovibond}
                </Row>
                <Row>
                    Diastatic Power: {fermentable.diastatic_power}
                </Row>
                <Row>
                    Fermentable Type: {fermentableTypeFriendlyString(fermentable.fermentable_type)}
                </Row>
                <Row>
                    { isReadOnly ? <Button onClick={() => {this.switchEditMode(true)}}>Edit</Button> : <><Button>Save</Button><Button onClick={() => {this.switchEditMode(false)}}>Cancel</Button ></>}
                </Row>
            </Container>
        )
    }
}

