import * as React from "react";
import { Alert, Col, Modal, Row } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

import Button from "@app/components/common/Button";
import { PersistedRecipe } from "@app/models/recipe";
import ServicesHelper from "@app/services/serviceshelper";
import { timestampToDate } from "@app/utils/date";


interface Props {
    recipe: PersistedRecipe,
    canView?: boolean,
    canEdit?: boolean,
    canDelete?: boolean,
    canBrew?: boolean,
    onDelete?: (recipeId: number) => void
}

interface State {
    showDeleteModal: boolean,
    deleteError?: string,
}

class RecipeItemComponent extends React.Component<Props, State> {

    public constructor(props: Props) {
        super(props);
        this.state = {
            showDeleteModal: false,
        };
    }

    public onHide = (evt: React.MouseEvent<HTMLButtonElement>) => {
        this.setState({
            ...this.state,
            showDeleteModal: false,
        });
    }

    public onShowDeleteModal = (evt: React.MouseEvent<HTMLButtonElement>) => {
        this.setState({
            ...this.state,
            showDeleteModal: true,
        });
    }

    public onDelete = (evt: React.MouseEvent<HTMLButtonElement>) => {
        const { recipe } = this.props;
        ServicesHelper.instance().recipes().deleteRecipe(recipe.id).then(resp => {
            if(resp.success) {
                this.setState({
                    ...this.state,
                    showDeleteModal: false,
                    deleteError: undefined,
                });

                this.props.onDelete(recipe.id);
            } else {
                this.setState({
                    ...this.state,
                    deleteError: resp.error,
                });
            }
        }, err => { throw err; });

    }

    public render() {
        const { recipe, canView, canEdit, canDelete, canBrew } = this.props;
        const { showDeleteModal, deleteError } = this.state;

        return (
            <Row className="recipeRowItem">
                <Col sm="5">
                    {recipe.name}
                </Col>
                <Col sm="2">
                    {timestampToDate(recipe.created_at)}
                </Col>
                <Col sm="2">
                    {timestampToDate(recipe.updated_at)}
                </Col>
                <Col sm="3">
                    {
                        canView ?
                            <LinkContainer to={`/recipes/view/${recipe.id}`}>
                                <Button variant="normal" size="small">View</Button>
                            </LinkContainer> :
                            null
                    }
                    {
                        canEdit ?
                            <LinkContainer to={`/recipes/edit/${recipe.id}`}>
                                <Button variant="blue" size="small">Modify</Button>
                            </LinkContainer> :
                            null
                    }
                    {
                        canBrew ?
                            <LinkContainer to={`/brews/new/${recipe.id}`}>
                                <Button variant="yellow" size="small">Brew</Button>
                            </LinkContainer> :
                            null
                    }
                    {
                        canDelete ?
                            <Button
                            variant="red"
                            size="small"
                            onClick={this.onShowDeleteModal}
                            >
                                Delete
                            </Button> :
                            null
                    }
                </Col>
                <Modal show={showDeleteModal}>
                    <Modal.Header>
                        <Modal.Title>Delete a recipe</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Are you sure you want to delete this recipe ? <br />
                        {recipe.name} <br />
                        { deleteError ?
                            <Alert variant="warning">
                                Unable to delete this recipe <br />
                                {deleteError}
                            </Alert> : null
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="blue" onClick={this.onHide} >
                            No
                        </Button>
                        <Button variant="red" onClick={this.onDelete} >
                            Yes
                        </Button>
                    </Modal.Footer>
                </Modal>
            </Row>
        )
    }

}

export default RecipeItemComponent;