import * as React from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { TrashFill } from "react-bootstrap-icons";

import { TimeUsed, friendlyTimeUsedString } from "@app/models/time_used";
import { RecipeWaterAdjustor } from "@app/models/water_adjustor";
import ServicesHelper from "@app/services/serviceshelper";

import FormControlComponent from "../common/FormControlComponent";

interface Props {
    recipeId: number,
    waterAdjustor: RecipeWaterAdjustor,
    onChange: (waterAdjustor: RecipeWaterAdjustor) => void,
    onDelete: (waterAdjustorId: number) => void,
}

interface State {
    amount: string;
}

class EditWaterAdjustorComponent extends React.Component<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            amount: props.waterAdjustor.amount.toFixed(1),
        };
    }

    public onChangeTimeUsed = (evt) => {
        ServicesHelper.instance()
            .recipes()
            .updateRecipeWaterAdjustorTimeUsed(this.props.recipeId, this.props.waterAdjustor.id, evt.target.value as TimeUsed)
            .then(resp => { return; }, err => { throw err; });

        this.props.onChange({
            ...this.props.waterAdjustor,
            time_used: evt.target.value as TimeUsed,
        });
    }

    public onChangeAmount = (evt) => {
        this.setState({
            ...this.state,
            amount: evt.target.value,
        });
    }

    public onBlurAmount = (evt) => {
        const amount = Number.parseFloat(this.state.amount);
        if (Number.isNaN(amount)) {
            // todo
            return;
        }

        ServicesHelper.instance()
            .recipes()
            .updateRecipeWaterAdjustorAmount(this.props.recipeId, this.props.waterAdjustor.id, amount)
            .then(resp => { return; }, err => { throw err; });

        this.props.onChange({
            ...this.props.waterAdjustor,
            amount,
        });
    }

    public render() {
        const { waterAdjustor, onDelete } = this.props;
        return (
            <Row>
                <Col sm={{offset:1, span: 2}}>
                    {waterAdjustor.name}
                </Col>
                <Col sm="2">
                    <Form.Select onChange={this.onChangeTimeUsed} defaultValue={waterAdjustor.time_used}>
                        <option value={TimeUsed.Mash}>{friendlyTimeUsedString(TimeUsed.Mash)}</option>
                        <option value={TimeUsed.Boil}>{friendlyTimeUsedString(TimeUsed.Boil)}</option>
                        <option value={TimeUsed.Fermentation}>{friendlyTimeUsedString(TimeUsed.Fermentation)}</option>
                    </Form.Select>
                </Col>
                <Col sm="1">
                    Amount:
                </Col>
                <Col sm="1">
                    <FormControlComponent
                            size="sm"
                            value={this.state.amount}
                            onChange={this.onChangeAmount}
                            onBlur={this.onBlurAmount}
                    />
                </Col>
                <Col sm="1">
                    gram(s)
                </Col>
                <Col sm={{offset:1, span:1}}>
                    <Button size="sm" variant="danger" onClick={(evt) => { onDelete(waterAdjustor.id); }}>
                        <TrashFill color="white" />
                    </Button>
                </Col>
            </Row>
        );
    }
}

export default EditWaterAdjustorComponent;