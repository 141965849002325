import { Job, JobStatus } from "@app/models/job";
import ServicesHelper from "@app/services/serviceshelper";

export interface JobsResponse {
    count: number,
    jobs: Job[],
}

export interface JobResponse {
    job: Job,
}

export interface JobByEventIdResponse {
    job?: Job,
}

export class JobsService {

    private uri: string;
    private servicesHelper: ServicesHelper

    public constructor(uri: string, servicesHelper: ServicesHelper) {
        this.uri = uri;
        this.servicesHelper = servicesHelper;
    }

    public getJobs(limit = 20, offset = 0, sort = "-created_at", status?: JobStatus): Promise<JobsResponse> {
        let uri = `${this.uri}?limit=${limit}&offset=${offset}&sort=${sort}`;
        if (status) {
            uri = `${uri}&status=${status}`;
        }

        return this.servicesHelper.do_get<JobsResponse>(uri);
    }

    public getJob(jobId: number): Promise<JobResponse> {
        return this.servicesHelper.do_get<JobResponse>(`${this.uri}${jobId}`);
    }

    public getJobByEventId(eventId: string): Promise<JobByEventIdResponse> {
        return this.servicesHelper.do_get<JobByEventIdResponse>(`${this.uri}event/${eventId}`);
    }
 }
