
export interface WaterProfile {
    id?: number,
    name: string,
    notes: string,
    calcium: number,
    bicarbonate: number,
    sulfate: number,
    chloride: number,
    sodium: number,
    magnesium: number,
    user_id?: number,
}

export enum AlkalinityType {
    HCO3 = "HCO3",
    CaCO3 = "CaCO3"
}

export interface SourceWaterProfile extends Omit<WaterProfile, "bicarbonate"> {
    alkalinity_type: AlkalinityType,
    alkalinity: number,
    ph: number,
}

export interface WaterReport {
    calcium: number,
    magnesium: number,
    sodium: number,
    chloride: number,
    sulfate: number,
    bicarbonate: number,
}