import * as React from "react";
import { Col, Row } from "react-bootstrap";
import { Pencil, Trash } from "react-bootstrap-icons";

import Button from "@app/components/common/Button";
import ConfirmationModalComponent from "@app/components/common/ConfirmationModalComponent";
import RecipeRatingFormComponent from "@app/components/recipes/RecipeRatingFormComponent";
import RecipeStarRatingComponent from "@app/components/recipes/RecipeStarRatingComponent";
import { RecipeRating } from "@app/models/recipe";
import ServicesHelper from "@app/services/serviceshelper";
import { timestampToDateTime } from "@app/utils/date";



interface State {
    showDeleteModal: boolean,
    showUpdateForm: boolean,
}

interface Props {
    rating: RecipeRating,
    onDelete?: (ratingId: number) => void,
    onUpdate?: (recipeRating: RecipeRating) => void,
}

class RecipeRatingItemComponent extends React.Component<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            showDeleteModal: false,
            showUpdateForm: false,
        };
    }

    public onClickDelete = (evt: React.MouseEvent<HTMLButtonElement>) => {
        this.setState({
            ...this.state,
            showDeleteModal: true,
        });
    }

    public onHideConfirmModal = () => {
        this.setState({
            ...this.state,
            showDeleteModal: false,
        });
    }

    public onConfirmDelete = () => {
        this.setState({
            ...this.state,
            showDeleteModal: false,
        });

        const { rating, onDelete } = this.props;

        ServicesHelper.instance().recipes().deleteRating(rating.id).then(
            resp => {
                if(onDelete) {
                    onDelete(rating.id);
                }
            },
            err => { throw err; }
        );
    }

    public onHideUpdateForm = () => {
        this.setState({
            ...this.state,
            showUpdateForm: false,
        })
    }

    public onClickEdit = () => {
        this.setState({
            ...this.state,
            showUpdateForm: true,
        })
    }

    public onUpdateRating = (recipeRating: RecipeRating) => {
        if(this.props.onUpdate) {
            this.props.onUpdate(recipeRating);
        }
    }

    public render() {
        const { rating } = this.props;
        const { showDeleteModal, showUpdateForm } = this.state;

        return (<Row>
            <Col>{rating.user?.display_name}</Col>
            <Col>
                <RecipeStarRatingComponent rating={rating.rating} />
            </Col>
            <Col>{rating.comment}</Col>
            <Col>{timestampToDateTime(rating.created_at)}</Col>
            <Col sm="1">
                <Button variant="grey" onClick={this.onClickEdit}>
                    <Pencil />
                </Button>
            </Col>
            <Col sm="1">
                <Button variant="red" onClick={this.onClickDelete}>
                    <Trash />
                </Button>
            </Col>
            <ConfirmationModalComponent
                isVisible={showDeleteModal}
                onCancel={this.onHideConfirmModal}
                onConfirm={this.onConfirmDelete}
                title="Delete Rating Confirmation"
                body="Are you sure you want to delete this rating ?"
            />
            <RecipeRatingFormComponent
                isVisible={showUpdateForm}
                recipeId={rating.recipe_id}
                recipeRating={rating}
                onHide={this.onHideUpdateForm}
                onUpdate={this.onUpdateRating}
            />
        </Row>)
    }
}

export default RecipeRatingItemComponent;

