import * as React from "react";
import { Col, Row } from "react-bootstrap";
import { Pencil, Trash } from "react-bootstrap-icons";

import Button from "@app/components/common/Button";
import ConfirmationModalComponent from "@app/components/common/ConfirmationModalComponent";
import { SourceWaterProfile } from "@app/models/waterProfile";
import ServicesHelper from "@app/services/serviceshelper";

interface Props {
    sourceWaterProfile: SourceWaterProfile,
    onEdit: (SourceWaterProfile: SourceWaterProfile) => void,
    onDelete: (SourceWaterProfile: SourceWaterProfile) => void,
    canEdit: boolean,
}

interface State {
    showDeleteModal: boolean,
}

class SourceWaterProfileItem extends React.Component<Props, State> {
     public constructor(props: Props) {
         super(props);

         this.state = {
            showDeleteModal: false,
         };
     }

     public onClickEdit = (evt: React.MouseEvent<HTMLButtonElement>) => {
        const { sourceWaterProfile, onEdit } = this.props;
        if(sourceWaterProfile.id) {
            onEdit(sourceWaterProfile);
        }
     }

     public onConfirmDelete = () => {
        const { sourceWaterProfile } = this.props;

        this.setState({
            ...this.state,
            showDeleteModal: false,
        });

        ServicesHelper.instance().ingredients().deleteSourceWaterProfile(sourceWaterProfile.id).then(
            resp => {
                this.props.onDelete(sourceWaterProfile);
            },
            err => { throw err; }
        )
     }

     public onCancelDelete = () => {
        this.setState({
            ...this.state,
            showDeleteModal: false,
        });
     }


     public onClickDelete = (evt: React.MouseEvent<HTMLButtonElement>) => {
        this.setState({
            ...this.state,
            showDeleteModal: true,
        });
     }

     public render() {
        const { sourceWaterProfile, canEdit } = this.props;
        const { showDeleteModal } = this.state;

        return (
            <Row className="tableRow">
                <Col sm="2">
                    {sourceWaterProfile.name}
                </Col>
                <Col sm="1">
                    {sourceWaterProfile.calcium}
                </Col>
                <Col sm="1">
                    {sourceWaterProfile.chloride}
                </Col>
                <Col sm="1">
                    {sourceWaterProfile.sulfate}
                </Col>
                <Col sm="1">
                    {sourceWaterProfile.sodium}
                </Col>
                <Col sm="1">
                    {sourceWaterProfile.magnesium}
                </Col>
                <Col sm="2">
                    {sourceWaterProfile.alkalinity} as {sourceWaterProfile.alkalinity_type}
                </Col>
                <Col sm="2">
                    { canEdit ?
                    (<>
                        <Button variant="blue" onClick={this.onClickEdit}>
                            <Pencil /> Edit
                        </Button>
                        <Button variant="red" onClick={this.onClickDelete}>
                            <Trash /> Delete
                        </Button>
                    </>) : `-`}
                </Col>
                <ConfirmationModalComponent
                    isVisible={showDeleteModal}
                    title="Delete water profile"
                    body={`Are you sure you want to delete '${sourceWaterProfile.name}' source water profile ?`}
                    onConfirm={this.onConfirmDelete}
                    onCancel={this.onCancelDelete}
                />
            </Row>
        );
     }
}

export default SourceWaterProfileItem;