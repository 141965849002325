import AwesomeDebouncePromise from "awesome-debounce-promise";
import * as React from "react";
import { Col, Container, Form, Row } from "react-bootstrap";

import LoadingComponent from "@app/components/common/LoadingComponent";
import NoResultRow from "@app/components/common/NoResultRow";
import PaginationComponent from "@app/components/common/PaginationComponent";
import SortableColumnComponent from "@app/components/common/SortableColumnComponent";
import RecipeItemComponent from "@app/components/recipes/RecipeItemComponent";
import { PersistedRecipe } from "@app/models/recipe";
import ServicesHelper from "@app/services/serviceshelper";


const getRecipes = (value: string, offset: number, limit: number, sortBy: string) => {
    return ServicesHelper.instance().recipes().getUserRecipes(value, offset, limit, sortBy).then(resp => {
        return resp;
    })
  };

const getRecipesDebounced = AwesomeDebouncePromise(
    getRecipes,
    300,
    { key: (value: string, offset: number, limit: number, sortBy: string) => "RecipesNameFilter" },
);

export interface State {
    persistedRecipes: PersistedRecipe[],
    totalCount: number,
    isLoading: boolean,
    nameFilter: string,
    sortBy: string,
}

interface Props {
    currentPage?: number,
}

export default class PublicRecipesComponent extends React.Component<Props, State> {
    public constructor(props: Props) {
        super(props);
        this.state = {
            isLoading: false,
            persistedRecipes: [],
            totalCount: 0,
            nameFilter: "",
            sortBy: "name",
        };
    }

    public componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        if(prevProps.currentPage !== this.props.currentPage || prevState.sortBy !== this.state.sortBy) {
            this.componentDidMount();
            window.scrollTo(0, 0);
        }
    }

    public componentDidMount() {
        this.setState({
            ...this.state,
            isLoading: true
        });

        const { currentPage } = this.props;
        const { nameFilter, sortBy } = this.state;

        ServicesHelper.instance().recipes().getRecipes({
                nameFilter,
                offset: ((currentPage ?? 1)-1) * 20,
                limit: 20,
                sort: sortBy,
        }).then((resp) => {
            this.setState({
                ...this.state,
                persistedRecipes: resp.persisted_recipes,
                totalCount: resp.total_count,
                isLoading: false,
            });
        }, err => { throw err; })
    }

    public onDeleteRecipe = (recipeId: number) => {
        this.setState({
            ...this.state,
            persistedRecipes: this.state.persistedRecipes.filter(recipe => recipe.id !== recipeId),
        });
    }

    public onChangeNameFilter = async (evt) => {
        const nameFilter = evt.target.value as string;
        const { sortBy } = this.state;

        this.setState({
            ...this.state,
            nameFilter,
        });

        if(nameFilter.length >= 3) {
            this.setState({
                ...this.state,
                isLoading: true,
                nameFilter,
            });

            const resp = await getRecipesDebounced(nameFilter, 0, 20, sortBy);

            this.setState({
                ...this.state,
                persistedRecipes: resp.persisted_recipes,
                totalCount: resp.total_count,
                isLoading: false,
            });
        }
        else if(nameFilter.length === 0) {
            this.setState({
                ...this.state,
                isLoading: true,
                nameFilter,
            }, () => this.componentDidMount());
        }
    }

    public onClickCreatedAt = (evt) => {
        const { sortBy } = this.state;

        this.setState({
            ...this.state,
            sortBy: "created_at",
        }, () => {
            if(sortBy !== "created_at") {
                this.componentDidMount();
            }
        });
    }

    public onClickUpdatedAt = (evt) => {
        const { sortBy } = this.state;

        this.setState({
            ...this.state,
            sortBy: "updated_at",
        }, () => {
            if(sortBy !== "updated_at") {
                this.componentDidMount();
            }
        });
    }

    public onClickSortableColumn = (name: string) => {
        const { sortBy } = this.state;
        let newSortBy: string;

        if(sortBy.endsWith(name)) {
            newSortBy = sortBy.startsWith("-") ? `${name}`: `-${name}`;
        } else {
            newSortBy = name;
        }

        this.setState({
            ...this.state,
            sortBy: newSortBy,
        });
    }

    public renderRecipesTable() {
        const { nameFilter, sortBy } = this.state;
        const numberOfPages = Math.ceil(this.state.totalCount / 20);

        return (
        <>
            <Container fluid className="tableContainer">
                <Row>
                    <Col sm="2">
                        Recipe Name:
                    </Col>
                    <Col sm="3">
                        <Form.Control
                            value={nameFilter}
                            onChange={this.onChangeNameFilter}
                        />
                    </Col>
                    <Col sm="6">
                    </Col>
                </Row>
                <Row className="tableHeader">
                    <SortableColumnComponent
                        onClick={this.onClickSortableColumn}
                        span={5}
                        currentSortBy={sortBy}
                        label="Name"
                        name="name"
                    />
                    <SortableColumnComponent
                        onClick={this.onClickSortableColumn}
                        span={2}
                        currentSortBy={sortBy}
                        label="Created At"
                        name="created_at"
                    />
                    <SortableColumnComponent
                        onClick={this.onClickSortableColumn}
                        span={2}
                        currentSortBy={sortBy}
                        label="Updated At"
                        name="updated_at"
                    />
                    <Col sm="3">
                        -
                    </Col>
                </Row>
                {
                    this.state.isLoading ?
                        <Row className="recipeRowItem">
                            <LoadingComponent className="loadingRow" />
                        </Row> :
                            (this.state.persistedRecipes.length === 0 ?
                            <NoResultRow className="recipeRowItem" caption="No recipe matching..." /> :
                            this.state.persistedRecipes.map((recipe) => (
                            <RecipeItemComponent
                                canView={true}
                                canEdit={recipe.is_mine}
                                canBrew={recipe.is_mine}
                                canDelete={recipe.is_mine}
                                key={recipe.id}
                                recipe={recipe}
                                onDelete={this.onDeleteRecipe}
                            />
                        ))
                    )
                }
                <Row className="paginationRow">
                        <Col>
                            <PaginationComponent
                                numberOfPages={numberOfPages}
                                currentPage={this.props.currentPage ?? 1}
                                pageLinkPrefix={`/recipes/public/`}
                            />
                        </Col>
                </Row>
            </Container>
        </>);
    }

    public render() {
        return (<>
            {this.renderRecipesTable()}
        </>)
    }

}