import * as React from "react";
import { Col, Row } from "react-bootstrap";
import { TrashFill } from "react-bootstrap-icons";

import Button from "@app/components/common/Button";
import FormControlComponent from "@app/components/common/FormControlComponent";
import { FieldValidations, fieldValueIsAnInteger, getFieldValidation } from "@app/models/fields";
import { RecipeFermentable } from "@app/models/recipe";


interface Props {
    fermentable: RecipeFermentable,
    totalGrains: number;
    onChangeAmount: (fermentableName: string, fermentableAmount: number) => void,
    onDelete: (fermentable: RecipeFermentable) => void,
}

interface State {
    fieldValidations: FieldValidations,
}

class EditFermentableComponent extends React.Component<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            fieldValidations: {},
        };
    }

    public onChangeAmount = (evt: React.ChangeEvent<HTMLInputElement>) => {
        const { fieldValidations } = this.state;
        const { fermentable, onChangeAmount } = this.props;

        const fieldValidationsReturn = fieldValueIsAnInteger(
            fieldValidations,
            `fermentable_${fermentable.fermentable.name}`,
            evt.target.value,
        );

        this.setState({
            ...this.state,
            fieldValidations: fieldValidationsReturn.fieldValidations,
        });

        if(fieldValidationsReturn.value) {
            onChangeAmount(fermentable.fermentable.name, fieldValidationsReturn.value);
        }
    }

    public onDelete = (evt: React.MouseEvent<HTMLButtonElement>) => {
        const { fermentable, onDelete } = this.props;
        onDelete(fermentable);
    }

    public render() {
        const { fieldValidations } = this.state;
        const { fermentable, totalGrains } = this.props;

        return (
        <Row key={`${fermentable.fermentable.name}`}>
            <Col sm="1">
                { ((fermentable.amount/totalGrains)*100.00).toFixed(2) } %
            </Col>
            <Col md="3">
                {fermentable.fermentable.name}
            </Col>
            <Col md="1">
                <FormControlComponent
                    size="sm"
                    value={fermentable.amount}
                    fieldValidation={getFieldValidation(fieldValidations, `fermentable_${fermentable.fermentable.name}`)}
                    onChange={this.onChangeAmount}
                />
            </Col>
            <Col md="1">
                gram(s)
            </Col>
            <Col md="1">
                PPG: {fermentable.fermentable.point_per_pound_gallon}
            </Col>
            <Col md="2">
                Lovibond: {fermentable.fermentable.lovibond}
            </Col>
            <Col>
                <Button variant="red" size="small" onClick={this.onDelete}>
                    <TrashFill color="white" />
                </Button>
            </Col>
        </Row>);
    }
}

export default EditFermentableComponent;
