import * as React from "react";
import { Alert, Form, Modal, Row } from "react-bootstrap";

import Button from "@app/components/common/Button";
import { RecipeRating } from "@app/models/recipe";
import ServicesHelper from "@app/services/serviceshelper";

import RecipeStarRatingComponent from "./RecipeStarRatingComponent";


interface State {
    errors: string[],
    isSubmitting: boolean,
    comment: string,
    rating: number,
}

interface Props {
    recipeId: number,
    isVisible: boolean,
    onHide: () => void,
    onCreate?: (recipeRating: RecipeRating) => void,
    onUpdate?: (recipeRating: RecipeRating) => void,
    recipeRating?: RecipeRating,
}

class RecipeRatingFormComponent extends React.Component<Props, State> {
    public constructor(props: Props) {
        super(props);

        const { recipeRating } = this.props;

        this.state = {
            isSubmitting: false,
            comment: recipeRating?.comment ?? "",
            rating: recipeRating?.rating ?? 0,
            errors: [],
        }
    }

    public onClickStar = (evt: React.MouseEvent<SVGElement>, starId: number) => {
        const rect = evt.currentTarget.getBoundingClientRect();
        const x = evt.clientX - rect.left;
        const clickWidthRatio = x / rect.width;
        let newRating = starId * 2;

        if(clickWidthRatio <= 0.5) {
            newRating--;
        }

        this.setState({
            ...this.state,
            rating: newRating,
        });

    }

    public onChangeComment = (evt: React.ChangeEvent<HTMLTextAreaElement>) => {
        this.setState({
            ...this.state,
            comment: evt.target.value,
        });
    }

    public updateRating() {
        const { recipeRating, onHide, onUpdate } = this.props;
        const { comment, rating } = this.state;

        if(!recipeRating) {
            return;
        }

        ServicesHelper.instance().recipes().updateRating(
            recipeRating.id,
            {
                comment,
                rating,
            }
        ).then(resp => {
            this.setState({
                ...this.state,
                rating: 0,
                comment: "",
                isSubmitting: false,
            });

            onHide();

            if(onUpdate) {
                onUpdate({
                    ...recipeRating,
                    comment,
                    rating,
                });
            }

        }, err => {
            this.setState({
                ...this.state,
                isSubmitting: false,
                errors: [
                    "Error while updating the rating..."
                ]
            });

            throw err;
        });
    }

    public createRating() {
        const { recipeId, onHide, onCreate } = this.props;
        const { comment, rating } = this.state;

        ServicesHelper.instance().recipes().addRecipeRating(
            recipeId,
            {
                comment,
                rating,
            }
        ).then(resp => {
            this.setState({
                ...this.state,
                rating: 0,
                comment: "",
                isSubmitting: false,
            });

            onHide();

            if(onCreate) {
                onCreate(resp);
            }

        }, err => {
            this.setState({
                ...this.state,
                isSubmitting: false,
                errors: [
                    "Error while creating the rating..."
                ]
            });

            throw err;
        });
    }

    public onClickSubmit = (evt: React.MouseEvent<HTMLButtonElement>) => {
        const { recipeRating } = this.props;
        const { isSubmitting } = this.state;

        if (isSubmitting) {
            return;
        }

        this.setState({
            ...this.state,
            isSubmitting: true,
        });

        if(recipeRating) {
            this.updateRating();
        } else {
            this.createRating();
        }
    }

    public render() {
        const { isVisible, onHide, recipeRating } = this.props;
        const { errors, comment, rating } = this.state;

        return <Modal show={isVisible}>
        <Modal.Header>
            <Modal.Title>Add Rating</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Row>
                { errors.length > 0 ? <Alert variant="danger">
                    {errors.map(error => {
                        return (
                            <span key={error}>
                                {error}
                            </span>);
                    })}
                </Alert> : null}
            </Row>
            <RecipeStarRatingComponent
                rating={rating}
                size="2em"
                onClick={this.onClickStar}
            />
            <Row>
                Comment:
                <Form.Control as="textarea" value={comment} onChange={this.onChangeComment} />
            </Row>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="grey" onClick={onHide} >
                Cancel
            </Button>
            <Button variant="blue" onClick={this.onClickSubmit} >
                {
                    recipeRating ? "Save" : "Add"
                }
            </Button>
        </Modal.Footer>
    </Modal>
    }

}

export default RecipeRatingFormComponent;