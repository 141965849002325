import * as React from 'react';
import { Container, Row } from "react-bootstrap";
import { CloudArrowUp, CloudDownload, PlusCircle } from "react-bootstrap-icons";
import { RouteComponentProps, withRouter } from 'react-router';
import { Link } from 'react-router-dom';

import Button from "@app/components/common/Button";
import EditRecipeComponent from "@app/components/recipes/EditRecipeComponent";
import ImportRecipeComponent from "@app/components/recipes/ImportRecipeComponent";
import MyRecipesComponent from "@app/components/recipes/MyRecipesComponent";
import NewRecipeComponent from "@app/components/recipes/NewRecipeComponent";
import PublicRecipesComponent from '@app/components/recipes/PublicRecipesComponent';
import ViewRecipeComponent from "@app/components/recipes/ViewRecipeComponent";
import "@app/assets/scss/components/RecipesComponent.scss";
import ServicesHelper from '@app/services/serviceshelper';



type RecipesParams = {
    recipeId?: string;
    action?: string;
    ratingId?: string;
}

interface Props {
    recipeId?: number,
}

type RecipesComponentProps = RouteComponentProps<RecipesParams>;

interface State {
    recipeFile: File,
    isImportFormVisible: boolean,
    isNewRecipeFormVisible: boolean,
}

class RecipesComponent extends React.Component<RecipesComponentProps, State> {

    public constructor(props: Props) {
        super(props)
        this.state = {
            recipeFile: null,
            isImportFormVisible: false,
            isNewRecipeFormVisible: false,
        }
    }

    public renderActionContent(action: string, recipeId?: number, ratingId?: number) {
        if(action === "view" && recipeId) {
            return (<ViewRecipeComponent
                    recipeId={recipeId}
                    ratingId={ratingId}
                    />);
        }
        else if (action === "public") {
            return (<PublicRecipesComponent currentPage={recipeId} />)
        }
        else if(action === "edit" && recipeId) {
            return (<EditRecipeComponent recipeId={recipeId} />);
        }
    }

    public onClickExport = (evt: React.MouseEvent<HTMLButtonElement>) => {
        ServicesHelper.instance().recipes().exportAllRecipes().then(resp => {
            // todo show feedback
        }, err => { throw err; });
    }

    public onClickImportRecipe = (evt: React.MouseEvent<HTMLButtonElement>) => {
        this.setState({
            ...this.state,
            isImportFormVisible: true,
        });
    }

    public onHideImportForm = (refresh: boolean) => {
        this.setState({
            ...this.state,
            isImportFormVisible: false,
        });
    }

    public onClickNewRecipe = (evt: React.MouseEvent<HTMLButtonElement>) => {
        this.setState({
            ...this.state,
            isNewRecipeFormVisible: true,
        });
    }

    public onHideNewRecipeForm = () => {
        this.setState({
            ...this.state,
            isNewRecipeFormVisible: false,
        });
    }

    public render() {
        const {action, recipeId, ratingId} = this.props.match.params;
        const { isImportFormVisible, isNewRecipeFormVisible } = this.state;

        return (
            <>
                {
                    !action || action === "list" || action === "public" ?
                        <Container fluid>
                            <h2>Recipe(s)</h2>
                            <Row className="margin-bottom-1 margin-left-1">
                                <Link to={`/recipes/list`}>
                                    <Button variant={action === "public" ? "blue" : "grey"}>My Recipe(s)</Button>
                                </Link>
                                <Link to={`/recipes/public`}>
                                    <Button variant={action !== "public" ? "blue" : "grey"}>Public Recipe(s)</Button>
                                </Link>
                            </Row>
                        </Container> :
                        null
                }
                { !action || action === "list" ?
                    (<>
                        <Container fluid className="recipesHeader">
                            <h3>My Recipe(s)</h3>
                            <Button onClick={this.onClickNewRecipe}>
                                <PlusCircle /> New Recipe
                            </Button>
                            <Button
                                className="ml-1"
                                onClick={this.onClickImportRecipe}
                            >
                                <CloudArrowUp /> Import Recipe
                            </Button>

                            <Button
                                className="ml-1"
                                onClick={this.onClickExport}
                            >
                                <CloudDownload /> Export recipe(s)
                            </Button>

                        </Container>
                        <Container fluid>
                            <MyRecipesComponent currentPage={recipeId}></MyRecipesComponent>
                        </Container>
                        <ImportRecipeComponent
                            isVisible={isImportFormVisible}
                            onHide={this.onHideImportForm}
                        />
                        <NewRecipeComponent
                            isVisible={isNewRecipeFormVisible}
                            onHide={this.onHideNewRecipeForm}
                        />
                    </>) :
                    this.renderActionContent(action as string, recipeId ? recipeId as number : undefined, ratingId ? ratingId as number : undefined)
                }
            </>
            )
    }
}

export default withRouter(RecipesComponent);