import * as React from "react";
import { Container, Nav } from "react-bootstrap";
import { Link, RouteComponentProps, withRouter } from 'react-router';
import { LinkContainer } from 'react-router-bootstrap';


import Grain from '@app/assets/img/ingredients/grain.svg';
import Hop from '@app/assets/img/ingredients/hop.svg';
import Producer from '@app/assets/img/ingredients/producer.svg';
import Yeast from '@app/assets/img/ingredients/yeast.svg';
import FermentablesComponent from "@app/components/ingredients/FermentablesComponent";
import HopsComponent from "@app/components/ingredients/HopsComponent";
import ProducersComponent from "@app/components/ingredients/ProducersComponent";
import YeastsComponent from "@app/components/ingredients/YeastsComponent";
import { UserRank } from "@app/models/user";
import { UserStore } from "@app/stores/userstore";

type IndredientsParams = {
  id?: number;
  activeTab?: string;
  action?: string;
  userStore: UserStore;
}

type IngredientsComponentProps = RouteComponentProps<IndredientsParams>;

class IngredientsComponent extends React.Component<IngredientsComponentProps, Record<string,never>> {

    public constructor(props: IngredientsComponentProps) {
        super(props);
    }

    public renderContent() {
        const {id, activeTab, action } = this.props.match.params;
        const isAdmin = this.props.userStore.user.user_rank === UserRank.Admin;

        switch(activeTab) {
            default:
            case "fermentables":
                return (<FermentablesComponent id={id} action={action} isAdmin={isAdmin} />);
            case "producers":
                return (<ProducersComponent id={id} action={action} />);
            case "yeasts":
                return (<YeastsComponent id={id} action={action} />)
            case "hops":
                return (<HopsComponent id={id} action={action} isAdmin={isAdmin} />)
        }

        return (<></>);
    }

    public render() {
        const activeTab = this.props.match.params.activeTab ?? "fermentables";

        return (
        <>
            <Container fluid>
                <Nav>
                    <LinkContainer to="/ingredients/fermentables">
                        <Nav.Link active={activeTab === "fermentables"}>
                            <img src={Grain} width="40px" height="40px" className="nav-item-icon" />
                            Fermentables
                        </Nav.Link>
                    </LinkContainer>
                    <LinkContainer to="/ingredients/hops">
                        <Nav.Link active={activeTab === "hops"}>
                            <img src={Hop} width="40px" height="40px" className="nav-item-icon" />
                            Hops
                        </Nav.Link>
                    </LinkContainer>
                    <LinkContainer to="/ingredients/yeasts">
                        <Nav.Link active={activeTab === "yeasts"}>
                            <img src={Yeast} width="40px" height="40px" className="nav-item-icon" />
                            Yeasts
                        </Nav.Link>
                    </LinkContainer>
                    <LinkContainer to="/ingredients/producers">
                        <Nav.Link active={activeTab === "producers"}>
                            <img src={Producer} width="40px" height="40px" className="nav-item-icon" />
                            Producers
                        </Nav.Link>
                    </LinkContainer>
                </Nav>
            </Container>
            <Container fluid>
                {this.renderContent()}
            </Container>
        </>
        )
    }
}

export default withRouter(IngredientsComponent);