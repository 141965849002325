
export enum YeastType {
    Ale = "ale",
    Lager = "lager"
}

export enum YeastForm {
    Dry = "dry",
    Liquid = "liquid"
}

export enum YeastFlocculation {
    Low = "low",
    Medium = "medium",
    High = "high",
}

export const friendlyYeastTypeString = (yeastType: YeastType): string => {
    switch(yeastType) {
        case YeastType.Ale:
            return "Ale";
        case YeastType.Lager:
            return "Lager";
    }
}

export const friendlyYeastFormString = (yeastForm: YeastForm): string => {
    switch(yeastForm) {
        case YeastForm.Dry:
            return "Dry";
        case YeastForm.Liquid:
            return "Liquid";
    }
}

export const friendlyYeastFlocculationString = (yeastFlocculation: YeastFlocculation): string => {
    switch(yeastFlocculation) {
        case YeastFlocculation.Low:
            return "Low";
        case YeastFlocculation.Medium:
            return "Medium";
        case YeastFlocculation.High:
            return "High";
    }
}

export interface Yeast {
    id?: number,
    name: string,
    type: YeastType,
    form: YeastForm,
    flocculation: YeastFlocculation,
    attenuation: number,
    min_temperature: number,
    max_temperature: number,
}