import AwesomeDebouncePromise from "awesome-debounce-promise";
import * as React from "react";
import { Col, Form, Row } from "react-bootstrap";

import FormControlComponent from "@app/components/common/FormControlComponent";
import { FieldValidation, FieldValidations } from "@app/models/fields";
import { RecipeYeast } from "@app/models/recipe";
import { Yeast, friendlyYeastFlocculationString, friendlyYeastFormString, friendlyYeastTypeString } from "@app/models/yeast";
import ServicesHelper from "@app/services/serviceshelper";

interface Props {
    recipeId: number,
    recipeYeast: RecipeYeast,
    onChange: (recipeYeast: RecipeYeast) => void,
}

interface State {
    yeastName: string,
    showYeastSuggestions: boolean,
    yeastSuggestions: Yeast[],
    invalidFields: FieldValidations,
    attenuation: string,
}

const getYeasts = (value: string) => {
    return ServicesHelper.instance().ingredients().getYeasts(value).then(resp => {
        return resp.yeasts;
    })
  };

const getYeastsDebounced = AwesomeDebouncePromise(
    getYeasts,
    800,
    { key: (value: string) => "Yeast" },
);

class EditYeastComponent extends React.Component<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            showYeastSuggestions: false,
            yeastSuggestions: [],
            yeastName: props.recipeYeast.yeast.name,
            invalidFields: {},
            attenuation: this.props.recipeYeast.attenuation.toFixed(0)
        };
    }

    public fieldValueIsAnInt(fieldName: string, fieldValue: string) : number | undefined {
        const value = Number(fieldValue);
        if (!Number.isInteger(value)) {
            const invalidFields = this.state.invalidFields ?? {};
            invalidFields[fieldName] = { message: `${fieldValue} is not an Integer`, currentValue: fieldValue }
            this.setState({
                ...this.state,
                invalidFields,
            })
            return undefined;
        }

        return value;
    }

    public reduceFieldValidations(fieldName: string) : FieldValidations {
        const newFieldValidations: FieldValidations = {};
        const fieldValidations = this.state.invalidFields ?? {}

        Object.keys(fieldValidations).forEach(key => {
            if (key !== fieldName) {
                newFieldValidations[key] = fieldValidations[key];
            }
        })

        return newFieldValidations;
    }

    public getFieldValidation(fieldName: string) : FieldValidation | undefined {
        if(this.state.invalidFields) {
            if(this.state.invalidFields[fieldName]) {
                return this.state.invalidFields[fieldName];
            }
        }

        return undefined;
    }

    public onChangeYeastName = async (evt) => {
        const currentYeastName = evt.target.value as string;

        this.setState({
            ...this.state,
            yeastName: currentYeastName,
        });

        if(currentYeastName !== this.state.yeastName) {
            const yeasts = await getYeastsDebounced(currentYeastName);
            this.setState({
                ...this.state,
                showYeastSuggestions: true,
                yeastSuggestions: yeasts,
                yeastName: currentYeastName,
            });
        }
    }

    public onClickYeastSuggestion = (yeast: Yeast, evt) => {
        this.setState({
            ...this.state,
            showYeastSuggestions: false,
            yeastName: yeast.name,
            attenuation: yeast.attenuation.toFixed(),
        });

        const { recipeYeast, recipeId } = this.props;

        if(yeast.id) {
            ServicesHelper.instance().recipes().updateRecipeYeast(recipeId, yeast.id).then(() => {
                return;
            }, err => { throw err; });
        }

        this.props.onChange({
            ...recipeYeast,
            yeast,
            attenuation: yeast.attenuation,
        });
    }

    public onChangeAttenuation = (evt) => {
        const attenuation = this.fieldValueIsAnInt("attenuation", evt.target.value as string);

        this.setState({
            ...this.state,
            attenuation: evt.target.value,
        });

        if (attenuation === undefined) {
            return;
        }

        const { recipeYeast } = this.props;

        this.props.onChange({
            ...recipeYeast,
            attenuation,
        });

        const invalidFields = this.reduceFieldValidations("attenuation");
        this.setState({
            ...this.state,
            invalidFields,
            attenuation: evt.target.value,
        });
    }


    public renderYeastSuggestions() {
        const { yeastSuggestions } = this.state;
        return (<ul className="suggestions-list">
            {yeastSuggestions.map(yeast => {
                return (<li className="suggestion-item" key={yeast.id} onClick={(evt) => this.onClickYeastSuggestion(yeast, evt)}>{yeast.name}</li>);
            })}
        </ul>);
    }

    public onKeyUpYeastName = (evt) => {
        if(evt.keyCode === 13) {
            this.setState({
                ...this.state,
                showYeastSuggestions: false,
            });

            // todo new hop probably ?
        }
    }

    public onBlurAttenuation = (evt) => {
        const { recipeId } = this.props;

        this.setState({
            ...this.state,
            attenuation: evt.target.value,
        });

        const attenuation = this.fieldValueIsAnInt("attenuation", evt.target.value as string)
        if (attenuation === undefined) {
            return;
        }

        ServicesHelper.instance().recipes().updateRecipeYeastAttenuation(recipeId, attenuation).then(() => {return;}, err => {throw err;});

        this.props.onChange({
            ...this.props.recipeYeast,
            attenuation,
        });

        const invalidFields = this.reduceFieldValidations("attenuation");
        this.setState({
            ...this.state,
            invalidFields,
        });
    }

    public closeYeastSuggestions() {
        this.setState({
            ...this.state,
            showYeastSuggestions: false,
            yeastSuggestions: [],
        });
    }

    public onBlurYeastName = (evt) => {
        setTimeout(() => this.closeYeastSuggestions(), 350);
    }

    public render() {
        const { recipeYeast } = this.props;
        const { yeastName, showYeastSuggestions, yeastSuggestions, attenuation } = this.state;

        return (
            <>
                <Row>
                    <Col sm="1">
                        Name:
                    </Col>
                    <Col sm="2">
                        <Form.Control
                            value={yeastName ?? ""}
                            onChange={this.onChangeYeastName}
                            onBlur={this.onBlurYeastName}
                            size="sm"
                        />
                        { showYeastSuggestions && yeastSuggestions.length > 0 ?
                            this.renderYeastSuggestions()
                            : null }
                    </Col>
                    <Col sm="1">
                    Attenuation:
                    </Col>
                    <Col sm="1">
                        <FormControlComponent
                            value={attenuation}
                            fieldValidation={this.getFieldValidation("attenuation")}
                            size="sm"
                            className="recipeFormControl"
                            onBlur={this.onBlurAttenuation}
                            onChange={this.onChangeAttenuation}
                        />
                        %
                    </Col>
                    <Col sm="3">
                        Type: {friendlyYeastTypeString(recipeYeast.yeast.type)}
                    </Col>
                    <Col sm="3">
                        Form: {friendlyYeastFormString(recipeYeast.yeast.form)}
                    </Col>
                </Row>
                <Row>
                    <Col sm="3">
                        Flocculation: {friendlyYeastFlocculationString(recipeYeast.yeast.flocculation)}
                    </Col>
                    <Col sm="3">
                        Temperature: {recipeYeast.yeast.min_temperature.toFixed(0)} - {recipeYeast.yeast.max_temperature.toFixed(0)} °C
                    </Col>
                </Row>
            </>
            );
    }

}

export default EditYeastComponent;