
export enum JobStatus {
    InProgress = "in-progress",
    Failed = "failed",
    Completed = "completed",
}

export interface JobLogRecord {
    message: string,
    created: number,
    level: number,
}

export interface JobData {
    name: string,
    progress: number,
    records: JobLogRecord[],
}

export interface Job {
    id: number,
    event_id: string,
    user_id?: number,
    created_at: number,
    updated_at: number,
    status: JobStatus,
    data: JobData,
}

export const jobStatusPrettyString = (jobStatus: JobStatus): string => {
    if(jobStatus === JobStatus.Completed) {
        return "Completed";
    } else if(jobStatus === JobStatus.Failed) {
        return "Failed";
    } else if(jobStatus === JobStatus.InProgress) {
        return "InProgress";
    }

    return "";
}