import * as React from "react";
import { Alert, Col, Form, Modal, Row } from "react-bootstrap";

import ServicesHelper from "@app/services/serviceshelper";

import Button from "../common/Button";

interface Props {
    recipeId: number,
    onImageUploaded: () => void,
}

interface State {
    isUploading: boolean,
    isUploadComplete: boolean,
    error?: string,
    imageFile?: File,
    isUploadModalVisible: boolean,
}


class UploadRecipeImageComponent extends React.Component<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            isUploading: false,
            isUploadComplete: false,
            isUploadModalVisible: false,
        };
    }

    public onChangeFile = (evt: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            ...this.state,
            imageFile: evt.target.files[0],
        });
    }

    public onClickUpload = (evt: React.MouseEvent<HTMLButtonElement>) => {
        const { onImageUploaded } = this.props;

        this.setState({
            ...this.state,
            isUploading: true,
        });

        ServicesHelper.instance().images().uploadRecipeImage(this.props.recipeId, this.state.imageFile).then(resp => {
            this.setState({
                ...this.state,
                isUploading: false,
                imageFile: undefined,
                isUploadComplete: true,
            }, () => {
                onImageUploaded();
                setTimeout(() => { this.onClose() }, 1500);
            });
        }, err => {
            this.setState({
                ...this.state,
                isUploading: false,
                error: "Error while uploading file",
            });
        });
    }

    public onClickAddImage = (evt: React.MouseEvent<HTMLButtonElement>) => {
        this.setState({
            ...this.state,
            isUploadModalVisible: true,
        });
    }

    public onClose = () => {
        this.setState({
            ...this.state,
            isUploadModalVisible: false,
            isUploadComplete: false,
            isUploading: false,
            error: undefined,
            imageFile: undefined,
        });
    }

    public render() {
        const { imageFile, isUploading, isUploadModalVisible, isUploadComplete, error } = this.state;

        return <>
        <Row>
            <Button onClick={this.onClickAddImage}>+ Image</Button>
        </Row>
        <Modal show={isUploadModalVisible}>
            <Modal.Header>Upload an image</Modal.Header>
            <Modal.Body>
                { isUploadComplete ? <Alert variant="success">Image uploaded !</Alert> : null}
                { error ? <Alert variant="danger">{error}</Alert> : null}
                <Row>
                    <Col>
                        <h4>Upload an image</h4>
                        <Form>
                            <Form.Control id="recipe_image"
                                type="file"
                                onChange={this.onChangeFile}
                            />
                        </Form>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button disabled={!imageFile || isUploading} onClick={this.onClickUpload}>
                    Upload
                </Button>
                <Button variant="yellow" disabled={isUploading} onClick={this.onClose}>
                    Cancel
                </Button>
            </Modal.Footer>
        </Modal>
        </>
    }
}

export default UploadRecipeImageComponent;