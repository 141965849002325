import moment = require("moment");

export const timestampToDate = (timestamp: number): string => {
    return moment.unix(timestamp).format("DD/MM/YYYY")
}

export const timestampToDateTime = (timestamp: number): string => {
    return moment.unix(timestamp).format("DD/MM/YYYY HH:mm");
}

export const getDaysDiffFromTimestamp = (timestamp1: number, timestamp2: number): number => {
    const date1 = moment.unix(timestamp1);
    const date2 = moment.unix(timestamp2);

    return date2.diff(date1, "days");
}