import * as React from 'react';
import { Alert, Col, Row } from "react-bootstrap";
import { DashCircle } from "react-bootstrap-icons";


interface Props {
    caption: string,
    offset?: number,
    span?: number,
    className?: string,
}

class NoResultRow extends React.Component<Props, Record<string,never>> {
    public constructor(props: Props) {
        super(props);
    }

    public render() {
        const { caption, offset, span, className } = this.props;

        return <Row className={className}>
            <Col sm={{offset: offset ?? 1, span: span ?? 10}}>
                <Alert variant="warning">
                    <DashCircle /> {caption}
                </Alert>
            </Col>
        </Row>;
    }
}

export default NoResultRow;