import * as React from "react";
import { Col, Container, ProgressBar, Row } from "react-bootstrap";
import { RouteComponentProps, withRouter } from 'react-router';

import LoadingComponent from "@app/components/common/LoadingComponent";
import { Job, jobStatusPrettyString } from "@app/models/job";
import ServicesHelper from "@app/services/serviceshelper";
import { timestampToDateTime } from "@app/utils/date";

interface JobComponentParams {
    id: number,
}

type ComponentProps = RouteComponentProps<JobComponentParams>;

interface State {
    job?: Job,
}

class JobComponent extends React.Component<ComponentProps,State> {
    public constructor(props: ComponentProps) {
        super(props);

        this.state = {};
    }

    public componentDidMount(): void {
        ServicesHelper.instance().jobs().getJob(this.props.match.params.id as number).then(resp => {
            this.setState({
                ...this.state,
                job: resp.job,
            });
        }, err => {throw err;});
    }

    public renderJob(job: Job) {
        return <>
            <Row>
                <h3>Job Information</h3>
            </Row>
            <Row>
                <Col sm="4">Name</Col>
                <Col sm="8">{job.data.name}</Col>
            </Row>
            <Row>
                <Col sm="4">Event Id</Col>
                <Col sm="8">{job.event_id}</Col>
            </Row>
            <Row>
                <Col sm="4">Progress</Col>
                <Col sm="8">
                    <ProgressBar now={job.data.progress * 100.00} />
                </Col>
            </Row>
            <Row>
                <Col sm="4">Status</Col>
                <Col sm="8">{jobStatusPrettyString(job.status)}</Col>
            </Row>
            <Row>
                <Col sm="4">Created At</Col>
                <Col sm="8">{timestampToDateTime(job.created_at)}</Col>
            </Row>
            <Row>
                <Col sm="4">Updated At</Col>
                <Col sm="8">{timestampToDateTime(job.updated_at)}</Col>
            </Row>
            <Row>
                <Col sm="4">Log(s)</Col>
                <Col sm="8">
                    <Container>
                        {
                            job.data.records.map(record => {
                                return (
                                    <Row>
                                        <Col sm={{offset:1, span:8}}>
                                            {record.message}
                                        </Col>
                                    </Row>
                                );
                            })
                        }
                    </Container>
                </Col>
            </Row>
        </>
    }

    public render() {
        const { job } = this.state;

        return <Container>
            { job ? this.renderJob(job) : <LoadingComponent /> }
        </Container>
    }
}

export default withRouter(JobComponent);