import { BadRequestError } from "@app/services/models";

export enum AlertType {
    Success = "success",
    Warning = "warning",
    Error = "error",
}

export class AlertObject {
    private static currentId = 0;

    private _id: number;
    private _alertType: AlertType;
    private _message: string;

    static fromBadRequest(error: BadRequestError): AlertObject {
        return new AlertObject(AlertType.Error, error.message);
    }

    public constructor(alertType: AlertType, message: string) {
        AlertObject.currentId += 1;

        this._id = AlertObject.currentId;
        this._alertType = alertType;
        this._message = message
    }

    public id(): number {
        return this._id;
    }

    public alertType(): AlertType {
        return this._alertType;
    }

    public message(): string {
        return this._message;
    }

    public variant(): string {
        switch(this._alertType) {
            case AlertType.Error:
                return "danger";
            case AlertType.Warning:
                return "warning";
            case AlertType.Success:
                return "success";
        }
    }
}