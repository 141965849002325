import * as React from "react";
import { Alert, Button, Col, Form, Row } from "react-bootstrap";

import ServicesHelper from "@app/services/serviceshelper";

interface State {
    currentPassword: string,
    newPassword: string,
    newPasswordConfirm: string,
    errors: string[],
    isUpdating: boolean,
    isSuccess: boolean,
}

class UserChangePasswordComponent extends React.Component<Record<string, never>, State> {
    public constructor(props: Record<string, never>) {
        super(props);

        this.state = {
            currentPassword: "",
            newPassword: "",
            newPasswordConfirm: "",
            errors: [],
            isUpdating: false,
            isSuccess: false,
        };
    }

    public onChangeCurrentPassword = (evt) => {
        this.setState({
            ...this.state,
            currentPassword: evt.target.value,
        });
    }

    public onChangeNewPassword = (evt) => {
        this.setState({
            ...this.state,
            newPassword: evt.target.value,
        });
    }

    public onChangeNewPasswordConfirm = (evt) => {
        this.setState({
            ...this.state,
            newPasswordConfirm: evt.target.value,
        });
    }

    public renderErrors() {
        const { errors } = this.state;
        return (<Row>
            <Col sm={{offset: 1, span: 6}}>
                {errors.map(error => {
                    return (
                        <Alert variant="danger">{error}</Alert>
                    );
                })}
            </Col>
        </Row>)
    }

    public onClickUpdate = (evt) => {
        const { currentPassword, newPassword, newPasswordConfirm } = this.state;
        const errors: string[] = [];

        if(currentPassword.length === 0) {
            errors.push("Must specify your current password");
        }

        if(newPassword.length === 0) {
            errors.push("Must specify a new password");
        }

        if(newPassword !== newPasswordConfirm) {
            errors.push("New password and new password confirmation aren't the same");
        }

        if(errors.length === 0) {
            this.setState({
                ...this.state,
                errors,
                isUpdating: true,
            });

            ServicesHelper.instance().user().changePassword(
                currentPassword,
                newPassword
            ).then(resp => {
                if(!resp.success) {
                    this.setState({
                        ...this.state,
                        isUpdating: false,
                        errors: [resp.message],
                    })
                } else {
                    this.setState({
                        ...this.state,
                        isUpdating: false,
                        isSuccess: true,
                    });
                }
            }, err => { throw err; });

        } else {
            this.setState({
                ...this.state,
                errors,
            });
        }

    }

    public render() {
        const { currentPassword, newPassword, newPasswordConfirm, isUpdating, errors, isSuccess } = this.state;

        return (
        <>
            <h4>Change password</h4>
            {
                errors.length > 0 ?
                    this.renderErrors() :
                    null
            }
            <>
            {
                isSuccess ? <Row>
                    <Col>
                        <Alert variant="success">Password changes with success!</Alert>
                    </Col>
                </Row> : null
            }
            </>
            <Row>
                <Col sm={{offset: 1, span: 3}}>
                    Current password:
                </Col>
                <Col sm="4">
                    <Form.Control
                        value={currentPassword}
                        onChange={this.onChangeCurrentPassword}
                    />
                </Col>
            </Row>
            <Row>
                <Col sm={{offset: 1, span: 3}}>New password:</Col>
                <Col sm="4">
                    <Form.Control
                        value={newPassword}
                        onChange={this.onChangeNewPassword}
                    />
                </Col>
            </Row>
            <Row>
                <Col sm={{offset: 1, span: 3}}>New password (Confirmation):</Col>
                <Col sm="4">
                    <Form.Control
                        value={newPasswordConfirm}
                        onChange={this.onChangeNewPasswordConfirm}
                    />
                </Col>
            </Row>
            <Row>
                <Col sm={{offset: 2, span: 3}}>
                    <Button
                        disabled={isUpdating}
                        onClick={this.onClickUpdate}
                    >
                        Update password
                    </Button>
                </Col>
            </Row>
        </>
        );
    }
}

export default UserChangePasswordComponent;