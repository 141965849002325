import * as React from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { TrashFill } from "react-bootstrap-icons";

import FormControlComponent from "@app/components/common/FormControlComponent";
import { FieldValidations, fieldValueIsANumber, fieldValueIsAnInteger, getFieldValidation, reduceFieldValidations } from "@app/models/fields";
import { MashStep, MashStepType, getMashFriendlyString } from "@app/models/recipe";

interface Props {
    mashStep: MashStep,
    onChangeVolume: (mashStepId: number, volume: number) => void,
    onChangeTemperature: (mashStepId: number, temperature: number) => void,
    onChangeType: (mashStepId: number, type: MashStepType) => void,
    onChangeTime: (mashStepId: number, time: number) => void,
    onDelete: (mashStepId: number) => void,
}

interface State {
    fieldValidations: FieldValidations,
}

class EditMashStepComponent extends React.Component<Props, State> {
    public constructor(props: Props) {
        super(props);
        this.state = {
            fieldValidations: {},
        };
    }

    public onChangeMashType = (evt) => {
        const { mashStep, onChangeType } = this.props;
        onChangeType(mashStep.id, evt.target.value as MashStepType);
    }

    public onChangeVolume = (evt) => {
        const { mashStep, onChangeVolume } = this.props;
        const { fieldValidations } = this.state;

        const fieldId = `mashStepVolume_${mashStep.id}`;
        const fieldValidationVolume = fieldValueIsANumber(fieldValidations, fieldId, evt.target.value as string);

        if(fieldValidationVolume.value === undefined) {
            return;
        }

        this.setState({
            ...this.state,
            fieldValidations: fieldValidationVolume.fieldValidations,
        });

        onChangeVolume(mashStep.id, fieldValidationVolume.value);
    }

    public onChangeTemperature = (evt) => {
        const { mashStep, onChangeTemperature } = this.props;
        const { fieldValidations } = this.state;

        const fieldId = `mashStepTemperature_${mashStep.id}`;
        const fieldValidationTemperature = fieldValueIsAnInteger(fieldValidations, fieldId, evt.target.value as string);

        if(fieldValidationTemperature.value === undefined) {
            return;
        }

        this.setState({
            ...this.state,
            fieldValidations: fieldValidationTemperature.fieldValidations,
        });

        onChangeTemperature(mashStep.id, fieldValidationTemperature.value);
    }

    public onChangeTime = (evt) => {
        const { mashStep, onChangeTime } = this.props;
        const { fieldValidations } = this.state;

        const fieldId = `mashStepTime_${mashStep.id}`;
        const fieldValidationTime = fieldValueIsAnInteger(fieldValidations, fieldId, evt.target.value as string);

        if(fieldValidationTime.value === undefined) {
            return;
        }

        this.setState({
            ...this.state,
            fieldValidations: fieldValidationTime.fieldValidations,
        });

        onChangeTime(mashStep.id, fieldValidationTime.value);
    }

    public onDelete = (evt) => {
        const { mashStep, onDelete } = this.props;
        onDelete(mashStep.id);
    }

    public render() {
        const { mashStep } = this.props;
        const { fieldValidations } = this.state;

        const volumeFieldValidation = getFieldValidation(fieldValidations, `mashStepVolume_${mashStep.id}`);
        const temperatureValidation = getFieldValidation(fieldValidations, `mashStepTemperature_${mashStep.id}`);
        const timeValidation = getFieldValidation(fieldValidations, `mashStepTime_${mashStep.id}`);

        return (
            <Row>
                <Col md="1">
                    <Form.Select defaultValue={mashStep.type} onChange={this.onChangeMashType}>
                        <option value={MashStepType.Infusion}>{getMashFriendlyString(MashStepType.Infusion)}</option>
                        <option value={MashStepType.Rest}>{getMashFriendlyString(MashStepType.Rest)}</option>
                        <option value={MashStepType.Sparge}>{getMashFriendlyString(MashStepType.Sparge)}</option>
                    </Form.Select>
                </Col>
                <Col sm="1">
                    <FormControlComponent
                        value={mashStep.volume}
                        onChange={this.onChangeVolume}
                        fieldValidation={volumeFieldValidation}
                    />
                </Col>
                <Col sm="1">
                    liter(s)
                </Col>
                <Col md="1">
                    <FormControlComponent
                        value={mashStep.temperature}
                        fieldValidation={temperatureValidation}
                        onChange={this.onChangeTemperature}
                    />
                </Col>
                <Col sm="1">
                    °C
                </Col>
                <Col md="1">
                    <FormControlComponent
                        value={mashStep.time ?? ""}
                        onChange={this.onChangeTime}
                        fieldValidation={timeValidation}
                    />
                </Col>
                <Col md="1">
                    minute(s)
                </Col>
                <Col md="1">{mashStep.description}</Col>
                <Col sm="1">
                    <Button variant="danger" size="sm" onClick={this.onDelete} >
                        <TrashFill color="white" />
                    </Button>
                </Col>
            </Row>
        );
    }
}

export default EditMashStepComponent;