import { Producer } from "./producer";

export enum FermentableType {
    BaseMalt = "base-malt",
    CrystalMalt = "crystal-malt",
    RoastedMalt = "roasted-malt",
    DryMaltExtract = "dry-malt-extract",
    LiquidMaltExtract = "liquid-malt-extract",
    Adjunct = "adjunct",
    Sugar = "sugar",
    Raw = "raw",
    Fruit = "fruit"
}

export const fermentableTypeFriendlyString = (fermentableType: FermentableType): string => {
    switch(fermentableType) {
        case FermentableType.BaseMalt:
            return "Base Malt"
        case FermentableType.CrystalMalt:
            return "Crystal Malt"
        case FermentableType.RoastedMalt:
            return "Roasted Malt"
        case FermentableType.DryMaltExtract:
            return "Dry Malt Extract"
        case FermentableType.LiquidMaltExtract:
            return "Liquid Malt Extract"
        case FermentableType.Adjunct:
            return "Adjunct"
        case FermentableType.Sugar:
            return "Sugar"
        case FermentableType.Raw:
            return "Raw"
        case FermentableType.Fruit:
            return "Fruit"
    }
}

export interface Fermentable {
    id?: number,
    name: string,
    lovibond: number,
    fermentable_type: FermentableType,
    point_per_pound_gallon: number,
    diastatic_power: number,
    producer?: Producer,
    producer_id?: number,
}