import * as React from "react";
import { Form, Modal, Row } from "react-bootstrap";

import Button from "@app/components/common/Button";
import LoadingComponent from "@app/components/common/LoadingComponent";
import { ImportedRecipeFormat } from "@app/models/recipe";
import ServicesHelper from "@app/services/serviceshelper";



interface Props {
    isVisible: boolean,
    onHide: (refresh: boolean) => void
}

interface State {
    recipeFile?: File,
    fileType: ImportedRecipeFormat,
    isUploading: boolean,
    isUploaded: boolean,
    isSuccess: boolean,
}

class ImportRecipeComponent extends React.Component<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            fileType: ImportedRecipeFormat.BeerXML,
            isUploading: false,
            isUploaded: false,
            isSuccess: false,
        };
    }


    public importAnotherRecipe = (evt: React.MouseEvent<HTMLButtonElement>) => {
        this.setState({
            ...this.state,
            isUploading: false,
            isUploaded: false,
            isSuccess: false,
        });
    }

    public importRecipe = (evt: React.MouseEvent<HTMLButtonElement>) => {
        const { recipeFile, fileType } = this.state;

        this.setState({
            ...this.state,
            isUploading: true,
        });

        ServicesHelper.instance().recipes().importRecipe(recipeFile, fileType).then(resp => {
            this.setState({
                ...this.state,
                isUploaded: true,
                isSuccess: true,
            })
        }).catch(() => {
            this.setState({
                ...this.state,
                isUploaded: true,
                isSuccess: false,
            });
        });
    }

    public onChange = (evt) => {
        this.setState({
            ...this.state,
            recipeFile: evt.target.files[0],
        })
    }

    public renderIsUploading() {
        const { isUploaded, isSuccess } = this.state;
        if (isUploaded) {
            return (<div>
                {isSuccess ? `Recipe uploaded with success!` : `An error as occured while uploading the recipe`}<br />
                <Button onClick={this.importAnotherRecipe}>Import another recipe</Button>
            </div>)
        } else {
            return (<div>
                <LoadingComponent />
                Recipe is uploading...
            </div>)
        }
    }

    public onClickCancel = (evt: React.MouseEvent<HTMLButtonElement>) => {
        this.props.onHide(false);
    }

    public onChangeRecipeFormat = (evt: React.ChangeEvent<HTMLSelectElement>) => {
        this.setState({
            ...this.state,
            fileType: evt.target.value as ImportedRecipeFormat,
        });
    }

    public render() {
        const { isUploading, recipeFile, fileType } = this.state;
        const { isVisible } = this.props;

        return (
        <Modal show={isVisible} >
            <Modal.Header>
                <Modal.Title>Import a BeerXML Recipe</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                { !isUploading ? (
                <Form>
                    <Row>
                        <Form.Label>File: </Form.Label>
                        <Form.Control id="recipe_xml"
                        type="file"
                        onChange={this.onChange}
                        />
                    </Row>
                    <Row>
                    <Form.Label>Format: </Form.Label>
                        <Form.Select
                            id="recipe_type"
                            onChange={this.onChangeRecipeFormat}
                            value={fileType}
                        >
                            <option value={ImportedRecipeFormat.BeerXML.toString()}>Beer XML</option>
                            <option value={ImportedRecipeFormat.BraumeisterJSON.toString()}>Braumeister JSON</option>
                            <option value={ImportedRecipeFormat.BraumeisterArchive.toString()}>Braumeister Archive</option>
                        </Form.Select>
                    </Row>
                </Form>) : this.renderIsUploading()}
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="grey"
                    onClick={this.onClickCancel}
                >
                    Cancel
                </Button>
                <Button
                    variant="blue"
                    disabled={!recipeFile || isUploading}
                    onClick={this.importRecipe}
                >
                    Import
                </Button>
            </Modal.Footer>
        </Modal>);
    }
}

export default ImportRecipeComponent;