import AwesomeDebouncePromise from "awesome-debounce-promise";
import * as React from "react";
import { Col, Container, Form, Modal, Row } from "react-bootstrap";
import { PlusCircle } from "react-bootstrap-icons";

import Button from "@app/components/common/Button";
import LoadingComponent from "@app/components/common/LoadingComponent";
import NoResultRow from "@app/components/common/NoResultRow";
import PaginationComponent from "@app/components/common/PaginationComponent";
import SortableColumnComponent from "@app/components/common/SortableColumnComponent";
import AddHopComponent from "@app/components/ingredients/AddHopComponent";
import { HopItemComponent } from "@app/components/ingredients/HopItemComponent";
import { Hop } from "@app/models/hop";
import ServicesHelper from "@app/services/serviceshelper";

const getHops = (value: string, offset: number, limit: number, sortBy: string) => {
    return ServicesHelper.instance().ingredients().getHops(value, offset, limit, sortBy).then(resp => {
        return resp;
    }, err => {throw err;})
  };

const getHopsDebounced = AwesomeDebouncePromise(
    getHops,
    300,
    { key: (value: string, offset: number, limit: number, sortBy: string) => "HopNameFilter" },
);


interface State {
    hops: Hop[],
    totalCount: number,
    isLoading: boolean,
    hopToDelete?: Hop,
    nameFilter?: string,
    showDeleteModal: boolean,
    showAddModal: boolean,
    sortBy: string,
}

interface Props {
    id?: number,
    action?: string,
    isAdmin: boolean,
}

class HopsComponent extends React.Component<Props, State> {

    public constructor(props: Props) {
        super(props)

        this.state = {
            hops: [],
            totalCount: 0,
            isLoading: false,
            showDeleteModal: false,
            showAddModal: false,
            sortBy: "name",
        }
    }

    public componentDidMount() {
        this.loadHops();
    }

    public componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        if(
            (prevProps.id !== this.props.id && (this.props.action === "browse" || !this.props.action))
            || prevState.sortBy !== this.state.sortBy
        ) {
            this.loadHops();
            window.scrollTo(0, 0);
        }
    }

    public loadHops() {
        this.setState({
            ...this.state,
            isLoading: true
        });

        const currentPage = (this.props.id ?? 1) - 1;
        const { sortBy, nameFilter } = this.state;

        ServicesHelper.instance().ingredients().getHops(nameFilter, currentPage * 20, 20, sortBy).then((resp) => {
            this.setState({
                ...this.state,
                hops: resp.hops,
                totalCount: resp.count,
                isLoading: false,
            })
        }, err => {throw err;});
    }

    public onShowAddModal = (evt: React.MouseEvent<HTMLButtonElement>) => {
        this.setState({
            ...this.state,
            showAddModal: true,
        });
    }

    public renderAddHopButton() {
        return (
        <Row>
            <Col>
                <Button onClick={this.onShowAddModal}>
                    <PlusCircle /> Hop
                </Button>
            </Col>
        </Row>);
    }

    public onDeleteHop = (hop: Hop) => {
        this.setState({
            ...this.state,
            showDeleteModal: true,
            hopToDelete: hop,
        });
    }

    public cancelDeleteHop = (evt: React.MouseEvent<HTMLButtonElement>) => {
        this.closeDeleteModal();
    }

    public deleteHopButton = (evt: React.MouseEvent<HTMLButtonElement>) => {
        const hopToDelete = this.state.hopToDelete;
        this.closeDeleteModal();

        ServicesHelper.instance().ingredients().deleteHop(hopToDelete).then(resp => {
            if(resp.status === 200) {
                this.setState({
                    ...this.state,
                    hops: this.state.hops.filter(hop => hop.id !== hopToDelete.id),
                });
            }
        }, err => {throw err;});
    }

    public closeDeleteModal() {
        this.setState({
            ...this.state,
            showDeleteModal: false,
            hopToDelete: undefined,
        });
    }

    public onHideAddModal = () => {
        this.setState({
            ...this.state,
            showAddModal: false,
        });
    }

    public onAddHop = () => {
        this.loadHops();
    }

    public onClickSortableColumn = (name: string) => {
        const { sortBy } = this.state;
        let newSortBy: string;

        if(sortBy.endsWith(name)) {
            newSortBy = sortBy.startsWith("-") ? `${name}`: `-${name}`;
        } else {
            newSortBy = name;
        }

        this.setState({
            ...this.state,
            sortBy: newSortBy,
        });
    }

    public onChangeNameFilter = async (evt) => {
        const currentPage = 0;
        const { sortBy } = this.state;

        this.setState({
            ...this.state,
            nameFilter: evt.target.value,
        });

        if(evt.target.value.length >= 3) {
            this.setState({
                ...this.state,
                isLoading: true,
                nameFilter: evt.target.value,
            });

            const hopsResponse = await getHopsDebounced(evt.target.value as string, currentPage * 20, 20, sortBy);

            this.setState({
                ...this.state,
                isLoading: false,
                hops: hopsResponse.hops,
                totalCount: hopsResponse.count,
            });

        } else if(evt.target.value.length === 0) {
            this.setState({
                ...this.state,
                nameFilter: evt.target.value,
            }, () => { this.loadHops() });
        }
    }

    public renderHopsTable() {
        const { hops, totalCount, showAddModal, isLoading, nameFilter, sortBy } = this.state;
        const { isAdmin } = this.props;
        const currentPage = (this.props.id ?? 1) - 1;

        return (
        <>
            <AddHopComponent
                show={showAddModal}
                onHide={this.onHideAddModal}
                onAdd={this.onAddHop}
            />
            <Container fluid className="tableContainer">
                <Row className="filterRow">
                    <Col sm={{offset:1, span:1}}>
                        Name:
                    </Col>
                    <Col sm="3">
                        <Form.Control value={nameFilter} onChange={this.onChangeNameFilter} size="sm" />
                    </Col>
                </Row>
                <Row className="tableHeader">
                    <SortableColumnComponent
                        name="name"
                        label="Name"
                        onClick={this.onClickSortableColumn}
                        span={3}
                        currentSortBy={sortBy}
                    />
                    <SortableColumnComponent
                        name="alpha_acid"
                        label="Alpha Acid"
                        onClick={this.onClickSortableColumn}
                        span={3}
                        currentSortBy={sortBy}
                    />
                    <Col sm="3">
                        Action
                    </Col>
                </Row>
                {
                    isLoading ?
                    <Row className="ingredientsRowItem"><LoadingComponent /></Row> :
                    (hops.length === 0 ?
                        <NoResultRow
                            className="ingredientsRowItem"
                            caption="No hop matching..."
                        /> :
                        hops.map((hop) => (
                            <HopItemComponent
                                key={hop.id}
                                hop={hop}
                                onDelete={this.onDeleteHop}
                                canEdit={isAdmin}
                            />)
                        )
                    )
                }
                <Row className="paginationRow">
                    <PaginationComponent
                        numberOfPages={Math.ceil(totalCount / 20)}
                        currentPage={currentPage+1}
                        pageLinkPrefix={`/ingredients/hops/browse/`}
                    />
                </Row>
            </Container>
        </>
        );

    }

    public renderContent() {
        const action: string = this.props.action ?? "browse";

        switch(action) {
            case "browse":
                return this.renderHopsTable();
        }

        return (<></>);

    }


    public render() {
        const { hopToDelete, showDeleteModal } = this.state;

        return (
            <>
                <Row>
                    <Col>
                        <h3>Hops</h3>
                    </Col>
                </Row>
                {this.renderAddHopButton()}
                <Container fluid>
                    {this.renderContent()}
                </Container>
                <Modal show={showDeleteModal}>
                    <Modal.Header>
                        <Modal.Title>Delete hop</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Are you sure you want to delete <strong>{hopToDelete?.name ?? ""}</strong> hop ?</Modal.Body>
                    <Modal.Footer>
                    <Button variant="grey" onClick={this.cancelDeleteHop}>
                        No
                    </Button>
                    <Button variant="blue" onClick={this.deleteHopButton}>
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>
            </>
        );
    }
}

export default HopsComponent;