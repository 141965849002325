import { ApiInfo } from "@app/models/api";
import ServicesHelper from "@app/services/serviceshelper";


export class ApiService {

    private uri: string;
    private servicesHelper: ServicesHelper

    public constructor(uri: string, servicesHelper: ServicesHelper) {
        this.uri = uri;
        this.servicesHelper = servicesHelper;
    }


    public getApiInfo(): Promise<ApiInfo> {
        return this.servicesHelper.do_get<ApiInfo>(`${this.uri}info`);
    }
 }
