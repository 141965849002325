import { raw } from "express";

export enum NotificationDataType {
    RecipeImported = "recipe-imported",
    RecipeImportFailed = "recipe-import-failed",
    RecipesExportReady = "recipes-export-ready",
    RecipeRatingPosted = "recipe-rating-posted",
    JobStarted = "job-started",
    JobCompleted = "job-completed",
}

export interface RawNotification {
    type: NotificationDataType,
    [key: string]: any,
}

export interface PingMessage {
    ping: boolean,
}

export class NotificationData {
    type: NotificationDataType;

    public constructor(notification: RawNotification) {
        this.type = notification.type;
    }
}

export class RecipeImportedNotificationData extends NotificationData {
    recipeId: number;
    recipeName: string;

    public constructor(notification: RawNotification) {
        super(notification);
        this.recipeId = notification.recipe_id as number;
        this.recipeName = notification.recipe_name as string;
    }
}

export class RecipeImportFailedData extends NotificationData {
    importedRecipeId: number;

    public constructor(notification: RawNotification) {
        super(notification);
        this.importedRecipeId = notification.imported_recipe_id as number;
    }
}


export class RecipeRatingPostedData extends NotificationData {
    recipeId: number;
    recipeName: string;
    ratingId: number;
    authorId: number;
    authorName: string;

    public constructor(notification: RawNotification) {
        super(notification);
        this.recipeId = notification.recipe_id as number;
        this.recipeName = notification.recipe_name as string;
        this.ratingId = notification.rating_id as number;
        this.authorId = notification.author_id as number;
        this.authorName = notification.author_name as string;
    }
}

export class JobStartedData extends NotificationData {
    job_id: number;
    event_id: string;
}

export class JobCompletedData extends NotificationData {
    job_id: number;
    event_id: string;
}

export class RecipesExportReadyData extends NotificationData {
    fileId: number;
    fileName: string;

    public constructor(notification: RawNotification) {
        super(notification);
        this.fileId = notification.file_id as number;
        this.fileName = notification.file_name as string;
    }
}

/* eslint-disable @typescript-eslint/no-unsafe-assignment */
export const getConcreteEvent = (rawNotification: RawNotification): NotificationData => {
    if (rawNotification.type === NotificationDataType.RecipeImported) {
        return new RecipeImportedNotificationData(rawNotification);
    } else if (rawNotification.type === NotificationDataType.RecipeImportFailed) {
        return rawNotification as RecipeImportFailedData;
    } else if (rawNotification.type === NotificationDataType.JobStarted) {
        return rawNotification as JobStartedData;
    } else if (rawNotification.type === NotificationDataType.JobCompleted) {
        return rawNotification as JobCompletedData;
    } else if (rawNotification.type === NotificationDataType.RecipesExportReady) {
        return new RecipesExportReadyData(rawNotification);
    } else if (rawNotification.type === NotificationDataType.RecipeRatingPosted) {
        return new RecipeRatingPostedData(rawNotification);
    }
}

export interface INotificationEvent {
    id: string;
    data: NotificationData;

}

export class NotificationEvent {
    private id: string;
    private data: NotificationData;

    public constructor(event: INotificationEvent) {
        this.id = event.id
        this.data = event.data
    }

    public getConcreteData(): NotificationData {
        return getConcreteEvent(this.data);
    }

    public getType(): NotificationDataType {
        return this.data.type;
    }

    public getCaption(): string {
        switch(this.getType()) {
            case NotificationDataType.RecipeImported:
                return "Recipe Imported";
            case NotificationDataType.RecipeImportFailed:
                return "Recipe Import Failed";
            case NotificationDataType.RecipesExportReady:
                return "Recipe(s) Export Ready";
            case NotificationDataType.RecipeRatingPosted:
                return "Rating posted on your recipe"
            default:
                return "Notification";
        }
    }

    public getId(): string {
        return this.id;
    }
}