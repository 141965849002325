export enum UserRank {
    Normal = "normal",
    Admin = "admin"
}

export interface User {
    display_name: string,
    created_at: number,
    email: string,
    user_id: string,
    user_rank: UserRank,
}

export interface UserSetting {
    api_key?: string,
}

export interface ProfileAvatar {
    id: number,
    source_image_uri: string,
    image_uri?: string,
    created_at: number,
}

export interface UserProfile {
    id: number,
    user_id: number,
    avatar?: ProfileAvatar,
    user?: User,
    location: string,
    my_brewery: string,
    website: string,
}