import * as React from "react";
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";

import { MeasurementType, getMeasurementTypeUnit } from "@app/models/measurement";
import { RecipeOtherIngredient } from "@app/models/recipe";
import { TimeUsed, friendlyTimeUsedString, getAllTimeUsed } from "@app/models/time_used";

interface Props {
    show: boolean,
    onClose: () => void,
    onChoose: (otherIngredient: RecipeOtherIngredient) => void,
}

interface State {
    name: string,
    timeUsed: TimeUsed,
    amountString: string,
    measurementType: MeasurementType,
}

class AddOtherIngredientComponent extends React.Component<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            name: "",
            timeUsed: TimeUsed.Mash,
            amountString: "0",
            measurementType: MeasurementType.Mass,
        };
    }

    public onClickAdd = (evt) => {
        const { name, timeUsed, measurementType, amountString } = this.state;

        let amount = Number.parseFloat(amountString);
        if (Number.isNaN(amount)) {
            amount = 0;
        }

        this.props.onChoose({
            name,
            time_used: timeUsed,
            measurement_type: measurementType,
            amount,
        });
    }

    public onChangeName = (evt) => {
        this.setState({
            ...this.state,
            name: evt.target.value,
        });
    }

    public onChangeAmount = (evt) => {
        this.setState({
            ...this.state,
            amountString: evt.target.value,
        });
    }

    public onChangeMeasurementType = (evt) => {
        this.setState({
            ...this.state,
            measurementType: evt.target.value as MeasurementType,
        });
    }

    public onChangeTimeUsed = (evt) => {
        this.setState({
            ...this.state,
            timeUsed: evt.target.value as TimeUsed,
        });
    }

    public render() {
        const { show, onClose } = this.props;
        const { name, timeUsed, amountString, measurementType } = this.state;

        return (
        <Modal show={show} onHide={onClose}>
            <Modal.Header>
                <Modal.Title>Add other ingredient</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <Row>
                        <Col>Name:</Col>
                        <Col>
                            <Form.Control value={name} onChange={this.onChangeName} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>Amount:</Col>
                        <Col>
                            <Form.Control value={amountString} onChange={this.onChangeAmount} />
                        </Col>
                        <Col>
                            <Form.Select
                            onChange={this.onChangeMeasurementType}
                            defaultValue={measurementType}
                            value={measurementType}
                            >
                                    <option value={MeasurementType.Mass}>{getMeasurementTypeUnit(MeasurementType.Mass)}</option>
                                    <option value={MeasurementType.Volume}>{getMeasurementTypeUnit(MeasurementType.Volume)}</option>
                            </Form.Select>
                        </Col>
                    </Row>
                    <Row>
                        <Col>Time Used:</Col>
                        <Col>
                            <Form.Select defaultValue={TimeUsed.Mash} value={timeUsed} onChange={this.onChangeTimeUsed}>
                            {
                                getAllTimeUsed().map(_timeUsed => <option key={_timeUsed} value={_timeUsed}>{friendlyTimeUsedString(_timeUsed)}</option>)
                            }
                            </Form.Select>
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={this.onClickAdd} disabled={name.length === 0}>Add</Button>
                <Button variant="secondary" onClick={this.props.onClose}>Cancel</Button>
            </Modal.Footer>
        </Modal>);
    }
}

export default AddOtherIngredientComponent;