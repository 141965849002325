import { BeerStyle } from "@app/models/beer_style";
import { BrewingSetup } from "@app/models/brewing_setup";
import { MeasurementType } from "@app/models/measurement";
import { HopAddition, HopForm, HopUse, ImportedRecipeFormat, MashStep, PersistedRecipe, PersistedRecipeWithRecipe, RecipeOtherIngredient, RecipeRating } from "@app/models/recipe";
import { TimeUsed } from "@app/models/time_used";
import { RecipeWaterAdjustor } from "@app/models/water_adjustor";
import ServicesHelper from "@app/services/serviceshelper";

export interface EventCreatedResponse {
    event_id: string,
}

export interface RecipesResponse {
    persisted_recipes: PersistedRecipe[],
    total_count: number,
}

export interface ImportRecipeResponse {
    id: number
}

export enum RecipeTextField {
    Name = "name",
    Notes = "notes",
    Style = "style"
}

export enum RecipeFloatField {
    BatchSize = "batch_size",
    BoilSize = "boil_size",
    Efficiency = "efficiency"
}

export enum RecipeMashStepFloatField {
    Volume = "volume",
    Temperature = "temperature"
}

export enum RecipeMashStepIntField {
    Time = "time",
}

export enum RecipeIntField {
    BoilTime = "boil_time"
}

export interface RecipeTextUpdateBody {
    value: string,
}

export interface RecipeFloatUpdateBody {
    value: number,
}

export interface RecipeIntUpdateBody {
    value: number,
}


export interface RecipeBooleanUpdateBody {
    value: boolean,
}

export interface RecipeHopAdditionHopBody {
    hop_id?: number,
    hop_name?: string,
}

// Use empty string here to null the value
export interface RecipeNullableIntUpdateBody {
    value: number | string,
}

export interface RecipeUpdateResponse {
    success: boolean,
    message?: string,
}

export interface CreateRecipeHopAdditionResponse {
    hop_addition: HopAddition,
}

export interface BeerStylesResponse {
    styles: BeerStyle[],
}

export interface AddFermentableToRecipeBody {
    id: number,
    amount: number,
}

export interface CreateRecipeMashStepResponse {
    mash_step: MashStep,
}

export interface UpdateRecipeYeastBody {
    yeast_id: number,
}

export interface CreateRecipeBody {
    brewing_setup_id: number,
    recipe_name: string,
    is_public: boolean,
}

export interface CreateRecipeResponse {
    recipe_id: number,
}

export interface AddWaterAdjustorBody {
    name: string,
}

export interface AddWaterAdjustorResponse {
    water_adjustor: RecipeWaterAdjustor,
}

export interface AddOtherIngredientBody {
    name: string,
    time_used: TimeUsed,
}

export interface AddOtherIngredientResponse {
    other_ingredient: RecipeOtherIngredient,
}

export interface UpdateSourceWaterProfileBody {
    source_water_profile_id?: number,
}

export interface UpdateRecipeBrewingSetupBody {
    brewing_setup_id: number,
}

export interface UpdateRecipeBrewingSetupResponse {
    success: boolean,
    brewing_setup?: BrewingSetup,
}

export interface DeleteRecipeResponse {
    success: boolean,
    error?: string,
}

export interface AddHopAdditionBody {
    hop_id?: number,
    use?: HopUse,
    form?: HopForm,
    alpha_acid?: number,
}

export interface BatchImportBody {
    api_key: string,
}

export interface CopyRecipeBody {
    recipe_name: string,
    brewing_setup_id: number,
}

export interface IGetRecipesArguments {
    nameFilter?: string,
    offset?: number,
    limit?: number,
    sort?: string,
    userId?: number,
}

export interface RecipeRatingsResponse {
    ratings: RecipeRating[],
    average?: number,
    total_count: number,
}

export interface RecipeRatingResponse {
    rating: RecipeRating,
}

export interface RecipeRatingBody {
    comment: string,
    rating: number,
}

class GetRecipesArguments {
    public nameFilter?: string = undefined;
    public offset?: number = undefined;
    public limit?: number = undefined;
    public sort = "created_at";
    public userId?: number = undefined;

    public constructor(args: IGetRecipesArguments) {
        this.nameFilter = args.nameFilter;
        this.offset = args.offset;
        this.limit = args.limit;
        this.userId = args.userId;

        if(args.sort) {
            this.sort = args.sort
        }
    }

    public getRouteArgs(): string {
        let routeArgs = `sort=${this.sort}`;

        if(this.nameFilter) {
            routeArgs = `${routeArgs}&nameFilter=${this.nameFilter}`;
        }

        if(this.offset) {
            routeArgs = `${routeArgs}&offset=${this.offset}`;
        }

        if(this.limit) {
            routeArgs = `${routeArgs}&limit=${this.limit}`;
        }

        if(this.userId) {
            routeArgs = `${routeArgs}&user_id=${this.userId}`;
        }

        return routeArgs;
    }
}

export class RecipesService {

    private uri: string;
    private servicesHelper: ServicesHelper

    public constructor(uri: string, servicesHelper: ServicesHelper) {
        this.uri = uri;
        this.servicesHelper = servicesHelper;
    }

    public importRecipe(recipeFile: File, fileType: ImportedRecipeFormat) {
        const formData = new FormData()
        formData.append("recipeFile", recipeFile, recipeFile.name);
        formData.append("fileType", fileType.toString());
        return this.servicesHelper.do_post_with_formdata<ImportRecipeResponse>(`${this.uri}import`, formData);
    }

    public copyRecipe(sourceRecipeId: number, recipeName: string, brewingSetupId: number) {
        return this.servicesHelper.do_post<CreateRecipeResponse, CopyRecipeBody>(`${this.uri}${sourceRecipeId}/copy`, {
            recipe_name: recipeName,
            brewing_setup_id: brewingSetupId,
        });
    }

    public getUserRecipes(nameFilter?: string, offset = 0, limit = 10, sort = "created_at") {
        let route = `${this.uri}mine?sort=${sort}&offset=${offset}&limit=${limit}`

        if(nameFilter && nameFilter.length > 0) {
            route = `${route}&name=${nameFilter}`
        }

        return this.servicesHelper.do_get<RecipesResponse>(route);
    }


    public getRecipes(args: IGetRecipesArguments) {
        const uri = `${this.uri}?${new GetRecipesArguments(args).getRouteArgs()}`;
        return this.servicesHelper.do_get<RecipesResponse>(uri);
    }

    public getRecipeRatings(recipe_id: number, offset = 0, limit = 20, withAverage = "true") {
        const uri = `${this.uri}${recipe_id}/ratings?offset=${offset}&limit=${limit}&with_average=${withAverage ? "true" : "false"}`;
        return this.servicesHelper.do_get<RecipeRatingsResponse>(uri);
    }

    public getRecipeRating(recipe_id: number, ratingId: number) {
        const uri = `${this.uri}${recipe_id}/ratings/${ratingId}`;
        return this.servicesHelper.do_get<RecipeRatingResponse>(uri);
    }

    public legacyGetRecipes(nameFilter?: string, offset = 0, limit = 10, sort = "created_at", userId?: string) {
        let route = `${this.uri}?sort=${sort}&offset=${offset}&limit=${limit}`

        if(nameFilter && nameFilter.length > 0) {
            route = `${route}&name=${nameFilter}`
        }

        if(userId) {
            route = `${route}&user_id=${userId}`;
        }

        return this.servicesHelper.do_get<RecipesResponse>(route);
    }

    public getRecipe(recipeId: number) {
        return this.servicesHelper.do_get<PersistedRecipeWithRecipe>(`${this.uri}${recipeId}`);
    }

    public updateRecipeTextField(recipeId: number, field: RecipeTextField, fieldValue: string): Promise<RecipeUpdateResponse> {
        const route = field as string;
        return this.servicesHelper.do_put<RecipeUpdateResponse, RecipeTextUpdateBody>(`${this.uri}${recipeId}/${route}`, {value: fieldValue});
    }

    public updateRecipeFloatField(recipeId: number, field: RecipeFloatField, fieldValue: number): Promise<RecipeUpdateResponse> {
        const route = field as string;
        return this.servicesHelper.do_put<RecipeUpdateResponse, RecipeFloatUpdateBody>(`${this.uri}${recipeId}/${route}`, {value: fieldValue});
    }

    public updateRecipeIntField(recipeId: number, field: RecipeIntField, fieldValue: number): Promise<RecipeUpdateResponse> {
        const route = field as string;
        return this.servicesHelper.do_put<RecipeUpdateResponse, RecipeIntUpdateBody>(`${this.uri}${recipeId}/${route}`, {value: fieldValue});
    }

    public updateFermentableAmount(recipeId: number, fermentableName: string, amount: number): Promise<RecipeUpdateResponse> {
        return this.servicesHelper.do_put<RecipeUpdateResponse, RecipeIntUpdateBody>(`${this.uri}${recipeId}/fermentables/${fermentableName}`, {value: amount});
    }

    public addFermentableToRecipe(recipeId: number, fermentableId: number, amount: number) : Promise<RecipeUpdateResponse> {
        return this.servicesHelper.do_post_raw<AddFermentableToRecipeBody>(`${this.uri}${recipeId}/fermentables`, {id: fermentableId, amount})
        .then(resp => {
            const response: RecipeUpdateResponse = {
                success: resp.status === 201,
            }

            return response;
        });
    }

    public deleteFermentableFromRecipe(recipeId: number, fermentableName: string): Promise<Response> {
        return this.servicesHelper.do_delete(`${this.uri}${recipeId}/fermentables/${fermentableName}`);
    }

    public getBeerStyles() : Promise<BeerStylesResponse> {
        return this.servicesHelper.do_get<BeerStylesResponse>(`${this.uri}styles`);
    }

    public updateRecipeMashStepType(recipeId: number, mashStepId: number, type: string) : Promise<RecipeUpdateResponse> {
        return this.servicesHelper.do_put<RecipeUpdateResponse, RecipeTextUpdateBody>(`${this.uri}${recipeId}/mash_steps/${mashStepId}/type`, {value: type});
    }

    public updateRecipeMashStepFloatField(recipeId: number, field: RecipeMashStepFloatField, mashStepId: number, value: number) : Promise<RecipeUpdateResponse> {
        const route = `${this.uri}${recipeId}/mash_steps/${mashStepId}/${field as string}`;
        return this.servicesHelper.do_put<RecipeUpdateResponse, RecipeFloatUpdateBody>(route, {value});
    }

    public updateRecipeMashStepIntField(recipeId: number, field: RecipeMashStepIntField, mashStepId: number, value?: number | string) : Promise<RecipeUpdateResponse> {
        const route = `${this.uri}${recipeId}/mash_steps/${mashStepId}/${field as string}`;
        return this.servicesHelper.do_put<RecipeUpdateResponse, RecipeNullableIntUpdateBody>(route, {value: value ?? ""});
    }

    public createRecipeMashStep(recipeId: number) : Promise<CreateRecipeMashStepResponse> {
        const route = `${this.uri}${recipeId}/mash_steps`;
        return this.servicesHelper.do_empty_post<CreateRecipeMashStepResponse>(route);
    }

    public deleteMashStepFromRecipe(recipeId: number, mashStepId: number): Promise<Response> {
        return this.servicesHelper.do_delete(`${this.uri}${recipeId}/mash_steps/${mashStepId}`);
    }

    public deleteHopAdditionFromRecipe(recipeId: number, hopAdditionId: number): Promise<Response> {
        return this.servicesHelper.do_delete(`${this.uri}${recipeId}/hops/${hopAdditionId}`);
    }

    public updateRecipeHopAdditionHop(recipeId: number, hopAdditionId: number, hopId: number): Promise<RecipeUpdateResponse> {
        return this.servicesHelper.do_put<RecipeUpdateResponse, RecipeHopAdditionHopBody>(`${this.uri}${recipeId}/hops/${hopAdditionId}/hop`, {hop_id: hopId});
    }

    public updateRecipeHopAdditionAlphaAcid(recipeId: number, hopAdditionId: number, alphaAcid: number): Promise<RecipeUpdateResponse> {
        return this.servicesHelper.do_put<RecipeUpdateResponse, RecipeFloatUpdateBody>(`${this.uri}${recipeId}/hops/${hopAdditionId}/alpha_acid`, {value: alphaAcid});
    }

    public updateRecipeHopAdditionAmount(recipeId: number, hopAdditionId: number, amount: number): Promise<RecipeUpdateResponse> {
        return this.servicesHelper.do_put<RecipeUpdateResponse, RecipeFloatUpdateBody>(`${this.uri}${recipeId}/hops/${hopAdditionId}/amount`, {value: amount});
    }

    public updateRecipeHopAdditionTime(recipeId: number, hopAdditionId: number, time: number): Promise<RecipeUpdateResponse> {
        return this.servicesHelper.do_put<RecipeUpdateResponse, RecipeFloatUpdateBody>(`${this.uri}${recipeId}/hops/${hopAdditionId}/time`, {value: time});
    }

    public updateRecipeHopAdditionTemperature(recipeId: number, hopAdditionId: number, temperature: number): Promise<RecipeUpdateResponse> {
        return this.servicesHelper.do_put<RecipeUpdateResponse, RecipeFloatUpdateBody>(`${this.uri}${recipeId}/hops/${hopAdditionId}/temperature`, {value: temperature});
    }

    public updateRecipeHopAdditionUse(recipeId: number, hopAdditionId: number, hopUse: HopUse): Promise<RecipeUpdateResponse> {
        return this.servicesHelper.do_put<RecipeUpdateResponse, RecipeTextUpdateBody>(`${this.uri}${recipeId}/hops/${hopAdditionId}/use`, {value: hopUse as string});
    }

    public createRecipeHopAddition(recipeId: number, hopId?: number, use?: HopUse, form?: HopForm, alphaAcid?: number): Promise<CreateRecipeHopAdditionResponse> {
        return this.servicesHelper.do_post<CreateRecipeHopAdditionResponse, AddHopAdditionBody>(
            `${this.uri}${recipeId}/hops`,
            {
                hop_id: hopId,
                use,
                form,
                alpha_acid: alphaAcid,
            });
    }

    public updateRecipeHopAdditionHopForm(recipeId: number, hopAdditionId: number, hopForm: HopForm) {
        return this.servicesHelper.do_put<RecipeUpdateResponse, RecipeTextUpdateBody>(`${this.uri}${recipeId}/hops/${hopAdditionId}/form`, {value: hopForm as string});
    }

    public updateRecipeYeastAttenuation(recipeId: number, attenuation: number) {
        return this.servicesHelper.do_put<RecipeUpdateResponse, RecipeIntUpdateBody>(`${this.uri}${recipeId}/yeast/attenuation`, {value: attenuation})
    }

    public updateRecipeYeast(recipeId: number, yeastId: number) {
        return this.servicesHelper.do_put<RecipeUpdateResponse, UpdateRecipeYeastBody>(`${this.uri}${recipeId}/yeast`, { yeast_id: yeastId});
    }

    public createRecipe(brewingSetupId: number, recipeName: string, isPublic = false): Promise<CreateRecipeResponse> {
        return this.servicesHelper.do_post<CreateRecipeResponse, CreateRecipeBody>(`${this.uri}`, {
            brewing_setup_id: brewingSetupId,
            recipe_name: recipeName,
            is_public: isPublic,
        });
    }

    public addRecipeWaterAdjustor(recipeId: number, adjustorName: string): Promise<AddWaterAdjustorResponse> {
        return this.servicesHelper.do_post<AddWaterAdjustorResponse, AddWaterAdjustorBody>(`${this.uri}${recipeId}/water_adjustors`, { name: adjustorName });
    }

    public updateRecipeWaterAdjustorAmount(recipeId: number, waterAdjustorId: number, amount: number): Promise<RecipeUpdateResponse> {
        return this.servicesHelper.do_put<RecipeUpdateResponse, RecipeIntUpdateBody>(`${this.uri}${recipeId}/water_adjustors/${waterAdjustorId}/amount`, {value: amount});
    }

    public updateRecipeWaterAdjustorTimeUsed(recipeId: number, waterAdjustorId: number, value: TimeUsed): Promise<RecipeUpdateResponse> {
        return this.servicesHelper.do_put<RecipeUpdateResponse, RecipeTextUpdateBody>(`${this.uri}${recipeId}/water_adjustors/${waterAdjustorId}/time_used`, {value: value as string});
    }

    public updateRecipeIsPublic(recipeId: number, isPublic: boolean): Promise<RecipeUpdateResponse> {
        return this.servicesHelper.do_put<RecipeUpdateResponse, RecipeBooleanUpdateBody>(`${this.uri}${recipeId}/is_public`, {
            value: isPublic,
        });
    }

    public deleteRecipeWaterAdjustor(recipeId: number, waterAdjustorId: number): Promise<Response> {
        return this.servicesHelper.do_delete(`${this.uri}${recipeId}/water_adjustors/${waterAdjustorId}`);
    }

    public updateRecipeSourceWater(recipeId: number, sourceWaterProfileId?: number) : Promise<RecipeUpdateResponse> {
        return this.servicesHelper.do_put<RecipeUpdateResponse, UpdateSourceWaterProfileBody>(`${this.uri}${recipeId}/source_water_profile`, {
            source_water_profile_id: sourceWaterProfileId,
        });
    }

    public updateRecipeBrewingSetup(recipeId: number, brewingSetupId: number) : Promise<UpdateRecipeBrewingSetupResponse> {
        return this.servicesHelper.do_put<UpdateRecipeBrewingSetupResponse, UpdateRecipeBrewingSetupBody>(`${this.uri}${recipeId}/brewing_setup`, {
            brewing_setup_id: brewingSetupId,
        });
    }

    public deleteRecipe(recipeId: number) : Promise<DeleteRecipeResponse> {
        return this.servicesHelper.do_delete_with_response<DeleteRecipeResponse>(`${this.uri}${recipeId}`);
    }

    public startBatchImport(apiKey: string): Promise<EventCreatedResponse> {
        return this.servicesHelper.do_post<EventCreatedResponse, BatchImportBody>(`${this.uri}import/brewer_friend`, {
            api_key: apiKey
        });
    }

    public getImportedRecipeURL(recipeId: number): string {
        const sessionKey = this.servicesHelper.getSessionKey();
        return `${this.uri}${recipeId}/imported?session_key=${sessionKey}`;
    }

    public addRecipeOtherIngredient(recipeId: number, ingredientName: string, timeUsed: TimeUsed): Promise<AddOtherIngredientResponse> {
        return this.servicesHelper.do_post<AddOtherIngredientResponse, AddOtherIngredientBody>(`${this.uri}${recipeId}/other_ingredients`, { name: ingredientName, time_used: timeUsed });
    }

    public updateRecipeOtherIngredientAmount(recipeId: number, otherIngredientId: number, amount: number): Promise<RecipeUpdateResponse> {
        return this.servicesHelper.do_put<RecipeUpdateResponse, RecipeIntUpdateBody>(`${this.uri}${recipeId}/other_ingredients/${otherIngredientId}/amount`, {value: amount});
    }

    public updateRecipeOtherIngredientTimeUsed(recipeId: number, otherIngredientId: number, value: TimeUsed): Promise<RecipeUpdateResponse> {
        return this.servicesHelper.do_put<RecipeUpdateResponse, RecipeTextUpdateBody>(`${this.uri}${recipeId}/other_ingredients/${otherIngredientId}/time_used`, {value: value as string});
    }

    public updateRecipeOtherIngredientMeasurementType(recipeId: number, otherIngredientId: number, value: MeasurementType): Promise<RecipeUpdateResponse> {
        return this.servicesHelper.do_put<RecipeUpdateResponse, RecipeTextUpdateBody>(`${this.uri}${recipeId}/other_ingredients/${otherIngredientId}/measurement_type`, {value: value as string});
    }

    public deleteRecipeOtherIngredient(recipeId: number, otherIngredientId: number): Promise<Response> {
        return this.servicesHelper.do_delete(`${this.uri}${recipeId}/other_ingredients/${otherIngredientId}`);
    }

    public addRecipeRating(recipeId: number, body: RecipeRatingBody): Promise<RecipeRating> {
        return this.servicesHelper.do_post<RecipeRating, RecipeRatingBody>(`${this.uri}${recipeId}/ratings`, body);
    }

    public deleteRating(ratingId: number): Promise<Response> {
        return this.servicesHelper.do_delete(`${this.uri}ratings/${ratingId}`);
    }

    public updateRating(ratingId: number, body: RecipeRatingBody): Promise<Response> {
        return this.servicesHelper.do_put(`${this.uri}ratings/${ratingId}`, body);
    }

    public exportAllRecipes(): Promise<Response> {
        return this.servicesHelper.do_empty_post(`${this.uri}export/all`);
    }
}