import * as React from "react";
import { Alert, Col, Container, Modal, Row } from "react-bootstrap";
import { Save } from "react-bootstrap-icons";

import HopIcon from "@app/assets/img/ingredients/hop.svg"
import Button from "@app/components/common/Button";
import RowFormControlComponent from "@app/components/common/RowFormControlComponent";
import { Hop } from "@app/models/hop";
import { BadRequestError } from "@app/services/models";
import ServicesHelper from "@app/services/serviceshelper";

interface Props {
    onHide: () => void,
    show: boolean,
    onAdd: () => void,
}

interface State {
    name: string,
    alphaAcid: string,
    isSubmitting: boolean,
    isCreated: boolean,
    errors?: string[],
}

class AddHopComponent extends React.Component<Props, State> {

    public constructor(props: Props) {
        super(props);

        this.state = {
            name: "",
            alphaAcid: "0",
            isSubmitting: false,
            isCreated: false,
            errors: [],
        };
    }

    public onChangeName = (evt: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            ...this.state,
            name: evt.target.value,
        });
    }

    public onChangeAlphaAcid = (evt: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            ...this.state,
            alphaAcid: evt.target.value,
        });
    }


    public onClickAddHop = (evt: React.MouseEvent<HTMLButtonElement>) => {
        const { name, alphaAcid} = this.state;
        const errors: string[] = [];

        const floatAlphaAcid = Number.parseFloat(alphaAcid);

        if(Number.isNaN(floatAlphaAcid)) {
            errors.push("Invalid Alpha Acid");
        }

        if(name.length === 0) {
            errors.push("Must specify a name");
        }

        if(errors.length > 0) {
            this.setState({
                ...this.state,
                errors,
            });

            return;
        }

        const hop: Hop = {
            name,
            alpha_acid: floatAlphaAcid,
        };

        this.setState({
            ...this.state,
            errors: [],
            isSubmitting: true,
        });

        ServicesHelper.instance().ingredients().addHop(hop).then(resp => {
            this.setState({
                ...this.state,
                isSubmitting: false,
                isCreated: true,
            });

            setTimeout(() => {
                this.props.onHide();
                this.props.onAdd();
                this.setState({
                    ...this.state,
                    isSubmitting: false,
                    isCreated: false,
                    errors: [],
                    name: "",
                    alphaAcid: "",
                });

            }, 1500);
        }).catch((error) => {
            const badRequest = error as BadRequestError;
            return badRequest;
        }).then(error => {
            if(error === undefined) {
                return;
            }

            const badRequest = error as BadRequestError;

            this.setState({
                ...this.state,
                isSubmitting: false,
                isCreated: false,
                errors: [...this.state.errors, badRequest.message],
            });
        }, err => {throw err;});
    }

    public render() {
        const { name, alphaAcid, isSubmitting, isCreated, errors} = this.state;
        const disabled = isSubmitting || isCreated;
        const { show, onHide } = this.props;

        return (
            <Modal show={show}>
                <Modal.Header>
                    <Modal.Title>
                        <img src={HopIcon} width="40px" height="40px" className="float-icon" />
                        New Hop
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container fluid>
                        <Row>
                            <Col md={{ span:8, offset:2}}>
                                {errors.length > 0 ? (
                                    <Alert variant="danger">
                                        <ul>
                                            {errors.map(err => { return (<li key={err}>{err}</li>) })}
                                        </ul>
                                    </Alert>
                                ) : null}
                                {isCreated ? <Alert variant="success">
                                                Hop created !
                                            </Alert> : null}
                                </Col>
                        </Row>
                        <RowFormControlComponent
                            label="Name"
                            value={name}
                            onChange={this.onChangeName}
                            placeholder="Hop name"
                            labelWidth={3}
                            disabled={disabled}
                        />
                        <RowFormControlComponent
                            label="Alpha Acid"
                            value={alphaAcid}
                            onChange={this.onChangeAlphaAcid}
                            placeholder="0"
                            type="number"
                            labelWidth={3}
                            disabled={disabled}
                        />
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="normal" onClick={this.onClickAddHop} disabled={disabled}>
                        <Save /> Add
                    </Button>
                    <Button variant="red" onClick={onHide}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
            );
    }
}

export default AddHopComponent;