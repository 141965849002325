

export enum SetupType {
    ThreeVessel = "three-vessel",
    BrewInABag = "brew-in-a-bag",
}

export enum ChillingMethod {
    Immersion = "immersion",
    IceBath = "ice-bath",
    NoChill = "no-chill",
    Counterflow = "counterflow",
    PlateChiller = "plate-chiller",
}

export const friendlySetupTypeString = (setupType: SetupType): string => {
    switch(setupType) {
        case SetupType.ThreeVessel:
            return "Three Vessel"
        case SetupType.BrewInABag:
            return "Brew In a Bag"
    }
}

export const friendlyChillingMethodString = (chillingMethod: ChillingMethod): string => {
    switch(chillingMethod) {
        case ChillingMethod.Immersion:
            return "Immersion"
        case ChillingMethod.IceBath:
            return "Ice Bath"
        case ChillingMethod.NoChill:
            return "No Chill"
        case ChillingMethod.Counterflow:
            return "Counterflow"
        case ChillingMethod.PlateChiller:
            return "Plate Chiller"
    }
}

export interface NewBrewingSetup {
    name: string,
    setup_type: SetupType,
    chilling_method: ChillingMethod,
    kettle_volume: number,
    default_batch_size: number,
    default_efficiency: number,
}

export interface BrewingSetup {
    id?: number,
    name: string,
    setup_type: SetupType,
    chilling_method: ChillingMethod,
    kettle_volume: number,
    mash_tun_volume: number,
    kettle_losses: number,
    grain_absorption: number,
    boil_evaporation_rate: number,
    default_boil_size: number,
    default_batch_size: number,
    default_efficiency: number,
    created_at: number,
    is_default: boolean,
}