import { action, makeObservable, observable } from 'mobx';

import { NotificationDataType, NotificationEvent } from '@app/models/notifications';
import { NotificationItem } from '@app/services/notifications';
import ServicesHelper from '@app/services/serviceshelper';
import { RootStore } from '@app/stores/rootstore';

export interface INotificationsStore {
  notifications: NotificationEvent[];
}

export class NotificationsStore implements INotificationsStore {
  private rootStore: RootStore;

  @observable notifications: NotificationEvent[];
  @observable jobNotifications: NotificationEvent[];
  @observable notificationsCount: number;

  constructor(rootStore?: RootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
    this.notifications = [];
    this.jobNotifications = [];
    this.notificationsCount = 0;
  }

  @action getInitialNotifications = (): void => {
    ServicesHelper.instance().notifications().getUnreadNotifications().then(resp => {
      const notifications: NotificationEvent[] = new Array<NotificationEvent>();

      resp.notifications.forEach((n: NotificationItem) => {
        notifications.push(new NotificationEvent({
          id: n.id.toString(),
          data: n.data,
        }));
      });
      this.notifications =  [];
      this.pushNotifications(notifications);
    }, err => { throw err; });
  }

  @action pushNotifications = (notifications: NotificationEvent[]): void => {
    notifications.forEach((n: NotificationEvent) => {
      this.pushNotification(n);
    });
  }

  @action pushNotification = (notification: NotificationEvent): void => {
    switch (notification.getType()) {
      case NotificationDataType.JobCompleted:
      case NotificationDataType.JobStarted:
        this.jobNotifications = this.jobNotifications.concat([notification])
      default:
        this.notifications = this.notifications.concat([notification]);
    }

    this.notificationsCount = this.notifications.length;
  }

  @action deleteJobNotification = (notificationId: string) => {
    this.jobNotifications = this.jobNotifications.filter(notification => notification.getId() !== notificationId);
  }

  @action deleteNotification = (notificationId: string) => {
    this.notifications = this.notifications.filter(notification => notification.getId() !== notificationId);
    this.notificationsCount = this.notifications.length;
  }

}